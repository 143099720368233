import React, { useEffect, useState, useRef } from 'react';
// import styled from 'styled-components';
import useIsMobile from '@hooks/useIsMobile';
// import { FloatButton, ConfigProvider } from 'antd';
// import { DownloadOutlined } from '@ant-design/icons';
import { animated, useSpring } from 'react-spring';
import { useGlobalProps } from '@hooks/useGlobalProps';
import { isAndroid } from '@utils/util';
import { useTranslation, } from 'react-i18next';
import NewsModal from '../home/NewsModal';
import { Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { SWITCH_ROUTES } from '@constants/routes';

const AddToHomeFloatButton = () => {
    const isMobile = useIsMobile();
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    let promptRef = useRef();

    const [selectedNews, setSelectedNews] = useState(null);
    const { i18n } = useTranslation();
    const [visible, setVisible] = useState(true);

    const { installPromptNews } = useGlobalProps();
    // const [selectedNews, setSelectedNews] = useState(null);

    const location = useLocation();

    const navigate = useNavigate();
    let jkeyRef = useRef();


    useEffect(() => {
        async function fetch() {
            const params = new URLSearchParams(location.search);
            jkeyRef.current = params.get('jkey');
        }
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function isRegisterUrl() {
        const currentURL = new URL(window.location.href); // Gets the full URL of the current window
        const pathname = currentURL.pathname; // '/rb' in your example
        const searchParams = currentURL.searchParams; // Parameters after '?' in your example

        // Check if pathname matches and 'jkey' parameter exists
        return pathname === '/rb' && searchParams.get('jkey') != null;

    }




    const animation = useSpring({
        to: {
            // opacity: visible ? 1 : 0,
            transform: visible ? 'translateY(0%)' : 'translateY(150%)',
        },
        from: {
            // opacity: 0,
            transform: 'translateY(100%)',
        },
        config: {
            mass: 2.2,
            tension: 210,
            friction: 10,
            velocity: 0.008
        }
    });

    const handleBeforeInstallPrompt = (event) => {
        // alert("handleBeforeInstallPrompt is trigger.")
        console.log(' handleBeforeInstallPrompt ')
        event.preventDefault();
        setDeferredPrompt(event);
        promptRef.current = event;
        window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
    };



    const handleAddToHomeScreen = () => {
        if (isAndroid() && deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');

                } else {

                    console.log('User dismissed the A2HS prompt');
                }

                window.addEventListener(
                    'beforeinstallprompt',
                    handleBeforeInstallPrompt
                );

            });
        } else {
            //installPromptNews
            //i18n.language
            setSelectedNews(installPromptNews[i18n.language])
        }
    };

    const redirectToRegisterPage = () => {
        setVisible(false);  // Start fade out
        setTimeout(() => setVisible(null), 3000);

        console.log(' set visible ->  ', visible)

        navigate({
            pathname: SWITCH_ROUTES.REGISTER,
            search: `?jkey=${jkeyRef.current}`,
        });
    };


    useEffect(() => {
        console.log('listen window.installPrompt ', window.installPrompt)
        if (window.installPrompt) {
            setDeferredPrompt(window.installPrompt);
            promptRef.current = window.installPrompt;

        } else {
            window.addEventListener(
                'beforeinstallprompt',
                handleBeforeInstallPrompt
            );
        }

        return () => {
            window.removeEventListener(
                'beforeinstallprompt',
                handleBeforeInstallPrompt
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.installPrompt]);

    console.log(" deferredPrompt ---->   ", deferredPrompt)
    useEffect(() => {
        if (visible && (isAndroid() && (!deferredPrompt && !promptRef.current)) && !jkeyRef.current) {

            setTimeout(() => {


                if (!deferredPrompt && !promptRef.current) {
                    setVisible(false);
                    setTimeout(() => setVisible(null), 3000);
                }
            }, 3000);
        }
    }, [deferredPrompt, visible])

    if (!isMobile) return null;

    const isRegister = isRegisterUrl();
    const iOSIsInstalled = window.navigator.standalone === true;

    if (!isRegister && iOSIsInstalled) return null;
    // if (!isMobile || (isAndroid() && !deferredPrompt) || !visible) return null;
    // if (hide) return null;
    if (visible === null) return null;

    return <>
        <Fab
            mainButtonStyles={{
                color: 'transparent',
                fontSize: 35,
                backgroundColor: 'transparent',
                boxShadow: 'none',        // Removes shadow
                border: 'none'            // Removes border
            }}
            style={{ bottom: 16, right: 16, zIndex: 799 }}
            // icon={<DownloadOutlined />}
            icon={
                <animated.div style={animation}>
                    <img
                        style={{
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Add shadow
                            borderRadius: '50%' // Optional: makes the border rounded if the image is square
                        }}
                        width='100px' height='100px' src={isRegister ? '/images/shared/fab_register.png' : '/images/shared/download_action.png'} alt='download_action' />
                </animated.div>
            }
            event={'click'}
            alwaysShowTitle={false}
            onClick={isRegister ? redirectToRegisterPage : handleAddToHomeScreen}
        >
            <NewsModal
                show={selectedNews !== null}
                onClose={() => {
                    setSelectedNews(null)
                    // setHide(true)
                    setVisible(false);  // Start fade out
                    setTimeout(() => setVisible(null), 3000);
                }}
                {...selectedNews}
            />
        </Fab >
    </>;

    // return <ConfigProvider theme={
    //     {
    //         token: {
    //             colorPrimary: globalTheme.primary,
    //             controlHeightLG: 70,
    //             fontSizeIcon: 30
    //         }
    //     }
    // }>
    //     <FloatButton type='primary' icon={
    //         <DownloadOutlined />
    //     } onClick={handleAddToHomeScreen} />
    //     <NewsModal
    //         show={selectedNews !== null}
    //         onClose={() => {
    //             setSelectedNews(null)
    //             setHide(true)
    //         }}
    //         {...selectedNews}
    //     />
    // </ConfigProvider >

};

export default AddToHomeFloatButton;

