import { setUserData } from '@api/user';
import { ColumnWrap, Column } from '@components/shared/View';
import AlertModal from '@components/shared/modals/AlertModal';
import { STATUS_CODE } from '@constants/api';
import { FONT_SIZE, PADDING, PASSWORD_MAX_LENGTH } from '@constants/numbers';
// import { SWITCH_ROUTES } from '@constants/routes';
import { useGlobalProps } from '@hooks/useGlobalProps';
import { parseSetUerDataRes } from '@utils/parseErrors';
import { validPassword } from '@utils/verify';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import UserProfileSection from '@components/profile/UserProfileSection';

const COLUMNS = [
    { title: 'old-password', name: 'passowrd' },
    { title: 'new-password', name: 'newPassowrd' },
    { title: 'confirm-password', name: 'confirmPassowrd' },
];
const INIT_ERROR = {
    passowrd: '',
    newPassowrd: '',
    confirmPassowrd: '',
};
const INIT_ALERT = {
    show: false,
    title: '',
};
export default function PasswordPage() {
    const [info, setInfo] = useState({ ...INIT_ERROR });
    const [error, setError] = useState({ ...INIT_ERROR });
    const [alert, setAlert] = useState({ ...INIT_ALERT });
    const { t } = useTranslation();
    const { user } = useGlobalProps();
    const tUser = t('user', { returnObjects: true });
    const tCommon = t('common', { returnObjects: true });
    const tModal = t('modal', { returnObjects: true });
    const tError = t('error', { returnObjects: true });

    const handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;
        setInfo((prev) => {
            return { ...prev, [name]: target.value };
        });
    };
    // const openLiveChat = () => {
    //     window.open(SWITCH_ROUTES.LIVE_CHAT)
    // }

    const submit = async () => {
        const { passowrd, newPassowrd, confirmPassowrd } = info;
        setError({ ...INIT_ERROR });
        if (!newPassowrd || !validPassword(newPassowrd)) {
            setError({ ...INIT_ERROR, newPassowrd: tUser['hint@password'] });
            return;
        }
        if (newPassowrd !== confirmPassowrd) {
            setError({
                ...INIT_ERROR,
                confirmPassowrd: tUser['hint@confirm-password'],
            });
            return;
        }
        const res = await setUserData({
            ukey: user.ukey,
            opwd: passowrd,
            npwd: newPassowrd,
        });
        if (res?.rtn === STATUS_CODE.SUCCESS) {
            setAlert({
                show: true,
                title: tCommon['common@success'],
                message: tModal['password@modify-successful'],
            });
            setInfo({ ...INIT_ERROR });
        } else if (res.rtn) {
            setAlert({
                show: true,
                title: tCommon['common@failed'],
                message: parseSetUerDataRes(tError, res),
            });
        } else {
            setAlert({
                show: true,
                title: tCommon['common@failed'],
                message: parseSetUerDataRes(tError, res),
            });
        }
    };

    return (
        <>
            <PasswordPageWrap>
                <UserProfileSection />
                <ColumnWrap className="custom-column-wrap">
                    {COLUMNS.map((c) => (
                        <Column
                            key={c.title}
                            title={tUser[`user@${c.title}`]}
                            name={c.name}
                            value={info[c.name]}
                            errorMsg={error[c.name]}
                            onChange={handleInputChange}
                            maxLength={PASSWORD_MAX_LENGTH}
                        />
                    ))}
                </ColumnWrap>
                <div className="password-button-wrap">
                    <button onClick={submit}>{tCommon['common@submit']}</button>
                    {/* <button onClick={openLiveChat}>{tCommon["rootHeader@livechat"]}</button> */}
                </div>
                <AlertModal
                    {...alert}
                    onConfirm={() => setAlert({ ...INIT_ALERT })}
                />
            </PasswordPageWrap>
        </>
    );
}

const PasswordPageWrap = styled.div`
    width: 100%;
    box-sizing: border-box;
    font-size: ${FONT_SIZE.NORMAL};
    padding: ${PADDING.USER_INFO_PAGE};
    .custom-column-wrap {
        margin-top: 20px; /* or however much space you prefer */
    }
    .password-button-wrap {
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin-top: 20px;
        padding-bottom: 5px;

        button {
            color: white !important;
        }
        button:first-child {
            width: 90px;
            background: ${({ theme }) => theme.primary};
        }
        button:nth-child(2) {
            color: black !important;
            border: ${({ theme }) => `2px solid ${theme.primary}`};
        }
    }
`;
