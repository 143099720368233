import { MAX_MOBILE_WIDTH } from '@constants/numbers';
import { useEffect, useState } from 'react';

const getIsMobileWidth = () => window.innerWidth <= MAX_MOBILE_WIDTH;
export default function useIsMobileWidth() {
    const [isMobile, setIsMobile] = useState(getIsMobileWidth());

    useEffect(() => {
        const onResize = () => {
            setIsMobile(getIsMobileWidth());
        };

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return isMobile;
}
