import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, FullWidthDivide } from '@components/shared/View';
import { useGlobalProps } from '@hooks/useGlobalProps';
import { getUserData, newUserBank, setUserBank } from '@api/user';
import { STATUS_CODE } from '@constants/api';
import { getBankOutList } from '@api/other';
import AddBankModal from '@components/bankAccount/AddBankModal';
import { parseErrorRes } from '@utils/parseErrors';
import AlertModal from '@components/shared/modals/AlertModal';
import BankTable, { parseBankRes } from '@components/bankAccount/BankTable';

const INIT_ALERT = {
    show: false,
    title: '',
}
export default function BankAccountPage() {
    const { user, setUserData } = useGlobalProps()
    const { t } = useTranslation()
    const tBank = t("bank", { returnObjects: true })
    const tCommon = t("common", { returnObjects: true })
    const tError = t("error", { returnObjects: true })

    const [openAddBankModal, setOpenAddBankModal] = useState(false);
    const [bankoutList, setBankoutList] = useState([]);
    const [bankaccounts, setBankaccounts] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [alert, setAlert] = useState({ ...INIT_ALERT });

    const getBankaccounts = async () => {
        if (user?.ukey) {
            const res = await getUserData({ ukey: user?.ukey })
            if (res.rtn === STATUS_CODE.SUCCESS && res?.acc_list && res.acc_list.length > 0) {
                setUserData(res);
                setBankaccounts(res.acc_list)
            } else {
                setBankaccounts([])
            }
        }
    }
    const bindBankaccount = async (req) => {
        const res = await setUserBank({
            ukey: user.ukey,
            bank: req?.bankcode,
            bankacc: req?.account
        })
        if (res?.rtn !== STATUS_CODE.SUCCESS) {
            setAlert({
                show: true,
                title: tCommon["common@failed"],
                message: parseErrorRes(tError, res),
            })
        } else {
            getBankaccounts()
        }
    }
    const onToggleAddBankModal = () => {
        setOpenAddBankModal((prev) => !prev);
    };
    const addBankaccount = async (req) => {
        const res = await newUserBank({
            ukey: user.ukey,
            bank: req.bank,
            banknm: req.name,
            bankacc: req.account
        })
        if (res?.rtn !== STATUS_CODE.SUCCESS) {
            setAlert({
                show: true,
                title: tCommon["common@failed"],
                message: parseErrorRes(tError, res),
            })
        } else {
            setOpenAddBankModal(false)
            getBankaccounts()
        }
    }

    useEffect(() => {
        getBankOutList().then(res => {
            if (res?.rtn === STATUS_CODE.SUCCESS && res?.bank_list) {
                setBankoutList(res.bank_list)
            } else setBankoutList([])
        })
    }, [])
    useEffect(() => {
        getBankaccounts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
    useEffect(() => {
        setDataSource(parseBankRes(bankaccounts))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankaccounts]);

    const endIdx = bankaccounts?.length
    const startIdx = endIdx === 0 ? 0 : 1
    const countDesc = tCommon["table@current-count-and-total-count"]
        ?.replace("{startIdx}", startIdx)
        ?.replace("{endIdx}", endIdx)
        ?.replace("{totalRecords}", endIdx)

    const canAdd = bankaccounts && bankaccounts.length < 10
    return (
        <BankAccountPageWrap>
            <BankTable
                dataSource={dataSource}
                tBank={tBank}
                bindBankaccount={bindBankaccount} />

            <p>{countDesc}</p>
            <FullWidthDivide />
            {canAdd && (
                <Button className="bank-add-btn" onClick={onToggleAddBankModal}>
                    {tBank['bank@add-bank']}
                </Button>
            )}
            <AddBankModal
                show={openAddBankModal}
                onToggleAddBankModal={onToggleAddBankModal}
                bankoutList={bankoutList}
                addBankaccount={addBankaccount} />
            <AlertModal {...alert} onConfirm={() => setAlert({ ...INIT_ALERT })} />
        </BankAccountPageWrap>
    );
}
const BankAccountPageWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    alignitems: center;
    justifycontent: center;
    width: 100%;
    padding: 3rem;

    .bank-add-btn {
        margin: 2rem auto;
    }
`;

// #region test data
// const TEST_DATA = [
//     //index-0, bankcode-1, bank name-2, 持卡人-3, 帳號-4, true=綁定帳號-5
//     [
//         1,
//         'MFBBMYKL',
//         'Alliance Bank',
//         'Bamboo test Account -1',
//         '12345677654321',
//         false,
//     ],
//     [
//         2,
//         'RJHIMYKL',
//         'Al-Rajhi Banking & Inv.Corp. (M) Bhd',
//         'Bamboo test Account -2',
//         '333333212119',
//         true,
//     ],
// ];
// // NOTE: 下拉顯示後面那個值 ex.Affin Bank，存前面的值call api用 ex.PHBMMYKL
// const TEST_BANK_LIST = [
//     ['PHBMMYKL', 'Affin Bank'],
//     ['AIBBMYKL', 'Affin Islamic Bank'],
//     ['AGOBMYKL', 'Agrobank'],
//     ['MFBBMYKL', 'Alliance Bank'],
//     ['ALSRMYKL', 'Alliance Islamic Bank'],
//     ['RJHIMYKL', 'Al-Rajhi Banking & Inv.Corp. (M) Bhd'],
//     ['ARBKMYKL', 'AmBank Berhad'],
//     ['AISLMYKL', 'AmIslamic Bank'],
//     ['BKKBMYKL', 'Bangkok Bank Berhad'],
//     ['BIMBMYKL', 'Bank Islam Malaysia Berhad'],
//     ['BMMBMYKL', 'Bank Muamalat Malaysia Berhad'],
//     ['BOFAMY2X', 'Bank of America'],
//     ['BKCHMYKL', 'Bank of China'],
//     ['BKRMMYKL', 'Bank Rakyat'],
//     ['BSNAMYK1', 'Bank Simpanan Nasional'],
//     ['BNPAMYKL', 'BNP Paribas'],
//     ['PCBCMYKL', 'China Construction Bank'],
//     ['CIBBMYKL', 'CIMB Bank'],
//     ['CTBBMYKL', 'CIMB Islamic Bank'],
//     ['CITIMYKL', 'Citibank'],
//     ['DEUTMYKL', 'Deutsche Bank'],
//     ['HLBBMYKL', 'Hong Leong Bank'],
//     ['HLIBMYKL', 'Hong Leong Islamic Bank'],
//     ['HMABMYKL', 'HSBC Amanah'],
//     ['HBMBMYKL', 'HSBC Bank'],
//     ['ICBKMYKL', 'Industrial & Commercial Bank of China'],
//     ['CHASMYKX', 'JP Morgan Chase Bank'],
//     ['KFHOMYKL', 'Kuwait Finance House'],
//     ['MBBEMYKL', 'Malayan Banking Berhad'],
//     ['MBISMYKL', 'Maybank Islamic Bank'],
//     ['AFBQMYKL', 'MBSB Bank Malaysia Berhad'],
//     ['MHCBMYKA', 'Mizuho Bank (M) Berhad'],
//     ['BOTKMYKX', 'MUFG Bank (Malaysia) BHD'],
//     ['OABBMYKL', 'OCBC Al-Amin Bank Berhad'],
//     ['OCBCMYKL', 'OCBC Bank'],
//     ['PBBEMYKL', 'Public Bank'],
//     ['PIBEMYK1', 'Public Islamic Bank Berhad'],
//     ['RHBBMYKL', 'RHB Bank'],
//     ['RHBAMYKL', 'RHB Islamic Bank'],
//     ['SCBLMYKX', 'Standard Chartered Bank Berhad'],
//     ['SCSRMYKK', 'Standard Chartered Saadiq Bank Berhad'],
//     ['SMBCMYKL', 'Sumitomo Mitsui Bank'],
//     ['UOVBMYKL', 'United Overseas Bank'],
// ];
// #endregion

