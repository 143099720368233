import {
    MAX_DATE_RANGES,
    QUICK_DATE_RANGE_PICKER,
} from '@components/shared/QuickTimeRangePickers';
import { covertResArrayIntoObjecMap } from '@utils/util';

export const TRANS_TYPE_ALL = 'all';
export const TRANSACTION_TYPE_OPTIONS = [
    { value: TRANS_TYPE_ALL, label: TRANS_TYPE_ALL },
    { value: 8, label: 'bank-in' },
    { value: 9, label: 'bank-out' },
    // {value: 15, label: 'aa-com'},
    { value: 18, label: 'bonus-in' },
    { value: 20, label: 'transfer' }, //+(transfer in)/-(transfer out)
    { value: 61, label: 'chat-in' },
    { value: 62, label: 'chat-out' },
    // {value: 101, label: 'game-in'},
    // {value: 102, label: 'game-out'},
];
// 時間，Type，in or out數字，餘額
export const TRANS_COLUMN_KEYS = {
    DATE: 'date',
    TYPE: 'type',
    AMOUNT: 'amount',
    BALANCE: 'balance',
    STATUS: 'status',
};

export function formatTransData(data) {
    return covertResArrayIntoObjecMap(data, {
        [TRANS_COLUMN_KEYS.DATE]: 0,
        [TRANS_COLUMN_KEYS.TYPE]: 1,
        [TRANS_COLUMN_KEYS.AMOUNT]: 2,
        [TRANS_COLUMN_KEYS.BALANCE]: 3,
        [TRANS_COLUMN_KEYS.STATUS]: 4,
    });
}

export const TRANS_MAX_DATE_RANGE = MAX_DATE_RANGES.TWO_MONTHS;
export const TRANS_QUICK_PICKERS = [
    QUICK_DATE_RANGE_PICKER.TODAY,
    QUICK_DATE_RANGE_PICKER.YESTERDAY,
    QUICK_DATE_RANGE_PICKER.THIS_WEEK,
    QUICK_DATE_RANGE_PICKER.PREV_WEEK,
    QUICK_DATE_RANGE_PICKER.THIS_MONTH,
    QUICK_DATE_RANGE_PICKER.PREV_MONTH,
];
export const DATE_FORMAT = 'YYYYMMDD';
export const TRANS_PAGE_SIZE = 5;
