import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import styled from "styled-components"
import { LOCAL_SELECTIONS } from '@constants/config';
import { ROOT_HEADER_HEIGHT, Z_INDEXS } from '@constants/numbers';

const SIMPLE_SELECT_STYLES = {
    control: base => ({
        ...base,
        height: 30,
        minHeight: 30,
        ...{ border: 'none', width: 38, background: 'transparent', overflow: 'hidden', "&:hover": { boxShadow: "none" } },
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: 30,
        padding: '0 6px',
        cursor: 'pointer',
        ...{ border: 'none', width: 38, background: 'transparent', overflow: 'hidden', "&:hover": { boxShadow: "none" } },
    }),
    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: 30,
    }),
    container: (provided, state) => ({
        ...provided,
    }),
    menu: (provided, state) => ({
        ...provided,
        marginLeft: -135,
        width: 170,
    }),
};
const SimpleLangSelector = (props) => {
    const { wrapClassname } = props
    const { i18n } = useTranslation();
    const langSelections = useMemo(() =>
        LOCAL_SELECTIONS.map(k => { return { ...k, imgPath: `/images/country/${k.value}.png` } })
        , [])
    const selectedLang = i18n.language
    const selectedOption = langSelections.filter(l => l.value === selectedLang)
    const handleLangChange = (langOption) => {
        if (langOption.value === selectedLang) return
        if (langOption.value) i18n.changeLanguage(langOption.value)
    }

    return (
        <SimpleLangSelectorWrap className={wrapClassname}>
            <ReactSelect
                className={'lang-selector'}
                styles={SIMPLE_SELECT_STYLES}
                value={selectedOption}
                options={langSelections}
                isSearchable={false}
                // menuIsOpen={true}
                onChange={handleLangChange}
                formatOptionLabel={c => (
                    <div className="lang-option">
                        <img src={c.imgPath} alt={`c-img-${c.label}`} />
                        <span>{c.label}</span>
                    </div>
                )} />
        </SimpleLangSelectorWrap>
    )
}

export default SimpleLangSelector

const SimpleLangSelectorWrap = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    height: ${ROOT_HEADER_HEIGHT}px;
    width: 38px;
    margin-right: -6px;
    background: white;
    z-index: ${Z_INDEXS.ROOT_HEADER};

    input {
        color: transparent !important;
    }

    .lang-selector {
        width: 170px;
        .lang-option {
            display: flex;
            align-items: center;
            gap: 10px;
            > img {
                height: 20px;
                width: 20px;
            }
        }
`