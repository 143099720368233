import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Primary } from '@constants/colors';

const ExpandSideBarItem = (props) => {
    const { icon, menuName, path, active, navigate, closeSidebarModal, noHover } = props;
    const onItemClick = () => {
        if(closeSidebarModal) closeSidebarModal()
        if (path) navigate(path);
    };

    return (
        <SidebarGroupWrap
            className={classNames('', { active })}
            onClick={onItemClick}
            noHover={noHover}
        >
            <div className="sb-item-wrap">
                {icon && (
                    <div>
                        <img
                            alt={menuName}
                            className="sb-itme-icon"
                            src={`/images/menu/${icon}.png`}
                        />
                    </div>
                )}
                <p>{menuName}</p>
            </div>
        </SidebarGroupWrap>
    );
};

const SidebarGroupWrap = styled.div`
    box-sizing: border-box;
    height: ${({ isOpen }) => (isOpen ? 'auto' : '60px')};
    background: ${Primary.White};
    color: ${Primary.Black};
    font-weight: 600;
    border-radius: 10px;
    margin: 10px;
    cursor: pointer;

    &.active  {
        background: ${({theme})=> theme.primary};
        color: ${Primary.White};
    }
    ${(props) => !props.noHover && `
        &:hover { 
            background: ${props.theme.primary};
            color: ${Primary.White}; 
        } 
    `}

    .sb-item-wrap {
        display: inline-flex;
        align-items: center;
        height: 60px;
        > div:first-child {
            width: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        .sb-itme-icon {
            mask-position: center;
            path {
                fill: white;
            }
        }
    }
`;

export default ExpandSideBarItem;
