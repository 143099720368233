import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import styled from 'styled-components';
import { DatePicker } from 'antd';
import { OutlineButton } from '@components/shared/View';
import { MAX_PAD_WIDTH } from '@constants/numbers';
dayjs.extend(isSameOrBefore);

const WHICH_PICKER = {
    START: 'start',
    END: 'end',
};
export const QUICK_DATE_RANGE_PICKER = {
    TODAY: 'today',
    YESTERDAY: 'yesterday',
    THIS_WEEK: 'this-week',
    PREV_WEEK: 'prev-week',
    THIS_MONTH: 'this-month',
    PREV_MONTH: 'prev-month',
};

const TODAY = dayjs();
export const MAX_DATE_RANGES = {
    TWO_MONTHS: 'two-months',
    SEVEN_DAYS: 'seven-days',
};
const QuickTimeRangePickers = (props) => {
    const {
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        maxDateRange,
        quickPickers,
    } = props;
    // const [startDate, setStartDate] = useState(TODAY.add(-2, 'month'))
    // const [endDate, setEndDate] = useState(TODAY)
    // const [maxDateRange, setMaxDateRange] = useState(maxDateRange)
    const { t } = useTranslation();
    const tCommon = t('common', { returnObjects: true });

    const onChange = (which) => (date) => {
        if (!date) return;
        if (which === WHICH_PICKER.START) {
            setStartDate(date);
            if (!date.isSame(endDate)) {
                if (endDate.diff(date) < 0) setEndDate(date);
                if (maxDateRange) {
                    switch (maxDateRange) {
                        case MAX_DATE_RANGES.TWO_MONTHS:
                            const twoMonth = date.add(2, 'month');
                            if (endDate.isAfter(twoMonth)) setEndDate(twoMonth);
                            break;
                        case MAX_DATE_RANGES.SEVEN_DAYS:
                            const sevenDays = date.add(7, 'day');
                            if (endDate.isAfter(sevenDays))
                                setEndDate(sevenDays);
                            break;
                        default:
                            break;
                    }
                }
            }
        } else if (which === WHICH_PICKER.END) setEndDate(date);
    };
    const disabledDate = (current) => {
        return current && current > TODAY.endOf('day');
    };
    const disabledEndDate = (current) => {
        if (!current) return true;
        if (current > TODAY) return true;
        if (!startDate.isSameOrBefore(current, 'day')) return true;
        if (maxDateRange) {
            switch (maxDateRange) {
                case MAX_DATE_RANGES.TWO_MONTHS:
                    if (!current.isSameOrBefore(startDate.add(2, 'month')))
                        return true;
                    break;
                case MAX_DATE_RANGES.SEVEN_DAYS:
                    if (!current.isSameOrBefore(startDate.add(7, 'day')))
                        return true;
                    break;
                default:
                    break;
            }
        }

        return false;
    };
    const setStartAndEnd = useCallback(
        (start, end) => {
            if (!start || !end) return;

            setStartDate(start);
            setEndDate(end);
        },
        [setStartDate, setEndDate]
    );
    const renderQuickPickers = useCallback(
        () =>
            quickPickers.map((k) => {
                const btnText = tCommon[`date-range@${k}`];
                let clickEvent = null;
                const today = dayjs();
                const yesterday = today.add(-1, 'day');
                switch (k) {
                    case QUICK_DATE_RANGE_PICKER.TODAY:
                        clickEvent = () => setStartAndEnd(today, today);
                        break;
                    case QUICK_DATE_RANGE_PICKER.YESTERDAY:
                        clickEvent = () => setStartAndEnd(yesterday, yesterday);
                        break;
                    case QUICK_DATE_RANGE_PICKER.THIS_WEEK:
                        clickEvent = () =>
                            setStartAndEnd(today.weekday(0), today.weekday(6));
                        break;
                    case QUICK_DATE_RANGE_PICKER.PREV_WEEK:
                        clickEvent = () =>
                            setStartAndEnd(
                                today.weekday(-7),
                                today.weekday(-1)
                            );
                        break;
                    case QUICK_DATE_RANGE_PICKER.THIS_MONTH:
                        clickEvent = () =>
                            setStartAndEnd(
                                today.startOf('month'),
                                today.endOf('month')
                            );
                        break;
                    case QUICK_DATE_RANGE_PICKER.PREV_MONTH:
                        const prevM = today.add(-1, 'month');
                        clickEvent = () =>
                            setStartAndEnd(
                                prevM.startOf('month'),
                                prevM.endOf('month')
                            );
                        break;
                    default:
                        break;
                }

                if (clickEvent)
                    return (
                        <OutlineButton key={btnText} onClick={clickEvent}>
                            {btnText}
                        </OutlineButton>
                    );
                else return null;
            }),
        [quickPickers, setStartAndEnd, tCommon]
    );

    return (
        <>
            <StartEndTimePickerWrap
                quickPickerLength={quickPickers?.length || 0}
            >
                <div className="item">
                    <p>{tCommon['date-range@start-date']}</p>
                    <DatePicker
                        className="date-picker-wrap"
                        size="large"
                        showToday={false}
                        allowClear={false}
                        disabledDate={disabledDate}
                        value={startDate}
                        onChange={onChange(WHICH_PICKER.START)}
                    />
                </div>

                <div className="item">
                    <p>{tCommon['date-range@end-date']}</p>
                    <DatePicker
                        className="date-picker-wrap"
                        size="large"
                        showToday={false}
                        allowClear={false}
                        disabledDate={disabledEndDate}
                        value={endDate}
                        onChange={onChange(WHICH_PICKER.END)}
                    />
                </div>

                {quickPickers && (
                    <div className="quick-time-range-pickers">
                        {renderQuickPickers()}
                    </div>
                )}
            </StartEndTimePickerWrap>
        </>
    );
};

export default QuickTimeRangePickers;

const StartEndTimePickerWrap = styled.div`
    display: flex;
    flex-wrap: no-wrap;
    flex-direction: row;
    gap: 15px;
    .item {
        display: flex;
        gap: 20px;
        height: 56px;
        > p {
            width: max-content;
        }
    }

    .date-picker-wrap {
        width: 20vw;
        max-width: 300px;
    }

    .quick-time-range-pickers {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        overflow: hidden;
    }

    ${({ quickPickerLength }) =>
        quickPickerLength === 6 &&
        `
        @media (max-width: 1470px) {
            flex-wrap: wrap;
        }
    `}
    ${({ quickPickerLength }) =>
        quickPickerLength === 3 &&
        `
        @media (max-width: 1100px) {
            flex-wrap: wrap;
        }
    `}
   
    @media (max-width: ${MAX_PAD_WIDTH}px) {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .item {
            justify-content: space-between;
        }
        .date-picker-wrap {
            max-width: none;
            flex: 1;
        }
    }
`;
