import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Form, Space } from 'antd';
import { getBirthInLS } from '@utils/ls';
import { FONT_SIZE } from '@constants/numbers';

const BirthdaySelect = (props) => {
    const isRegister = props?.isRegister || false;
    const isInitialEmpty = props?.isInitialEmpty || false;

    const { t } = useTranslation();
    const tUser = t('user', { returnObjects: true });
    const birthdayInLocalStorage = getBirthInLS();
    const createBirthdayOptions = (len) =>
        Array.from({ length: len }, (_, index) =>
            index < 9 ? `0${index + 1}` : `${index + 1}`
        );
    const fromItem = {
        name: 'birthday',
        label: tUser['user@birthday'],
        isSelect: true,
        childen: [
            {
                name: 'day',
                options: createBirthdayOptions(31),
                initialValue: isInitialEmpty
                    ? ''
                    : birthdayInLocalStorage.substring(0, 2),
                text: '(D) /',
            },
            {
                name: 'month',
                options: createBirthdayOptions(12),
                initialValue: isInitialEmpty
                    ? ''
                    : birthdayInLocalStorage.substring(2),
                text: '(M)',
            },
        ],
    };

    return (
        <BirthdaySelectWarp>
            <Form.Item
                name={fromItem.name}
                label={isRegister ? '' : `${fromItem.label}：`}
                className={isRegister && 'isRegister'}
            >
                <Space.Compact className="base-select">
                    {isRegister && <h4>{fromItem.label}：</h4>}
                    {fromItem.childen.map((e) => {
                        return (
                            <div key={e.name} className="base-select-item">
                                <Form.Item
                                    noStyle
                                    name={e.name}
                                    initialValue={e.initialValue}
                                >
                                    <select>
                                        <option
                                            value=""
                                            disabled
                                            hidden
                                        ></option>
                                        {e.options.map((option) => (
                                            <option value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </Form.Item>
                                <p>{e.text}</p>
                            </div>
                        );
                    })}
                </Space.Compact>
            </Form.Item>
        </BirthdaySelectWarp>
    );
};

export default BirthdaySelect;

const BirthdaySelectWarp = styled.div`
    .base-select {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        margin: 4px 0 0;

        &-item {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
            flex: 1;
            flex-basis: 48%;

            select {
                padding: 8px 11px;
                width: 100%;
                background: transparent;
                border-radius: 5px;
                font-size: ${FONT_SIZE.NORMAL};
            }

            p {
                font-size: 18px;
                font-weight: 400;
                color: #8c8c8c;
                white-space: pre;
                margin: 0 0 0 8px;
            }

            &:last-child > div {
                margin-left: 10px;
            }
        }
    }

    .base-select-item:last-child {
        margin-left: 3%;
    }

    .isRegister {
        margin-bottom: 16px !important;
        .base-select {
            margin: 10px 0 16px;
            h4 {
                width: 100%;
                font-size: 16px;
                font-weight: 400;
                color: #121212;
                margin: 0 0 4px;
            }
        }
    }
`;
