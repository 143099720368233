import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import BaseModal from 'src/components/shared/modals/BaseModal';
import { FlexDirectionConfigs } from '@constants/config';
import { FONT_SIZE, MAX_PAD_WIDTH, PASSWORD_MAX_LENGTH } from '@constants/numbers';
import { STATUS_CODE } from '@constants/api';

import { RiCloseFill } from 'react-icons/ri';
import { Button, Form, Input, Spin } from 'antd';
import Swal from 'sweetalert2'

import { requestSMSForForgotPW, forgotPasswordV2 } from '@api/user';

import AlertModal from '@components/shared/modals/AlertModal';

import { parseLoginRes } from '@utils/parseErrors';
import { useGlobalProps } from '@hooks/useGlobalProps';
import { FaMobile } from 'react-icons/fa';
import { TbLogin } from "react-icons/tb";

import { Link } from 'react-router-dom';
import { parseDoUserApplyRes } from '@utils/parseErrors';
import FullNameTexFiled from '@components/shared/FullNameTextFiled';
import { getDVCInLS } from '@utils/ls';
import BirthdaySelect from 'src/components/shared/BirthdaySelect';

const PHONE_DEFAULT_TELHD = '01';
const PHONE_DEFAULT_MIN = 0;
const PHONE_DEFAULT_MAX = 13;

const ForgotPasswordV2View = ({ onClose, onBack }) => {
    const INIT_ALERT = {
        show: false,
        title: '',
        message: '',
        children: null,
    };
    const [errorMsg, setErrorMsg] = useState('');
    const { user } = useGlobalProps();
    const { t } = useTranslation();
    const tUser = t('user', { returnObjects: true });
    const tError = t('error', { returnObjects: true });
    const stopPropagation = (e) => {
        e.stopPropagation();
    };
    const [countdown, setCountdown] = useState(0); // 倒數計時
    const [phoneValue, setPhoneValue] = useState('');

    // eslint-disable-next-line no-unused-vars
    const [isSending, setIsSending] = useState(false); // 是否正在發送驗證碼
    const [alert, setAlert] = useState({ ...INIT_ALERT });
    const tCommon = t('common', { returnObjects: true });
    const closeAlert = () => setAlert({ ...INIT_ALERT });
    const formRef = useRef(null); // Step 1: Create the ref

    const hintPhone = tUser['hint@phone']
        ? tUser['hint@phone']
            .replace('{telhd}', user?.telhd || PHONE_DEFAULT_TELHD)
            .replace('{minln}', user?.minln || PHONE_DEFAULT_MIN)
            .replace('{maxln}', user?.maxln || PHONE_DEFAULT_MAX)
        : '';

    const handleChange = (e) => {
        // const { phone } = e.target.value;
        if (e.target.name === 'phone') {
            setPhoneValue(e.target.value);
        }
    };

    // #region API
    const handleSubmit = async (values) => {

        console.log(' values ->  ', values)
        const { phone, month, day, full_name } = values;

        const req = {
            dvc: getDVCInLS(),
            tel: phone.trim(),
        };

        setErrorMsg('');


        if (user.dvc_type === 'n') {
            req.unm = full_name;
            req.bd = `${day}${month}`;
        } else if (user.dvc_type === 'b') {
            req.unm = 'aw123';
            req.bd = `${day}${month}`;
        } else {
            req.unm = 'aw123';
            req.bd = `${'01'}${'01'}`;
        }

        let res = await forgotPasswordV2(req);

        let errorMsg;
        if (res) {
            const { rtn } = res;
            if (rtn === STATUS_CODE.SUCCESS) {
                //success alert
                await Swal.fire({
                    title: tCommon['common@success'],
                    text: tUser['user@password_change_applied_successfully'],
                    icon: "success"
                });
                onBack();
            } else {
                errorMsg = parseLoginRes(tError, res);
            }
        } else {
            errorMsg = parseLoginRes(tError, null);
        }

        if (errorMsg) {
            setErrorMsg(errorMsg);
        }
    };
    // #endregion


    const validatorPhone = (_rule, value) => {
        if (!value) return Promise.reject();
        const trimmedValue = value.trim();

        const validLen = !(
            trimmedValue.length < user.minln || trimmedValue.length > user.maxln
        );
        if (user?.telhd && user?.minln && user?.maxln) {
            const telhds = user?.telhd.split(',');
            let isValid = false;
            telhds.forEach((e) => {
                if (!isValid && trimmedValue.startsWith(e) && validLen) {
                    isValid = true;
                }
            });
            if (!!isValid) return Promise.resolve();
        }

        return Promise.reject();
    };

    const handleSendVerification = async () => {
        setErrorMsg('');

        if (countdown > 0) {
            return;
        }

        if (!phoneValue) {
            setErrorMsg(tError['error@no-fill-phone']);
            return;
        }

        setIsSending(true);
        const res = await requestSMSForForgotPW({
            tel: phoneValue.trim()
        });
        setIsSending(false);

        console.log(' REQ SMS RES -->  ', res);
        if (!res || res?.rtn !== STATUS_CODE.SUCCESS) {
            if (res?.rtn === 49) {
                setErrorMsg(tError['error@limitReached']);
                return;

            } else if (res?.rtn === 77) {
                setAlert({
                    show: true,
                    title: tCommon['common@failed'],
                    message: parseDoUserApplyRes(tError, res),
                    children: null,
                    onConfirm: closeAlert,
                });
            } else {

                setAlert({
                    show: true,
                    title: tCommon['common@failed'],
                    message: parseDoUserApplyRes(tError, res),
                    children: null,
                    onConfirm: closeAlert,
                });
                return;

            }


        }
        setCountdown(120);

        const timer = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown <= 1) {
                    clearInterval(timer);
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);
    };

    const forgotPasswordform = [
        {
            name: 'phone',
            rules: [
                {
                    required: true,
                    message: hintPhone,
                    min: user?.minln || PHONE_DEFAULT_MIN,
                    max: user?.maxln || PHONE_DEFAULT_MAX,
                    whitespace: false,
                    validator: validatorPhone,
                },
            ],
            addonBefore: <FaMobile />,
            help: hintPhone,
        }
    ];

    // #endregion
    return (<BaseModal
        show={true}
        justifyContent={FlexDirectionConfigs.Center}
        alignItems={FlexDirectionConfigs.Center}
        handleOutsideClick={stopPropagation}
    >

        <ForgotPasswordContent getCodeButtonDisabled={countdown > 0}>
            <div className="login-modal-close" onClick={onClose}>
                <RiCloseFill />
            </div>


            {/* implement here */}
            <Form
                ref={formRef}
                className="card-form"
                colon={false}
                onFinish={handleSubmit}
                onChange={handleChange}
                size="large"
                autoComplete="off"
            >
                <div className="card-head">
                    <h1>{tUser['user@forgot']}</h1>
                </div>
                {forgotPasswordform.map((item) => {
                    return (
                        <Form.Item
                            key={`${item.name}${item?.initialValue}`}
                            name={item.name}
                            rules={item?.rules}
                            help={item?.help}
                            initialValue={item?.initialValue}
                            className={`register-form-${item.name}`}
                        >
                            <Input
                                name={item.name}
                                type={
                                    item?.isPassword ? 'password' : 'text'
                                }
                                addonBefore={item.addonBefore}
                                placeholder={tUser[`user@${item.name}`]}
                                disabled={item?.disabled}
                                maxLength={
                                    item?.isPassword
                                        ? PASSWORD_MAX_LENGTH
                                        : ''
                                }
                                autoComplete="new-password"
                                addonAfter={
                                    item?.isVerifiedCode && (

                                        <div
                                            onClick={isSending ? null : handleSendVerification}
                                            disabled={countdown > 0}
                                        >
                                            {isSending ?
                                                <SpinContainer>
                                                    <Spin
                                                        className='spinner'
                                                        style={{
                                                            color: 'white',
                                                            top: '25%',
                                                            left: '45%',
                                                            position: 'absolute',
                                                        }}
                                                    /> </SpinContainer>
                                                : countdown > 0
                                                    ? `${countdown} ${countdown === 1
                                                        ? tUser[
                                                        'user@second'
                                                        ]
                                                        : tUser[
                                                        'user@seconds'
                                                        ]
                                                    }`
                                                    : tUser[
                                                    'user@getVerificationCode'
                                                    ]}

                                        </div>
                                    )
                                }
                            />
                        </Form.Item>
                    );
                })}
                {user?.dvc_type === 'n' &&
                    <>
                        <FullNameTexFiled isRegister={true} isInitialEmpty={true} />
                        <BirthdaySelect isRegister={true} isInitialEmpty={true} />
                    </>}
                {user?.dvc_type === 'b' && <BirthdaySelect isRegister={true} isInitialEmpty={true} />}
                {errorMsg && <p className="red">{errorMsg}</p>}
                <Form.Item className="register-btn">
                    <Button type="primary" htmlType="submit">
                        {tUser['register@submit']}
                    </Button>
                </Form.Item>
                <div className='forgot'>
                    <Link to='#' onClick={() => { onBack() }}><TbLogin /> {tUser['user@back-to-login']} </Link>
                </div>
            </Form>

            <AlertModal {...alert} />
        </ForgotPasswordContent>
    </BaseModal>)

};

export default ForgotPasswordV2View;


const ForgotPasswordContent = styled.div`
    color: #2c2d2e;
    position: relative;
    padding: 48px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background: white;
    height: 90vh;
    width: 95vw;
    overflow-x: hidden;
    box-sizing: box-sizing;
    max-height: 650px;
    max-width: 500px;

    .register-form-smsVerificationCode {

        .ant-input-group-addon:last-child {
            cursor:  ${(props) =>
        props.getCodeButtonDisabled ? 'default' : 'pointer'};
            width:30%;
            div {
                color:#fff;
                &:link,
                &:visited,
                &:hover,
                &:active {
                    color: #fff;
                }
            }
            background-color: ${({ theme }) =>
        (props) =>
            props.getCodeButtonDisabled ? 'grey' : theme.primary};
        } 
    }

    .card-head  {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;

        h1 {
            font-size: 28px;
            font-weight: 500;
            line-height: 1.2;
            margin: 0 auto;
        }
    }

    .card-form {
        button {
            margin-top: 16px;
            width: 100%;
            background: ${({ theme }) => theme.primary} !important;
            color: #fff;
        }
    }

    .ant-input-group-addon {
        font-size: 18px;
    }

	.ant-form-item-label {
		padding-bottom: 4px;
		label {
			height: 1rem !important;
			line-height: 1rem !important;
		}
	}

    .login-modal-close {
        position: absolute;
        top: 8px;
        right: 8px;
        height: 40px;
        width: 40px;
        padding 4px;
        cursor: pointer;
        svg {
            height: 100%;
            width: 100%;
            color: #7f7f7f;
        }
    }

    .login-modal-body {
        width: 100%;

        .logo {
            margin: 0 auto 24px;
            height: 86px;
            width: 100%;
            background: ${({ theme }) => theme.primary};
            border-radius: 10px;

            @media (min-width: ${MAX_PAD_WIDTH}px) {
                height: 120px;
            }

            img {
                display: block;
                height: 100%;
                margin:0 auto;
            }
        }

        .form {
            width: 100%;
            input {
                width: -webkit-fill-available;
            }
            .ant-form-item {
                margin: 0 auto 16px;
            }
        }

        .submit {
            margin: 0 auto;
            button {
                width: 100%;
                background: ${({ theme }) => theme.primary} !important;
                color: #fff;
            }
        }


    }

    .login-modal-footer {
        text-align: center;
        padding-top: 16px;
        border-top: 1px solid #ccc;
  
        p {
            width: 100%;
            font-size: 14px;
            line-height: 21px;
            margin: 0 auto;
        }
    }

    .red {
        color: red;
        font-size: ${FONT_SIZE.NORMAL};
    }

    .forgot {
        color: inherit;
        font-size: 14px;
        margin: 0 auto 40px;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        a, a:link, a:visited {
            text-decoration: none;
            color:inherit;
        }

        a:hover {
            color: ${({ theme }) => theme.primary};
        }

        svg {
            margin-right: 5px;
        }
    }
`;

const SpinContainer = styled.div`
    textalign: center;
    position: relative;
    width: 100px;
    height: 40px;
    .spinner {
        .ant-spin-dot-item {
            background-color: white;
          }
    }
`;
