/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getMarquee } from "@api/other";
import Marquee from "react-fast-marquee";

const MarqueeView= () => {
    const [ msg , setMsg] = useState(null)
    const { i18n } = useTranslation()
    const getData = async() => {
        const res = await getMarquee(i18n.language)
        if(res && res?.[0]) setMsg(res[0])
        else setMsg(null)
    }
    useEffect(() => {
        getData();
    }, [i18n.language])

    if(!msg) return null

    const now = moment().valueOf();
    let display = now > moment(msg.startDate).valueOf()
    if(display && msg.isPeriod && msg.endDate) display = now < moment(msg.endDate).valueOf()
    if(!display) return null

    return (
        <Marquee className="top-marquee">
            <span>{msg.content}</span> 
        </Marquee>
    )
}

export default MarqueeView