import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import BaseModal from 'src/components/shared/modals/BaseModal';
import { FlexDirectionConfigs } from '@constants/config';
import { Form, Input, Spin } from 'antd';
import { useGlobalProps } from '@hooks/useGlobalProps';
import { registerV2VerifyCode } from '@api/user';
import { STATUS_CODE } from '@constants/api';
import { parseDoUserApplyRes } from '@utils/parseErrors';
import { FaMobile, FaSms } from 'react-icons/fa';
import {
    FONT_SIZE,
} from '@constants/numbers';
import AlertModal from '@components/shared/modals/AlertModal';
import { getDVCInLS } from '@utils/ls';
import { SWITCH_ROUTES } from '@constants/routes';


const PHONE_DEFAULT_TELHD = '01';
const PHONE_DEFAULT_MIN = 0;
const PHONE_DEFAULT_MAX = 13;
const INIT_ALERT = {
    show: false,
    title: '',
    message: '',
    children: null,
};

const RegisterMobileVerifyModal = ({ show, onCancel, onConfirm, message, initiaPhoneValue }) => {

    // const [isSending, setIsSending] = useState(false); // 是否正在發送驗證碼
    const [phoneValue, setPhoneValue] = useState(initiaPhoneValue);
    const [codeValue, setCodeValue] = useState('');

    const [alert, setAlert] = useState({ ...INIT_ALERT });
    const [isLoading, setIsLoading] = useState(false);
    const [countdown, setCountdown] = useState(120); // Countdown timer set to 120 seconds

    const { t } = useTranslation()
    const tCommon = t("common", { returnObjects: true })
    const tUser = t("user", { returnObjects: true })
    const tError = t('error', { returnObjects: true });
    const tModal = t('modal', { returnObjects: true });

    const closeAlert = () => setAlert({ ...INIT_ALERT });

    const { user } =
        useGlobalProps();

    const stopPropagation = (e) => {
        e.stopPropagation()
    }


    useEffect(() => {
        setPhoneValue(initiaPhoneValue)
    }, [initiaPhoneValue]);


    useEffect(() => {
        let timer;
        if (show && countdown > 0) {
            timer = setTimeout(() => setCountdown(countdown - 1), 1000);
        } else if (countdown === 0) {
            setAlert({
                show: true,
                title: tCommon['common@failed'],
                message: tError['error@cannot_get_code_and_wait_for_cs'],
                onConfirm: () => {
                    closeAlert();
                    onCancel(); // Close the modal on timeout
                },
            });
        }
        return () => clearTimeout(timer);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countdown, show]);

    const handleChange = (e) => {
        // const { phone } = e.target.value;
        if (e.target.name === 'phone') {
            setPhoneValue(e.target.value);
        }

        if (e.target.name === 'smsVerificationCode') {
            setCodeValue(e.target.value);
        }
    };


    const validatorPhone = (_rule, value) => {
        if (!value) return Promise.reject();
        const trimmedValue = value.trim();

        const validLen = !(
            trimmedValue.length < user.minln || trimmedValue.length > user.maxln
        );
        if (user?.telhd && user?.minln && user?.maxln) {
            const telhds = user?.telhd.split(',');
            let isValid = false;
            telhds.forEach((e) => {
                if (!isValid && trimmedValue.startsWith(e) && validLen) {
                    isValid = true;
                }
            });
            if (!!isValid) return Promise.resolve();
        }

        return Promise.reject();
    };


    const hintPhone = tUser['hint@phone']
        ? tUser['hint@phone']
            .replace('{telhd}', user?.telhd || PHONE_DEFAULT_TELHD)
            .replace('{minln}', user?.minln || PHONE_DEFAULT_MIN)
            .replace('{maxln}', user?.maxln || PHONE_DEFAULT_MAX)
        : '';


    const form = [

        {
            name: 'phone',
            rules: [
                {
                    required: true,
                    message: hintPhone,
                    min: user?.minln || PHONE_DEFAULT_MIN,
                    max: user?.maxln || PHONE_DEFAULT_MAX,
                    whitespace: false,
                    validator: validatorPhone,
                },
            ],
            addonBefore: <FaMobile />,
            // help: hintPhone,
            initialValue: initiaPhoneValue,
            readOnly: true
        },
        {
            name: 'smsVerificationCode',
            rules: [
                {
                    required: true,
                    message: tUser['hint@smsVerification'],
                    whitespace: false,
                    len: 6, // 假設驗證碼是 6 位數
                },
            ],
            addonBefore: <FaSms />, // 更換為合適的 icon
            help: tUser['hint@smsVerification'],
            isVerifiedCode: true,
        },

    ];
    return (
        <BaseModal show={show}
            justifyContent={FlexDirectionConfigs.Center}
            alignItems={FlexDirectionConfigs.Center}
            handleOutsideClick={stopPropagation}>
            <ConfirmContnet onClick={stopPropagation}>
                <p>{message}</p>
                <Form
                    className="register-card-form"
                    colon={false}
                    // onFinish={handleSubmit}
                    onChange={handleChange}
                    size="large"
                >
                    <p>{tModal['register@register-sms-sent']}</p>
                    <p>{tUser['user@countdown']}: {countdown} {countdown === 1 ? tUser['user@second'] : tUser['user@seconds']}</p>
                    {form.map((item) => {
                        return (
                            <Form.Item
                                key={`${item.name}${item?.initialValue}`}
                                name={item.name}
                                rules={item?.rules}
                                help={item?.help}
                                initialValue={item?.initialValue}
                                className={`register-form-${item.name}`}
                                readOnly={item.readOnly}
                            >
                                <Input
                                    name={item.name}
                                    type={
                                        item?.isPassword ? 'password' : 'text'
                                    }
                                    addonBefore={item.addonBefore}
                                    placeholder={tUser[`user@${item.name}`]}
                                    readOnly={item?.readOnly}
                                    maxLength={
                                        ''
                                    }
                                />
                            </Form.Item>
                        );
                    })}
                </Form>
                <div className='confirm-modal-btns'>
                    <button onClick={async () => {
                        console.log(' phone value  ', phoneValue)
                        if (!phoneValue) {
                            setAlert({
                                show: true,
                                title: tCommon['common@failed'],
                                message: tError['error@must_fill_mobile_number'],
                                onConfirm: closeAlert,
                            });
                            return;
                        }
                        if (!codeValue) {
                            setAlert({
                                show: true,
                                title: tCommon['common@failed'],
                                message: tError['error@must_fill_verify_code'],
                                onConfirm: closeAlert,
                            });
                            return;
                        }
                        //should send api to verify code
                        setIsLoading(true)
                        const res = await registerV2VerifyCode({
                            tel: phoneValue.trim(),
                            jc: codeValue.trim(),
                            dvc: getDVCInLS()

                        });
                        setIsLoading(false)

                        if (res && res?.rtn === STATUS_CODE.SUCCESS) {
                            // onConfirm(phoneValue);
                            setAlert({
                                show: true,
                                title: tModal['register@register-successful'],
                                children: (
                                    <RegisterSuccessfulDesc>
                                        {tModal['register@register-successful-desc']}
                                        <br />
                                        {res?.acc}
                                        <br />
                                        {res?.pwd}
                                    </RegisterSuccessfulDesc>
                                ),
                                onConfirm: () => {
                                    closeAlert();
                                    window.location.href = SWITCH_ROUTES.ROOT;
                                },
                            });


                        } else {
                            setAlert({
                                show: true,
                                title: tCommon['common@failed'],
                                message: parseDoUserApplyRes(tError, res),
                                children: null,
                                onConfirm: closeAlert,
                            });
                        }


                    }}> {isLoading ? <Spin /> : tCommon["common@confirm"]}</button>
                    {/* <button onClick={onCancel}>{tCommon["common@cancel"]}</button> */}
                </div>
            </ConfirmContnet>
            <AlertModal {...alert} />
        </BaseModal>
    );
};

export default RegisterMobileVerifyModal;

const ConfirmContnet = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: white;
    padding: 20px 40px;
    gap: 20px;
    min-width: 300px;
    >p {
        text-align: center;
        font-size: ${FONT_SIZE.ALERT_MODAL_TITLE};
        font-weight: 700;
    }
    
    .confirm-modal-btns {
        display: flex;
        justify-content: center;
        gap: 20px;
        >button {
            width: 50%;
        }
    }

    &-head {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;

        h1 {
            font-size: 28px;
            font-weight: 500;
            line-height: 1.2;
            margin: 0 auto;
        }
    }
    &-form {
        button {
            margin-top: 16px;
            width: 100%;
            background: ${({ theme }) => theme.primary} !important;
            color: #fff;
        }
    }
`;

const RegisterSuccessfulDesc = styled.p`
    color: red;
    font-size: ${FONT_SIZE.NORMAL};
    font-weight: 700;
    margin: 8px 0px;
    text-align: center;
`;