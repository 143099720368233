import React from 'react';
import styled from 'styled-components';
import { Table, ConfigProvider } from 'antd';
import { FONT_SIZE } from '@constants/numbers';
import { Button } from '@components/shared/View';
import { covertResArrayIntoObjecMap } from '@utils/util';
import useIsMobile from '@hooks/useIsMobile';

const IS_BOUND_TEXT = 'isBound';
const BANKCODE_TEXT = 'bankcode';
const BANKNAME_TEXT = 'bankname';
const BANKACCOUNT_TEXT = 'account';
const OWNER_TEXT = 'name';
const TC_LENGTH = 'tcLength';

const Empty = () => <div />;

export function parseBankRes(bankaccounts) {
    return covertResArrayIntoObjecMap(bankaccounts, {
        [BANKCODE_TEXT]: 1,
        [BANKNAME_TEXT]: 2,
        [OWNER_TEXT]: 3,
        [BANKACCOUNT_TEXT]: 4,
        [IS_BOUND_TEXT]: 5,
        [TC_LENGTH]: 6
    });
}

export default function BankTable(props) {
    const { dataSource, bindBankaccount, tBank } = props;
    const isMobile = useIsMobile();

    const renderPCAccountColumn = (text, item) => {
        return (
            <div className="bankacc-pc-account-column">
                <span>{item[BANKNAME_TEXT]}</span>
                <span>{item[BANKACCOUNT_TEXT]}</span>
            </div>
        );
    };
    const renderMobileAccountColumn = (text, item) => {
        return (
            <div className="bankacc-pc-account-column mobile-acc-column">
                <span>{item[BANKNAME_TEXT]}</span>
                <span>{item[OWNER_TEXT]}</span>
                <span>{item[BANKACCOUNT_TEXT]}</span>
            </div>
        );
    };
    const renderBoundColumn = (text, item) => {
        if (item?.isBound)
            return (
                <div className="bankaccount-primary-text">
                    {tBank['bank@bound']}
                </div>
            );
        else
            return (
                <Button onClick={() => bindBankaccount?.(item)}>
                    {tBank['bank@unbound']}
                </Button>
            );
    };

    const columns = [
        // {
        //     title: tBank['bank@bank-code'],
        //     dataIndex: 'bankcode',
        //     key: 'bankcode',
        // },
        // {
        //     title: tBank['bank@bank-name'],
        //     dataIndex: 'bankname',
        //     key: 'bankname',
        //     // NOTE: min-width
        //     width: 300,
        // },
        {
            title: tBank['bank@bank-account'],
            dataIndex: 'account',
            key: 'account',
            render: renderPCAccountColumn,
        },
        {
            title: tBank['bank@bank-owner'],
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: tBank['bank@bound'],
            dataIndex: 'bound',
            key: 'bound',
            render: renderBoundColumn,
        },
    ];
    const mobileColumns = [
        {
            title: tBank['bank@bank-account'],
            key: 'account',
            render: renderMobileAccountColumn,
        },
        {
            title: tBank['bank@bound'],
            dataIndex: 'bound',
            key: 'bound',
            render: renderBoundColumn,
        },
    ];

    return (
        <TableComponentWrap>
            <ConfigProvider renderEmpty={Empty}>
                <Table
                    className="bank-table"
                    rowKey={(r) => `${r.bankcode}+${r.account}`}
                    bordered
                    dataSource={dataSource}
                    columns={isMobile ? mobileColumns : columns}
                    pagination={false}
                />
            </ConfigProvider>
        </TableComponentWrap>
    );
}

const TableComponentWrap = styled.div`
    width: 100%;

    .bank-table {
        display: block;
    }
    .bank-table-md {
        display: block;
        p {
            margin: 4px 0;
        }
    }
    .bankacc-pc-account-column {
        display: flex;
        align-items: center;
        gap: 5px;
        > span:first-child {
            background: rgb(33, 37, 41);
            color: white;
            padding: 2px 5px;
            border-radius: 25px;
        }
    }
    .mobile-acc-column {
        flex-direction: column;
        align-items: flex-start;
    }
    .bankaccount-primary-text {
        font-size: 16px;
        font-weight: 900;
    }
    .ant-table {
        font-size: ${FONT_SIZE.NORMAL};
    }
`;

// .bank-table {
//     display: none;

//     @media (min-width: ${MAX_MOBILE_WIDTH}px) {
//         display: none;
//     }

//     @media (min-width: ${MAX_PAD_WIDTH}px) {
//         display: block;
//     }
// }

// .bank-table-md {
//     display: block;
//     @media (min-width: ${MAX_MOBILE_WIDTH}px) {
//         display: block;
//     }

//     @media (min-width: ${MAX_PAD_WIDTH}px) {
//         display: none;
//     }

//     p {
//         margin: 4px 0;
//     }
// }
