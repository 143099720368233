import React, { useEffect, useState } from 'react';
import { Outlet, useOutletContext, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import RootHeader from '@components/header/RootHeader';
import Sidebar from '@components/sidebar/Sidebar';
// import { getMyIp } from '@api/other';
// import { getDevice } from '@api/user';
import { STATUS_CODE } from '@constants/api';
import { getDVCInLS, setDVCInLS } from '@utils/ls';
import useMaintenance from '@hooks/useMaintenance';
// import MarqueeView from '@components/shared/MarqueeView';
import LoginModal from '@components/shared/modals/LoginModal';
import SharedLayout1Modal from '@components/shared/modals/SharedLayout1Modal';
import SharedLayout2Modal from '@components/shared/modals/SharedLayout2Modal';

import { getUserInfo } from '@api/user';
import { useGlobalProps } from '@hooks/useGlobalProps';
import usePermissionPages from '@hooks/usePermissionPages';
import LoggedOutModal from '@components/shared/modals/LoggedOutModal';
import AlertModal from '@components/shared/modals/AlertModal';
import { shouldDisplayBalanceError } from '@constants/config';
import PullToRefresh from 'react-simple-pull-to-refresh';
import MetaPixel from '@utils/metaPixel';
import AddToHomeFloatButton from '@components/shared/AddToHomeFloatButton';


export default function Root() {
    const [openSideBarModal, setOpenSideBarModal] = useState(false);
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [openAlertModal, setOpenAlertModal] = useState(false);

    const { user, balanceError, shareDialogLayout, defaultLanguage, getBalance, pixelCodes } = useGlobalProps();

    const { t, i18n, ready } = useTranslation();
    const tError = t('error', { returnObjects: true });
    const location = useLocation();  // Use the location hook

    useMaintenance();
    usePermissionPages();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    const onToggleSideBarModal = () => {
        setOpenSideBarModal((prev) => !prev);
    };
    const onToggleLoginModal = () => {
        setOpenLoginModal((prev) => !prev);
    };
    const init = async () => {
        const dvc = getDVCInLS();
        if (!dvc) {
            const res = await getUserInfo();
            if (res.rtn === STATUS_CODE.SUCCESS && res.device_id)
                setDVCInLS(res.device_id);
        }
    };


    const handleRefresh = async () => {
        if (user?.ukey)
            await getBalance();
    };

    useEffect(() => {
        init();
        i18n.changeLanguage(defaultLanguage)
    }, [i18n, defaultLanguage]);

    useEffect(() => {
        if (shouldDisplayBalanceError() && balanceError != null)
            setOpenAlertModal(true)
    }, [balanceError]);

    if (!ready || !i18n) return null;

    const isLogin = !!user?.ukey;
    const isHomeOrRB = location.pathname === '/' || location.pathname === '/rb'; // Check path

    return (
        <>
            {/* <MarqueeView /> */}



            <RootWrap>
                {pixelCodes && pixelCodes.map((e) => <MetaPixel code={e} />)}

                <Sidebar
                    openSideBarModal={openSideBarModal}
                    onToggleSideBarModal={onToggleSideBarModal}
                    setOpenLoginModal={setOpenLoginModal}
                    isLogin={isLogin}
                />
                <PullToRefresh className='pull-to-refresh' onRefresh={handleRefresh}>
                    <Detail>
                        <RootHeader
                            onToggleSideBarModal={onToggleSideBarModal}
                            onToggleLoginModal={onToggleLoginModal}
                            changeLanguage={changeLanguage}
                            selectedLang={i18n.language}
                            isLogin={isLogin}
                            acc={user?.acc}
                        />

                        <OutletWrap>
                            <Outlet context={{ t }} />
                        </OutletWrap>

                    </Detail>
                </PullToRefresh>

            </RootWrap >


            {isHomeOrRB && <AddToHomeFloatButton />}

            {openLoginModal && (
                <LoginModal onToggleLoginModal={onToggleLoginModal} />
            )
            }
            {shareDialogLayout === 1 && <SharedLayout1Modal />}
            {shareDialogLayout === 2 && <SharedLayout2Modal />}
            <LoggedOutModal />
            <AlertModal
                show={openAlertModal}
                title={tError['error@balance_error']}
                message={balanceError?.msg}
                onConfirm={() => {
                    console.log(' confirm ')
                    setOpenAlertModal(false);
                }}
            />
        </>
    );
}

export function useBOContext() {
    return useOutletContext();
}

const RootWrap = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: -webkit-fill-available;
    overflow: hidden;

    .pull-to-refresh {
        z-index: 11;
    }
`;
const Detail = styled.div`
    display: flex;
    /* flex: 1; */
    flex-direction: column;
    width: 100%;
    height: -webkit-fill-available;
    overflow-y: hidden;
`;

const OutletWrap = styled.div`
    padding: 0px 10px 10px 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
`;
