import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import BaseModal from 'src/components/shared/modals/BaseModal';
import { FlexDirectionConfigs } from '@constants/config';
import { FONT_SIZE } from '@constants/numbers';

const ConfirmModal = ({ show, onCancel, onConfirm, title, message, children, actionTitle }) => {
    const { t } = useTranslation()
    const tCommon = t("common", { returnObjects: true })
    const stopPropagation = (e) => {
        e.stopPropagation()
    }

    return (
        <BaseModal show={show}
            justifyContent={FlexDirectionConfigs.Center}
            alignItems={FlexDirectionConfigs.Center}
            handleOutsideClick={stopPropagation}>
            <ConfirmContnet onClick={stopPropagation}>
                {title && <p className="alert-title">{title}</p>}
                {message && <p>{message}</p>}
                {children}
                <div className='confirm-modal-btns'>
                    <button onClick={onConfirm}> {actionTitle ? actionTitle : tCommon['common@confirm']}</button>
                    <button onClick={onCancel}>{tCommon["common@cancel"]}</button>
                </div>
            </ConfirmContnet>
        </BaseModal>
    );
};

export default ConfirmModal;

const ConfirmContnet = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: white;
    padding: 20px 40px;
    gap: 20px;
    min-width: 300px;
    >p {
        text-align: center;
        font-size: ${FONT_SIZE.ALERT_MODAL_TITLE};
        font-weight: 700;
    }
    
    .confirm-modal-btns {
        display: flex;
        justify-content: center;
        gap: 20px;
        >button {
            width: 50%;
        }
    }
`;
