import React, { useCallback, useState, useContext } from 'react';
import { getWithdrawCode } from '@api/other';

// Create a context
const QRCodeContext = React.createContext();

export function QRCodeProvider({ children }) {
    const [urlCache, setUrlCache] = useState({});
    const [depositQRCodeUrl, setDepositQRCodeUrl] = useState();

    const generateQRCode = useCallback(
        async (inputUrl) => {
            if (urlCache[inputUrl]) {
                setDepositQRCodeUrl(urlCache[inputUrl]);
                return;
            }

            try {
                const resultUrl = await getWithdrawCode(inputUrl);
                // Update the cache with the new URL.
                setUrlCache((prevCache) => ({
                    ...prevCache,
                    [inputUrl]: resultUrl,
                }));

                setDepositQRCodeUrl(resultUrl);
            } catch (error) {
                console.error('Failed to process URL', error);
            }
        },
        [urlCache]
    );

    const value = {
        depositQRCodeUrl,
        generateQRCode,
    };

    return (
        <QRCodeContext.Provider value={value}>
            {children}
        </QRCodeContext.Provider>
    );
}

export function useQRCode() {
    const context = useContext(QRCodeContext);
    if (!context) {
        throw new Error('useQRCode must be used within a QRCodeProvider');
    }
    return context;
}
