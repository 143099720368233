import React from 'react';
import styled from 'styled-components';
import BaseModal from 'src/components/shared/modals/BaseModal';
import { FlexDirectionConfigs } from '@constants/config';
import { SCROLL_BAR_STYLE } from '@constants/theme';
import { MAX_PAD_WIDTH, POPUP_MIN_HEIGHT } from '@constants/numbers';

const NewsModal = (props) => {
    const { show, title, content, onClose } = props;
    const stopPropagation = (e) => {
        e.stopPropagation();
    };
    return (
        <BaseModal
            show={show}
            justifyContent={FlexDirectionConfigs.Center}
            alignItems={FlexDirectionConfigs.Center}
            handleOutsideClick={stopPropagation}
        >
            <NewsContent
                onClick={stopPropagation}
                displayHeaderBorder={title != null}
            >
                <article>
                    <div className="news-model-header">
                        <h3>{title}</h3>
                        <div className="close-icon" onClick={onClose} />
                    </div>
                    <div
                        className="news-model-body"
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                </article>
            </NewsContent>
        </BaseModal>
    );
};

export default NewsModal;

const NewsContent = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    background: white;
    height: 98%;
    width: 95vw;
    min-height: 98%;

    @media (min-width: ${MAX_PAD_WIDTH}px) {
        height: 94vh;
        width: 58.5%;
        min-width: 480px;
        max-height: 910px;
        max-width: 800px;
    }
    @media (min-height: ${POPUP_MIN_HEIGHT}px) {
        min-height: ${POPUP_MIN_HEIGHT}px;
    }

    > article {
        max-height: 100%;
        overflow: hidden;
        max-width: 100%;
        width: 100%;
        > div {
            padding: 0px 16px;
        }
        .news-model-header {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: space-between;
            border-bottom: ${(props) => {
                return props.displayHeaderBorder
                    ? '1px solid lightgray;'
                    : '0px;';
            }}
            height: 4rem;
        }

        .news-model-body {
            position: relative;
            flex: 1 1 auto;
            overflow-y: auto;
            max-height: calc(100% - 4rem);
            overflow-x: hidden;

            ${SCROLL_BAR_STYLE}
        }
    }

    .close-icon {
        position: absolute;
        right: 15px;
        cursor: pointer;
        mask-image: url('/images/shared/close.svg');
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        min-width: 18px;
        min-height: 18px;
        background: black;
    }
`;
