import React, { useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSidebarMenu } from '@hooks/useSidebarMenu';
import ExpandSideBarItem from '@components/sidebar/ExpandSideBarItem';
import CollapseSideBarItem from '@components/sidebar/CollapseSideBarItem';
import SideBarModal from '@components/shared/modals/SideBarModal';
import useIsMobile from '@hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import { IMAGE_DOMAINS } from '@constants/api';
import { getCurrentYYYYMMDDhhmmss } from '@utils/util';
import AfterLoginView from './AfterLoginView';
import { SidebarWrap } from '@components/sidebar/styles';
import { SWITCH_ROUTES } from '@constants/routes';
import { useGlobalProps } from "@hooks/useGlobalProps";

export const Sidebar = (props) => {
    const { setOpenLoginModal, isLogin } = props; //openSideBarModal, onToggleSideBarModal,
    const { isExpand } = useSidebarMenu();
    const { t } = useTranslation();
    const tCommon = t('common', { returnObjects: true });
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const location = useLocation();
    const { menuItems, gameDisplaying } = useGlobalProps()

    let jkeyRef = useRef();

    useEffect(() => {
        async function fetch() {
            const params = new URLSearchParams(location.search);
            jkeyRef.current = params.get('jkey');
        }
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const logoImgUrl = isExpand
        ? `${IMAGE_DOMAINS.LOGOS
        }/desktop-logo.png?gettime=${getCurrentYYYYMMDDhhmmss()}`
        : `${IMAGE_DOMAINS.LOGOS
        }/mobile-logo.png?gettime=${getCurrentYYYYMMDDhhmmss()}`;


    if (isMobile)
        return (
            <SideBarModal
                {...props}
                menuItems={menuItems}
                navigate={navigate}
                location={location}
                tCommon={tCommon}
            />
        );

    const showBalance = isExpand && isLogin;
    const displayLoginBtn = isExpand && !isLogin;

    return (
        <SidebarWrap isExpand={isExpand} isLogin={isLogin} gameDisplaying={gameDisplaying}>
            <div className="logo-wrap">
                <img alt="logo" src={logoImgUrl} />
                {!jkeyRef.current && displayLoginBtn && (
                    <button
                        className="login-btn"
                        onClick={() => setOpenLoginModal(true)}
                    >
                        {tCommon['common@login']}
                    </button>
                )}
                {jkeyRef.current && displayLoginBtn && (
                    <button
                        className="login-btn"
                        onClick={() => {
                            navigate({
                                pathname: SWITCH_ROUTES.REGISTER,
                                search: `?jkey=${jkeyRef.current}`,
                            });
                        }}
                    >
                        {tCommon['common@register']}
                    </button>
                )}
                {showBalance && <AfterLoginView />}
            </div>
            <>
                <div className="sidebar-items">
                    {isExpand ? (
                        <>
                            {menuItems.map((m) => (
                                <ExpandSideBarItem
                                    key={m.menuName}
                                    active={m.path === location.pathname}
                                    {...m}
                                    menuName={`${m.menuNamePrefix
                                        ? `${m.menuNamePrefix} `
                                        : ''
                                        }${tCommon[`sidebar@${m.menuName}`]}`}
                                    navigate={navigate}
                                />
                            ))}
                        </>
                    ) : (
                        <>
                            {menuItems.map((m) => (
                                <CollapseSideBarItem
                                    key={m.menuName}
                                    active={m.path === location.pathname}
                                    {...m}
                                    navigate={navigate}
                                />
                            ))}
                        </>
                    )}
                </div>
            </>
        </SidebarWrap>
    );
};

export default Sidebar;
