import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IMAGE_DOMAINS } from '@constants/api';
import useMaintenance from '@hooks/useMaintenance';
import { getCurrentYYYYMMDDhhmmss } from '@utils/util';
import { MAX_PAD_WIDTH } from '@constants/numbers';
import { MAINTENANCE_TEXT } from '@constants/config';
export default function MaintenancePage(props) {
    const { msg } = useMaintenance();
    const [displayEndTime, setDisplayEndTime] = useState(null);

    useEffect(() => {
        if (msg) {
            const { isPeriod, endDate } = msg;
            setDisplayEndTime(
                isPeriod && endDate
                    ? moment(endDate).format('YYYY-MM-DD HH:mm')
                    : null
            );
        }
    }, [msg]);

    return (
        <MaintenancePageWrap>
            <div className="maintenance-header">
                <img
                    alt="logo"
                    src={`${
                        IMAGE_DOMAINS.LOGOS
                    }/desktop-logo.png?gettime=${getCurrentYYYYMMDDhhmmss()}`}
                />
            </div>
            <div>
                <div className="maintenance-body">
                    <img alt="maintenance" src="/images/maintenance.png" />
                    {displayEndTime && (
                        <p className="desktop-time-bolder">{`~ ${displayEndTime}`}</p>
                    )}
                </div>
                <div className="maintenance-announcement">
                    系統維護公告
                    <br />
                    官網暫停使用
                    <br />
                    System Maintenance Notice
                    <br />
                    Official Website Temporarily Unavailable
                    <br />
                    {MAINTENANCE_TEXT}
                    {displayEndTime && (
                        <p className="mobile-time-bolder">{`~ ${displayEndTime}`}</p>
                    )}
                </div>
            </div>
        </MaintenancePageWrap>
    );
}

const MaintenancePageWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: -webkit-fill-available;
    background: ${({ theme }) => theme.minor};
    box-sizing: border-box;

    > div:nth-child(2) {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 10%;
        color: red;
        font-weight: 500;
        align-items: center;
        font-size: 1rem;
        line-height: 1.5rem;

        flex-direction: column;
        gap: 0px;
        > div:nth-child(2) {
            padding-top: 4%;
        }
    }
    .maintenance-header {
        height: 75px;
        background: ${({ theme }) => theme.primary};
        padding-left: 50px;
        display: flex;
        align-items: center;
        > img {
            height: 90%;
            width: auto;
        }
    }
    .maintenance-announcement {
        white-space: pre-line;
    }

    .mobile-time-bolder,
    desktop-time-bolder {
        font-weight: 900;
    }
    .desktop-time-bolder {
        display: none;
    }
    .mobile-time-bolder {
        display: block;
    }
    .maintenance-body {
        img {
            height: 20vh;
        }
    }

    @media (min-width: ${MAX_PAD_WIDTH}px) {
        > div:nth-child(2) {
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            gap: 5%;
            font-size: 1.5rem;
            line-height: 2.6rem;
        }

        .desktop-time-bolder {
            display: block;
        }
        .mobile-time-bolder {
            display: none;
        }
        .maintenance-body {
            img {
                height: auto;
            }
        }
    }
`;
