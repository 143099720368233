import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Select, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import { MAX_PAD_WIDTH } from '@constants/numbers';
import BaseModal from 'src/components/shared/modals/BaseModal';
import { FlexDirectionConfigs } from '@constants/config';
import {
    Button,
    Column,
    ColumnWrap,
    CustomColumn,
} from '@components/shared/View';

const INIT_FORM = {
    bank: '',
    account: '',
};
const Empty = () => <div />;
const BankAccountModal = ({
    show,
    onToggleAddBankModal,
    bankoutList,
    addBankaccount,
    item,
    needFullName
}) => {
    const { t } = useTranslation();
    const tBank = t('bank', { returnObjects: true });
    const tUser = t('user', { returnObjects: true });
    const tCommon = t('common', { returnObjects: true });
    const [form, setForm] = useState({ ...INIT_FORM });
    const [options, setOptions] = useState([]);
    const stopPropagation = (e) => {
        e.stopPropagation();
    };
    const handleFormatData = () => {
        const formatData = bankoutList?.map((i) => ({
            value: i[0],
            label: i[1],
        }));
        setOptions(formatData);
    };
    const handleOptionChange = (value) => {
        const name = 'bank';
        setForm((prev) => ({ ...prev, [name]: value }));
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm((prev) => ({ ...prev, [name]: value }));
    };
    const handlesubmit = async () => {
        addBankaccount(form);
    };
    const isDisbaled = () => {
        if (!form.bank || !form.account) return true;

        if (needFullName && !form.fullName) return true;

        return false;
    };

    const sharedSelect = (
        <ConfigProvider renderEmpty={Empty}>
            <Select
                className="shard-select"
                size="large"
                placeholder={tBank['bank@select']}
                onChange={handleOptionChange}
                value={form.bank}
                options={options}
            />
        </ConfigProvider>
    );

    useEffect(() => {
        if (show) setForm({ ...INIT_FORM });
        if (item) {

            const form = { id: item.id, bank: item.bankcode, account: item.account }
            if (needFullName) {
                form['fullName'] = item.name;
            }
            setForm(form);
        }
    }, [show, item, needFullName]);
    useEffect(() => {
        handleFormatData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankoutList]);

    return (
        <BaseModal
            show={show}
            justifyContent={FlexDirectionConfigs.Center}
            alignItems={FlexDirectionConfigs.Center}
            handleOutsideClick={stopPropagation}
        >
            <AddBankModalContent onClick={stopPropagation}>
                <div className="shared-model-header">
                    <h3>
                        {item
                            ? tBank['bank@change-bank']
                            : tBank['bank@add-bank']}
                    </h3>
                    <div
                        className="close-icon"
                        onClick={onToggleAddBankModal}
                    />
                </div>
                <div className="shard-modal-content">
                    <ColumnWrap className="mt-20 mb-60 pl-3">
                        <CustomColumn
                            title={tBank['bank@bank']}
                            child={sharedSelect}
                        />
                        <Column
                            title={tBank['bank@bank-code']}
                            name={'account'}
                            value={form.account}
                            onChange={handleInputChange}
                        />
                        <Column
                            title={tUser['user@full_name']}
                            name={'fullName'}
                            value={form.fullName}
                            onChange={handleInputChange}
                        />
                        <CustomColumn
                            child={
                                <Button
                                    onClick={handlesubmit}
                                    disabled={isDisbaled()}
                                >
                                    {tCommon['common@submit']}
                                </Button>
                            }
                            notRequired
                        />
                    </ColumnWrap>
                </div>
            </AddBankModalContent>
        </BaseModal>
    );
};

export default BankAccountModal;

const PC_WIDTH = 600;
const AddBankModalContent = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    background: white;
    height: auto;
    width: 95vw;
    max-height: 90%;

    @media (min-width: ${MAX_PAD_WIDTH}px) {
        height: auto;
        width: ${PC_WIDTH}px;
        min-width: ${PC_WIDTH}px;
        max-width: ${PC_WIDTH}px;
        max-height: 95vh;
        overflow-y: auto;
    }

    > div {
        padding: 0px 16px;
        width: 100%;
    }

    .shard-select {
        .ant-select-selector {
            border-width: 1px;
            border-color: #4d4d4d;
            &:hover {
                border-color: blue !important;
            }
            &:active {
                border-color: blue !important;
            }
        }
    }

    .shared-model-header {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid lightgray;
        height: 4rem;
    }
    .shard-modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem 16px;
        .shared-aff-field {
            width: 45vw;
            margin-top: 5px;
            @media (min-width: ${MAX_PAD_WIDTH}px) {
                width: 300px;
            }
        }
    }
    .close-icon {
        position: absolute;
        right: 15px;
        cursor: pointer;
        mask-image: url('/images/shared/close.svg');
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        min-width: 18px;
        min-height: 18px;
        background: black;
    }
`;
