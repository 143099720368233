import { APIS } from '@constants/api';
// import { GET_IP_DOMAIN } from "@constants/api";
import { generalFetch } from '@utils/generalFetch';
import { objectToQueryString } from '@utils/util';
// export async function getMyIp() {
//     try {
//         const response = await fetch(GET_IP_DOMAIN);
//         const data = await response.json();
//         return data.ip
//     } catch (error) {
//         console.error('Error fetching IP:', error);
//     }
//     return null
// }

export const getLiveChatUrl = (reuqest) => {
    // dvc: device token.  after login -> ukey

    return generalFetch(
        `${APIS.OTHER.LIVE_CHAT}${objectToQueryString(reuqest)}`,
        'GET'
    );
};
export const getMarquee = (lang) => {
    return generalFetch(`${APIS.OTHER.MARQUEE}?language=${lang}`, 'GET');
};
export const getBanners = (language) => {
    return generalFetch(`${APIS.OTHER.BANNERS}?language=${language}`, 'GET');
};
export const getAnnouncement = (language) => {
    return generalFetch(`${APIS.OTHER.ANNOUNCES}?language=${language}`, 'GET');
};
export const getNews = (language) => {
    return generalFetch(`${APIS.OTHER.NEWS}?language=${language}`, 'GET');
};
export const getNewsById = (id) => {
    return generalFetch(`${APIS.OTHER.NEWS}/${id}`, 'GET');
};
export const getDPolicy = (language) => {
    return generalFetch(
        `${APIS.OTHER.DEPOSIT_POLICY}?language=${language}`,
        'GET'
    );
};
export const getWPolicy = (language) => {
    return generalFetch(
        `${APIS.OTHER.WITHDRAWAL_POLICY}?language=${language}`,
        'GET'
    );
};

export const getMaintenances = () => {
    return generalFetch(`${APIS.OTHER.MAINTENANCES}?language=all`, 'GET');
};
export const getColors = () => {
    return generalFetch(`${APIS.OTHER.COLORS}`, 'GET');
};

export const getBankOutList = () => {
    return generalFetch(APIS.OTHER.GET_BANK_OUT_LIST, 'GET');
};

export const getWithdrawCode = async (url) => {
    const res = await generalFetch(
        APIS.OTHER.WITHDRAWAL_POLICY + '/qrcode',
        'POST',
        {
            Url: url,
        }
    );

    return res?.url ? decodeURI(res?.url) : null;
};
