import React, { useRef, useEffect } from 'react';
// import styled from 'styled-components';
import BaseModal from 'src/components/shared/modals/BaseModal';
import { FlexDirectionConfigs } from '@constants/config';
// import CollapseSideBarItem from '@components/sidebar/CollapseSideBarItem';
// import { MENU_LOGO_VIEW_HEIGHT, MOBILE_SIDEBAR_WIDTH_EXPAND } from '@constants/numbers';
import { IMAGE_DOMAINS } from '@constants/api';
import { getCurrentYYYYMMDDhhmmss } from '@utils/util';
import { SidebarWrap } from '@components/sidebar/styles';
import ExpandSideBarItem from '@components/sidebar/ExpandSideBarItem';
import AfterLoginView from '@components/sidebar/AfterLoginView';
import { SWITCH_ROUTES } from '@constants/routes';

const CURRENT_TIME = getCurrentYYYYMMDDhhmmss();
const SideBarModal = (props) => {
    const {
        menuItems,
        openSideBarModal,
        onToggleSideBarModal,
        setOpenLoginModal,
        navigate,
        location,
        isLogin,
        tCommon,
    } = props;
    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    let jkeyRef = useRef();
    useEffect(() => {
        async function fetch() {
            const params = new URLSearchParams(location.search);
            jkeyRef.current = params.get('jkey');
        }
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logoImgUrl = `${IMAGE_DOMAINS.LOGOS}/desktop-logo.png?gettime=${CURRENT_TIME}`;
    return (
        <BaseModal
            show={openSideBarModal}
            justifyContent={FlexDirectionConfigs.FlexStart}
            alignItems={FlexDirectionConfigs.FlexStart}
            handleOutsideClick={onToggleSideBarModal}
        >
            <SidebarWrap
                isExpand={true}
                isLogin={isLogin}
                onClick={stopPropagation}
                isMobile={true}
            >
                <div className="logo-wrap">
                    <img alt="logo" src={logoImgUrl} />
                    {!jkeyRef.current && !isLogin && (
                        <button
                            className="login-btn"
                            onClick={() => setOpenLoginModal(true)}
                        >
                            {tCommon['common@login']}
                        </button>
                    )}
                    {jkeyRef.current && !isLogin && (
                        <button
                            className="login-btn"
                            onClick={() => {
                                navigate({
                                    pathname: SWITCH_ROUTES.REGISTER,
                                    search: `?jkey=${jkeyRef.current}`,
                                });
                            }}
                        >
                            {tCommon['common@register']}
                        </button>
                    )}

                    {isLogin && <AfterLoginView />}
                </div>
                <div className="sidebar-items">
                    {menuItems.map((m) => (
                        <ExpandSideBarItem
                            key={m.menuName}
                            active={m.path === location.pathname}
                            {...m}
                            menuName={`${
                                m.menuNamePrefix ? `${m.menuNamePrefix} ` : ''
                            }${tCommon[`sidebar@${m.menuName}`]}`}
                            navigate={navigate}
                            closeSidebarModal={onToggleSideBarModal}
                        />
                    ))}
                </div>
            </SidebarWrap>
        </BaseModal>
    );
};

export default React.memo(SideBarModal);
