import { MAX_DATE_RANGES, QUICK_DATE_RANGE_PICKER } from "@components/shared/QuickTimeRangePickers"
import { covertResArrayIntoObjecMap } from "@utils/util"

export const BET_COLUMN_KEYS = {
    DATE: 'date',
    GAME_NAME: 'game-name',
    BET: 'bet',
    WIN: 'win',
    CURRENY: 'curreny',
    WINLOSS: 'winloss',
}
export function formatBetData(data) {
    let arr = covertResArrayIntoObjecMap(data, {
        [BET_COLUMN_KEYS.DATE]: 0,
        [BET_COLUMN_KEYS.GAME_NAME]: 1,
        [BET_COLUMN_KEYS.BET]: 2,
        [BET_COLUMN_KEYS.WIN]: 3,
        [BET_COLUMN_KEYS.CURRENY]: 4,
    })
    arr = arr.map(a=> {
        let winLoss
        if(`${a[BET_COLUMN_KEYS.WIN]}`.indexOf('.') !== -1 || `${a[BET_COLUMN_KEYS.BET]}`.indexOf('.') !== -1) {
            winLoss = (a[BET_COLUMN_KEYS.WIN]- a[BET_COLUMN_KEYS.BET]).toFixed(2)
        }else  winLoss = a[BET_COLUMN_KEYS.WIN]- a[BET_COLUMN_KEYS.BET]
        return {...a, [BET_COLUMN_KEYS.WINLOSS]: winLoss}})
    return arr
}

export const BET_MAX_DATE_RANGE = MAX_DATE_RANGES.SEVEN_DAYS
export const BET_QUICK_PICKERS = [
    QUICK_DATE_RANGE_PICKER.TODAY, 
    QUICK_DATE_RANGE_PICKER.YESTERDAY, 
    QUICK_DATE_RANGE_PICKER.THIS_WEEK, 
]
export const DATE_FORMAT = 'YYYYMMDD'
export const BET_PAGE_SIZE = 5
