import React, { useState, createContext, useContext, useEffect, useCallback } from 'react';
import {
    getDVCInLS,
    getJKeyInLS,
    setDVCInLS,
    setJkeyInLS,
    setNkeyInLS,
    setUkeyInLS,
    setUserRoleInLS,
} from '@utils/ls';
import {
    getUserBalance,
    getUserData,
    getUserInfo,
    getAvatarImageUrl,
} from '@api/user';
import { STATUS_CODE } from '@constants/api';
import { INTERVAL_TIMES } from '@constants/numbers';
import { NO_BALANCE_ROUTES } from '@constants/config';
import {
    getAllowUserGrading,
    getCurrencyDecimalPlace,
    getDisplayProfilePic,
    getDepositAmountOptions,
    getWithdrawAmountOptions,
    getIsLiveChatOn,
    getIsTelegramOn,
    getTelegramAccount,
    getMenuItemKeys,
    getIsWhatsappOn,
    getWhatsappAccount,
    getInstallPromptNews,
    getForgotPasswordEnabled,
    getShareDialogLayout,
    getDefaultLanguage,
    getDepositIsSemiAuto,
    getDepositQRCodeOptions,
    getPixelCodes,
    getRegisterFlowType,
    getForgotPasswordFlowType,
    getDepositStepperOptions,
    getWithdrawStepperOptions,
    getAdPageAnnouncement

} from '@api/system';

import { MENU_ITEM_LOGOUT, MENU_ITEM_HOME, CommonMenuItems } from '@constants/config';
import { getNewsById } from '@api/other';

const GlobalPropsContext = createContext({
    balance: null,
    user: null,
    setUser: () => { },
    showShardModal: false,
    setShowShardModal: () => { },
});

let interval = null;
let balanceFailureCount = 0;
const GlobalPropsProvider = ({ children }) => {
    const [globalTheme, setGlobalTheme] = useState({});
    const [balance, setBalance] = useState(null);
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [showShardModal, setShowShardModal] = useState(false);
    const [showLoggedOutModal, setShowLoggedOutModal] = useState(false);
    const [allowUserGrading, setAllowUserGrading] = useState(false);
    const [noBanner, setNoBanner] = useState(false);
    const [decimalPlace, setDecimalPlace] = useState(0);
    const [displayProfilePic, setDisplayProfilePic] = useState(false);

    const [depositAmountOptions, setDepositAmountOptions] = useState(false);


    const [depositQRCodeOptions, setDepositQRCodeOptions] = useState(false);

    const [withdrawAmountOptions, setWithdrawAmountOptions] = useState(false);

    const [mainUnit, setMainUnit] = useState('');

    const [mainUnitRaw, setMainUnitRaw] = useState('');

    const [secondUnit, setSecondUnit] = useState('');

    const [secondUnitRaw, setSecondUnitRaw] = useState('');

    const [unitRate, setUnitRate] = useState(1);


    const [avatarUrl, setAvatarUrl] = useState(null);

    const [homeActiveTab, setHomeActiveTab] = useState(0);

    const [isLiveChatOn, setIsLiveChatOn] = useState(true);

    const [isTelegramOn, setIsTelegramOn] = useState(false);

    const [telegramAccount, setTelegramAccount] = useState(null);

    const [isWhatsappOn, setIsWhatsappOn] = useState(false);

    const [whatsappAccount, setWhatsappAccount] = useState(null);

    const [installPromptNews, setInstallPromptNews] = useState(null);

    const [menuItemKeys, setMenuItemKeys] = useState([]);

    const [menuItems, setMenuItems] = useState([]);

    const [balanceError, setBalanceError] = useState(null);

    const [isSemiAutoDeposit, setIsSemiAutoDeposit] = useState(false);

    const [isForgotPasswordEnabled, setIsForgotPasswordEnabled] = useState(false);

    const [defaultLanguage, setDefaultLanguage] = useState('en');

    const [shareDialogLayout, setShareDialogLayout] = useState(1);

    const [termsNews, setTermsNews] = useState(null);

    const [pixelCodes, setPixelCodes] = useState([]);

    const [registerFlowType, setRegisterFlowType] = useState(1);

    const [forgotPasswordFlowType, setForgotPasswordFlowType] = useState(1);

    const [depositStepperOptions, setDepositStepperOptions] = useState(false);


    const [withdrawStepperOptions, setWithdrawStepperOptions] = useState(false);


    const [adPageAnnouncement, setAdPageAnnouncement] = useState({});

    const [gameDisplaying, setGameDisplaying] = useState(false);


    const isLogin = !!user?.ukey;

    const reGetuserInfo = (request) => {
        const defaultReq = {
            dvc: getDVCInLS(),
            jkey: getJKeyInLS(),
        };
        const req = request || defaultReq;
        getUserInfo(req).then((res) => {
            if (res?.rtn === STATUS_CODE.SUCCESS) {
                window.firstMissingUserError = false;
                const { rtn, msg, ...others } = res;
                setUkeyInLS(res.ukey);
                setNkeyInLS(res.nkey);
                setJkeyInLS(res.jkey || req?.jkey);
                setDVCInLS(res.device_id);
                setUserRoleInLS(res.user_role);
                setUser(others);
                getAvatarImageUrl(others.acc)
                    .then((res) => {
                        if (res.imageUrl) {
                            setAvatarUrl(res.imageUrl);
                        }
                    })
                    .catch((e) => {
                        console.log(' not found avatar');
                    });
            }
        });

        getAllowUserGrading().then((res) => {
            if (res?.value) {
                setAllowUserGrading(res?.value === '1');
            }
        });
        getCurrencyDecimalPlace().then((res) => {
            if (res?.value && !isNaN(parseInt(res?.value))) {
                setDecimalPlace(parseInt(res?.value));
            }
        });
        getDisplayProfilePic().then((res) => {
            if (res?.value) {
                setDisplayProfilePic(res?.value === '1');
            }
        });

        getDepositAmountOptions().then((res) => {
            if (res?.value) {
                setDepositAmountOptions(JSON.parse(res?.value));
            }
        });
        getWithdrawAmountOptions().then((res) => {
            if (res?.value) {
                setWithdrawAmountOptions(JSON.parse(res?.value));
            }
        });
        getIsLiveChatOn().then((res) => {
            if (res?.value) {
                setIsLiveChatOn(res?.value === '1');
            }
        });
        getIsTelegramOn().then((res) => {
            if (res?.value) {
                setIsTelegramOn(res?.value === '1');
            }
        });
        getTelegramAccount().then((res) => {
            if (res?.value) {
                setTelegramAccount(res?.value);
            }
        });
        getMenuItemKeys().then((res) => {
            if (res?.value) {
                setMenuItemKeys(JSON.parse(res?.value));
            }
        }).catch(e => { });

        getIsWhatsappOn().then((res) => {
            if (res?.value) {
                setIsWhatsappOn(res?.value === '1');
            }
        });
        getWhatsappAccount().then((res) => {
            if (res?.value) {
                setWhatsappAccount(res?.value);
            }
        });

        getInstallPromptNews().then(async (res) => {
            if (res?.value) {

                const newsMap = JSON.parse(res?.value)
                const promises = [];
                for (const lang in newsMap) {
                    const newsId = newsMap[lang];
                    promises.push(getNewsById(newsId))
                }

                const results = await Promise.allSettled(promises);
                const newsContentMap = {};
                for (const result of results) {
                    if (result.status === 'fulfilled') {
                        newsContentMap[result.value.language] = result.value
                    }
                }

                setInstallPromptNews(newsContentMap);
            }
        }).catch(e => { });;

        getForgotPasswordEnabled().then((res) => {
            if (res?.value) {
                setIsForgotPasswordEnabled(res?.value === '1');
            }
        });

        getShareDialogLayout().then((res) => {
            if (res?.value && !isNaN(parseInt(res?.value))) {
                setShareDialogLayout(parseInt(res?.value));
            }
        });

        getDefaultLanguage().then((res) => {
            if (res?.value) {
                setDefaultLanguage(res?.value);
            }
        });


        getDepositIsSemiAuto().then((res) => {
            if (res?.value) {
                setIsSemiAutoDeposit(res?.value === '1');
            }
        });

        getDepositQRCodeOptions().then((res) => {
            if (res?.value) {
                setDepositQRCodeOptions(JSON.parse(res?.value));
            }
        });

        getPixelCodes().then((res) => {
            if (res?.value) {
                setPixelCodes(JSON.parse(res?.value));
            }
        });

        getRegisterFlowType().then((res) => {
            if (res?.value && !isNaN(parseInt(res?.value))) {
                setRegisterFlowType(parseInt(res?.value));
            }
        });

        getForgotPasswordFlowType().then((res) => {
            if (res?.value && !isNaN(parseInt(res?.value))) {
                setForgotPasswordFlowType(parseInt(res?.value));
            }
        });

        getDepositStepperOptions().then((res) => {
            if (res?.value) {
                setDepositStepperOptions(JSON.parse(res?.value));
            }
        });

        getWithdrawStepperOptions().then((res) => {
            if (res?.value) {
                setWithdrawStepperOptions(JSON.parse(res?.value));
            }
        });

        getAdPageAnnouncement().then(async (res) => {
            if (res?.value) {
                const newsMap = JSON.parse(res?.value)
                const promises = [];
                for (const lang in newsMap) {
                    const newsId = newsMap[lang];
                    promises.push(getNewsById(newsId))
                }

                const results = await Promise.allSettled(promises);
                const newsContentMap = {};
                for (const result of results) {
                    if (result.status === 'fulfilled') {
                        newsContentMap[result.value.language] = result.value
                    }
                }

                const query = new URLSearchParams(window.location.search) // id=123
                if (!!query.get('jkey')) {
                    setAdPageAnnouncement(newsContentMap);
                }

            }
        });

        // const query = new URLSearchParams(window.location.search) // id=123
        // if ((IS_AW_KE || IS_BITSBET) && !!query.get('jkey')) {
        //     getNews('en').then(res => {
        //         if (!res || !Array.isArray(res)) throw Error('getNewsData error.');
        //         for (const news of res) {
        //             if (news.id === 9) {
        //                 setTermsNews(news);
        //                 break;
        //             }
        //         }
        //     });

        // }

    };


    const updateHomeActiveTab = (index) => {
        setHomeActiveTab(index);
    };

    useEffect(() => {
        window._setShowLoggedOutModal = setShowLoggedOutModal;
        window.firstMissingUserError = true;
        const dvc = getDVCInLS();
        const searchParams = new URLSearchParams(window.location.search);
        const jkey = searchParams.get('jkey');
        setJkeyInLS(jkey);
        let req = {};
        if (dvc) req.dvc = dvc;
        if (jkey) req.jkey = jkey;
        reGetuserInfo(req);
    }, []);

    useEffect(() => {
        if (depositAmountOptions && depositAmountOptions.currency) {
            setMainUnit(`(${depositAmountOptions.currency})`);
            setMainUnitRaw(depositAmountOptions.currency)
        } else if (withdrawAmountOptions && withdrawAmountOptions.currency) {
            setMainUnit(`(${withdrawAmountOptions.currency})`);
            setMainUnitRaw(withdrawAmountOptions.currency)
        }
        if (depositAmountOptions && depositAmountOptions.pointUnit) {
            setSecondUnit(`(${depositAmountOptions.pointUnit})`);
            setSecondUnitRaw(depositAmountOptions.pointUnit)
        } else if (withdrawAmountOptions && withdrawAmountOptions.pointUnit) {
            setSecondUnit(`(${withdrawAmountOptions.pointUnit})`);
            setSecondUnitRaw(withdrawAmountOptions.pointUnit)
        }
        if (depositAmountOptions && depositAmountOptions.rate) {
            setUnitRate(depositAmountOptions.rate)

        } else if (withdrawAmountOptions && withdrawAmountOptions.rate) {
            setUnitRate(withdrawAmountOptions.rate)
        }

    }, [depositAmountOptions, withdrawAmountOptions]);

    const getBalance = useCallback(async () => {
        if (NO_BALANCE_ROUTES.includes(window.location.pathname))
            return;
        try {
            let res = await getUserBalance({ ukey: user.ukey });
            if (res?.rtn === STATUS_CODE.SUCCESS) {
                balanceFailureCount = 0;
                // setBalanceError(null);
                setBalanceError(null);
                setBalance(res);
                if (interval) {
                    clearInterval(interval);
                    interval = null;
                }
            } else if (res?.rtn === STATUS_CODE.TIMEOUT) {
                if (balanceFailureCount > 2) {
                    setBalanceError({ ...res, msg: `${res?.msg}. Error Code : ${res?.rtn}` });
                    if (interval) {
                        clearInterval(interval);
                        interval = null;
                    }
                }
                balanceFailureCount++;
                if (!interval)
                    interval = setInterval(getBalance, INTERVAL_TIMES.GET_BALANCE);

            } else if (res?.rtn === STATUS_CODE.MISSING_USER) {
                if (interval) {
                    clearInterval(interval);
                    interval = null;
                }
            } else {
                setBalanceError({ ...res, msg: `${res?.msg}. Error Code : ${res?.rtn}` });
            }
        } catch (e) {
            // setBalanceError({ msg: `${e.toString()}` });
        }
    }, [user]);

    // const getBalance = async () => {

    //     if (NO_BALANCE_ROUTES.includes(window.location.pathname))
    //         return;
    //     try {
    //         let res = await getUserBalance({ ukey: user.ukey });
    //         if (res?.rtn === STATUS_CODE.SUCCESS) {
    //             balanceFailureCount = 0;
    //             // setBalanceError(null);
    //             setBalanceError(null);
    //             setBalance(res);
    //         } else if (res?.rtn === STATUS_CODE.TIMEOUT) {
    //             if (balanceFailureCount > 2) {
    //                 setBalanceError({ ...res, msg: `${res?.msg}. Error Code : ${res?.rtn}` });
    //             }
    //             balanceFailureCount++;
    //         } else if (res?.rtn === STATUS_CODE.MISSING_USER) {
    //             if (interval) {
    //                 clearInterval(interval);
    //                 interval = null;
    //             }
    //         } else {
    //             setBalanceError({ ...res, msg: `${res?.msg}. Error Code : ${res?.rtn}` });
    //         }
    //     } catch (e) {
    //         // setBalanceError({ msg: `${e.toString()}` });
    //     }
    // };


    useEffect(() => {
        if (user?.ukey) {
            getUserData({ ukey: user.ukey }).then((r) => {
                if (r?.rtn === STATUS_CODE.SUCCESS) setUserData(r);
                else setUserData({});
            });

            getBalance();
            // interval = setInterval(getBalance, INTERVAL_TIMES.GET_BALANCE);
            return () => {
                if (interval) {
                    clearInterval(interval);
                    interval = null;
                }
            };
        } else {
            if (interval) {
                clearInterval(interval);
                interval = null;
            }
        }
    }, [user, getBalance]);

    useEffect(() => {
        if (menuItemKeys) {
            let keys = menuItemKeys;
            if (user && user.io_yn !== 1) {
                keys = keys.filter(e => (e !== 'deposit' && e !== 'withdrawal'));
            }

            const displayMenuItems = keys.map((key) => {
                const item = CommonMenuItems[key];
                if (noBanner) {
                    if (item.path.indexOf('nobanner') < 0) {
                        item.path = item.path + window.location.search;
                    }
                }
                return item;
            })
            const items = [{ ...MENU_ITEM_HOME, path: noBanner ? '/' + window.location.search : '/' }, ...displayMenuItems];

            setMenuItems(isLogin
                ? [...items, { ...MENU_ITEM_LOGOUT, path: noBanner ? MENU_ITEM_LOGOUT.path + window.location.search : MENU_ITEM_LOGOUT.path }]
                : items.filter((m) => !m?.needLogin))
        }

    }, [menuItemKeys, isLogin, noBanner, user]);

    const value = {
        balance,
        user,
        setUser,
        userData,
        setUserData,
        showShardModal,
        setShowShardModal,
        showLoggedOutModal,
        setShowLoggedOutModal,
        reGetuserInfo,
        allowUserGrading,
        decimalPlace,
        displayProfilePic,
        homeActiveTab,
        updateHomeActiveTab,
        avatarUrl,
        setAvatarUrl,
        depositAmountOptions,
        withdrawAmountOptions,
        mainUnit,
        mainUnitRaw,
        secondUnit,
        secondUnitRaw,
        unitRate,
        isLiveChatOn,
        isTelegramOn,
        telegramAccount,
        menuItems,
        balanceError,
        noBanner,
        setNoBanner,
        getBalance,
        isSemiAutoDeposit,
        globalTheme,
        setGlobalTheme,
        isWhatsappOn,
        whatsappAccount,
        installPromptNews,
        isForgotPasswordEnabled,
        shareDialogLayout,
        defaultLanguage,
        termsNews,
        setTermsNews,
        depositQRCodeOptions,
        pixelCodes,
        registerFlowType,
        forgotPasswordFlowType,
        depositStepperOptions,
        withdrawStepperOptions,
        adPageAnnouncement,
        setAdPageAnnouncement,
        gameDisplaying,
        setGameDisplaying
    };

    return (
        <GlobalPropsContext.Provider value={value}>
            {children}
        </GlobalPropsContext.Provider>
    );
};



export const useGlobalProps = () => useContext(GlobalPropsContext);

export default GlobalPropsProvider;
