import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Button as AntdButton } from 'antd';
import AffiliateOverview from '@components/shared/AffiliateOverview';
import { Button, PaymentPageWrap } from '@components/shared/View';
import QuickTimeRangePickers from '@components/shared/QuickTimeRangePickers';
import DetailsModal from '@components/affiliateLog/DetailsModal';
import styled from 'styled-components';
import {
    AFF_COLUMN_KEYS,
    AFF_MAX_DATE_RANGE,
    AFF_PAGE_SIZE,
    AFF_QUICK_PICKERS,
    DATE_FORMAT,
    formatDayData,
    formatEachDayDetail,
    setAffRowKey,
} from '@components/affiliateLog/configAndFunc';
import PaginationTable, {
    renderPagination,
} from '@components/shared/PaginationTable';
import { useGlobalProps } from '@hooks/useGlobalProps';
import { getAday, getAdetail } from '@api/user';
import { STATUS_CODE } from '@constants/api';
import { parseErrorRes } from '@utils/parseErrors';
import AlertModal from '@components/shared/modals/AlertModal';
import { UserRoleEnum } from '@constants/config';
import { displayAmountWithDecimalPlace } from '@utils/util';

const TODAY = dayjs();
const INIT_ALERT = {
    show: false,
    title: '',
};
export default function AffiliateLogPage() {
    const [startDate, setStartDate] = useState(TODAY);
    const [endDate, setEndDate] = useState(TODAY);
    const [dayData, setDayData] = useState([]);
    const [dayDetailData, setDayDetailData] = useState({});
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    const [displayDetailData, setDisplayDetailData] = useState({}); // title, details
    const [loading, setLoading] = useState(false);
    const [detailLoading, setDetailLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [alert, setAlert] = useState({ ...INIT_ALERT });
    const { user, allowUserGrading, mainUnit, decimalPlace, getBalance } = useGlobalProps();
    const { t } = useTranslation();
    const tCommon = t('common', { returnObjects: true });
    const tAffiliate = t('affiliate', { returnObjects: true });
    const tError = t('error', { returnObjects: true });

    const onOpenDetailsModal = async (item) => {
        const date = item[AFF_COLUMN_KEYS.DATE];
        setOpenDetailsModal(true);
        if (dayDetailData.hasOwnProperty(item[date])) {
            setDisplayDetailData({
                date,
                details: dayDetailData[item[date]],
            });
        } else {
            setDetailLoading(true);
            // const failedRes = {"rtn":123,"msg":"Error test.","page_index":0,"rec_count":0,"rec_list":null}
            const res = await getAdetail({
                ukey: user.ukey,
                date: item[AFF_COLUMN_KEYS.DATE],
            });
            if (res?.rtn === STATUS_CODE.SUCCESS) {
                const detail = formatEachDayDetail(res?.user_day);
                setDayDetailData({ ...dayDetailData, [date]: detail });
                setDisplayDetailData({
                    date,
                    details: detail,
                });
            } else {
                setOpenDetailsModal(false);
                setAlert({
                    show: true,
                    title: tCommon['common@failed'],
                    message: parseErrorRes(tError, res),
                });
            }
            setDetailLoading(false);
        }
    };
    const onCloseDetailsModal = () => {
        setDetailLoading(false);
        setOpenDetailsModal(false);
        setDisplayDetailData({});
    };
    const renderDetailsColumn = (_text, item) => {
        return (
            <AntdButton onClick={() => onOpenDetailsModal(item)}>
                {tAffiliate['affiliate@detail']}
            </AntdButton>
        );
    };

    const columns = [
        {
            title: tAffiliate['affiliate@date'],
            dataIndex: 'date',
            key: 'date',
            width: '20%',
            // align: 'center'
        },
        {
            title: tAffiliate['affiliate@people-count'],
            dataIndex: 'people-count',
            key: 'people-count',
            // align: 'right'
        },
        {
            title: `${tAffiliate['affiliate@total-amount']}${mainUnit && ` ${mainUnit}`
                }`,
            dataIndex: 'total-amount',
            key: 'total-amount',
            // align: 'right'
            render: (text, _) => <>{displayAmountWithDecimalPlace(
                Number(text),
                decimalPlace
            )}</>
        },
        {
            title: tAffiliate['affiliate@detail'],
            dataIndex: 'details',
            key: 'details',
            // align: 'center',
            render: renderDetailsColumn,
        },
    ];

    const getColumns = () => {
        if (allowUserGrading && user.user_role === UserRoleEnum.L1) {
            columns.splice(1, 1);
        }

        return columns;
    };

    const paginationConfig = {
        current: currentPage,
        pageSize: AFF_PAGE_SIZE,
        simple: true,
        showLessItems: true,
        defaultPageSize: AFF_PAGE_SIZE,
        showSizeChanger: false,
        // pageSizeOptions: ['5', '10', '20', '30', '50'],
        itemRender: renderPagination(tCommon, currentPage),
    };
    const onPageChange = (pagination, _filters, _sorter, _extra) => {
        const { current } = pagination; //, pageSize
        setCurrentPage(current);
    };
    // const totalPage = Math.ceil(dayData?.length/AFF_PAGE_SIZE)
    // const setPage = (page) => {
    //     if(page > totalPage) return;
    //     else setCurrentPage(page);
    // }
    const handlesubmit = async () => {
        setLoading(true);
        const req = {
            ukey: user.ukey,
            std: startDate.format(DATE_FORMAT),
            endd: endDate.format(DATE_FORMAT),
        };
        // const failedRes = {"rtn":123,"msg":"Error test.","page_index":0,"rec_count":0,"rec_list":null}
        const res = await getAday(req);
        if (res?.rtn === STATUS_CODE.SUCCESS) {
            if (res?.user_daily) setDayData(formatDayData(res.user_daily));
            else setDayData([]);
        } else {
            setAlert({
                show: true,
                title: tCommon['common@failed'],
                message: parseErrorRes(tError, res),
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        if (user?.ukey) handlesubmit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (user?.ukey) getBalance();
    }, [user, getBalance]);

    return (
        <AffiliateLogPageWrap>
            <AffiliateOverview />
            <QuickTimeRangePickers
                maxDateRange={AFF_MAX_DATE_RANGE}
                quickPickers={AFF_QUICK_PICKERS}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
            />

            <div className="btn-center-wrap mt-20">
                <Button onClick={handlesubmit}>
                    {tCommon['common@submit']}
                </Button>
            </div>

            <PaginationTable
                tCommon={tCommon}
                rowKey={setAffRowKey}
                dataSource={dayData}
                columns={getColumns()}
                pagination={paginationConfig}
                onChange={onPageChange}
                currentPage={currentPage}
                loading={loading}
            />

            <DetailsModal
                show={openDetailsModal}
                onClose={onCloseDetailsModal}
                loading={detailLoading}
                displayDetailData={displayDetailData}
            />

            <AlertModal
                {...alert}
                onConfirm={() => setAlert({ ...INIT_ALERT })}
            />
        </AffiliateLogPageWrap>
    );
}

const AffiliateLogPageWrap = styled(PaymentPageWrap)`
    .btn-center-wrap {
        display: flex;
        // justify-content:center;
    }

    table {
        margin: 0 0 3rem 0;
    }
`;
// //欄位名稱是： [日期, 幾個人, 總貢獻金額]
// const TEST_DATA_EACH_DAY = [["20230516",4,15359.60],["20230510",3,2847.18],["20230503",3,8380.00],["20230502",3,12164.00],["20230425",4,6300.25],["20230424",4,3999.00],["20230419",4,2056.00],["20230418",4,2052.00],["20230417",3,2156.00],["20230416", 1, 2056.00],["20230415", 11, 56.00],["20230414",1,956.00],["20230413",4,2056.00],["20230412",4,2056.00],["20230411",1,2056.00],["20230410",4, 56.00],["20230409",14,12056.00]]

// -> 處理完後的資料會是長這樣 [{層數: "l 1", 比率: "×8‰", 使用者: "TT234144A", 總下注金額: 225200.00, 總紅利金額: 1801.60}, .....]
// const TEST_DATA_EACH_DAY_DETAIL = [[0,"L0","8‰","MY234141B",1036.00,8.29],[1,"L1","3‰","MY234142A",1598.00,4.79],[2,"L1","3‰","MY234152A",736.00,2.21],[3,"L2","1‰(MY234142A)","MY234143A",7940.00,7.94],[4,"L2","1‰(MY234152A)","MY234153A",1134.00,1.13],[5,"L2","1‰(MY234142A)","MY234163A",508.00,0.51]]
