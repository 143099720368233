import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import BaseModal from 'src/components/shared/modals/BaseModal';
import { FlexDirectionConfigs } from '@constants/config';
import { FONT_SIZE, MAX_PAD_WIDTH, PASSWORD_MAX_LENGTH } from '@constants/numbers';
import { STATUS_CODE } from '@constants/api';

// import { RiCloseFill } from 'react-icons/ri';
import { Button, Form, Input } from 'antd';
// import Swal from 'sweetalert2'

import AlertModal from '@components/shared/modals/AlertModal';

import { useGlobalProps } from '@hooks/useGlobalProps';
import { FaLock, FaCheckDouble } from 'react-icons/fa';

import { validPassword } from '@utils/verify';
import { parseSetUerDataRes } from '@utils/parseErrors';
import { setUserData } from '@api/user';

const ChangePasswordModal = ({ show, oldPw, onClose, onBack }) => {
    const INIT_ALERT = {
        show: false,
        title: '',
        message: '',
        children: null,
    };
    const [errorMsg, setErrorMsg] = useState('');
    const { user } = useGlobalProps();
    const { t } = useTranslation();
    const tUser = t('user', { returnObjects: true });
    const tError = t('error', { returnObjects: true });
    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const [alert, setAlert] = useState({ ...INIT_ALERT });
    const tCommon = t('common', { returnObjects: true });
    const formRef = useRef(null); // Step 1: Create the ref
    const tModal = t('modal', { returnObjects: true });


    // #region API
    const handleSubmit = async (values) => {

        const { confirm_password } = values;
        setErrorMsg('');
        const res = await setUserData({
            ukey: user.ukey,
            opwd: oldPw,
            npwd: confirm_password,
        });
        if (res?.rtn === STATUS_CODE.SUCCESS) {
            setAlert({
                show: true,
                title: tCommon['common@success'],
                message: tModal['password@modify-successful'],
            });
        } else if (res.rtn) {
            setAlert({
                show: true,
                title: tCommon['common@failed'],
                message: parseSetUerDataRes(tError, res),
            });
        } else {
            setAlert({
                show: true,
                title: tCommon['common@failed'],
                message: parseSetUerDataRes(tError, res),
            });
        }
    };
    // #endregion

    // #region form config
    const validatorPassword = (_rule, value) => {
        if (!value) return Promise.reject();
        if (validPassword(value)) return Promise.resolve();
        return Promise.reject();
    };

    const forgotPasswordform = [
        {
            name: 'new_password',
            rules: [
                {
                    required: true,
                    message: tUser['hint@password'],
                    min: 6,
                    max: PASSWORD_MAX_LENGTH,
                    whitespace: false,
                    validator: validatorPassword,
                },
            ],
            addonBefore: <FaLock />,
            help: tUser['hint@password'],
            isPassword: true,
        },
        {
            name: 'confirm_password',
            rules: [
                {
                    required: true,
                    message: tError['error@passwords-do-not-match'],
                    min: 6,
                    max: PASSWORD_MAX_LENGTH,
                    whitespace: false,
                    dependencies: ['new_password'],
                    validator: (_, value, callback) => {
                        if (!value) return Promise.reject();
                        const form = formRef.current; // Access the form instance
                        if (value && value !== form.getFieldValue('new_password')) {
                            return Promise.reject();
                        } else {
                            return Promise.resolve();
                        }
                    },

                },
            ],
            addonBefore: <FaCheckDouble />,
            isPassword: true,
        },
    ];

    // #endregion
    return (<BaseModal
        show={show}
        justifyContent={FlexDirectionConfigs.Center}
        alignItems={FlexDirectionConfigs.Center}
        handleOutsideClick={stopPropagation}
    >

        <ForgotPasswordContent >
            {/* <div className="login-modal-close" onClick={onClose}>
                <RiCloseFill />
            </div> */}


            {/* implement here */}
            <Form
                ref={formRef}
                className="card-form"
                colon={false}
                onFinish={handleSubmit}

                size="large"
                autoComplete="off"
            >
                <div className="card-head">
                    <h1>{tUser['user@change_password']}</h1>
                </div>
                {forgotPasswordform.map((item) => {

                    return (
                        <Form.Item
                            key={`${item.name}${item?.initialValue}`}
                            name={item.name}
                            rules={item?.rules}
                            help={item?.help}
                            initialValue={item?.initialValue}
                            className={`register-form-${item.name}`}
                        >
                            <Input
                                name={item.name}
                                type={
                                    item?.isPassword ? 'password' : 'text'
                                }
                                addonBefore={item.addonBefore}
                                placeholder={tUser[`user@${item.name}`]}
                                disabled={item?.disabled}
                                maxLength={
                                    item?.isPassword
                                        ? PASSWORD_MAX_LENGTH
                                        : ''
                                }
                                autoComplete="new-password"
                            />
                        </Form.Item>
                    );
                })}
                {errorMsg && <p className="red">{errorMsg}</p>}
                <Form.Item className="register-btn">
                    <Button type="primary" htmlType="submit">
                        {tUser['register@submit']}
                    </Button>
                </Form.Item>
            </Form>

            <AlertModal {...alert} onConfirm={() => {
                onClose()
            }} />
        </ForgotPasswordContent>
    </BaseModal>)

};

export default ChangePasswordModal;


const ForgotPasswordContent = styled.div`
    color: #2c2d2e;
    position: relative;
    padding: 48px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background: white;
    height: 90vh;
    width: 95vw;
    overflow-x: hidden;
    box-sizing: box-sizing;
    max-height: 650px;
    max-width: 500px;

    .register-form-smsVerificationCode {

        .ant-input-group-addon:last-child {
            cursor:  ${(props) =>
        props.getCodeButtonDisabled ? 'default' : 'pointer'};
            width:30%;
            div {
                color:#fff;
                &:link,
                &:visited,
                &:hover,
                &:active {
                    color: #fff;
                }
            }
            background-color: ${({ theme }) =>
        (props) =>
            props.getCodeButtonDisabled ? 'grey' : theme.primary};
        } 
    }

    .card-head  {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;

        h1 {
            font-size: 28px;
            font-weight: 500;
            line-height: 1.2;
            margin: 0 auto;
        }
    }

    .card-form {
        button {
            margin-top: 16px;
            width: 100%;
            background: ${({ theme }) => theme.primary} !important;
            color: #fff;
        }
    }

    .ant-input-group-addon {
        font-size: 18px;
    }

	.ant-form-item-label {
		padding-bottom: 4px;
		label {
			height: 1rem !important;
			line-height: 1rem !important;
		}
	}

    .login-modal-close {
        position: absolute;
        top: 8px;
        right: 8px;
        height: 40px;
        width: 40px;
        padding 4px;
        cursor: pointer;
        svg {
            height: 100%;
            width: 100%;
            color: #7f7f7f;
        }
    }

    .login-modal-body {
        width: 100%;

        .logo {
            margin: 0 auto 24px;
            height: 86px;
            width: 100%;
            background: ${({ theme }) => theme.primary};
            border-radius: 10px;

            @media (min-width: ${MAX_PAD_WIDTH}px) {
                height: 120px;
            }

            img {
                display: block;
                height: 100%;
                margin:0 auto;
            }
        }

        .form {
            width: 100%;
            input {
                width: -webkit-fill-available;
            }
            .ant-form-item {
                margin: 0 auto 16px;
            }
        }

        .submit {
            margin: 0 auto;
            button {
                width: 100%;
                background: ${({ theme }) => theme.primary} !important;
                color: #fff;
            }
        }


    }

    .login-modal-footer {
        text-align: center;
        padding-top: 16px;
        border-top: 1px solid #ccc;
  
        p {
            width: 100%;
            font-size: 14px;
            line-height: 21px;
            margin: 0 auto;
        }
    }

    .red {
        color: red;
        font-size: ${FONT_SIZE.NORMAL};
    }

    .forgot {
        color: inherit;
        font-size: 14px;
        margin: 0 auto 40px;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        a, a:link, a:visited {
            text-decoration: none;
            color:inherit;
        }

        a:hover {
            color: ${({ theme }) => theme.primary};
        }

        svg {
            margin-right: 5px;
        }
    }
`;