import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AffiliateOverview from '@components/shared/AffiliateOverview';
import { Button, PaymentPageWrap } from '@components/shared/View';
import { useGlobalProps } from '@hooks/useGlobalProps';
import QuickTimeRangePickers from '@components/shared/QuickTimeRangePickers';
import { getGameRec } from '@api/user';
import { STATUS_CODE } from '@constants/api';
import {
    BET_COLUMN_KEYS,
    BET_MAX_DATE_RANGE,
    BET_PAGE_SIZE,
    BET_QUICK_PICKERS,
    DATE_FORMAT,
    formatBetData,
} from '@components/betLog/configAndFunc';
import PaginationTable, {
    renderPagination,
} from '@components/shared/PaginationTable';
import { parseErrorRes } from '@utils/parseErrors';
import AlertModal from '@components/shared/modals/AlertModal';
import { displayAmountWithDecimalPlace } from '@utils/util';

const TODAY = dayjs();
const INIT_ALERT = {
    show: false,
    title: '',
};
export default function BetLogPage() {
    const [startDate, setStartDate] = useState(TODAY);
    const [endDate, setEndDate] = useState(TODAY);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ ...INIT_ALERT });
    const { user, mainUnit, decimalPlace, getBalance } = useGlobalProps();
    const { t } = useTranslation();
    const tCommon = t('common', { returnObjects: true });
    const tBet = t('bet', { returnObjects: true });
    const tError = t('error', { returnObjects: true });

    const columns = [
        {
            title: tBet['bet@date'],
            dataIndex: BET_COLUMN_KEYS.DATE,
            key: BET_COLUMN_KEYS.DATE,
            width: '20%',
            render: (text) => text?.substr(0, 19),
        },
        {
            title: tBet['bet@game-name'],
            dataIndex: BET_COLUMN_KEYS.GAME_NAME,
            key: BET_COLUMN_KEYS.GAME_NAME,
        },
        {
            title: `${tBet['bet@bet-amount']}${mainUnit && ` ${mainUnit}`}`,
            dataIndex: BET_COLUMN_KEYS.BET,
            key: BET_COLUMN_KEYS.BET,
            render: (text, _) => <>{displayAmountWithDecimalPlace(
                Number(text),
                decimalPlace
            )}</>
        },
        {
            title: `${tBet['bet@win-amount']}${mainUnit && ` ${mainUnit}`}`,
            dataIndex: BET_COLUMN_KEYS.WIN,
            key: BET_COLUMN_KEYS.WIN,
            render: (text, _) => <>{displayAmountWithDecimalPlace(
                Number(text),
                decimalPlace
            )}</>
        },
        {
            title: `${tBet['bet@winloss']}${mainUnit && ` ${mainUnit}`}`,
            dataIndex: BET_COLUMN_KEYS.WINLOSS,
            key: BET_COLUMN_KEYS.WINLOSS,
            render: (text, _) => <>{displayAmountWithDecimalPlace(
                Number(text),
                decimalPlace
            )}</>
        },
    ];
    const paginationConfig = {
        current: currentPage,
        pageSize: BET_PAGE_SIZE,
        simple: true,
        showLessItems: true,
        defaultPageSize: BET_PAGE_SIZE,
        showSizeChanger: false,
        // pageSizeOptions: ['5', '10', '20', '30', '50'],
        total: totalCount,
        itemRender: renderPagination(tCommon, currentPage),
    };
    const getData = async (page) => {
        setLoading(true);
        const p = page || currentPage;
        let req = {
            ukey: user.ukey,
            std: startDate.format(DATE_FORMAT),
            endd: endDate.format(DATE_FORMAT),
            pgs: BET_PAGE_SIZE,
            pgidx: p,
        };
        // const failedRes = {"rtn":123,"msg":"Error test.","page_index":0,"rec_count":0,"rec_list":null}
        const res = await getGameRec(req);
        if (res?.rtn === STATUS_CODE.SUCCESS || res?.rtn === 9) {
            // 9 ="No data in range"
            if (res?.rec_list) setData(formatBetData(res.rec_list));
            else setData([]);
            if (res?.page_index && res.page_index !== p)
                setCurrentPage(res.page_index);
            if (totalCount !== res?.rec_count) setTotalCount(res?.rec_count);
        } else {
            setAlert({
                show: true,
                title: tCommon['common@failed'],
                message: parseErrorRes(tError, res),
            });
        }
        setLoading(false);
    };
    const onPageChange = (pagination, _filters, _sorter, _extra) => {
        const { current } = pagination; //, pageSize
        setCurrentPage(current);
        getData(current);
    };
    const handlesubmit = async () => {
        await getData();
    };
    useEffect(() => {
        if (user?.ukey) getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (user?.ukey) getBalance();
    }, [user, getBalance]);
    return (
        <BetLogPageWrap>
            <AffiliateOverview />

            <QuickTimeRangePickers
                maxDateRange={BET_MAX_DATE_RANGE}
                quickPickers={BET_QUICK_PICKERS}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
            />

            <div className="btn-center-wrap mt-20">
                <Button onClick={handlesubmit}>
                    {tCommon['common@submit']}
                </Button>
            </div>

            <PaginationTable
                tCommon={tCommon}
                rowKey={(r) => `${Math.random()}-${r[BET_COLUMN_KEYS.DATE]}`}
                dataSource={data}
                columns={columns}
                pagination={paginationConfig}
                onChange={onPageChange}
                currentPage={currentPage}
                loading={loading}
            />

            <AlertModal
                {...alert}
                onConfirm={() => setAlert({ ...INIT_ALERT })}
            />
        </BetLogPageWrap>
    );
}

const BetLogPageWrap = styled(PaymentPageWrap)`
    .btn-center-wrap {
        display: flex;
        // justify-content:center;
    }

    table {
        margin: 0 0 3rem 0;
    }
`;
