import { APIS } from '@constants/api';
import { generalFetch } from '@utils/generalFetch';
import { objectToQueryString } from '@utils/util';
import { IS_GW, IS_PECH } from '@constants/config';
import Compressor from 'compressorjs';


export const requestSMSForForgotPW = (req) => {
    // ?tel=0101010101
    return generalFetch(
        `${APIS.USER.REQ_SMS_FP}${objectToQueryString(req)}`,
        'GET'
    );
};

export const changePWForForgotPW = (req) => {
    // ?tel=0112345678&chc=665186&npwd=147258396&unm=my name&bd=2301
    return generalFetch(
        `${APIS.USER.CHANGE_PASSWORD_FP}${objectToQueryString(req)}`,
        'GET'
    );
};

export const requestSMS = (req) => {
    // ?dvc=da3520c1-90b0-b6e4-fdac-4a03e3c53507&tel=0101010101&pwd=123123&bd=0101&intro=MY345678A
    return generalFetch(
        `${APIS.USER.REQ_SMS}${objectToQueryString(req)}`,
        'GET'
    );
};

// export const getDevice = (uip) => {
//     return generalFetch(`${APIS.USER.GET_DEVICE}?uip=${uip}`, 'GET');
// };
//replace getDevice
export const getUserInfo = (req) => {
    // ?logo=gw123&dvc=da3520c1-90b0-b6e4-fdac-4a03e3c53507  -> if no dvc, will get dvc
    if (!req) req = {};
    req.logo = IS_GW ? 'gw123' : IS_PECH ? 'pech' : 'aw123';
    if (IS_PECH) {
        req = { ...req, op: 'KHA2A' };
    }
    return generalFetch(
        `${APIS.USER.GET_USER_INFO}${objectToQueryString(req)}`,
        'GET'
    );
};

export const register = (req) => {
    // ?dvc=da3520c1-90b0-b6e4-fdac-4a03e3c53507&tel=0101010101&pwd=123123&bd=0101&intro=MY345678A
    return generalFetch(
        `${APIS.USER.REGISTER}${objectToQueryString(req)}`,
        'GET'
    );
};
export const login = (req) => {
    // ?dvc=fff03991-6d66-77af-0554-2bfb1b6f0540&acc=MY345678A&pwd=123456
    return generalFetch(`${APIS.USER.LOGIN}${objectToQueryString(req)}`, 'GET');
};
export const logout = (req) => {
    // ?ukey=54dbb01a-f5ef-5759-3e3b-548a9388fb18
    return generalFetch(
        `${APIS.USER.LOGOUT}${objectToQueryString(req)}`,
        'GET'
    );
};
export const getUserData = (req) => {
    // ?ukey=54dbb01a-f5ef-5759-3e3b-548a9388fb18
    return generalFetch(
        `${APIS.USER.GET_USER_DATA}${objectToQueryString(req)}`,
        'GET'
    );
};
export const setUserData = (req) => {
    // ?ukey=88ed320e-e2a2-4533-6f0f-054193f85718&opwd=123123&npwd=123456
    return generalFetch(
        `${APIS.USER.SET_USER_DATA}${objectToQueryString(req)}`,
        'GET'
    );
};
export const getUserBalance = (req) => {
    // ?ukey=88ed320e-e2a2-4533-6f0f-054193f85718
    return generalFetch(
        `${APIS.USER.GET_USER_BALANCE}${objectToQueryString(req)}`,
        'GET'
    );
};
export const getUserBank = (req) => {
    // ?ukey=88ed320e-e2a2-4533-6f0f-054193f85718
    return generalFetch(
        `${APIS.USER.GET_USER_BANK}${objectToQueryString(req)}`,
        'GET'
    );
};
export const newUserBank = (req) => {
    // add bank
    // ?ukey=bcfb31aa-caef-4b43-be0b-650a9fa8bb18&bank=RJHIMYKL&banknm=Bamboo test Account -2&bankacc=333333212119
    return generalFetch(
        `${APIS.USER.NEW_USER_BANKE}${objectToQueryString(req)}`,
        'GET'
    );
};
export const setUserBank = (req) => {
    // set default bank
    // ?ukey=bcfb31aa-caef-4b43-be0b-650a9fa8bb18&bank=RJHIMYKL&bankacc=333333212119
    return generalFetch(
        `${APIS.USER.SET_USER_BANKE}${objectToQueryString(req)}`,
        'GET'
    );
};

export const updateUserBank = (req) => {
    // set default bank
    // ?ukey=bcfb31aa-caef-4b43-be0b-650a9fa8bb18&bank=RJHIMYKL&bankacc=333333212119
    return generalFetch(
        `${APIS.USER.UPDATE_USER_BANKE}${objectToQueryString(req)}`,
        'GET'
    );
};

// pgs=每頁幾筆；pgidx=第幾頁(在每頁pgs筆的情況下)（最小是1）
// 已拿到Res內page_index為主，因為超過的話api還是會吐資料
export const getTransLog = (req) => {
    // ?ukey=14c931ea-c1a9-b29f-f911-caa39748b718&std=20230401&endd=20230728&pgs=100&pgidx=2
    return generalFetch(
        `${APIS.USER.GET_TRANS_LOG}${objectToQueryString(req)}`,
        'GET'
    );
};
export const getAday = (req) => {
    // ?ukey=74db312e-fc2a-ae92-b909-caa09768f418&std=20230701&endd=20230728
    return generalFetch(
        `${APIS.USER.GET_A_DAY}${objectToQueryString(req)}`,
        'GET'
    );
};
export const getAdetail = (req) => {
    // ?ukey=74db312e-fc2a-ae92-b909-caa09768f418&date=20230728
    return generalFetch(
        `${APIS.USER.GET_A_DETAIL}${objectToQueryString(req)}`,
        'GET'
    );
};
export const getGameRec = (req) => {
    // ?ukey=74db312e-fc2a-ae92-b909-caa09768f418&std=20230728&endd=20230729&pgs=15&pgidx=2
    return generalFetch(
        `${APIS.USER.GET_GAME_REC}${objectToQueryString(req)}`,
        'GET'
    );
};

export const uploadAvatarProps = (filename, userId, onChange) => {
    return {
        name: 'Image',
        action: `${APIS.USER.UPLOAD_AVATAR_IMAGE}?filename=${filename}&userId=${userId}&autoUpdate=false`,
        // headers: {
        //     authorization: 'authorization-text',
        // },
        onChange: onChange,
        beforeUpload: (file, fileList) => {
            return new Promise((resolve, reject) => {
                new Compressor(file, {
                    // quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
                    maxWidth: 120,
                    success: (compressedResult) => {
                        // compressedResult has the compressed file.
                        // Use the compressed file to upload the images to your server.
                        resolve(compressedResult);
                    },
                });
            });
        },
    };
};

export const getAvatarImageUrl = (acc) => {
    return generalFetch(
        `${APIS.USER.GET_AVATAR_IMAGE_URL}?userAccount=${acc}`,
        'GET'
    );
};

export const updateAvatarImageUrl = (acc, path) => {
    return generalFetch(
        `${APIS.USER.UPDATE_AVATAR_IMAGE_URL}?userAccount=${acc}&imagePath=${path}`,
        'PATCH'
    );
};

export const deleteAvatarImageUrl = (acc) => {
    return generalFetch(
        `${APIS.USER.DELETE_AVATAR_IMAGE_URL}?userAccount=${acc}`,
        'DELETE'
    );
};

export const registerV2 = (req) => {
    // ?dvc=da3520c1-90b0-b6e4-fdac-4a03e3c53507&tel=0101010101&pwd=123123&bd=0101&intro=MY345678A
    return generalFetch(
        `${APIS.USER.REGISTER_V2}${objectToQueryString(req)}`,
        'GET'
    );
};

export const registerV2VerifyCode = (req) => {
    // ?dvc=da3520c1-90b0-b6e4-fdac-4a03e3c53507&tel=0101010101&pwd=123123&bd=0101&intro=MY345678A
    return generalFetch(
        `${APIS.USER.REGISTER_V2_SUBMIT_VERIFY_CODE}${objectToQueryString(req)}`,
        'GET'
    );
};

export const forgotPasswordV2 = (req) => {
    return generalFetch(
        `${APIS.USER.FORGOT_PASSWORD_V2}${objectToQueryString(req)}`,
        'GET'
    );
};