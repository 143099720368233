import React, { useState, useRef, useEffect } from 'react';
import { Avatar, Row, Col, Space, Button, Typography } from 'antd';
import styled from 'styled-components';
import { useGlobalProps } from '@hooks/useGlobalProps';
import { parseBirthDate } from '@utils/util';
import { useTranslation } from 'react-i18next';
import { deleteAvatarImageUrl, updateAvatarImageUrl } from '@api/user';
import { MAX_PAD_WIDTH } from '@constants/numbers';
import AvatarPickerModal from '@components/shared/modals/AvatarPickerModal';

export default function UserProfileSection() {
    const { user, userData, displayProfilePic, avatarUrl, setAvatarUrl } =
        useGlobalProps();
    const [avatarSrc, setAvatarSrc] = useState(avatarUrl); // default path or user's avatar path
    const { t } = useTranslation();
    const tUser = t('user', { returnObjects: true });
    const fileInputRef = useRef(null); // Create a ref

    const [showAvatarPicker, setShowAvatarPicker] = useState(false);
    useEffect(() => {
        setAvatarSrc(avatarUrl);
    }, [avatarUrl]);

    const handleDeleteClick = async () => {
        // Logic for deleting or removing the picture
        // ...
        const res = await deleteAvatarImageUrl(user.acc);

        console.log(' delete res ->  ', res);
        setAvatarSrc(null); // or set to a default image path
        setAvatarUrl(null); // or set to a default image path
        if (fileInputRef.current) fileInputRef.current.value = null;
    };

    const handleUpdatePhoto = () => {
        setShowAvatarPicker(true);
    };

    if (!user || !userData) return <></>;
    const birthDate = userData ? parseBirthDate(userData.birth_date) : '';

    return (
        <>
            <UserProfileWrap>
                <Row
                    align="middle"
                    justify={displayProfilePic ? 'space-around' : 'start'}
                    gutter={[16, 16]}
                >
                    {displayProfilePic && (
                        <CenteredCol xs={24} md={12}>
                            <Avatar
                                size={100}
                                src={avatarSrc || '/images/avatar.png'}
                            />

                            <ButtonSpace>
                                {avatarSrc ? (
                                    <Space>
                                        <ActionButton
                                            onClick={handleUpdatePhoto}
                                        >
                                            {tUser['avatar@change']}
                                        </ActionButton>

                                        <ActionButton
                                            onClick={handleDeleteClick}
                                        >
                                            {tUser['avatar@delete']}
                                        </ActionButton>
                                    </Space>
                                ) : (
                                    <ActionButton onClick={handleUpdatePhoto}>
                                        {tUser['avatar@add']}
                                    </ActionButton>
                                )}
                            </ButtonSpace>
                        </CenteredCol>
                    )}
                    <CenteredSMCol xs={24} md={displayProfilePic ? 12 : 24} displayPic={displayProfilePic}>
                        <Space direction="vertical" size="large">
                            <Typography.Title level={3}>
                                {user.acc}
                            </Typography.Title>
                            <Space>
                                <img
                                    src="/images/mobile-verified.png"
                                    alt="Mobile"
                                    width={32}
                                    height={32}
                                />
                                <Typography.Text>
                                    {userData.tel}
                                </Typography.Text>
                            </Space>
                            {birthDate && (
                                <Space>
                                    <img
                                        src="/images/birthday-cake.png"
                                        alt="BirthDate"
                                        width={32}
                                        height={32}
                                    />
                                    <Typography.Text>
                                        {`${birthDate.month}/${birthDate.day}`}
                                    </Typography.Text>
                                </Space>
                            )}
                        </Space>
                    </CenteredSMCol>
                </Row>
            </UserProfileWrap>
            <AvatarPickerModal
                show={showAvatarPicker}
                user={user}
                onCancel={() => {
                    setShowAvatarPicker(false);
                }}
                originalAvatar={avatarSrc}
                onSetAvatar={(url) => {
                    setAvatarSrc(url);
                    setAvatarUrl(url);
                    updateAvatarImageUrl(user.acc, url);
                    setShowAvatarPicker(false);
                }}
            ></AvatarPickerModal>
        </>
    );
}

// Optional: Styled container if you want a border around the entire section
const UserProfileWrap = styled.div`
    border: 1px solid #000; // border around the entire section
    padding: 20px; // space between border and content
    border-radius: 10px; // if you want rounded corners
`;

const CenteredCol = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: center; // aligns items horizontally in a column (i.e., along the cross axis)
    justify-content: center; // centers items vertically, if there is free space available
`;

const CenteredSMCol = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: center; // aligns items horizontally in a column (i.e., along the cross axis)

    ${({ displayPic }) => displayPic && `
        @media (min-width: ${MAX_PAD_WIDTH}px) {
            align-items: start; // aligns items horizontally in a column (i.e., along the cross axis)
        }
    `}
   
`;

const ButtonSpace = styled.div`
    margin-top: 10px; // for space between avatar and buttons
    display: flex;
    flex-direction: column; // stack the buttons

    button {
        // You might want to add some style for the buttons
        margin-bottom: 5px; // for example, space between the buttons
    }
`;

const ActionButton = styled(Button)`
    color: black !important;
    border: ${({ theme }) => `2px solid ${theme.primary}`};
    width: 100px;
    border-radius: 50px;
`;

// const ActionInputButton = styled.input`
//     color: black !important;
//     border: ${({ theme }) => `2px solid ${theme.primary}`};
//     width: 100px;
//     border-radius: 50px;
// `;
