export const theme = {
    primary: 'rgb(220,40,1)',
    minor: 'rgb(255,234,223)',
};

export const SCROLL_BAR_STYLE = `
    ::-webkit-scrollbar {
        display: block;
        width: 0.3rem;
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px #01DC66;
        background: #01DC66;
    }
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px transparent;
        border-radius: 10px;
        background-color: transparent;
    }
`