export function parseLoginRes(tError, res) {
    if (!res)
        return `${tError['error@refer-error-code']}. ${tError['error@error-code']}`;

    const { rtn } = res;
    if (rtn === 97) {
        return tError['error@wrong-acc-or-pwd'];
    } else if (rtn === 99) {
        return tError['error@closed-acc'];
    }

    // return `${tError['error@refer-error-code']}. ${tError['error@error-code']} : ${rtn}`;
    return `${res?.msg}. ${tError['error@error-code']} : ${rtn}`;

    //     0 成功
    // 10 無法產生user key(100次失敗)
    // 90 要用 https 來呼叫
    // 91 沒給機碼(最少12碼)
    // 92 沒給帳號
    // 93 沒給密碼
    // 94 查不到帳號
    // 95 帳密對了，但是新機器，所以要再傳生日ddmm
    // 97 用帳密查不到帳號，所以應該是密碼錯
    // 99 帳號已關閉
}

export function parseGetUserInfoRes(tError, res) {
    if (!res)
        return `${tError['error@refer-error-code']}. ${tError['error@error-code']}`;

    const { rtn } = res;
    // if(rtn === 97) {
    //     return tError["error@wrong-acc-or-pwd"]
    // }else if(rtn === 99){
    //     return tError["error@closed-acc"]
    // }

    return `${tError['error@refer-error-code']}. ${res?.msg} ${tError['error@error-code']} : ${rtn}`;
    // return `${res?.msg}. ${tError['error@error-code']} : ${rtn}`;
}

export function parseSetUerDataRes(tError, res) {
    if (!res)
        return `${tError['error@refer-error-code']}. ${tError['error@error-code']}`;

    const { rtn } = res;
    if (rtn === 83 || rtn === 93) {
        return tError['error@wrong-old-pwd'];
    } else if (rtn === 95) {
        return tError['error@new-pwd-same-as-old'];
    }

    // return `${tError['error@refer-error-code']}. ${tError['error@error-code']} : ${rtn}`;
    return `${res?.msg}. ${tError['error@error-code']} : ${rtn}`;

    //     0 成功
    // 90 要用 https 來呼叫
    // 91 User Key 錯誤
    // 92 查不到 User
    // 93 舊密碼太短<6
    // 94 新密碼太短<6
    // 95 新舊密碼一樣
    // 81 密碼鎖住
    // 82 查不到帳戶檔
    // 83 舊密碼錯
    // 84 無法改密碼
    // 85 改密碼過程出現意外錯誤
}

export function parseDoUserApplyRes(tError, res) {
    if (!res)
        return `${tError['error@refer-error-code']}. ${tError['error@error-code']}`;
    if (res?.rtn === 86) {
        return tError['error@wrong-phone'];
    }
    if (res?.rtn === 49) {
        return tError['error@reached_limit_and_wait_for_cs'];
    }

    // return `${tError['error@refer-error-code']}. ${tError['error@error-code']} : ${res?.rtn}`;
    return `${res?.msg}. ${tError['error@error-code']} : ${res?.rtn}`;
}
export function parseErrorRes(tError, res) {
    // return `${tError['error@refer-error-code']}. ${tError['error@error-code']} : ${res?.rtn}`;
    return `${res?.msg}. ${tError['error@error-code']} : ${res?.rtn}`;
}
