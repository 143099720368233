/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import BaseModal from 'src/components/shared/modals/BaseModal';
import { FlexDirectionConfigs } from '@constants/config';
import { Primary } from '@constants/colors';
import { getHideAnnouncementInLS, setHideAnnouncementInLS } from '@utils/ls';
import { getCurrentYYYYMMDD } from '@utils/util';
import { getAnnouncement } from '@api/other';
import moment from 'moment';
import { SCROLL_BAR_STYLE } from '@constants/theme';
import { MAX_PAD_WIDTH, POPUP_MIN_HEIGHT } from '@constants/numbers';

const AnnouncementModal = () => {
    const [announcement, setAnnouncement] = useState(null)
    const [hideAnnouncement, setHideAnnouncement] = useState(true)
    const [selectedCheckbox, setSelectedCheckbox] = useState(false)
    const { i18n, t } = useTranslation()
    const tCommon = t("common", { returnObjects: true })

    const stopPropagation = (e) => {
        e.stopPropagation()
    }
    const handleCheckboxChange = (e) => {
        setSelectedCheckbox(e.target.checked)
    }
    const handleHideAnnouncement = () => {
        if(selectedCheckbox) {
            setHideAnnouncementInLS(i18n.language, getCurrentYYYYMMDD())
        }
        setHideAnnouncement(true);
    }
    const getData = async() => {
       const str = getHideAnnouncementInLS(i18n.language);
       let hide = true
       if(str && str.length === 8) {
           const todayStr = getCurrentYYYYMMDD();
           try {
                if(parseInt(todayStr) - parseInt(str) > 0) hide = false
           }catch(e){
                hide = false
           }
       }else {
            hide = false
       }

       setHideAnnouncement(false)
       if(!hide) {
            const res = await getAnnouncement(i18n.language)
            if(res && res[0]) setAnnouncement(res[0])
            else setAnnouncement(null)
       }
    }
    
    useEffect(()=> {
        getData()
    },[i18n.language])

    if(hideAnnouncement || !announcement) return null

    const now = moment().valueOf();
    let display = now > moment(announcement.startDate).valueOf()
    if(display && announcement.isPeriod && announcement.endDate) display = now < moment(announcement.endDate).valueOf()
    if(!display) return null
    return (
        <BaseModal show={true} 
            justifyContent={FlexDirectionConfigs.Center} 
            alignItems={FlexDirectionConfigs.Center}
            handleOutsideClick={stopPropagation}>
            <AnnouncementContent onClick={stopPropagation}>
                <div className='announcement-head'>
                    <img alt="megaphone" src={'/images/shared/megaphone.png'} />
                    {announcement.title
                }</div>
                <div dangerouslySetInnerHTML={{ __html: announcement.content }}/>
                    {/* <figure>
                        <img alt="announce" src="/images/announce.jpg" />
                    </figure>
                </div> */}
                <div>
                    <input type="checkbox" id="announcement-checkbox" onChange={handleCheckboxChange}/>
                    <label htmlFor="announcement-checkbox">{t("announcement@close-tip")}</label>
                </div>
                <button onClick={handleHideAnnouncement}>{tCommon["common@close"]}</button>
            </AnnouncementContent>
        </BaseModal>
    );
};

export default AnnouncementModal;

const AnnouncementContent = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    background: white;
    height: 98%;
    width: 95vw;
    overflow-x: hidden;
    min-height: 98%;
    box-sizing: box-sizing;

    @media (min-width: ${MAX_PAD_WIDTH}px) {
        height: 94vh;
        width: 58.5%;
        min-width: 480px;
        max-width: 800px;
    }
    @media (min-width: ${MAX_PAD_WIDTH}px) and (min-height: ${POPUP_MIN_HEIGHT}px){
        max-height: 910px;
        min-height: ${POPUP_MIN_HEIGHT}px;
    }
    
    .announcement-head {
        border-radius: 5px;
        background: ${({theme}) => theme.primary};
        padding: 20px;
        font-size: 1.3em;
        font-weight: bold;
        width: 100%;
        >img {
            height: 20px;
            margin-right: 10px;
            margin-bottom: -2px;
        }
    }
    
    >div:nth-child(2){
        position: relative;
        max-height: 90%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0px 16px;
        ${SCROLL_BAR_STYLE}
    }
    >div:nth-child(3){
        margin-top: 10px;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    >button {
        background: ${Primary.Red};
        color: white;
        width: 20%;
        min-width: 145px;
        margin: 10px;
    }

`;
