import moment from 'moment';
import numeral from 'numeral';
import { IS_KH, IS_AW_MM, IS_AW_VN } from '@constants/config';

export const DATE_YYYYMMDD_FORMAT = 'YYYYMMDD';
export function getCurrentYYYYMMDD() {
    return moment().format(DATE_YYYYMMDD_FORMAT);
}
const DATE_YYYYMMDDhhmmss_FORMAT = 'YYYYMMDDhhmmss';
export function getCurrentYYYYMMDDhhmmss() {
    return moment().format(DATE_YYYYMMDDhhmmss_FORMAT);
}

export function objectToQueryString(object) {
    if (!object) return '';
    let str = '';
    if (typeof object === 'object') {
        str += '?';
        const suffix = Object.keys(object)
            .map((item) => `${item}=${object[item]}&`)
            .join('');
        str += suffix;
        str = str.substring(0, str.length - 1);
        return str;
    }
    console.error(`use objectToString but parameter's type isn't object`);
    return str;
}

const precision = (val) => {
    if (isNaN(val)) return 0;
    if (!isFinite(val)) return 0;
    var e = 1,
        p = 0;

    for (let i = 0; i < 10; i++) {
        if (Math.round(val * e) / e !== val) {
            e *= 10;
            p++;
        }
    }
    return p;
};

export const displayAmountWithPrecision = (val = 0) => {
    const decimalPlace = precision(Number(val));
    return displayAmountWithDecimalPlace(val, decimalPlace);
};

export const displayAmount = (val = 0, integer = false) => {
    if (integer) return numeral(val).format('0,0');

    if (IS_AW_MM) return numeral(val).format('0,0');

    if (IS_KH || IS_AW_VN) return numeral(val).format('0,0.000');

    return numeral(val).format('0,0.00');
};

// export const displayAmountWithDecimalPlace = (val = 0, decimalPlace = 0) => {
//     let formatString = '';
//     for (let i = 0; i < decimalPlace; i++) {
//         if (i === 0) {
//             formatString += '.';
//         }
//         formatString += '0';
//     }

//     return numeral(val).format(`0,0${formatString}`);
// };

export const displayAmountWithDecimalPlace = (val = 0, decimalPlace = 0) => {
    if (decimalPlace === 0) {
        val = Math.trunc(val);
    }
    let formatString = '0,0';
    if (decimalPlace > 0) {
        formatString += '.';
        for (let i = 0; i < decimalPlace; i++) {
            formatString += '0';
        }
    }

    return numeral(val).format(formatString);
};

export const covertResArrayIntoObjecMap = (res, objectMap) => {
    if (!res || res.length === 0 || !objectMap) return [];

    const keyMap = Object.keys(objectMap).map((k) => {
        return { k, idx: objectMap[k] };
    });
    const obejctData = [];
    res.forEach((r) => {
        const formatItem = {};
        keyMap.forEach((m) => (formatItem[m.k] = r[m.idx]));
        obejctData.push(formatItem);
    });
    return obejctData;
};

/* eslint-disable */
export function lowerCaseObjectKeys(obj) {
    let str = JSON.stringify(obj);
    const pattern = /\"([a-z0-9_-]{0,})\"\:/gi;
    str = str.replace(pattern, function () {
        return `"${arguments[0]
            .charAt(1)
            .toLowerCase()}${arguments[0].slice(2)}`;
    });
    return JSON.parse(str);
}

export function isAndroid() {
    return /Android/i.test(navigator.userAgent);
}

export function isOS() {
    return /iOS/i.test(navigator.userAgent);
}

export function getOperatingSystem(window) {
    let operatingSystem = 'Not known';
    if (window.navigator.appVersion.indexOf('Win') !== -1) {
        operatingSystem = 'Windows OS';
    }
    if (window.navigator.appVersion.indexOf('Mac') !== -1) {
        operatingSystem = 'MacOS';
    }
    if (window.navigator.appVersion.indexOf('X11') !== -1) {
        operatingSystem = 'UNIX OS';
    }
    if (window.navigator.appVersion.indexOf('Linux') !== -1) {
        operatingSystem = 'Linux OS';
    }

    return operatingSystem;
}

export function getChromeVersion() {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

    return raw ? parseInt(raw[2], 10) : false;
}

export function isValidURL(str) {
    const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
        'i'
    ); // fragment locator
    return !!pattern.test(str);
}

export const parseBirthDate = (birthDate) => {
    // Check if birthDate is valid
    if (!birthDate || birthDate.length !== 4) {
        return null; // or a default value
    }

    const month = birthDate.substring(0, 2); // Extracts the month part
    const day = birthDate.substring(2, 4); // Extracts the day part

    // Check if month and day are valid numbers (optional)
    if (isNaN(month) || isNaN(day)) {
        return null; // or a default value
    }

    return { month, day };
};
