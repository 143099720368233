import { Helmet } from 'react-helmet';
import { getCurrentYYYYMMDDhhmmss } from '@utils/util';
import { IMAGE_DOMAINS } from '@constants/api';
import useIsMobile from '@hooks/useIsMobile';


const webIconpath = `${IMAGE_DOMAINS.LOGOS}/website-logo.ico?gettime=${getCurrentYYYYMMDDhhmmss()}`
const pwaIconPath = `${IMAGE_DOMAINS.LOGOS}/pwa-logo.png?gettime=${getCurrentYYYYMMDDhhmmss()}`
const CustomWebsiteHead = () => {
    const isMobile = useIsMobile()
    // const desc = "Thiss issss website desssccc test"
    return (
        <>
            <Helmet>
                {/* <meta name="description" content={desc} /> */}
                <link rel="icon" href={webIconpath} />
                <link rel="shortcut icon" type="image/x-icon" href={webIconpath}/>
                {/* <link rel="apple-touch-icon" href={pwaIconPath} /> */}
                {/* <link rel="apple-touch-icon" type="image/x-icon" href={pwaIconPath} /> */}
               {/* <link rel="apple-touch-startup-image" type="image/x-icon" href={pwaIconPath}/> */}
                
                {/* <meta property="og:title" content="GW123" /> 
                <meta property="og:type" content="website" />
                <meta property="og:image" content={`${IMAGE_DOMAINS.LOGOS}/desktop-logo.png?gettime=${getCurrentYYYYMMDDhhmmss()}`} />
                <meta property="og:description" content={desc} /> */}

                {isMobile && <link sizes="72x72" rel="apple-touch-icon"  href={pwaIconPath}/>}
                {isMobile && <link sizes="114x114" rel="apple-touch-icon" href={pwaIconPath}/>}
                {isMobile && <link sizes="192x192" rel="apple-touch-icon" href={pwaIconPath}/>}
                {isMobile && <link sizes="512x512" rel="apple-touch-icon" href={pwaIconPath}/>}
            </Helmet>
        </>
    )
}
export default CustomWebsiteHead
