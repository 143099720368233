import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import FullScreenIframe from '@components/home/FullScreenIframe';
import { useNavigate, useLocation } from 'react-router-dom';
import { SWITCH_ROUTES } from '@constants/routes';

export default function WithdrawBankingPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const iframeRef = useRef(null);
    const [gameUrl, setGameUrl] = useState(null);

    const handleDismiss = () => {
        console.log(' handle dismiss ');
        navigate(SWITCH_ROUTES.ROOT);
    };
    useEffect(() => {
        if (location.state && location.state.gameUrl) {
            setGameUrl(location.state.gameUrl);
        }

        if (iframeRef.current) {
            iframeRef.current.window.close = () => {
                handleDismiss();
                console.log(' iframeRef.current.window.close   ');
            };
        }

        const handleMessage = (message) => {
            if (message.data) {
                try {
                    const data = JSON.parse(message.data);
                    if (data && !!data.IsCloseGame) {
                        handleDismiss();
                    }
                } catch (error) {}
            }
        };

        window.addEventListener('message', handleMessage, false);

        return () => {
            window.removeEventListener('message', handleMessage);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {});
    return (
        <GamePageWrap>
            {gameUrl != null && (
                <FullScreenIframe
                    innerRef={iframeRef}
                    url={gameUrl}
                    onDismiss={handleDismiss}
                />
            )}
        </GamePageWrap>
    );
}

const GamePageWrap = styled.div`
    width: 100%;
`;
