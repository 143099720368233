/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AffiliateOverview from '@components/shared/AffiliateOverview';
import {
    Button,
    Column,
    ColumnWrap,
    CustomColumn,
    PaymentPageWrap,
    PolicyWrap,
    QRCodeFrame,
    QRCodeLogoImage,
    OutlineButton,
    PaymentOptionOutlineButton,
    PaymentImg
} from '@components/shared/View';
import { useGlobalProps } from '@hooks/useGlobalProps';
import ConfirmModal from '@components/shared/modals/ConfirmModal';
import AlertModal from '@components/shared/modals/AlertModal';
import { FONT_SIZE } from '@constants/numbers';
import { parseErrorRes } from '@utils/parseErrors';
import styled from 'styled-components';
import { deposit, setOptIn } from '@api/payment';
import { STATUS_CODE } from '@constants/api';
import { getDPolicy } from '@api/other';
import { displayAmountWithPrecision } from '@utils/util';
import { IS_PECH } from '@constants/config';
import { isValidURL } from '@utils/util';
import { Spin } from 'antd';
import { useQRCode } from '@hooks/useQRCode';

import { BANK_METAS_INFO } from '@constants/config'

const INIT_INPUT_FILED = {
    accountName: '',
    amount: '',
    lastFive: '',
};
const INIT_ALERT = {
    show: false,
    title: '',
    DepositPage,
};
export default function DepositPage() {
    const [qrCodeLoading, setQrCodeLoading] = useState(false);
    const { depositQRCodeUrl, generateQRCode } = useQRCode();

    const [inputField, setInputField] = useState({ ...INIT_INPUT_FILED });
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [alert, setAlert] = useState({ ...INIT_ALERT });
    const [policy, setPolicy] = useState(null);
    const { user, userData, mainUnit, mainUnitRaw, getBalance, pixelCodes } = useGlobalProps(); //, balance
    const { i18n, t } = useTranslation();
    const tCommon = t('common', { returnObjects: true });
    const tPayment = t('payment', { returnObjects: true });
    // const tBank = t('bank', { returnObjects: true });
    const tError = t('error', { returnObjects: true });
    // const [messageApi, contextHolder] = message.useMessage();
    const bankList = ['MYBK01', 'MYBK02', 'MYBK03', 'MYBK04', 'MYBK05'];
    const [paymentOption, setPaymentOption] = useState('MYBK01');

    // const navigate = useNavigate();
    const onPaymentOptionClick = (e) => {
        setPaymentOption(e.target.name);
    };

    const getPolicy = async () => {
        const res = await getDPolicy(i18n.language);
        if (res && res[0]) setPolicy(res[0]);
        else setPolicy(null);
    };
    // const getMax = () => {
    //     if(!userData?.bank_in_max || !balance?.bln) return 0
    //     return userData.bank_in_max - balance.bln
    // }
    const maxAmount = user?.ki_max || 0;
    const handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;
        let val = target.value;
        if (name === 'amount' && maxAmount > 0) {
            if (val > maxAmount) val = maxAmount;
        }

        setInputField((prev) => {
            return { ...prev, [name]: val };
        });
    };


    // useEffect(() => {
    //     getBankOutList().then((res) => {
    //         if (res?.rtn === STATUS_CODE.SUCCESS && res?.bank_list) {
    //             setBankList(res?.bank_list.map((e) => {
    //                 const [bankcode] = e;
    //                 return bankcode.trim();
    //             }));
    //         }
    //     });
    //     console.log(bankList)
    // }, []);

    const checkError = () => {
        let message = null;

        if (IS_PECH) {
            if (!inputField.amount) {
                message = tError['error@must_fill_amount'];
            } else if (!inputField.lastFive) {
                message = tError['error@must_fill_receipt_number'];
            } else if (inputField.lastFive.length < 6) {
                message = tError['error@at_least_6_characters'];
            }
        } else {
            // if (!inputField.accountName) {
            //     message = tError['error@must_fill_account_holder'];
            // }

            if (!inputField.amount) {
                message = tError['error@must_fill_amount'];
            }
        }

        try {
            if (parseInt(inputField.amount) < userData?.bank_in_min) {
                message = tError['error@deposit_insufficient_amount'];
            }
        } catch (e) {
            message = tError['error@deposit_insufficient_amount'];
        }

        return message;
    };

    useEffect(() => {
        if (user?.ukey) getBalance();
    }, [user, getBalance]);

    useEffect(() => {
        if (userData?.user_name)
            setInputField({ ...inputField, accountName: userData.user_name });
    }, [userData]);

    useEffect(() => {
        getPolicy();
    }, [i18n.language]);

    useEffect(() => {
        if (depositQRCodeUrl) {
            setQrCodeLoading(false);
            return;
        }
        const url =
            'https://link.payway.com.kh/aba?id=2B42712A14A0&code=216042&acc=010589955&dynamic=true';

        if (isValidURL(url)) {
            setQrCodeLoading(true);
            generateQRCode(url);
        }
    }, [generateQRCode, depositQRCodeUrl]);

    const doDeposit = async () => {
        setShowConfirmModal(false);

        if (IS_PECH) {
            const res = await setOptIn({
                ukey: user.ukey,
                val: inputField.amount,
                prcnm: 'ABA',
                tc: inputField.lastFive,
            });
            if (res?.rtn === STATUS_CODE.SUCCESS) {

                setAlert({
                    show: true,
                    title: tCommon['common@success'],
                    message: tPayment['payment@deposit-successfully'],
                });
                setInputField({ ...INIT_INPUT_FILED });
            } else {
                setAlert({
                    show: true,
                    title: tCommon['common@failed'],
                    message: parseErrorRes(tError, res),
                });
            }
            return;
        }

        const newTab = window.open('');
        const res = await deposit({
            ukey: user.ukey,
            // unm: inputField.accountName,
            val: inputField.amount,
            ob: paymentOption,
            prcnm: paymentOption,
        });
        // const succRes = {"rtn":0,"msg":"success","blnAft":990.00,"nkey":"16d5b4d8-da82-eaf0-776d-d1897cd95d13"}

        if (res?.rtn === STATUS_CODE.SUCCESS && res?.url) {
            setInputField({ ...INIT_INPUT_FILED });

            // navigate(SWITCH_ROUTES.DEPOSIT_BANKING, {
            //     state: {
            //         gameUrl: res?.url,
            //     },
            // });

            newTab.location.href = res.url;
        } else {
            newTab.close();
            setAlert({
                show: true,
                title: tCommon['common@failed'],
                message: parseErrorRes(tError, res),
            });
        }
    };

    const displayPolicy = policy && policy.content?.length > 0;
    return (
        <PaymentPageWrap>
            {/* {contextHolder} */}
            <AffiliateOverview />

            <ColumnWrap className="mt-20 mb-1 pl-3">
                {IS_PECH ? (
                    <>
                        <CustomColumn
                            notRequired
                            title={tPayment['payment@master-topup-code']}
                            child={
                                <>
                                    {qrCodeLoading && (
                                        <SpinContainer>
                                            <Spin
                                                style={{
                                                    top: '50%',
                                                    position: 'absolute',
                                                }}
                                            />
                                        </SpinContainer>
                                    )}
                                    {depositQRCodeUrl && (
                                        <QRCodeFrame>
                                            <QRCodeLogoImage
                                                src="https://link.payway.com.kh/images/usd-aba-qr.svg"
                                                alt="aba-logo"
                                            />
                                            <img
                                                src={depositQRCodeUrl}
                                                class="uk-animation-fade"
                                                alt="aba-qrcode"
                                            />
                                        </QRCodeFrame>
                                    )}
                                </>
                            }
                        />
                        <Column
                            title={`${tPayment['payment@deposit-amount']}${mainUnit && ` ${mainUnit}`
                                }`}
                            name={'amount'}
                            value={inputField.amount}
                            type="number"
                            onChange={handleInputChange}
                        />
                        <Column
                            title={tPayment['payment@last-six-receipt-number']}
                            name={'lastFive'}
                            value={inputField.lastFive}
                            onChange={handleInputChange}
                        />
                    </>
                ) : (
                    <>
                        <CustomColumn
                            title={tPayment['payment@deposit-method']}
                            child={
                                <OutlineButton textColor={'black'}>
                                    {tPayment['payment@deposit-method-quick']}
                                </OutlineButton>
                            }
                        />
                        <CustomColumn
                            title={tPayment['payment@deposit-payment']}
                            child={
                                <>
                                    <div style={{ display: 'flex' }}>
                                        {bankList.map((code) => {
                                            const bankInfo = BANK_METAS_INFO[code];
                                            if (!bankInfo) return null;
                                            return <PaymentOptionOutlineButton
                                                key={code}
                                                onClick={onPaymentOptionClick}
                                                name={bankInfo.name}
                                                isSelected={
                                                    paymentOption === bankInfo.name
                                                }

                                            >
                                                <PaymentImg
                                                    width={bankInfo.imageWidth ? bankInfo.imageWidth : '100px'}
                                                    src={bankInfo.imageUrl}
                                                    alt={bankInfo.name}
                                                    name={bankInfo.name}
                                                />
                                            </PaymentOptionOutlineButton>
                                        })}
                                    </div>

                                </>
                            }
                        />
                        {/* <CustomColumn
                            title={tPayment['payment@deposit-payment']}
                            child={
                                <Button>{tPayment['payment@GGGPay']}</Button>
                            }
                        /> */}
                        {/* <Column
                            title={tBank['bank@bank-owner']}
                            name={'accountName'}
                            value={inputField.accountName}
                            onChange={handleInputChange}
                        /> */}
                        <Column
                            title={`${tPayment['payment@deposit-amount']}${mainUnit && ` ${mainUnit}`
                                }`}
                            name={'amount'}
                            value={inputField.amount}
                            type="number"
                            onChange={handleInputChange}
                        />
                        <CustomColumn
                            notRequired
                            child={
                                <DepositInfo>
                                    <p>
                                        {userData?.bank_in_min > 0 && !maxAmount && `${tPayment['payment@deposit-min-amount']}: ${displayAmountWithPrecision(
                                            userData?.bank_in_min
                                        )}${mainUnit && ` ${mainUnit}`
                                            }`}
                                        {!userData?.bank_in_min && maxAmount > 0 && `${tPayment['payment@deposit-max-amount']}: ${displayAmountWithPrecision(
                                            maxAmount
                                        )}${mainUnit && ` ${mainUnit}`
                                            }`}
                                        {userData?.bank_in_min > 0 && maxAmount > 0 && `${tPayment['payment@deposit-amount-limit']
                                            }: ${displayAmountWithPrecision(
                                                userData?.bank_in_min
                                            )} ~ ${displayAmountWithPrecision(
                                                maxAmount
                                            )}${mainUnit && ` ${mainUnit}`
                                            }`}
                                    </p>
                                </DepositInfo>
                            }
                        />
                    </>
                )}

                <CustomColumn
                    // child={<Button disabled={isDisabled()}  onClick={()=>setShowConfirmModal(true)}>{tCommon["common@submit"]}</Button>}
                    child={
                        <Button
                            onClick={() => {
                                const message = checkError();
                                if (message) {
                                    setAlert({
                                        show: true,
                                        title: tCommon['common@failed'],
                                        message: message,
                                    });
                                    return;
                                }

                                if (pixelCodes && pixelCodes.length > 0) {
                                    pixelCodes.forEach((code) => window.fbq('trackSingleCustom', code, 'DepositSubmission'));

                                }

                                setShowConfirmModal(true);
                            }}
                        >
                            {tCommon['common@submit']}
                        </Button>
                    }
                    notRequired
                />
            </ColumnWrap>

            {displayPolicy && (
                <PolicyWrap>
                    <div dangerouslySetInnerHTML={{ __html: policy.content }} />
                </PolicyWrap>
            )}

            <ConfirmModal
                show={showConfirmModal}
                title={tPayment['payment@confirm-deposit']}
                children={
                    <DepositInfo>
                        {/* <p className="text-center">{`${tBank['bank@bank-owner']}: ${inputField.accountName}`}</p> */}
                        <p className="text-center">{`${tPayment['payment@deposit-amount']}: ${inputField.amount}${mainUnitRaw && ` ${mainUnitRaw}`
                            }`}</p>
                    </DepositInfo>
                }
                onConfirm={doDeposit}
                onCancel={() => setShowConfirmModal(false)}
            />
            <AlertModal
                {...alert}
                onConfirm={() => setAlert({ ...INIT_ALERT })}
            />
        </PaymentPageWrap>
    );
}

const DepositInfo = styled.div`
    color: black;
    > p {
        margin: 0;
        margin-top: 5px;
        font-size: ${FONT_SIZE.ALERT_MODAL_TITLE};
        font-weight: 800;
    }
    .text-center {
        text-align: center;
    }
`;

const SpinContainer = styled.div`
    textalign: center;
    position: relative;
    width: 180px;
    height: 180px;
`;
