import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { getGameGroup, getPlayGameUrl, getBBAGPlayGameUrl, getGameListByListId } from '@api/game';
import { IMAGE_DOMAINS, STATUS_CODE } from '@constants/api';
import { useTranslation } from 'react-i18next';
import { LOCALS, LOCALS_IGS_CODE_MAP } from '@constants/config';
import { MAX_MOBILE_WIDTH, MAX_PAD_WIDTH } from '@constants/numbers';
import NewsModal from './NewsModal';
import { getNews } from '@api/other';
import { useGlobalProps } from '@hooks/useGlobalProps';
import { getDVCInLS } from '@utils/ls';
import { useNavigate } from 'react-router-dom';
import { SWITCH_ROUTES } from '@constants/routes';
import useMobileDetect from '@hooks/useMobileDetect';
import ConfirmModal from '@components/shared/modals/ConfirmModal';
import LoginModal from '@components/shared/modals/LoginModal';



const NEWS_TAB_GRP = 'NEWS';
export default function TabsComponent() {
    const [activeTab, setActiveTab] = useState(0);
    const [gameGroups, setGameGroups] = useState([]);
    const [news, setNews] = useState(null);
    const [games, setGames] = useState([]);
    const [selectedNews, setSelectedNews] = useState(null);
    const { i18n, t } = useTranslation();
    const { user, homeActiveTab, updateHomeActiveTab } = useGlobalProps();
    const image_lang = i18n.language === LOCALS.cn ? 'CH' : 'EN';
    const [loginConfirmShow, setLoginConfirmShow] = useState(false);
    const tCommon = t("common", { returnObjects: true });
    const [openLoginModal, setOpenLoginModal] = useState(false);

    const isLogin = !!user?.ukey;

    const isMobileDevice = useMobileDetect();
    const navigate = useNavigate();
    // #region ui
    const handleTabClick = (index) => {
        setActiveTab(index);
        updateHomeActiveTab(index);
    };
    const handleGameClick = (game) => async (event) => {
        event.preventDefault();
        console.log(' game --->  ', game);

        if (!game.isFree && !isLogin) {
            setLoginConfirmShow(true)
            return;
        }
        // const newTab = window.open('', '_blank')


        try {
            let url;
            if (game.source === 'BBAG') {
                url = await getBBAGGameUrl(game.gameID);
            } else {
                url = await getGameUrl(game.gameID);
            }

            if (url) {
                const urlObj = new URL(url);

                // Set or replace the 'lang' query parameter
                let langCode = LOCALS_IGS_CODE_MAP[i18n.language];
                if (langCode) {
                    urlObj.searchParams.set('lang', langCode);
                }
                if (game.source === 'BBAG') {
                    urlObj.searchParams.set('game_id', game.gameID);
                }

                // urlObj.searchParams.set('gameID', '5702');

                // Determine if additional parameters should be added based on the device type
                const modifiedUrl = isMobileDevice ? urlObj.toString() : `${urlObj.toString()}&fullscreen=1`;

                navigate(SWITCH_ROUTES.GAME, {
                    state: {
                        gameUrl: modifiedUrl,
                    },
                });
            }

        } catch (error) {
            console.log(' error ->  ', error);
        }

        // url ? (newTab.location.href = url) : newTab.close();
        //ryan
        // if (url) window.location.href = url;
    };
    // #endregion

    // #region call api
    const getNewsData = async () => {
        try {
            const res = await getNews(i18n.language);
            if (!res || !Array.isArray(res)) throw Error('getNewsData error.');
            setNews(res);
        } catch (error) {
            setNews([]);
            console.error(error);
        }
    };
    const getGroups = async () => {
        try {
            const res = await getGameGroup(i18n.language);
            if (!res || !Array.isArray(res)) throw Error('getGroups error.');
            setGameGroups(res);

            if (!homeActiveTab) {
                const idx = res.findIndex(
                    (g) => g.id === res.filter((g) => g.defaultTab)?.[0]?.id
                );
                const index = idx !== -1 ? idx : 0;
                setActiveTab(index);
                updateHomeActiveTab(index);
            } else {
                setActiveTab(homeActiveTab);
            }
        } catch (error) {
            setGameGroups([]);
            console.error(error);
        }
    };

    // const getGames = async (groupId) => {
    //     try {
    //         const res = await getGameListByGroup(groupId);
    //         if (res.rtn !== STATUS_CODE.SUCCESS) throw Error(res.msg);
    //         setGames(res.game_list);
    //     } catch (error) {
    //         setGames([]);
    //         console.error(error);
    //     }
    // };

    const getGamesByListId = async (listId) => {
        try {
            const res = await getGameListByListId(listId);
            setGames(res);
        } catch (error) {
            setGames([]);
            console.error(error);
        }
    };

    const getGameUrl = async (gameId) => {
        try {
            let params = { gid: gameId };
            if (!user || user.mode === 1) {
                params.dvc = user?.device_id || getDVCInLS();
            } else {
                params.ukey = user.ukey;
            }
            const res = await getPlayGameUrl(params);
            if (res.rtn !== STATUS_CODE.SUCCESS) throw Error(res.msg);

            return res.play_url;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    const getBBAGGameUrl = async (gameId) => {
        try {
            let params = { gid: gameId };
            if (!user || user.mode === 1) {
                params.dvc = user?.device_id || getDVCInLS();
            } else {
                params.ukey = user.ukey;
            }
            const res = await getBBAGPlayGameUrl(params);
            if (res.rtn !== STATUS_CODE.SUCCESS) throw Error(res.msg);
            console.log(' getBBAGGameUrl -->  ', res)
            return res.url;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    // #endregion

    useEffect(() => {
        getNewsData();
        getGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    useEffect(() => {
        // setGames(null)

        const grp = gameGroups?.[activeTab]?.grp;
        const listId = gameGroups?.[activeTab]?.listID;
        if (grp !== NEWS_TAB_GRP) {
            if (listId) {
                getGamesByListId(listId);
            } else {
                setGames([])
            }
        }
        // grp !== NEWS_TAB_GRP ? getGames(grp) : setGames([]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    const categoryItemWidth =
        gameGroups?.length > 0 ? `${(1 / gameGroups.length) * 100}%` : '100%';
    return (
        <Tabs>
            <TabHeader>
                <nav>
                    {gameGroups.map((group, index) => (
                        <Tab
                            key={index}
                            className={classNames({
                                active: activeTab === index,
                            })}
                            style={{ width: categoryItemWidth }}
                            onClick={() => handleTabClick(index)}
                        >
                            <div>
                                <ReactSVG
                                    src={`${IMAGE_DOMAINS.BO}${group.imagePath}`}
                                />
                                <p>{group.name}</p>
                            </div>
                        </Tab>
                    ))}
                </nav>
            </TabHeader>
            {gameGroups[activeTab]?.grp === NEWS_TAB_GRP ? (
                <NewsPane>
                    {!news ? (
                        <div className="game-loading-wrap">
                            <img
                                src="images/shared/loading.gif"
                                alt="brand-logo"
                            />
                        </div>
                    ) : (
                        // : news.length === 0 ?
                        //     <div className='game-loading-wrap'>
                        //         目前沒有資料
                        //     </div>
                        news.map((n) => (
                            <NewItem
                                key={n.id}
                                onClick={() => setSelectedNews({ ...n })}
                                item={{ ...n }}
                            />
                        ))
                    )}
                </NewsPane>
            ) : (
                <TabPane>
                    {games !== null ? (
                        <>
                            <ul>
                                {games.map((game, itemIndex) => (
                                    <li key={itemIndex}>
                                        <a
                                            href="/#"
                                            // target="main"
                                            rel="noopener noreferrer"
                                            onClick={handleGameClick(game)}

                                        >
                                            <div style={{
                                                position: "relative"
                                            }}>
                                                <img
                                                    src={`${IMAGE_DOMAINS.ORIGIN}/${image_lang === 'CH' ? game.imagePathCH : game.imagePathEN}`}
                                                    alt={image_lang === 'CH' ? game.nameCH : game.nameEN}
                                                    title={image_lang === 'CH' ? game.nameCH : game.nameEN}
                                                // style={{ opacity: (game.isFree || isLogin) ? "1" : "0.5" }}


                                                />

                                                {!game.isFree && !isLogin && (
                                                    <div
                                                        style={{
                                                            width: '90%',
                                                            position: "absolute",
                                                            bottom: 0, // Aligned to the bottom of the image
                                                            left: '5%',
                                                            right: '5%', // Ensures the div spans the entire width of the image
                                                            borderRadius: "0 0 6px 6px",
                                                            height: "40%", // Mask covers the bottom half of the image
                                                            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black mask
                                                            display: "flex",
                                                            justifyContent: "flex-end", // Right-aligns the lock icon
                                                            alignItems: "center", // Bottom-aligns the lock icon

                                                        }}
                                                    >
                                                        <div style={{ width: "30%" }}>
                                                            <img alt='lock' src='/images/lock.png' />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>



                                            <p>
                                                {image_lang === 'CH' ? game.nameCH : game.nameEN}
                                            </p>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                            {/* {!games.length && <i>目前沒有資料</i>} */}
                        </>
                    ) : (
                        <div className="game-loading-wrap">
                            <img
                                src="images/shared/loading.gif"
                                alt="loading"
                            />
                        </div>
                    )}
                </TabPane>
            )
            }
            <NewsModal
                show={selectedNews !== null}
                onClose={() => setSelectedNews(null)}
                {...selectedNews}
            />
            <ConfirmModal
                show={loginConfirmShow}
                message={tCommon['common@login-to-access-game']}
                actionTitle={tCommon['common@login']}
                onConfirm={() => {
                    setLoginConfirmShow(false);
                    setOpenLoginModal(true)
                }}
                onCancel={() => setLoginConfirmShow(false)}
            />
            {
                openLoginModal && (
                    <LoginModal onToggleLoginModal={() => { setOpenLoginModal((prev) => !prev); }} />
                )
            }

        </Tabs >
    );
}
const NewItem = (props) => {
    const {
        item: { title, imagePath },
        onClick,
    } = props;
    // const { id, title, imagePath, sort, content } = item
    return (
        <>
            <div onClick={onClick}>
                <img src={`${IMAGE_DOMAINS.BO}${imagePath}`} alt={title} />
                {/* <img src={`/images/temp/news/${imagePath}`} /> */}
            </div>
        </>
    );
};

// #region css
const Tabs = styled.div`
    .game-loading-wrap {
        padding-top: 50px;
        width: 100%;
        > img {
            height: 40px;
            width: 40px;
        }
    }

    padding-bottom: 20px;
`;

const TabHeader = styled.div`
    overflow-x: scroll;

    nav {
        height: 5rem;
        text-shadow: none;
        width: 140%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        white-space: nowrap;
        overflow-y: hidden;
        box-sizing: border-box;

        @media (min-width: ${MAX_MOBILE_WIDTH}px) {
            width: 120%;
        }

        @media (min-width: ${MAX_PAD_WIDTH}px) {
            height: 6rem;
            width: 100%;
        }
    }
`;



const Tab = styled.a`
    text-align: center;
    padding: 0 0.5rem;
    flex: 1;
    height: 100%;
    transition: all 0.3s ease-in-out;
    color: ${({ theme }) => theme.primary};
    background: #fff;
    border: ${({ theme }) => `1px solid ${theme.primary}`};
    cursor: pointer;
    box-sizing: border-box;

    &.active {
        color: white;
        background: ${({ theme }) => theme.primary};
        path {
            fill: white;
        }
    }

    & > div {
        display: inline-block;
        max-width: 50px;
        width: 100%;
        height: 100%;

        @media (min-width: ${MAX_PAD_WIDTH}px) {
            max-width: 60px;
            padding-top: 5px;
        }

        & > div {
            display: block;
            line-height: 1;
        }


    }

    svg {
        width: 100%;
        height: 100%;
        path {
            fill: ${({ theme }) => theme.primary};
        }
    }

    p {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        font-size: 0.75rem;
        line-height: 1rem;
    }

    @media (min-width: ${MAX_PAD_WIDTH}px) {
        p {
            font-size: 1rem;
            line-height: 1rem;
        }
    }
`;

const TabPane = styled.div`
    ul {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        padding: 5px;
        height: 100%;
    }

    li {
        display: flex;
        justify-content: center;
        list-style: none;
        flex: 0 0 auto;
        width: 33.33333333%;

        @media (min-width: ${MAX_PAD_WIDTH}px) {
            width: 16.66666667%;
        }
    }

    a {
        width: 100%;
    }

    img {
        display: block;
        padding: 0.1rem;
        width: 90%;
        margin: 0 auto;
    }
    @media (min-width: ${MAX_PAD_WIDTH}px) {
        img {
            padding: 0px;
        }
    }
    p {
        width: 100%;
        margin: 0 auto;
        font-size: 14px;
        text-align: center;
        word-break: break-word;
    }

    i {
        display: block;
        padding-bottom: 1rem;
        text-align: center;
        font-style: normal;
        color: #666;
        height: 5rem;
    }
`;

const NewsPane = styled.div`
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 5px;
    height: 100%;

    > div {
        display: flex;
        justify-content: center;
        list-style: none;
        padding: 0.5rem;
        flex: 0 0 auto;
        width: 33.33333333%;
        cursor: pointer;
        padding: 0.1rem;

        @media (min-width: ${MAX_PAD_WIDTH}px) {
            width: 20%;
        }

        > img {
            width: 100%;
            height: auto;
        }
    }
`;
// #endregion
