import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import BaseModal from 'src/components/shared/modals/BaseModal';
import { FlexDirectionConfigs } from '@constants/config';
import { FONT_SIZE, MAX_PAD_WIDTH } from '@constants/numbers';
import { uploadAvatarProps } from '@api/user';
import { Button, Upload, message, Divider, Avatar } from 'antd';
import ImageSelectBar from '../ImageSelectBar';
import { Button as ViewButton } from '../View';

const AvatarPickerModal = ({
    show,
    user,
    onCancel,
    onSetAvatar,
    originalAvatar,
}) => {
    const { t } = useTranslation();
    const tUser = t('user', { returnObjects: true });
    const tCommon = t('common', { returnObjects: true });
    const [uploading, setUploading] = useState(false);
    const [selectedDefaultAvatar, setSelectedDefaultAvatar] = useState(null);

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const [defaultMaleAvatars, setDefaultMaleAvatars] = useState([]);
    const [defaultFemaleAvatars, setDefaultFemaleAvatars] = useState([]);

    useEffect(() => {
        const maleAvatars = [];
        const femaleAvatars = [];
        for (let i = 1; i <= 30; i++) {
            const paddedNumber = String(i).padStart(2, '0');
            maleAvatars.push(`/images/avatar/male_100X100_${paddedNumber}.png`);
            femaleAvatars.push(
                `/images/avatar/female_100X100_${paddedNumber}.png`
            );
        }
        setDefaultMaleAvatars(maleAvatars);
        setDefaultFemaleAvatars(femaleAvatars);
    }, []);

    useEffect(() => {
        if (originalAvatar) {
            setSelectedDefaultAvatar(originalAvatar);
        }
    }, [originalAvatar]);

    const handleDefaultAvatarClick = (avatarSrc) => {
        setSelectedDefaultAvatar(avatarSrc);
    };

    const hadleSubmit = () => {
        if (selectedDefaultAvatar) {
            onSetAvatar(selectedDefaultAvatar);
        }
    };

    const handleUploadChange = async (info) => {
        if (info.file.status === 'uploading') {
            setUploading(true);
            return;
        }

        if (info.file.status === 'done') {
            // Assuming the response contains the URL of the image
            const responseUrl = info.file.response.path;
            if (responseUrl) {
                message.success(
                    tUser['avatar@upload_success']?.replace(
                        '{filename}',
                        info.file.name
                    )
                );
                // console.log(' responseUrl ->  ', responseUrl);
                setSelectedDefaultAvatar(responseUrl);
            } else {
                // Handle the failure of obtaining a URL from the response
                message.error(`Failed to upload ${info.file.name} file.`);
            }

            setUploading(false);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setUploading(false);
        }
    };

    return (
        <BaseModal
            show={show}
            justifyContent={FlexDirectionConfigs.Center}
            alignItems={FlexDirectionConfigs.Center}
            handleOutsideClick={stopPropagation}
        >
            <AlertContnet onClick={stopPropagation}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        position: 'relative',
                        width: '100%',
                    }}
                >
                    <p className="alert-title">
                        {tUser['avatar@upload_or_select']}
                    </p>
                    <div className="close-icon" onClick={onCancel} />
                </div>

                <ScrollableContent>
                    <div className="avatar-wrap">
                        <Avatar
                            // style={{ marginRight: '12px' }}
                            size={100}
                            src={selectedDefaultAvatar || '/images/avatar.png'}
                        />
                    </div>

                    <Upload
                        {...uploadAvatarProps(
                            `avatar_${new Date().getTime()}`,
                            user.acc,
                            handleUploadChange
                        )}
                        showUploadList={false}
                    >
                        <ActionButton disabled={uploading}>
                            {tUser['avatar@upload']}
                        </ActionButton>
                    </Upload>
                    <StyledDivider />
                    {tUser['avatar@male']}
                    <ImageSelectBar
                        avatars={defaultMaleAvatars}
                        selectedDefaultAvatar={selectedDefaultAvatar}
                        handleDefaultAvatarClick={handleDefaultAvatarClick}
                    />
                    {tUser['avatar@female']}
                    <ImageSelectBar
                        avatars={defaultFemaleAvatars}
                        selectedDefaultAvatar={selectedDefaultAvatar}
                        handleDefaultAvatarClick={handleDefaultAvatarClick}
                    />
                </ScrollableContent>
                <ViewButton onClick={hadleSubmit}>
                    {tCommon['common@submit']}
                </ViewButton>
            </AlertContnet>
        </BaseModal>
    );
};

export default AvatarPickerModal;

const AlertContnet = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    background: white;
    padding: 16px 16px;
    gap: 10px;
    width: auto;
    max-width: 95vw;

    > p {
        margin: 10px 0px;
    }

    .avatar-wrap {
        heigh: 100px;
    }

    .alert-title {
        font-size: ${FONT_SIZE.ALERT_MODAL_TITLE};
        font-weight: 700;
    }

    @media (min-width: ${MAX_PAD_WIDTH}px) {
        width: auto;
        max-width: 800px;
        min-width: 300px;
    }

    .default-avatar-wrapper {
        overflow-x: auto; // Enables horizontal scrolling
        width: 100%; // Or set to another fixed value
        position: relative;
        display: flex;
        justify-content: center;
    }

    .default-avatar-container {
        display: flex;
        gap: 10px;
        overflow-x: auto; // Enables horizontal scrolling
        white-space: nowrap; // Prevents items from wrapping to the next line
        width: 90%;
        img {
            cursor: pointer;
            border: 2px solid transparent;
            user-select: none;
            &.selected {
                border-color: blue;
            }
        }
    }
    .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 1;
        &.left {
            left: 0;
        }
        &.right {
            right: 0;
        }
    }
    .close-icon {
        position: absolute;
        right: 15px;
        cursor: pointer;
        mask-image: url('/images/shared/close.svg');
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        min-width: 18px;
        min-height: 18px;
        background: black;
    }
`;

const ActionButton = styled(Button)`
    color: black !important;
    border: ${({ theme }) => `2px solid ${theme.primary}`};
    width: 100px;
    border-radius: 50px;
`;

const StyledDivider = styled(Divider)`
    margin-top: 16px; // reduce the top margin
    margin-bottom: 16px; // reduce the bottom margin

    @media (max-width: ${MAX_PAD_WIDTH}px) {
        // Assuming ${MAX_PAD_WIDTH} is your breakpoint for mobile devices
        margin-top: 8px; // even smaller for mobile
        margin-bottom: 8px; // even smaller for mobile
    }
`;

const ScrollableContent = styled.div`
    max-height: 70vh; // This height can be adjusted as needed
    overflow-y: auto; // This will create a scrollbar if the content exceeds the max-height
    text-align: center;
    // For mobile devices, you can have a different max-height
    @media (max-width: ${MAX_PAD_WIDTH}px) {
        max-height: 70vh; // Adjust for mobile layout as needed
    }
`;
