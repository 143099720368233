import { useEffect } from 'react';

const DetectSplash = () => {
    useEffect(() => {
        document.getElementById("splashView")?.classList?.add("trans-opacity-out");
        setTimeout(()=> document.getElementById("splashView")?.remove(), 1200)
    }, [])

    return null
}
export default DetectSplash
