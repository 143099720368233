import React, { useState } from 'react';
import styled from 'styled-components';
// import { BsFileEarmarkPersonFill } from 'react-icons/bs';
import { BiShareAlt, BiRefresh } from 'react-icons/bi';
import { MdAttachMoney } from "react-icons/md";

import { useTranslation } from 'react-i18next';
import { useGlobalProps } from '@hooks/useGlobalProps';
import { FONT_SIZE } from '@constants/numbers';
import { displayAmountWithDecimalPlace } from '@utils/util';
import { UserRoleEnum } from '@constants/config';
import { Avatar } from 'antd';
export const AfterLoginView = () => {
    const {
        balance,
        user,
        setShowShardModal,
        allowUserGrading,
        decimalPlace,
        displayProfilePic,
        avatarUrl,
        getBalance
    } = useGlobalProps();

    const [isDisplayPoint, setIsDisplayPoint] = useState(false)

    const { t } = useTranslation();
    const tUser = t('user', { returnObjects: true });
    const handleRefreshClick = () => {
        // Your logic here
        getBalance();
    };

    const handlePointClick = (e) => {
        e.preventDefault();

        setIsDisplayPoint((prev) => !prev)
    }

    const displayBalance = () => {
        if (balance?.bln === undefined && balance?.pts === undefined) {
            return '--'
        }

        return isDisplayPoint ? displayAmountWithDecimalPlace(
            balance?.pts,
            0
        ) : displayAmountWithDecimalPlace(
            balance?.bln,
            decimalPlace
        );
    }

    if (!user) return null;

    return (
        <AfterLoginViewWrap>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {displayProfilePic && (
                    <Avatar
                        style={{ marginRight: '12px' }}
                        size={50}
                        src={avatarUrl || '/images/avatar.png'}
                    />
                )}

                <label style={{ display: 'flex', alignItems: 'center' }}>
                    {!displayProfilePic && user && user.user_role !== 0 && (
                        <Avatar
                            style={{ marginRight: '4px' }}
                            size={20}
                            src={`/images/avatar/${user.user_role}.png`}
                        />
                    )}
                    {/* <BsFileEarmarkPersonFill style={{ marginRight: '4px' }} /> */}
                    <span>{user?.acc}</span>
                </label>
            </div>

            <label>
                <button onClick={handlePointClick} className="icon-button">
                    {!isDisplayPoint ? <MdAttachMoney size={20} /> : <div>&nbsp;{'P'}&nbsp;</div>}
                </button>

                <span className="primary">
                    {displayBalance()}
                </span>
                <button onClick={handleRefreshClick} className="icon-button">
                    <BiRefresh size={20} />
                </button>
            </label>

            {allowUserGrading ? (
                user &&
                ([UserRoleEnum.L2, UserRoleEnum.L3].includes(user.user_role) ||
                    !user.user_role) && (
                    <button onClick={() => setShowShardModal(true)}>
                        <BiShareAlt />
                        {tUser['share@friend']}
                    </button>
                )
            ) : (
                <button onClick={() => setShowShardModal(true)}>
                    <BiShareAlt />
                    {tUser['share@friend']}
                </button>
            )}
        </AfterLoginViewWrap>
    );
};

const AfterLoginViewWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding: 10px 5px;
    color: black;
    font-size: ${FONT_SIZE.NORMAL};

    .ant-avatar img {
        width: 100%;
        height: auto;
    }

    .icon-button {
        background: none;
        border: none;
        padding: 0;
        margin-left: 8px;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: black;
      }


    .primary {
        color: ${({ theme }) => theme.primary};
        font-weight: 700;
    }
    > label {
        display: flex;
        align-items: center;
        justify-content: center;
        > span {
            margin-left: 5px;
        }
    }

    > button {
        color: black !important;
        border: ${({ theme }) => `2px solid ${theme.primary}`};
        width: 70%;
        border-radius: 50px;
        > svg {
            width: auto;
            height: 23px;
            margin-bottom: -6px;
            margin-right: 3px;
        }
    }
`;
export default AfterLoginView;
