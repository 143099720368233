const ROUTES = {
    ROOT: '/',
    REGISTER: 'ra', //register with an affiliate
    ROOT_WITH_REF: 'rb', //register with an affiliate
    LIVE_CHAT: 'livechat',
    TELEGARM: 'https://telegram.me',
    WHATSAPP: 'https://wa.me',
    MAINTENANCE: 'maintenance',
    PROMOS: 'promos',
    PROFILE: 'profile',
    DEPOSIT: 'deposit',
    WITHDRAWAL: 'withdrawal',
    TRANSFER: 'transfer',
    BANKACCOUNT: 'bank-account',
    TRANSATION_LOG: 'transaction-log',
    BET_LOG: 'bet-log',
    AFFILIATE_LOG: 'affiliate-log',
    LOGOUT: 'logout',
    GAME: 'game',
    DEPOSIT_BANKING: 'deposit-banking',
    WITHDRAWAL_BANKING: 'withdraw-banking',
};

export const SWITCH_ROUTES = {
    ROOT: ROUTES.ROOT,
    REGISTER: `/${ROUTES.REGISTER}`,
    ROOT_WITH_REF: `/${ROUTES.ROOT_WITH_REF}`,
    LIVE_CHAT: `/${ROUTES.LIVE_CHAT}`,
    MAINTENANCE: `/${ROUTES.MAINTENANCE}`,
    PROMOS: `${ROUTES.ROOT}${ROUTES.HOME}`,
    PROFILE: `${ROUTES.ROOT}${ROUTES.PROFILE}`,
    DEPOSIT: `${ROUTES.ROOT}${ROUTES.DEPOSIT}`,
    WITHDRAWAL: `${ROUTES.ROOT}${ROUTES.WITHDRAWAL}`,
    TRANSFER: `${ROUTES.ROOT}${ROUTES.TRANSFER}`,
    BANKACCOUNT: `${ROUTES.ROOT}${ROUTES.BANKACCOUNT}`,
    TRANSATION_LOG: `${ROUTES.ROOT}${ROUTES.TRANSATION_LOG}`,
    BET_LOG: `${ROUTES.ROOT}${ROUTES.BET_LOG}`,
    AFFILIATE_LOG: `${ROUTES.ROOT}${ROUTES.AFFILIATE_LOG}`,
    LOGOUT: `${ROUTES.ROOT}${ROUTES.LOGOUT}`,
    GAME: `${ROUTES.ROOT}${ROUTES.GAME}`,
    DEPOSIT_BANKING: `${ROUTES.ROOT}${ROUTES.DEPOSIT_BANKING}`,
    WITHDRAWAL_BANKING: `${ROUTES.ROOT}${ROUTES.WITHDRAWAL_BANKING}`,
};

export default ROUTES;
