// https://www.i18next.com/translation-function/objects-and-arrays
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { LOCALS } from '@constants/config';
import { getLangInLS } from '@utils/ls';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // fallbackLng: LOCALS.en,
    lng: getLangInLS() || LOCALS.en,
    debug: true,
    caches: ['localStorage'],
    interpolation: {
      escapeValue: false, 
    }
  });


export default i18n;