import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Primary } from '@constants/colors';

const CollapseSideBarItem = (props) => {
    const { icon, menuName, path, active, navigate } = props;
    const onItemClick = () => {
        if (path) navigate(path);
    };

    return (
        <CollapseSideBarItemWrap className={classNames('', { active })}>
            <div className="sb-item-wrap" onClick={onItemClick}>
                {icon && (
                         <img
                            alt={menuName}
                            className="sb-itme-icon"
                            src={`/images/menu/${icon}.png`}
                        />
                )}
            </div>
        </CollapseSideBarItemWrap>
    );
};

const CollapseSideBarItemWrap = styled.div`
    box-sizing: border-box;
    height: 60px;
    background: ${Primary.White};
    // box-shadow: 0px 0px 2px #000000;
    border-radius: 10px;
    margin: 10px;
    cursor: pointer;

    &:hover, &.active  {
        background: ${({theme})=> theme.primary};
    }

    .sb-item-wrap {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
`;

export default CollapseSideBarItem;
