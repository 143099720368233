import React from 'react';
import styled from 'styled-components';

export const generateCaptcha = () => {
    const captchaChars = '0123456789';//abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ
    let captchaValue = '';
    for (let i = 0; i < 4; i++) {
        const randomIndex = Math.floor(Math.random() * captchaChars.length);
        captchaValue += captchaChars.charAt(randomIndex);
    }
    return captchaValue
}
const CaptchaView = ({ captchaValue }) => {
    return (
        <CaptchaWrap>
            <Captcha>
                {captchaValue}
            </Captcha>
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                {[...Array(5)].map((_, index) => (
                    <div
                        key={index}
                        style={{
                            position: 'absolute',
                            top: `${Math.random() * 100}%`,
                            left: `${Math.random() * 100}%`,
                            transform: `rotate(${Math.random() * 360}deg)`,
                            width: `${Math.random() * 120}px`,
                            height: '1px',
                            background: `hsl(${Math.random() * 360}, 80%, 50%)`
                        }}
                    />
                ))}
            </div>
        </CaptchaWrap>
    );
}

export default React.memo(CaptchaView)


const CaptchaWrap = styled.div`
    position: relative;
    color: white;
    display: block;
    height: 40px;
    width: 100%;
    overflow: hidden;
`
const Captcha = styled.span`
    position: absolute;
    left: 0;
    color: white;
    background: black;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 25px;
    letter-spacing: 8px;
    font-weight: 900;
    padding-left: 10px;
`