/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { STATUS_CODE } from '@constants/api';
import { MAX_PAD_WIDTH } from '@constants/numbers';
import { getLiveChatUrl } from '@api/other';
// import { getMyIp } from '@api/other';
// import { getDevice } from '@api/user';
import { getDVCInLS } from '@utils/ls';
import { useGlobalProps } from '@hooks/useGlobalProps';
import AlertModal from '@components/shared/modals/AlertModal';
import { isAndroid } from '@utils/util';
const INIT_ALERT = {
    show: false,
    title: '',
    message: '',
    children: null,
};
export default function LoginPage() {
    const [url, setUrl] = useState(null);
    const { user } = useGlobalProps();
    const { t, i18n } = useTranslation();
    const [alert, setAlert] = useState({ ...INIT_ALERT });

    const tCommon = t('common', { returnObjects: true });
    const getUrl = async () => {
        try {
            let params = {};
            if (!user || user.mode === 1) {
                params.dvc = user?.device_id || getDVCInLS();
            } else {
                params.ukey = user.ukey;
            }
            const res = await getLiveChatUrl(params);
            if (res.rtn !== STATUS_CODE.SUCCESS) throw Error(res.msg);
            setUrl(res.chat_url);
        } catch (error) {
            setUrl('');
            console.error(error);
        }
    };

    const closeAlert = () => setAlert({ ...INIT_ALERT });

    useEffect(() => {
        getUrl();
    }, [i18n.language, user]);

    useEffect(() => {
        if (isAndroid() && navigator && navigator.permissions) {
            navigator.permissions
                .query({ name: 'microphone' })
                .then(function (result) {
                    if (result.state === 'granted') {
                    } else if (result.state === 'prompt') {
                    } else if (result.state === 'denied') {
                        setAlert({
                            show: true,
                            title: tCommon['common@turnOnMicrophonePermission'],
                            children: <></>,
                            onConfirm: () => {
                                closeAlert();
                            },
                        });
                    }
                    result.onchange = function () {};
                });
        }
    }, []);

    return (
        <LiveChatPageWrap>
            <Helmet>
                <title>{tCommon['rootHeader@livechat']}</title>
            </Helmet>
            <ChatTitle>
                <h1>{tCommon['livechat@title']}</h1>
            </ChatTitle>
            <ChatWidget>
                {url && (
                    <iframe
                        allow="autoplay; display-capture *; picture-in-picture *; fullscreen *;microphone"
                        src={`${url}fromGW=true`}
                        allowtransparency="true"
                        id="chat-widget"
                        name="chat-widget"
                        title="chat widget"
                    />
                )}
            </ChatWidget>
            <AlertModal {...alert} />
        </LiveChatPageWrap>
    );
}

const LiveChatPageWrap = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: -webkit-fill-available;
    background-image: url(/images/livechat-bg.png);
    background-size: cover;
    background-repeat: no-repeat
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
  
`;

const ChatTitle = styled.div`
    display: none;
    color: white;

    @media (min-width: ${MAX_PAD_WIDTH}px) {
        display: block;
        text-align: right;
        padding-right: 70px;
    }
`;

const ChatWidget = styled.div`
    position: absolute;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 16px;
    border-radius: 10px;
    box-sizing: border-box;

    @media (min-width: ${MAX_PAD_WIDTH}px) {
        position: relative;
        width: 400px;
        max-height: 700px;
        height: calc(100vh - 30px);
    }

    iframe {
        background: white;
        border-radius: 10px;
        border-width: 0;
        width: 100%;
        height: 100%;
        display: block;
    }
`;
