import React from 'react';
import { ConfigProvider, Table } from 'antd';
import styled from 'styled-components';
import classNames from 'classnames';
import { FONT_SIZE, MAX_PAD_WIDTH } from '@constants/numbers';
import { EmptyView } from './View';


const PaginationTable = (props) => {
    const { tCommon, currentPage, totalPage, ...others } = props;
    const { dataSource, pagination } = props;
    const { pageSize, total } = pagination;
    // const { rowKey, dataSource, columns, pagination, onChange,} = props
    const totalRecords = total || dataSource?.length;
    let startIdx = (currentPage - 1) * pageSize + 1;
    let endIdx = startIdx + pageSize - 1;
    if (endIdx > totalRecords) endIdx = totalRecords;
    if (endIdx === 0) startIdx = 0;

    const countDesc = tCommon['table@current-count-and-total-count']
        ?.replace('{startIdx}', startIdx)
        ?.replace('{endIdx}', endIdx)
        ?.replace('{totalRecords}', totalRecords);
    const showEmptyPageBtns = dataSource?.length === 0 && totalRecords === 0;
    // const showCountDesc = dataSource?.length>0 && totalRecords > 0
    return (
        <TableWrap>
            <ConfigProvider renderEmpty={EmptyView(tCommon)}>
                <Table className="pagination-table" {...others} bordered />
            </ConfigProvider>

            <div
                className={classNames('pagination-text', { showEmptyPageBtns })}
            >
                <p>{countDesc}</p>

                {/* {showEmptyPageBtns && 
                    <EmptyChangePageBtns>
                        <div className='ant-pagination-prev'>
                            <span>{tCommon["table@prev-page"]}</span>
                        </div>

                        <div className='ant-pagination-next ant-pagination-disable'>
                            <span disabled>{tCommon["table@next-page"]}</span>
                        </div>
                    </EmptyChangePageBtns>
                } */}
            </div>
        </TableWrap>
    );
};

export default PaginationTable;

export const renderPagination =
    (tCommon, currentPage) => (_current, type, originalElement) => {
        if (type === 'prev') {
            return (
                <div>
                    <span>{tCommon['table@prev-page']}</span>
                    <div className="page-number">{currentPage}</div>
                </div>
            );
        }
        if (type === 'next') {
            return <span>{tCommon['table@next-page']}</span>;
        }
        return originalElement;
    };

const PAGINATION_BUTTON_HEIHGT = 35;
// const PAGINATION_BUTTON_WIDTH = 55
// const PAGINATION_TEXT_POSTION = Math.round(PAGINATION_BUTTON_HEIHGT + 32 / 2)
// width: ${PAGINATION_BUTTON_WIDTH}px;

const prevNextPageBtnStyle = `
    .ant-pagination-prev {
        width: auto;
        display: contents;
        & > div {
            display: flex;
        }
        span {
            width: auto;
            height: ${PAGINATION_BUTTON_HEIHGT}px;
            line-height: ${PAGINATION_BUTTON_HEIHGT}px;
            vertical-align: center;
            background: red;
            background: #d8d8d8;
            color: black;
            border-radius: 5px 0 0 5px;
        }
    }
    .ant-pagination-next {
        width: auto;
        height: ${PAGINATION_BUTTON_HEIHGT}px;
        line-height: ${PAGINATION_BUTTON_HEIHGT}px;
        vertical-align: center;
        background: #d8d8d8;
        color: black;
        border-radius: 0 5px 5px 0;

    text-align: center;
    }
`;

const TableWrap = styled.div`
    position: relative;

    .pagination-table {
        margin: 0 0 3rem 0;
    }
    .pagination-text {
        position: absolute;
        bottom: 3rem;
        left: 0;
        p {
            margin: 0;
        }
    }

    .ant-table {
        font-size: ${FONT_SIZE.NORMAL};
    }

    @media (max-width: ${MAX_PAD_WIDTH}px) {
        .pagination-text {
            width: 50%;
        }
    }

    .ant-pagination {
        .ant-pagination-simple-pager {
            display: none;
        }

        ${prevNextPageBtnStyle};

        .page-number {
            width: 40px;
            height: ${PAGINATION_BUTTON_HEIHGT}px;
            line-height: ${PAGINATION_BUTTON_HEIHGT}px;
            vertical-align: center;
            background: ${({ theme }) => theme.primary};
            color: #fff;
            border-radius: 0;
        }
    }

    .showEmptyPageBtns {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
`;
