import React from 'react';
import styled from 'styled-components';

const IframeContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
`;

const StyledIframe = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
`;

// const DismissButton = styled.button`
//     position: absolute;
//     top: 10px;
//     right: 10px;
//     display: flex;
//     align-items: center; /* vertically aligns items in the center */
//     justify-content: center; /* horizontally aligns items in the center */
//     background-color: rgba(0, 0, 0, 0.6); /* semi-transparent black */
//     color: white;
//     border: none;
//     width: 30px;
//     height: 30px;
//     cursor: pointer;
//     border-radius: 50%; /* circle shape */
//     font-size: 20px; /* size of the cross */
//     z-index: 10000; /* Ensure it's above the iframe */
//     box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); /* Optional: shadow for better visibility */

//     &:hover {
//         background-color: rgba(0, 0, 0, 0.8); /* slightly darker on hover */
//     }
// `;

const FullScreenIframe = ({ url, onDismiss, innerRef }) => {
    return (
        <IframeContainer>
            {/* <DismissButton onClick={onDismiss}>✖</DismissButton> */}
            <StyledIframe
                ref={innerRef}
                src={url}
                title="Fullscreen Iframe"
                // allowfullscreen="true"
                allow="autoplay; display-capture *; picture-in-picture *; fullscreen *;microphone"
            />
        </IframeContainer>
    );
};

export default FullScreenIframe;
