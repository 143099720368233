/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AffiliateOverview from '@components/shared/AffiliateOverview';
import {
    Button,
    Column,
    ColumnWrap,
    CustomColumn,
    PaymentPageWrap,
    PolicyWrap,
    PaymentOptionOutlineButton,
    PaymentImg,
} from '@components/shared/View';
import { useGlobalProps } from '@hooks/useGlobalProps';
import ConfirmModal from '@components/shared/modals/ConfirmModal';
import AlertModal from '@components/shared/modals/AlertModal';
import { FONT_SIZE } from '@constants/numbers';
import { parseErrorRes } from '@utils/parseErrors';
import styled from 'styled-components';
import { setOptIn, SemiAutoDeposit, getDepositBankAccountInfos } from '@api/payment';
import { STATUS_CODE } from '@constants/api';
import { getDPolicy } from '@api/other';

import { Spin, message, Card, Statistic } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { parseBankRes } from '@components/bankAccount/SingleBankTable';
import { displayAmountWithPrecision } from '@utils/util';
import { getBankOutList } from '@api/other';
import { BANK_METAS_INFO } from '@constants/config'
import AmountColumn from '@components/shared/AmountColumn';
import BankQRCodeModal from '@components/shared/modals/BankQRCodeModel';
import MetaPixel from '@utils/metaPixel';

const INIT_INPUT_FILED = {
    accountName: '',
    amount: '',
    lastFive: '',
};
const INIT_ALERT = {
    show: false,
    title: '',
    DepositAWGeneralPage,
};
export default function DepositAWGeneralPage() {
    const [inputField, setInputField] = useState({ ...INIT_INPUT_FILED });
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showQRCodeModal, setShowQRCodeModal] = useState(false);
    const [alert, setAlert] = useState({ ...INIT_ALERT });
    const [policy, setPolicy] = useState(null);
    const {
        user,
        userData,
        depositAmountOptions,
        mainUnit,
        mainUnitRaw,
        secondUnitRaw,
        unitRate,
        getBalance,
        isSemiAutoDeposit,
        depositQRCodeOptions,
        pixelCodes,
        depositStepperOptions
    } = useGlobalProps(); //, balance
    const { i18n, t } = useTranslation();
    const tCommon = t('common', { returnObjects: true });
    const tPayment = t('payment', { returnObjects: true });
    const tBank = t('bank', { returnObjects: true });
    const tError = t('error', { returnObjects: true });
    const [messageApi, contextHolder] = message.useMessage();
    const [isLoading, setIsLoading] = useState(false);

    const [bankInfo, setBankInfo] = useState({});

    const [paymentOption, setPaymentOption] = useState('');

    const [amountInfos, setAmountInfos] = useState([]);

    const [bankList, setBankList] = useState([]);

    const onPaymentOptionClick = (e) => {
        setPaymentOption(e.target.name);
    };

    const maxAmount = user?.ki_max || 0;


    const getPolicy = async () => {
        const res = await getDPolicy(i18n.language);
        if (res && res[0]) setPolicy(res[0]);
        else setPolicy(null);
    };

    useEffect(() => {
        getBankOutList().then((res) => {
            if (res?.rtn === STATUS_CODE.SUCCESS && res?.bank_list) {
                setBankList(res?.bank_list.map((e) => {
                    const [bankcode] = e;
                    return bankcode.trim();
                }));
            }
        });
        console.log(bankList)
    }, []);

    const getBankInfos = async (fetechedUser) => {
        const res = await getDepositBankAccountInfos({
            ukey: fetechedUser.ukey,
        });
        if (res) {
            if (res.acc_list) {
                const banks = parseBankRes(res.acc_list);
                const result = banks.reduce((obj, item) => {
                    obj[item.bankcode.trim()] = item;
                    return obj;
                }, {});

                setBankInfo(result);
            }
        }
    };


    const handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;
        let val = target.value;
        if (name === 'amount' && maxAmount > 0) {
            if (val > maxAmount) val = maxAmount;
        }

        setInputField((prev) => {
            return { ...prev, [name]: val };
        });
    };


    const semiAutoCheckError = () => {
        let message = null;

        if (!inputField.lastFive) {
            message = tError['error@must_fill_receipt_number'];
        } else if (inputField.lastFive.length < 6) {
            message = tError['error@at_least_6_characters'];
        }

        if (!paymentOption) {
            message = tError['error@deposit_option_required'];
        }

        return message;
    };

    const checkError = () => {
        let message = null;

        if (!inputField.amount) {
            message = tError['error@must_fill_amount'];
        } else if (!inputField.lastFive) {
            message = tError['error@must_fill_receipt_number'];
        } else if (inputField.lastFive.length < 6) {
            message = tError['error@at_least_6_characters'];
        }

        if (!paymentOption) {
            message = tError['error@deposit_option_required'];
        }

        try {
            if (
                (!depositAmountOptions || !depositAmountOptions.display) &&
                parseInt(inputField.amount) < userData?.bank_in_min
            ) {
                message = tError['error@deposit_insufficient_amount'];
            }
        } catch (e) {
            message = tError['error@deposit_insufficient_amount'];
        }

        return message;
    };

    useEffect(() => {
        if (user?.ukey) getBalance();
    }, [user, getBalance]);

    useEffect(() => {
        if (Object.keys(depositAmountOptions).length > 0) {
            const infos = [];
            if (depositAmountOptions.amounts) {
                for (const amount of depositAmountOptions.amounts) {
                    const info = {
                        amount,
                        currency: depositAmountOptions.currency,
                        pointUnit: depositAmountOptions.pointUnit,
                        points: depositAmountOptions.rate
                            ? amount * parseFloat(depositAmountOptions.rate)
                            : 0,
                        rate: depositAmountOptions.rate
                            ? parseFloat(depositAmountOptions.rate)
                            : 0,
                    };
                    infos.push(info);
                }
            }

            setAmountInfos(infos);
        }
    }, [depositAmountOptions]);

    useEffect(() => {
        if (userData?.user_name)
            setInputField({ ...inputField, accountName: userData.user_name });
    }, [userData]);

    useEffect(() => {
        if (user) {
            getBankInfos(user);
        }
    }, [user]);


    useEffect(() => {
        getPolicy();
    }, [i18n.language]);

    const doDeposit = async () => {
        setIsLoading(true);
        setShowConfirmModal(false);
        let res;

        if (isSemiAutoDeposit) {
            res = await SemiAutoDeposit({
                ukey: user.ukey,
                tc: inputField.lastFive.trim(),
                bank: paymentOption,
                tl: bankInfo[paymentOption].tcLength
            });
        } else {
            res = await setOptIn({
                ukey: user.ukey,
                val: inputField.amount,
                prcnm: paymentOption,
                tc: inputField.lastFive,
                ob: paymentOption,
                oa: bankInfo[paymentOption].account
            });
        }
        setIsLoading(false);

        if (res?.rtn === STATUS_CODE.SUCCESS) {

            setAlert({
                show: true,
                title: tCommon['common@success'],
                message: tPayment['payment@deposit-successfully'],
            });
            setInputField({ ...INIT_INPUT_FILED });
        } else {
            setAlert({
                show: true,
                title: tCommon['common@failed'],
                message: parseErrorRes(tError, res),
            });
        }
    };

    const handleSelectAmount = (val) => {
        setInputField((prev) => {
            if (depositStepperOptions && depositStepperOptions.display && parseFloat(val) > 0 && prev.amount !== '') {
                if (maxAmount > 0 && (parseFloat(prev.amount) + parseFloat(val) > maxAmount)) {
                    return { ...prev, amount: maxAmount };
                }
                return { ...prev, amount: parseFloat(prev.amount) + parseFloat(val) };
            } else {
                return { ...prev, amount: val === 0 ? '' : val };
            }
        });
    };



    const displayPolicy = policy && policy.content?.length > 0;
    return (
        <PaymentPageWrap>
            {pixelCodes && pixelCodes.map((e) => <MetaPixel code={e} />)}

            {contextHolder}
            <AffiliateOverview />
            <ColumnWrap className="mt-20 mb-1 pl-3" alignItems="start">

                <>
                    <CustomColumn
                        title={tPayment['payment@deposit-method']}
                        child={
                            <>
                                <div style={{ display: 'flex' }}>
                                    {bankList.map((code) => {
                                        const info = BANK_METAS_INFO[code];
                                        if (!info) return null;
                                        if (!bankInfo[info.name] || !bankInfo[info.name].account) return null;
                                        return <PaymentOptionOutlineButton
                                            key={code}
                                            onClick={onPaymentOptionClick}
                                            name={info.name}
                                            isSelected={
                                                paymentOption === info.name
                                            }
                                        >
                                            <PaymentImg
                                                width={info.imageWidth ? info.imageWidth : '100px'}
                                                src={info.imageUrl}
                                                alt={info.name}
                                                name={info.name}
                                            />
                                        </PaymentOptionOutlineButton>
                                    })}
                                </div>
                                {paymentOption && bankInfo[paymentOption] && bankInfo[paymentOption].account && (
                                    <BankInfo>
                                        <Card>
                                            <Statistic
                                                title={`${paymentOption} ${tBank['bank@bank-code']}`}
                                                valueRender={() => {
                                                    return (
                                                        <>
                                                            <BankInfo>
                                                                {`${bankInfo[paymentOption].account}`}
                                                                <CopyToClipboard
                                                                    text={
                                                                        bankInfo[
                                                                            paymentOption
                                                                        ]
                                                                            .account
                                                                    }
                                                                    style={{
                                                                        marginLeft: 16,
                                                                    }}
                                                                    onCopy={() => {
                                                                        messageApi.open(
                                                                            {
                                                                                type: 'success',
                                                                                content:
                                                                                    tPayment[
                                                                                    'payment@acc-copied-success'
                                                                                    ],
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    <div className="copy-icon" />
                                                                </CopyToClipboard>
                                                                {depositQRCodeOptions
                                                                    && depositQRCodeOptions[paymentOption]
                                                                    && depositQRCodeOptions[paymentOption].display === true && <div className="qrcode-icon" style={{ marginLeft: '8px' }}
                                                                        onClick={() => {
                                                                            setShowQRCodeModal(true)
                                                                        }} />}
                                                            </BankInfo>
                                                        </>
                                                    );
                                                }}
                                            ></Statistic>
                                        </Card>
                                    </BankInfo>
                                )}
                            </>
                        }
                    />
                    {isSemiAutoDeposit ? <></> : <AmountColumn
                        title={tPayment['payment@deposit-amount']}
                        mainUnit={mainUnit}
                        displayAmountOptions={depositAmountOptions && depositAmountOptions.display}
                        amount={inputField.amount}
                        onAmountChange={handleInputChange}
                        onSelecteAmount={handleSelectAmount}
                        amountInfos={amountInfos}
                        displayStepper={depositStepperOptions && depositStepperOptions.display}

                    />}

                    <Column
                        title={tPayment['payment@last-six-receipt-number']}
                        name={'lastFive'}
                        value={inputField.lastFive}
                        onChange={handleInputChange}
                    />
                </>

                <CustomColumn
                    notRequired
                    child={
                        <DepositInfo>
                            <p>
                                {userData?.bank_in_min > 0 && !maxAmount && `${tPayment['payment@deposit-min-amount']}: ${displayAmountWithPrecision(
                                    userData?.bank_in_min
                                )}${mainUnit && ` ${mainUnit}`
                                    }`}
                                {!userData?.bank_in_min && maxAmount > 0 && `${tPayment['payment@deposit-max-amount']}: ${displayAmountWithPrecision(
                                    maxAmount
                                )}${mainUnit && ` ${mainUnit}`
                                    }`}
                                {userData?.bank_in_min > 0 && maxAmount > 0 && `${tPayment['payment@deposit-amount-limit']
                                    }: ${displayAmountWithPrecision(
                                        userData?.bank_in_min
                                    )} ~ ${displayAmountWithPrecision(
                                        maxAmount
                                    )}${mainUnit && ` ${mainUnit}`
                                    }`}
                            </p>
                        </DepositInfo>
                    }
                />

                <CustomColumn
                    child={
                        <SpinContainer>
                            {isLoading ? (
                                <Spin
                                    style={{
                                        top: '25%',
                                        left: '25%',
                                        position: 'absolute',
                                    }}
                                />
                            ) : (
                                <Button
                                    onClick={() => {
                                        const message = isSemiAutoDeposit ? semiAutoCheckError() : checkError();
                                        if (message) {
                                            setAlert({
                                                show: true,
                                                title: tCommon['common@failed'],
                                                message: message,
                                            });
                                            return;
                                        }
                                        if (pixelCodes && pixelCodes.length > 0) {
                                            pixelCodes.forEach((code) => window.fbq('trackSingleCustom', code, 'DepositSubmission'));
                                        }

                                        setShowConfirmModal(true);
                                    }}
                                >
                                    {tCommon['common@submit']}
                                </Button>
                            )}
                        </SpinContainer>
                    }
                    notRequired
                />
            </ColumnWrap>

            {displayPolicy && (
                <PolicyWrap>
                    <div dangerouslySetInnerHTML={{ __html: policy.content }} />
                </PolicyWrap>
            )}

            <BankQRCodeModal
                show={showQRCodeModal}
                title={`${paymentOption} ${tBank['bank@bank-code']}`}
                onConfirm={() => setShowQRCodeModal(false)}
                QRCodeValue={bankInfo[paymentOption] && bankInfo[paymentOption].name} />

            <ConfirmModal
                show={showConfirmModal}
                title={tPayment['payment@confirm-deposit']}
                children={
                    <DepositInfo>
                        {
                            isSemiAutoDeposit ? <p style={{ color: 'red' }}>{tPayment["payment@please_wait"]}</p> : <p className="text-center">{`${tPayment['payment@deposit-amount']}: ${inputField.amount}${mainUnitRaw && ` ${mainUnitRaw}`
                                }${secondUnitRaw && unitRate && ` (${inputField.amount * parseFloat(unitRate)} ${secondUnitRaw})`
                                }`}</p>
                        }
                    </DepositInfo>
                }
                onConfirm={doDeposit}
                onCancel={() => setShowConfirmModal(false)}
            />
            <AlertModal
                {...alert}
                onConfirm={() => setAlert({ ...INIT_ALERT })}
            />
        </PaymentPageWrap >
    );
}

const DepositInfo = styled.div`
    color: black;
    > p {
        margin: 0;
        margin-top: 5px;
        font-size: ${FONT_SIZE.ALERT_MODAL_TITLE};
        font-weight: 800;
    }
    .text-center {
        text-align: center;
    }
`;

const BankInfo = styled.div`
    color: black;
    margin-top: 16px;
    display: flex;
    align-items: center;
    .copy-icon {
        cursor: pointer;
        mask-image: url('/images/shared/copy.svg');
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        min-width: 18px;
        min-height: 18px;
        background: black;
    }
    .qrcode-icon {
        cursor: pointer;
        mask-image: url('/images/shared/qrcode.svg');
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        min-width: 18px;
        min-height: 18px;
        background: black;
    }
`;

const SpinContainer = styled.div`
    textalign: center;
    position: relative;
    width: 100px;
    height: 40px;
`;
