import { useState, useEffect } from 'react';

// Custom hook to detect if the user is on a mobile device
function useMobileDetect() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Regular expression for mobile user agent detection
        const mobileRegex =
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

        if (navigator && navigator.userAgent.match(mobileRegex)) {
            setIsMobile(true);
        }
    }, []);

    return isMobile;
}

export default useMobileDetect;
