import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BiGift } from 'react-icons/bi'; //BiShareAlt,
import { FONT_SIZE, MAX_PAD_WIDTH } from '@constants/numbers';
import { useGlobalProps } from '@hooks/useGlobalProps';
import useIsMobile from '@hooks/useIsMobile';
import ConfirmModal from '@components/shared/modals/ConfirmModal';
import AlertModal from '@components/shared/modals/AlertModal';
import { doBonusIn } from '@api/payment';
import { STATUS_CODE } from '@constants/api';
import { parseErrorRes } from '@utils/parseErrors';
import { displayAmountWithDecimalPlace } from '@utils/util';
import { UserRoleEnum } from '@constants/config';

const INIT_ALERT = {
    show: false,
    title: '',
};
export default function AffiliateOverview() {
    const {
        user,
        balance,
        userData,
        reGetuserInfo,
        allowUserGrading,
        decimalPlace,
    } = useGlobalProps(); //setShowShardModal,
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [alert, setAlert] = useState({ ...INIT_ALERT });
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    const tCommon = t('common', { returnObjects: true });
    const tUser = t('user', { returnObjects: true });
    const tPayment = t('payment', { returnObjects: true });
    const tError = t('error', { returnObjects: true });
    const hasBonus = userData?.bonus && userData?.bonus > 1;
    const cashText = isMobile
        ? tPayment['payment@mobile-balance']
        : tPayment['payment@balance'];
    const bonusText = isMobile
        ? tPayment['payment@mobile-bonus-balance']
        : tPayment['payment@bonus-balance'];
    const withdrawalBonus = async () => {
        const val = parseInt(userData.bonus);
        if (!val || val < 1) {
            setOpenConfirmModal(false);
            return;
        }
        // const succRes = {"rtn":0,"msg":"success","blnAft":1153.00,"bnsAft":0.66,"nkey":"bb541e86-3e91-de90-f7b8-518987c4961e"}
        const res = await doBonusIn({
            val,
            ukey: user.ukey,
            tkey: user.nkey,
        });
        setOpenConfirmModal(false);
        if (res?.rtn === STATUS_CODE.SUCCESS) {
            reGetuserInfo();
        } else {
            setAlert({
                show: true,
                title: tCommon['common@failed'],
                message: parseErrorRes(tError, res),
            });
        }
    };

    const lData = (
        <>
            <p>
                {tUser['aff@l1']} <br />{' '}
                <AffOverivewValue>{userData?.l1_cnt}</AffOverivewValue>
            </p>
            <div className="aff-overview-divide" />
            <p>
                {tUser['aff@l2']} <br />{' '}
                <AffOverivewValue>{userData?.l2_cnt}</AffOverivewValue>
            </p>
            <div className="aff-overview-divide" />
            <p>
                {tUser['aff@l3']} <br />{' '}
                <AffOverivewValue>{userData?.l3_cnt}</AffOverivewValue>
            </p>
        </>
    );
    const btns = (
        <>
            {/* <div onClick={()=> setShowShardModal(true)}><BiShareAlt />{tUser["share@friend"]}</div> */}
            <div
                className={!hasBonus ? 'disabled' : ''}
                onClick={() => (hasBonus ? setOpenConfirmModal(true) : null)}
            >
                <BiGift /> {tPayment['payment@withdrawal-bonus']}
            </div>
        </>
    );

    const modals = (
        <>
            <ConfirmModal
                show={openConfirmModal}
                message={tPayment['payment@withdrawal-bonus']}
                children={<p>{`${parseInt(userData?.bonus)}`}</p>}
                onConfirm={withdrawalBonus}
                onCancel={() => setOpenConfirmModal(false)}
            />
            <AlertModal
                {...alert}
                onConfirm={() => setAlert({ ...INIT_ALERT })}
            />
        </>
    );

    const displayBalance = displayAmountWithDecimalPlace(
        balance?.bln,
        decimalPlace
    );
    const displayBonus = displayAmountWithDecimalPlace(
        Math.floor(userData?.bonus),
        decimalPlace
    );
    if (isMobile)
        return (
            <AffiliateOverviewMWrap>
                {allowUserGrading ? (
                    user &&
                        (user.user_role === UserRoleEnum.L3 || !user.user_role) ? (
                        <div>{lData}</div>
                    ) : (
                        <div></div>
                    )
                ) : (
                    <div>{lData}</div>
                )}
                <div>
                    <div>
                        <p>
                            {cashText}{' '}
                            <AffOverivewValue>
                                {displayBalance}
                            </AffOverivewValue>
                        </p>
                        <p>
                            {bonusText}{' '}
                            <AffOverivewValue>{displayBonus}</AffOverivewValue>
                        </p>
                    </div>
                    <div className="aff-overview-m-btns">{btns}</div>
                </div>
                {modals}
            </AffiliateOverviewMWrap>
        );

    return (
        <AffiliateOverviewWrap>
            <div className="aff-overview-l-wrap">
                {allowUserGrading ? (
                    user &&
                    (user.user_role === UserRoleEnum.L3 || !user.user_role) && (
                        <div>{lData}</div>
                    )
                ) : (
                    <div>{lData}</div>
                )}
                <div>
                    <p>
                        {cashText} <br />{' '}
                        <AffOverivewValue>{displayBalance}</AffOverivewValue>
                    </p>
                    <div className="aff-overview-divide" />
                    <p>
                        {bonusText} <br />{' '}
                        <AffOverivewValue>{displayBonus}</AffOverivewValue>
                    </p>
                </div>
            </div>
            <div className="aff-overview-btns">{btns}</div>
            {allowUserGrading ? (
                user &&
                (user.user_role === UserRoleEnum.L3 || !user.user_role) && (
                    <div className="aff-overview-center-border" />
                )
            ) : (
                <div className="aff-overview-center-border" />
            )}
            {modals}
        </AffiliateOverviewWrap>
    );
}

const AffiliateOverviewMWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 16px;
    border-radius: 1rem;
    background-color: rgb(33, 37, 41);
    color: white;
    font-size: 12px;
    > div:first-child {
        display: flex;
        width: 100%;
        > p {
            flex: 1;
            text-align: center;
            margin: 0px;
        }
    }
    > div:nth-child(2) {
        display: flex;
        justify-content: space-between;
        padding-top: 16px;
        align-items: flex-end;
        p {
            margin: 0px;
        }
        .aff-overview-m-btns {
            display: flex;
            flex-direction: column;
            gap: 10px;
            > div {
                cursor: pointer;
                text-align: center;
                padding: 6px 23.5px 5px 23px;
                border-radius: 5px;
                background: ${({ theme }) => theme.primary};
                font-size: ${FONT_SIZE.NORMAL};
                > svg {
                    margin-bottom: -3px;
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }

    .disabled {
        background: gray !important;
        cursor: not-allowed !important;
    }
`;

const AffiliateOverviewWrap = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    padding: 32px 16px;
    border-radius: 1rem;
    background-color: rgb(33, 37, 41);
    color: white;
    font-size: ${FONT_SIZE.NORMAL};

    .aff-overview-l-wrap {
        display: flex;
        flex: 2;
        flex-direction: column;
        padding: 0px 15%;
        text-align: center;
        gap: 20px;
        > div {
            display: flex;
            justify-content: space-between;
            > p {
                margin: 0px;
            }
        }
    }

    .aff-overview-btns {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-end;
        > div {
            cursor: pointer;
            text-align: center;
            width: 80%;
            padding: 10px 17px 11px 11px;
            border-radius: 5px;
            font-size: ${FONT_SIZE.ALERT_MODAL_TITLE};
            background: ${({ theme }) => theme.primary};
            > svg {
                width: 25px;
                height: 25px;
                margin-bottom: -5px;
            }
        }
    }

    .disabled {
        background: gray !important;
        cursor: not-allowed !important;
    }

    .aff-overview-divide {
        height: 90%;
        margin: auto;
        width: 1px;
        background: #636363;
    }
    .aff-overview-center-border {
        width: 96%;
        height: 1px;
        position: absolute;
        top: calc(50% - 6px);
        left: 2%;
        border-top: 1px solid #636363;
    }
`;

const AffOverivewValue = styled.span`
    font-weight: 800;
    font-size: 24px;

    @media (min-width: ${MAX_PAD_WIDTH}px) {
        font-size: ${FONT_SIZE.LARGE};
    }
`;
