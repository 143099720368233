/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Input } from 'antd';
import BaseModal from 'src/components/shared/modals/BaseModal';
import { FlexDirectionConfigs } from '@constants/config';
import { getCurrentYYYYMMDDhhmmss } from '@utils/util';
import { FONT_SIZE, MAX_MOBILE_WIDTH, MAX_PAD_WIDTH } from '@constants/numbers';
import { IMAGE_DOMAINS } from '@constants/api';
import { QRCode } from 'antd';
import { useGlobalProps } from '@hooks/useGlobalProps';
import { getJKeyInLS } from '@utils/ls';
import AlertModal from '@components/shared/modals/AlertModal';
import useIsMobileWidth from '@hooks/useIsMobileWidth';
import debounce from 'lodash/debounce';
import { SWITCH_ROUTES } from '@constants/routes';

// import { Form, InputGroup } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

const LogoImgUrl = `${IMAGE_DOMAINS.LOGOS
    }/desktop-logo.png?gettime=${getCurrentYYYYMMDDhhmmss()}`;
const SharedLayout2Modal = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const { user } = useGlobalProps();
    const isMobile = useIsMobileWidth();

    const { showShardModal, setShowShardModal } = useGlobalProps();
    const { t } = useTranslation();
    const tCommon = t('common', { returnObjects: true });
    const tModal = t('modal', { returnObjects: true });
    const tUser = t('user', { returnObjects: true });

    const stopPropagation = (e) => {
        e.stopPropagation();
    };
    const onClose = () => {
        setShowShardModal(false);
    };
    const onCopy = () => {
        setShowAlert(true);
    };

    const debouncedResize = debounce(() => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);

    }, 200);


    const renderQRCode = () => {
        // let size = isMobile ? width * 0.7 : isPad ? width * 0.5 : 300;
        let size = isMobile ? width * 0.7 : width * 0.5;
        if (size > 350) size = 350;
        const diff = size / (height * 0.95) - (285 / (640 * 0.95));
        if (diff > 0) {
            size -= diff * 640 * (640 * 0.95) / 285
        }
        return (
            <QRCode
                className="shared-qrcode"
                value={registerUrl}
                style={{
                    padding: size / 20,
                    border: '1px solid black',
                }}
                // type='canvas'
                size={size}
            />
        );
    };
    useEffect(() => {
        const onResizeWithAngle = (event) => {
            debouncedResize();
            // const onResize = () => {
            //     resize();
            //     window.removeEventListener('resize', onResize);
            // };

            // window.addEventListener('resize', onResize);
        };

        window.addEventListener('resize', onResizeWithAngle);
        window.addEventListener('orientationchange', onResizeWithAngle);
        return () => {
            window.removeEventListener('orientationchange', onResizeWithAngle);
            window.removeEventListener('resize', onResizeWithAngle);
        };
    }, []);

    const jkey = getJKeyInLS() || user?.jkey;
    // const registerUrl = `${window.location.origin}${SWITCH_ROUTES.REGISTER}?jkey=${jkey}`;
    // const registerUrl = `${window.location.origin}?jkey=${jkey}`;
    const registerUrl = `${window.location.origin}${SWITCH_ROUTES.ROOT_WITH_REF}?jkey=${jkey}`;

    return (
        <>
            <BaseModal
                show={showShardModal}
                justifyContent={FlexDirectionConfigs.Center}
                alignItems={FlexDirectionConfigs.Center}
                handleOutsideClick={stopPropagation}
            >
                <SharedModalContent
                    onClick={stopPropagation}
                    qrCodeWidth={
                        isMobile ? '90%' : `${width * 0.5}px`
                    }
                >
                    <div className="shared-model-header">
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                            <span><h3>{`${tUser['user@affiliate']}`}</h3></span>
                            <span style={{ paddingLeft: '8px' }}><p>{`${user?.acc}`}</p></span>
                        </div>
                        <div className="close-icon" onClick={onClose} />
                    </div>
                    <div className="shard-modal-content">
                        <div className="logo">
                            <img alt="logo" src={LogoImgUrl} />
                        </div>

                        {renderQRCode()}

                        <Input
                            style={{ color: 'black', textAlign: 'center', fontSize: 16 }}
                            className="shared-aff-field"
                            value={tUser['user@share_prompt']}
                            disabled={true}
                        />

                        <CopyToClipboard text={registerUrl} onCopy={onCopy}>
                            <button className="shared-btn">
                                {registerUrl}
                            </button>
                        </CopyToClipboard>
                    </div>
                </SharedModalContent>
            </BaseModal>
            <AlertModal
                show={showAlert}
                title={tCommon['common@success']}
                actionTitle={tCommon['common@copy']}
                children={
                    <AlertMsg>
                        <p>
                            {tModal['shared@copy-success']}
                            <br />
                            {/* {registerUrl} */}
                        </p>
                    </AlertMsg>
                }
                onConfirm={() => setShowAlert(false)}
            />
        </>
    );
};

export default SharedLayout2Modal;

const SharedModalContent = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    background: white;

    height: auto;
    width: 95vw;
    max-height: 95%;
    max-width: 500px;
    overflow-y: auto;

    // @media (min-width: ${MAX_PAD_WIDTH}px) {
    //     height: auto;
    //     width: 50vw;
    //     min-width: 500px;
    //     max-height: 95vh;
    //     overflow-y: auto;
    // }

    // @media (max-width: ${MAX_MOBILE_WIDTH}px) {
    //     height: auto;
    //     width: 95vw;
    //     min-width: 300px;
    //     max-height: 95vh;
    //     overflow-y: auto;
    // }

    > div {
        padding: 0px 16px;
        width: 100%;
    }
    .shared-model-header {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid lightgray;
        height: 3rem;
    }
    .shard-modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.5rem 0px;

        .shared-aff-field {
            width: ${(props) => props.qrCodeWidth};
            margin-top: 5px;
            max-width: 350px;

            // @media (min-width: ${MAX_PAD_WIDTH}px) {
            //     width: ${(props) => props.qrCodeWidth};
            // }

            // @media (min-width: ${MAX_MOBILE_WIDTH}px) {
            //     width: ${(props) => props.qrCodeWidth};
            // }

            span {
                border-color: black;
            }
            svg {
                path {
                    color: black;
                }
            }
            input {
                color: black;
                border-color: black !important;
                height: 40px;
            }
            svg {
                color: black;
            }
            input:disabled {
                color: black;
            }
        }
        .logo {
            margin: 0 auto 10px;
            height: 86px;
            width: ${(props) => props.qrCodeWidth};
            background: ${({ theme }) => theme.primary};
            border-radius: 10px;
            max-width: 350px;

            // @media (min-width: ${MAX_PAD_WIDTH}px) {
            //     width: ${(props) => props.qrCodeWidth};
            //     height: 120px;
            // }

            // @media (min-width: ${MAX_MOBILE_WIDTH}px) {
            //     width: ${(props) => props.qrCodeWidth};
            //     height: 120px;
            // }

            img {
                display: block;
                height: 100%;
                margin: 0 auto;
            }
        }

        .shared-desc {
            width: 100%;
            padding: 0.5rem 3rem;
            text-align: center;
            color: white;
            font-size: ${FONT_SIZE.NORMAL};
            background: ${({ theme }) => theme.primary};
        }
    }

    .shared-btn {
        margin: 8px;
        padding: 18px 18px;
        font-size: ${FONT_SIZE.NORMAL};
        background: ${({ theme }) => theme.primary};
        color: white;
        border: none;
        max-width: 350px;
        text-align: left;
    }

    .close-icon {
        position: absolute;
        right: 15px;
        cursor: pointer;
        mask-image: url('/images/shared/close.svg');
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        min-width: 18px;
        min-height: 18px;
        background: black;
    }
`;

const AlertMsg = styled.div`
    text-align: center;
`;
