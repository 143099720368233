import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import BaseModal from 'src/components/shared/modals/BaseModal';
import BirthdaySelect from 'src/components/shared/BirthdaySelect';
import { FlexDirectionConfigs } from '@constants/config';
import { FONT_SIZE, MAX_PAD_WIDTH } from '@constants/numbers';
import { IMAGE_DOMAINS, STATUS_CODE } from '@constants/api';
import { getCurrentYYYYMMDDhhmmss } from '@utils/util';
import { RiLockPasswordFill } from "react-icons/ri";

import { RiCloseFill } from 'react-icons/ri';
import { Button, Checkbox, Form, Input } from 'antd';
import { Link } from 'react-router-dom';

import { getUserInfo, login } from '@api/user';
import {
    getAccInLS,
    getDVCInLS,
    getPassInLS,
    getRememberInLS,
    setAccInLS,
    setBirthInLS,
    setJkeyInLS,
    setNkeyInLS,
    setPassInLS,
    setRememberInLS,
    setUkeyInLS,
    setUserRoleInLS,
} from '@utils/ls';
import { parseGetUserInfoRes, parseLoginRes } from '@utils/parseErrors';
import { useGlobalProps } from '@hooks/useGlobalProps';
import { getAvatarImageUrl } from '@api/user';
import ForgotPasswordView from './ForgotPasswordView';
import ForgotPasswordV2View from './ForgotPasswordV2View';
import FullNameTexFiled from '@components/shared/FullNameTextFiled';
import ChangePasswordModal from './ChangePasswordModal';

const logoImgUrl = `${IMAGE_DOMAINS.LOGOS
    }/desktop-logo.png?gettime=${getCurrentYYYYMMDDhhmmss()}`;
const LoginModal = ({ onToggleLoginModal }) => {
    const [needBirth, setNeedBirth] = useState(false); // NOTE: Default false
    const [needFullName, setNeedFullName] = useState(false); // NOTE: Default false
    const [changePasswordModalInfo, setChangePasswordModalInfo] = useState({ show: false, pw: '' }); // NOTE: Default false

    const [errorMsg, setErrorMsg] = useState('');
    const { setUser, setAvatarUrl, isForgotPasswordEnabled, pixelCodes, forgotPasswordFlowType } = useGlobalProps();
    const { t } = useTranslation();
    const tUser = t('user', { returnObjects: true });
    const tError = t('error', { returnObjects: true });
    const stopPropagation = (e) => {
        e.stopPropagation();
    };
    const [isForgotPWMode, setIsForgotPWMode] = useState(false); // NOTE: Default false


    // #region Local Storage
    const handleRememberMe = (values) => {
        const rememberMe = values?.remember || false;
        if (rememberMe) {
            const birthday =
                values?.day && values?.month
                    ? `${values.day}${values.month}`
                    : '';
            setRememberInLS(true);
            setAccInLS(values.account);
            setPassInLS(values.password);
            setBirthInLS(birthday);
        } else {
            setRememberInLS(false);
            setAccInLS('');
            setPassInLS('');
            setBirthInLS('');
        }
    };
    // #endregion

    // #region API
    const handleSubmit = async (values) => {
        setErrorMsg('');
        const birthday =
            values?.day && values?.month ? `${values.day}${values.month}` : '';
        const dvc = getDVCInLS();
        let res = await login({
            acc: values.account,
            pwd: values.password,
            dvc,
            bd: birthday,
            unm: values.full_name ?? ''
        });

        let errorMsg;
        if (res) {
            const { rtn } = res;
            if (rtn === STATUS_CODE.SUCCESS) {
                const userInfoRes = await getUserInfo({ dvc });
                if (userInfoRes?.rtn === STATUS_CODE.SUCCESS) {
                    window.firstMissingUserError = false;
                    const { rtn, msg, ...others } = userInfoRes;
                    setUkeyInLS(userInfoRes.ukey);
                    setJkeyInLS(userInfoRes.jkey);
                    setNkeyInLS(userInfoRes.nkey);
                    setUserRoleInLS(userInfoRes.user_role);
                    setUser(others);
                    handleRememberMe(values);
                    // onToggleLoginModal();
                    getAvatarImageUrl(others.acc)
                        .then((res) => {
                            if (res.imageUrl) {
                                setAvatarUrl(res.imageUrl);
                            }
                        })
                        .catch((e) => {
                            console.log(' not found avatar');
                        });
                    if (values.password.length === 4) {
                        setChangePasswordModalInfo({ show: true, pw: values.password })
                    } else {
                        onToggleLoginModal();
                    }
                    return;
                } else {
                    errorMsg = parseGetUserInfoRes(tError, userInfoRes);
                }

            } else if (rtn === STATUS_CODE.NEED_BIRTH) {
                setNeedBirth(true);
                errorMsg = tError['error@input-birthday'];

            } else if (rtn === STATUS_CODE.NEED_FULLNAME) {
                setNeedFullName(true);
                errorMsg = tError['error@input-full-name'];

            } else {
                errorMsg = parseLoginRes(tError, res);
            }
        } else {
            errorMsg = parseLoginRes(tError, null);
        }

        if (errorMsg) {
            setErrorMsg(errorMsg);
        }
    };
    // #endregion


    const prevForm = [
        {
            name: 'account',
            label: tUser['user@phone_account'],
            initialValue: getAccInLS() || '',
            // rules: [{ required: true, message: tUser['hint@phone'], min: 10, max: 11, whitespace: false, validator: validatorPhone }],
            // initialValue: 'MY345678A',
        },
        {
            name: 'password',
            label: tUser['user@password'],
            initialValue: getPassInLS() || '',
            // rules: [{ required: true, message: tUser['hint@password'], min: 6, whitespace: false, validator: validatorPassword }],
            isPassword: true,
            // initialValue: '123456'
        },
    ];

    const suffixForm = {
        name: 'remember',
        initialValue: getRememberInLS() || false,
    };
    // #endregion

    return (
        <BaseModal
            show={true}
            justifyContent={FlexDirectionConfigs.Center}
            alignItems={FlexDirectionConfigs.Center}
            handleOutsideClick={stopPropagation}
        >
            <LoginContent onClick={stopPropagation}>
                <div className="login-modal-close" onClick={onToggleLoginModal}>
                    <RiCloseFill />
                </div>
                {isForgotPWMode ?
                    forgotPasswordFlowType === 1 ? <ForgotPasswordView onClose={onToggleLoginModal} onBack={() => { setIsForgotPWMode(false) }} /> : <ForgotPasswordV2View onClose={onToggleLoginModal} onBack={() => { setIsForgotPWMode(false) }} />
                    :
                    <>   <div className="login-modal-body">
                        <div className="logo">
                            <img alt="logo" src={logoImgUrl} />
                        </div>
                        <Form
                            className="form"
                            colon={false}
                            onFinish={handleSubmit}
                            size="large"
                            autoComplete="off"
                            layout="vertical"
                        >
                            {prevForm.map((item) => (
                                <Form.Item
                                    key={item.name}
                                    name={item.name}
                                    initialValue={item.initialValue}
                                    rules={item.rules}
                                    valuePropName={
                                        item?.isCheckbox ? 'checked' : 'value'
                                    }
                                    label={item?.label ? `${item.label}：` : null}
                                >
                                    <Input
                                        id={`disable-chrome-auto-fill-${Math.random()}-${Math.random()}`}
                                        // name={`disable-chrome-auto-fill-${Math.random()}-${Math.random()}`}
                                        type={
                                            item?.isPassword ? 'password' : 'text'
                                        }
                                        autoComplete="new-password"
                                    // autoComplete="off"
                                    // onFocus={(event) => {
                                    //     event.target.setAttribute('autocomplete', 'off');
                                    //     console.log(event.target.autocomplete);
                                    //   }}
                                    />
                                </Form.Item>
                            ))}
                            {needBirth && <BirthdaySelect isInitialEmpty={true} />}
                            {needFullName && <FullNameTexFiled isInitialEmpty={true} />}

                            <Form.Item
                                name={suffixForm.name}
                                initialValue={suffixForm.initialValue}
                                valuePropName="checked"
                            >
                                <Checkbox>
                                    {tUser[`user@${suffixForm.name}`]}
                                </Checkbox>
                            </Form.Item>
                            {errorMsg && <p className="red">{errorMsg}</p>}
                            <Form.Item className="submit">
                                <Button type="primary" htmlType="submit" onClick={() => {
                                    console.log(" login submit click ");
                                    if (pixelCodes && pixelCodes.length > 0) {
                                        pixelCodes.forEach((code) => window.fbq('trackSingleCustom', code, 'Login'));
                                    }
                                }}>
                                    {tUser['login@submit']}
                                </Button>
                            </Form.Item>
                        </Form>
                        {
                            isForgotPasswordEnabled &&
                            <div className='forgot'>
                                <Link to='#' onClick={() => { setIsForgotPWMode(true) }}> <RiLockPasswordFill /> {tUser['user@forgot']} </Link>
                            </div>
                        }

                    </div>
                        <div className="login-modal-footer">
                            <p>{tUser['login@description']}</p>
                        </div></>
                }


            </LoginContent>
            <ChangePasswordModal show={changePasswordModalInfo.show} oldPw={changePasswordModalInfo.pw} onClose={() => {
                console.log(" on ChangePasswordModal close  ")
                onToggleLoginModal();
            }} />
        </BaseModal>
    );
};

export default LoginModal;

const LoginContent = styled.div`
    color: #2c2d2e;
    position: relative;
    padding: 48px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background: white;
    height: 90vh;
    width: 95vw;
    overflow-x: hidden;
    box-sizing: box-sizing;
    max-height: 650px;
    max-width: 500px;

    .ant-input-group-addon {
        font-size: 18px;
    }

	.ant-form-item-label {
		padding-bottom: 4px;
		label {
			height: 1rem !important;
			line-height: 1rem !important;
		}
	}

    .login-modal-close {
        position: absolute;
        top: 8px;
        right: 8px;
        height: 40px;
        width: 40px;
        padding 4px;
        cursor: pointer;
        svg {
            height: 100%;
            width: 100%;
            color: #7f7f7f;
        }
    }

    .login-modal-body {
        width: 100%;

        .logo {
            margin: 0 auto 24px;
            height: 86px;
            width: 100%;
            background: ${({ theme }) => theme.primary};
            border-radius: 10px;

            @media (min-width: ${MAX_PAD_WIDTH}px) {
                height: 120px;
            }

            img {
                display: block;
                height: 100%;
                margin:0 auto;
            }
        }

        .form {
            width: 100%;
            input {
                width: -webkit-fill-available;
            }
            .ant-form-item {
                margin: 0 auto 16px;
            }
        }

        .submit {
            margin: 0 auto;
            button {
                width: 100%;
                background: ${({ theme }) => theme.primary} !important;
                color: #fff;
            }
        }

        .forgot {
            color: inherit;
            font-size: 14px;
            margin: 0 auto 40px;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            a, a:link, a:visited {
                text-decoration: none;
                color:inherit;
            }

            a:hover {
                color: ${({ theme }) => theme.primary};
            }

            svg {
                margin-right: 5px;
            }
        }
    }

    .login-modal-footer {
        text-align: center;
        padding-top: 16px;
        border-top: 1px solid #ccc;
  
        p {
            width: 100%;
            font-size: 14px;
            line-height: 21px;
            margin: 0 auto;
        }
    }

    .red {
        color: red;
        font-size: ${FONT_SIZE.NORMAL};
    }
`;