import {
    MAX_DATE_RANGES,
    QUICK_DATE_RANGE_PICKER,
} from '@components/shared/QuickTimeRangePickers';
import { covertResArrayIntoObjecMap } from '@utils/util';
import { displayAmountWithDecimalPlace } from '@utils/util';

// [日期, 幾個人, 總貢獻金額]
export const AFF_COLUMN_KEYS = {
    DATE: 'date',
    PEOPLE_COUNT: 'people-count',
    TOTAL_AMOUNT: 'total-amount',
};

// [層數, 比率, 使用者, 總下注金額, 總紅利金額]
export const AFF_COLUMN_DETAIL_KEYS = {
    LAYER: 'layer',
    RATIO: 'ratio',
    USERNAME: 'username',
    TOTAL_BET: 'total-bet',
    TOTAL_BONUS: 'total-bonus',
    PERCENTAGE: 'percentage',
};

export function formatDayData(list) {
    const data = covertResArrayIntoObjecMap(list, {
        [AFF_COLUMN_KEYS.DATE]: 0,
        [AFF_COLUMN_KEYS.PEOPLE_COUNT]: 1,
        [AFF_COLUMN_KEYS.TOTAL_AMOUNT]: 2,
    });
    const formatData = data.map((item) => {
        // TODO: handle date
        // item[AFF_COLUMN_KEYS.TOTAL_AMOUNT] = Number(item[AFF_COLUMN_KEYS.TOTAL_AMOUNT]).toFixed(2)
        return item;
    });
    return formatData;
}

export function formatDayDataWithDecimalPLace(list, decimalPlace) {
    const data = covertResArrayIntoObjecMap(list, {
        [AFF_COLUMN_KEYS.DATE]: 0,
        [AFF_COLUMN_KEYS.PEOPLE_COUNT]: 1,
        [AFF_COLUMN_KEYS.TOTAL_AMOUNT]: 2,
    });
    const formatData = data.map((item) => {
        // TODO: handle date
        item[AFF_COLUMN_KEYS.TOTAL_AMOUNT] = displayAmountWithDecimalPlace(
            Number(item[AFF_COLUMN_KEYS.TOTAL_AMOUNT]),
            decimalPlace
        );
        return item;
    });
    return formatData;
}

export function formatEachDayDetail(data) {
    return covertResArrayIntoObjecMap(data, {
        [AFF_COLUMN_DETAIL_KEYS.LAYER]: 1,
        [AFF_COLUMN_DETAIL_KEYS.RATIO]: 2,
        [AFF_COLUMN_DETAIL_KEYS.USERNAME]: 3,
        [AFF_COLUMN_DETAIL_KEYS.TOTAL_BET]: 4,
        [AFF_COLUMN_DETAIL_KEYS.TOTAL_BONUS]: 5,
        [AFF_COLUMN_DETAIL_KEYS.PERCENTAGE]: 6,
    });
}
export function setAffRowKey(record) {
    return Object.keys(record).reduce((acc, key) => acc + record[key], '');
}

export const AFF_MAX_DATE_RANGE = MAX_DATE_RANGES.TWO_MONTHS;
export const AFF_QUICK_PICKERS = [
    QUICK_DATE_RANGE_PICKER.TODAY,
    QUICK_DATE_RANGE_PICKER.YESTERDAY,
    QUICK_DATE_RANGE_PICKER.THIS_WEEK,
    QUICK_DATE_RANGE_PICKER.PREV_WEEK,
    QUICK_DATE_RANGE_PICKER.THIS_MONTH,
    QUICK_DATE_RANGE_PICKER.PREV_MONTH,
];
export const DATE_FORMAT = 'YYYYMMDD';
export const AFF_PAGE_SIZE = 5;
