import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ConfigProvider, Table, Tag } from 'antd';
import { FONT_SIZE, MAX_PAD_WIDTH } from '@constants/numbers';
import BaseModal from 'src/components/shared/modals/BaseModal';
import { FlexDirectionConfigs } from '@constants/config';
import { displayAmountWithDecimalPlace } from '@utils/util';
import { useGlobalProps } from '@hooks/useGlobalProps';

function EmptyView() {
    return <div />;
}
const DetailsModal = ({ show, onClose, displayDetailData, loading }) => {
    const { decimalPlace } = useGlobalProps();
    const { t } = useTranslation();
    const tAffiliate = t('affiliate', { returnObjects: true });

    const date = displayDetailData?.date || tAffiliate['affiliate@date_loading'];
    const [dataSource, setDataSource] = useState([]);
    const columns = [
        {
            title: tAffiliate['affiliate@layer'],
            dataIndex: 'layer',
            key: 'layer',
            // align: 'right'
        },
        {
            title: tAffiliate['affiliate@ratio'],
            dataIndex: 'ratio',
            key: 'ratio',
            // align: 'right'
            render: (text, record) => {
                // Custom render logic here
                // console.log(' record ', record);
                return (
                    <div>
                        {text}{' '}
                        {record.percentage && (
                            <Tag
                                color={
                                    record.percentage === 1 ? 'green' : 'gold'
                                }
                            >
                                {`${record.percentage * 100}%`}
                            </Tag>
                        )}
                    </div>
                );
            },
        },
        {
            title: tAffiliate['affiliate@total-bet'],
            dataIndex: 'total-bet',
            key: 'total-bet',
            // align: 'right'
        },
        {
            title: tAffiliate['affiliate@total-bonus'],
            dataIndex: 'total-bonus',
            key: 'total-bonus',
            // align: 'right'
        },
        {
            title: tAffiliate['affiliate@username'],
            dataIndex: 'username',
            key: 'username',
        },
    ];
    const stopPropagation = (e) => {
        e.stopPropagation();
    };
    const setRowKey = (record) =>
        Object.keys(record).reduce((acc, key) => acc + record[key], '');
    const formatData = (list) => {
        const data = list?.details || [];
        const formatData = data.map((item) => {
            // TODO: handle layer and ratio
            // item['total-bonus'] = Number(item['total-bonus']).toFixed(2);
            const bonusNumber = Number(item['total-bonus'].replace(/,/g, ''));
            if (Number.isFinite(bonusNumber)) {
                item['total-bonus'] = displayAmountWithDecimalPlace(
                    bonusNumber,
                    decimalPlace
                );
            }
            const betNumber = Number(item['total-bet'].replace(/,/g, ''));
            if (Number.isFinite(betNumber)) {
                item['total-bet'] = displayAmountWithDecimalPlace(
                    betNumber,
                    decimalPlace
                );
            }

            return item;
        });
        return formatData;
    };

    useEffect(() => {
        setDataSource(formatData(displayDetailData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayDetailData]);

    // console.log('detailsData', detailsData)
    // console.log('dataSource', dataSource)
    // console.log('columns', columns)

    return (
        <BaseModal
            show={show}
            justifyContent={FlexDirectionConfigs.Center}
            alignItems={FlexDirectionConfigs.Center}
            handleOutsideClick={stopPropagation}
        >
            <DetailsModalContent onClick={stopPropagation}>
                <div className="shared-model-header">
                    <h3>{date}</h3>
                    <div className="close-icon" onClick={onClose} />
                </div>
                <div className="shard-modal-content affiliate-details-modal">
                    <ConfigProvider renderEmpty={EmptyView}>
                        <Table
                            className="affiliate-details-modal-table"
                            rowKey={setRowKey}
                            bordered
                            dataSource={dataSource}
                            columns={columns}
                            pagination={false}
                            scroll={false}
                            loading={loading}
                        />
                    </ConfigProvider>
                </div>
            </DetailsModalContent>
        </BaseModal>
    );
};

export default DetailsModal;

const PC_WIDTH = 600;
const DetailsModalContent = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    background: white;
    height: auto;
    width: 95vw;
    max-height: 90%;

    @media (min-width: ${MAX_PAD_WIDTH}px) {
        height: auto;
        width: ${PC_WIDTH}px;
        min-width: ${PC_WIDTH}px;
        max-width: ${PC_WIDTH}px;
        max-height: 95vh;
        overflow-y: auto;
    }

    > div {
        padding: 0px 16px;
        width: 100%;
    }

    .shared-model-header {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid lightgray;
        height: 4rem;
    }
    .shard-modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem 0px;
        .shared-aff-field {
            width: 45vw;
            margin-top: 5px;
            @media (min-width: ${MAX_PAD_WIDTH}px) {
                width: 300px;
            }
        }
    }
    .close-icon {
        position: absolute;
        right: 15px;
        cursor: pointer;
        mask-image: url('/images/shared/close.svg');
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        min-width: 18px;
        min-height: 18px;
        background: black;
    }

    .affiliate-details-modal {
        padding: 1rem;
        width: 100%;
        &-table {
            display: block;
            overflow: scroll;
            width: 100%;
        }
    }

    .ant-table {
        font-size: ${FONT_SIZE.NORMAL};
    }
`;
