
import { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { SWITCH_ROUTES } from "@constants/routes";
import { getMaintenances } from "@api/other";

export default function useMaintenance() {
    const [msg, setMsg] = useState(null);
    // const { i18n } = useTranslation()
    const navigate = useNavigate()

    const getMaintenanceInfo = async() => {
        const res = await getMaintenances()
        if(res && res?.[0]) setMsg(res[0])
        else setMsg(undefined)
    }

    useEffect(() => {
        getMaintenanceInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(msg) {
            const now = moment().valueOf();
            let inMaintenance = now > moment(msg.startDate).valueOf()
            if(inMaintenance && msg.isPeriod && msg.endDate) inMaintenance = now < moment(msg.endDate).valueOf()

            const inMaintenancePage = window.location.pathname.startsWith(SWITCH_ROUTES.MAINTENANCE)
            if(inMaintenance && !inMaintenancePage) navigate(SWITCH_ROUTES.MAINTENANCE)
            else if(!inMaintenance) {
                if(inMaintenancePage) navigate(SWITCH_ROUTES.ROOT)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [msg])

    return {msg}
}