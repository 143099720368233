import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FullScreenIframe from '@components/home/FullScreenIframe';
import { useNavigate, useLocation } from 'react-router-dom';
import { SWITCH_ROUTES } from '@constants/routes';
import { useGlobalProps } from '@hooks/useGlobalProps';

export default function GamePage() {
    const location = useLocation();
    const navigate = useNavigate();
    const { user, getBalance, setGameDisplaying } = useGlobalProps();

    const [gameUrl, setGameUrl] = useState(null);

    const handleDismiss = () => {
        console.log(' handle dismiss ');
        navigate(SWITCH_ROUTES.ROOT);
    };
    useEffect(() => {
        if (location.state && location.state.gameUrl) {
            setGameDisplaying(true);
            setGameUrl(location.state.gameUrl);
        }


        const handleMessage = (message) => {
            if (message.data) {
                try {
                    const data = JSON.parse(message.data);
                    if (data && !!data.IsCloseGame) {
                        setGameDisplaying(false);
                        handleDismiss();
                    }
                } catch (error) { }
            }
        };

        window.addEventListener('message', handleMessage, false);

        return () => {
            window.removeEventListener('message', handleMessage);
            setGameDisplaying(false);
            if (user?.ukey) getBalance();
        };


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => { });
    return (
        <GamePageWrap>
            {gameUrl != null && (
                <FullScreenIframe url={gameUrl} onDismiss={handleDismiss} />
            )}
        </GamePageWrap>
    );
}

const GamePageWrap = styled.div`
    width: 100%;

`;
