/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';

import {
    OutlineButton,
    ButtonRow,
    MobileOutlineButtonWrap,
} from '@components/shared/View';
import { displayAmountWithPrecision } from '@utils/util';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import styled from 'styled-components';
import { useGlobalProps } from '@hooks/useGlobalProps';

const AmountSelectionsView = (props) => {
    const { amountInfos, onSelectAmount, innerRef, displayStepper } = props;
    const { globalTheme } = useGlobalProps();
    const { i18n } = useTranslation();

    const { t } = useTranslation();
    const tCommon = t("common", { returnObjects: true });
    const buttonStyle = {
        width: '100%',
        minWidth: '120px',
    };
    const renderAmountSelections = useCallback(() => {
        let info = {};
        if (amountInfos && amountInfos.length > 0) {
            info = amountInfos[0];
        }

        if (info.pointUnit && info.currency) {
            return (
                <ButtonRow ref={innerRef}>
                    {amountInfos.map((k, index) => {
                        const btnText = displayAmountWithPrecision(k['amount']);
                        let clickEvent = null;
                        clickEvent = onSelectAmount;
                        if (clickEvent)
                            return (
                                <MobileOutlineButtonWrap key={index} index={index}>
                                    <OutlineButton
                                        key={btnText}
                                        onClick={() => clickEvent(k['amount'])}
                                        style={buttonStyle}
                                    >
                                        {displayStepper && ' + '}
                                        {btnText}
                                        {` ${k.currency}`}
                                        {!!k.rate && k['rate'] !== 0 && (
                                            <>
                                                <br />
                                                {`(${displayAmountWithPrecision(
                                                    k.points
                                                )}${k.pointUnit
                                                    ? ` ${k.pointUnit}`
                                                    : ''
                                                    })`}
                                            </>
                                        )}
                                    </OutlineButton>
                                </MobileOutlineButtonWrap>
                            );
                        else return null;
                    })}
                    {displayStepper &&
                        <MobileOutlineButtonWrap key={-1} index={-1}>
                            <OutlineButton
                                key={'clear'}
                                onClick={() => onSelectAmount(0)}
                                style={buttonStyle}

                            >
                                {tCommon['common@clear']}
                            </OutlineButton>
                        </MobileOutlineButtonWrap>}
                </ButtonRow>
            );
        } else {
            return (
                <>
                    {amountInfos.map((k, index) => {
                        const btnText = displayAmountWithPrecision(k['amount']);
                        let clickEvent = null;
                        clickEvent = onSelectAmount;
                        if (clickEvent)
                            return (
                                <AmountTag color={globalTheme.primary} bordered={true} key={index} index={index}>
                                    <div onClick={() => clickEvent(k['amount'])} >
                                        {displayStepper && ' + '}
                                        {btnText}
                                    </div>

                                </AmountTag>
                            );
                        else return null;
                    })}
                    {displayStepper &&
                        <AmountTag color={globalTheme.primary} bordered={false} key={-1} index={-1}>
                            <div onClick={() => onSelectAmount(0)}>
                                {tCommon['common@clear']}
                            </div>
                        </AmountTag>}
                </>
            );
        }

    }, [amountInfos, i18n.language]);

    return (
        <>{renderAmountSelections()}</>
    )
}

export default AmountSelectionsView


const AmountTag = styled(Tag)`
    padding: 6px;
    font-size: 16px;
    cursor: pointer;
    background-color: white;
    min-width: 80px;
    text-align: center;
    margin-bottom: 10px;
`;