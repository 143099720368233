import { useRouteError } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    // Inline CSS styles
    const styles = {
        errorPage: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            backgroundColor: '#f7f8fa', // Soft background color
            color: '#333', // Text color
            textAlign: 'center',
            padding: '20px',
        },
        heading: {
            fontSize: '2rem',
            marginBottom: '10px',
        },
        message: {
            fontSize: '1.2rem',
            marginBottom: '20px',
        },
        button: {
            fontSize: '1rem',
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
        },
    };


    return (
        <div id="error-page" style={styles.errorPage}>
            <h1 style={styles.heading}>Oops!</h1>
            <p style={styles.message}>Sorry, an unexpected error has occurred.</p>
            <p style={styles.message}>
                <i>{error.statusText || error.message}</i>
            </p>
            <button onClick={goBack} style={styles.button}>Go Back</button>
        </div>
    );
}
