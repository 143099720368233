import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import { FaIdCard } from 'react-icons/fa';

const FullNameTexFiled = (props) => {
    const isRegister = props?.isRegister || false;
    // const isInitialEmpty = props?.isInitialEmpty || false;

    const { t } = useTranslation();
    const tUser = t('user', { returnObjects: true });

    const fromItem = {
        name: 'full_name',
        label: tUser['user@full_name'],
        help: tUser['hint@full_name'],
        addonBefore: <FaIdCard />,
    };

    return (
        <FullNameTexFiledWarp >
            <Form.Item
                key={`${fromItem.name}${fromItem?.initialValue}`}
                name={fromItem.name}
                label={isRegister ? '' : `${fromItem.label}：`}
                className={isRegister && 'isRegister'}
                rules={fromItem?.rules}
                help={fromItem?.help}
                initialValue={fromItem?.initialValue}
            >
                <Input
                    name={fromItem.name}
                    type={
                        fromItem?.isPassword ? 'password' : 'text'
                    }
                    addonBefore={fromItem.addonBefore}
                    placeholder={tUser[`user@${fromItem.name}`]}
                    disabled={fromItem?.disabled}
                    maxLength={
                        ''
                    }
                />
            </Form.Item>
        </FullNameTexFiledWarp>
    );
};

export default FullNameTexFiled;

const FullNameTexFiledWarp = styled.div`
   

    .base-select-item:last-child {
        margin-left: 3%;
    }

    .isRegister {
        margin-bottom: 16px !important;
        .base-select {
            margin: 10px 0 16px;
            
        }
    }

   
`;
