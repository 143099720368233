import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useGlobalProps } from "./useGlobalProps";
import { SWITCH_ROUTES } from "@constants/routes";

export default function usePermissionPages() {
    const location = useLocation()
    const { user, menuItems, noBanner } = useGlobalProps()
    const [routesAfterLogin, setRoutesAfterLogin] = useState([])

    useEffect(() => {
        setRoutesAfterLogin(menuItems.filter(m => m.needLogin === true).map(m => m.path));
    }, [menuItems])

    useEffect(() => {
        if (user && routesAfterLogin.includes(location.pathname)) {
            if (!user?.ukey || user?.mode !== 0) {
                window.location.href = noBanner ? SWITCH_ROUTES.ROOT + window.location.search : SWITCH_ROUTES.ROOT;
            }
        }
    }, [location, user, routesAfterLogin, noBanner])

    return null;
}