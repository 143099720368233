/* eslint no-console: ["error", { allow: ["log", "error"] }] */
// import { STATUS_CODE } from '@constants/api';
import { STATUS_CODE } from '@constants/api';
import { lowerCaseObjectKeys } from 'src/utils/util';
// import { toast } from 'react-toastify';

export const FETCH_METHOD = 'POST' | 'GET' | 'DELETE' | 'PUT' | 'PATCH';
export function headerConfig(type, payload) {
    const headers =
        type === 'POST'
            ? { 'Content-Type': 'application/json' }
            : {
                // cache: 'no-cache',
                // Accept: 'application/json',
                // 'Content-Type': 'application/json',
            };

    const realPayload = JSON.stringify(payload);
    const body = type === 'GET' ? null : realPayload;
    return {
        method: type,
        headers,
        body,
    };
}
export async function generalFetch(url, method, payload) {
    return fetch(url, { ...headerConfig(method, payload) })
        .then((response) => {
            return response.json();
        })
        .then((res) => {
            let result;
            if (res && typeof res === 'object')
                result = lowerCaseObjectKeys(res);

            if (res?.rtn === STATUS_CODE.MISSING_USER) {
                if (!window.firstMissingUserError) {
                    window._setShowLoggedOutModal(true);
                }
                window.firstMissingUserError = false;
            }
            // const { rtn, msg } = res;
            // if (code !== STATUS_CODE.SUCCESS)
            //     toast.error(`Error Code:${res.code}. Msg:${msg}`);

            return result;
        })
        .catch((response) => {
            console.log('error', `${url}. ${response}`);
        });
}
