import React, {
    useState,
    createContext,
    useContext,
    useMemo
} from 'react';
import { getSidebarMode, setSidebarMode } from '@utils/ls';

export const ExpandState = {
    EXPAND: 'expand',
    COLLAPSE: 'collapse'
}
const SidebarMenuContext = createContext({
    isExpand: true,
    setMenuExpandState: () => {},
    onToggle: () => {}
});


const SidebarMenuProvider = ({ children }) => {
    const [isExpand, setIsExpand] = useState(
        getSidebarMode() === ExpandState.EXPAND
    );
    const value = useMemo(() => {
        const setMenuExpandState = (s) => {
            setSidebarMode(s);
            setIsExpand(s === ExpandState.EXPAND);
        };
        const onToggle = () => {
            setIsExpand(p => {
                setSidebarMode(!p ? ExpandState.EXPAND : ExpandState.COLLAPSE);
                return !p;
            });
        };
        return {
            setMenuExpandState,
            onToggle,
            isExpand
        };
    }, [isExpand]);

    return (
        <SidebarMenuContext.Provider value={value}>
            {children}
        </SidebarMenuContext.Provider>
    );
};

export const useSidebarMenu = () => useContext(SidebarMenuContext);

export default SidebarMenuProvider;
