export const SIDEBAR_WIDTH_EXPAND = 230;
export const SIDEBAR_WIDTH_COLLAPSE = 80;
export const MOBILE_SIDEBAR_WIDTH_EXPAND = 100;
export const ROOT_HEADER_HEIGHT = 20;
export const MENU_WITDH_DEFAULT = 160;
export const MENU_LOGO_VIEW_HEIGHT = 205;
export const LIVE_CHAT_FLOATING = 300;
export const POPUP_MIN_HEIGHT = 870;
export const Z_INDEXS = {
    ROOT_HEADER: 10,
    REACT_DATEPICKER_POPPER: 100,
    MODAL: 800,
};

// export const MAX_MOBILE_WIDTH = 375;
export const MAX_MOBILE_WIDTH = 500;

export const MAX_PAD_WIDTH = 768;

export const PADDING = {
    USER_INFO_PAGE: '3rem',
};
export const HEIGHT = {
    INPUT: '40px',
};
export const FONT_SIZE = {
    NORMAL: '16px',
    ALERT_MODAL_TITLE: '20px',
    LARGE: '30px',
};

export const INTERVAL_TIMES = {
    GET_BALANCE: 5 * 1000,
};

export const PASSWORD_MAX_LENGTH = 20;
