import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import BaseModal from 'src/components/shared/modals/BaseModal';
import { FlexDirectionConfigs } from '@constants/config';
import { Form, Input } from 'antd';
import { useGlobalProps } from '@hooks/useGlobalProps';
import { requestSMS } from '@api/user';
import { STATUS_CODE } from '@constants/api';
import { parseDoUserApplyRes } from '@utils/parseErrors';
import { FaMobile, FaSms } from 'react-icons/fa';
import {
    FONT_SIZE,
} from '@constants/numbers';
import AlertModal from '@components/shared/modals/AlertModal';

const PHONE_DEFAULT_TELHD = '01';
const PHONE_DEFAULT_MIN = 0;
const PHONE_DEFAULT_MAX = 13;
const INIT_ALERT = {
    show: false,
    title: '',
    message: '',
    children: null,
};

const MobileVerifyModal = ({ show, onCancel, onConfirm, message, children }) => {

    // const [isSending, setIsSending] = useState(false); // 是否正在發送驗證碼
    const [countdown, setCountdown] = useState(0); // 倒數計時
    const [phoneValue, setPhoneValue] = useState('');
    const [codeValue, setCodeValue] = useState('');

    const [errorMsg, setErrorMsg] = useState('');
    const [alert, setAlert] = useState({ ...INIT_ALERT });

    const { t } = useTranslation()
    const tCommon = t("common", { returnObjects: true })
    const tUser = t("user", { returnObjects: true })
    const tError = t('error', { returnObjects: true });
    let jkeyRef = useRef();

    const closeAlert = () => setAlert({ ...INIT_ALERT });

    const { user } =
        useGlobalProps();

    const stopPropagation = (e) => {
        e.stopPropagation()
    }


    useEffect(() => {
        async function fetch() {
            if (user)
                jkeyRef.current = user.jkey;
            setPhoneValue('');
            setCodeValue('');
        }
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (e) => {
        // const { phone } = e.target.value;
        if (e.target.name === 'phone') {
            setPhoneValue(e.target.value);
        }

        if (e.target.name === 'smsVerificationCode') {
            setCodeValue(e.target.value);
        }
    };


    const validatorPhone = (_rule, value) => {
        if (!value) return Promise.reject();
        const trimmedValue = value.trim();

        const validLen = !(
            trimmedValue.length < user.minln || trimmedValue.length > user.maxln
        );
        if (user?.telhd && user?.minln && user?.maxln) {
            const telhds = user?.telhd.split(',');
            let isValid = false;
            telhds.forEach((e) => {
                if (!isValid && trimmedValue.startsWith(e) && validLen) {
                    isValid = true;
                }
            });
            if (!!isValid) return Promise.resolve();
        }

        return Promise.reject();
    };
    const handleSendVerification = async () => {
        setErrorMsg('');

        if (countdown > 0) {
            return;
        }

        if (!phoneValue) {
            setErrorMsg(tError['error@no-fill-phone']);
            return;
        }

        // setIsSending(true);
        const res = await requestSMS({
            tel: phoneValue.trim(),
            jkey: jkeyRef.current,
        });

        if (res && res?.rtn !== STATUS_CODE.SUCCESS) {
            if (res?.rtn === 49) {
                setErrorMsg(tError['error@limitReached']);
            } else {
                setAlert({
                    show: true,
                    title: tCommon['common@failed'],
                    message: parseDoUserApplyRes(tError, res),
                    children: null,
                    onConfirm: closeAlert,
                });
            }
            return;
        }

        // setIsSending(false);
        setCountdown(120);

        const timer = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown <= 1) {
                    clearInterval(timer);
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);
    };

    const hintPhone = tUser['hint@phone']
        ? tUser['hint@phone']
            .replace('{telhd}', user?.telhd || PHONE_DEFAULT_TELHD)
            .replace('{minln}', user?.minln || PHONE_DEFAULT_MIN)
            .replace('{maxln}', user?.maxln || PHONE_DEFAULT_MAX)
        : '';


    const form = [

        {
            name: 'phone',
            rules: [
                {
                    required: true,
                    message: hintPhone,
                    min: user?.minln || PHONE_DEFAULT_MIN,
                    max: user?.maxln || PHONE_DEFAULT_MAX,
                    whitespace: false,
                    validator: validatorPhone,
                },
            ],
            addonBefore: <FaMobile />,
            help: hintPhone,
        },
        {
            name: 'smsVerificationCode',
            rules: [
                {
                    required: true,
                    message: tUser['hint@smsVerification'],
                    whitespace: false,
                    len: 6, // 假設驗證碼是 6 位數
                },
            ],
            addonBefore: <FaSms />, // 更換為合適的 icon
            help: tUser['hint@smsVerification'],
            isVerifiedCode: true,
        },

    ];
    return (
        <BaseModal show={show}
            justifyContent={FlexDirectionConfigs.Center}
            alignItems={FlexDirectionConfigs.Center}
            handleOutsideClick={stopPropagation}>
            <ConfirmContnet onClick={stopPropagation}>
                <p>{message}</p>
                <Form
                    className="register-card-form"
                    colon={false}
                    // onFinish={handleSubmit}
                    onChange={handleChange}
                    size="large"
                >
                    {form.map((item) => {
                        return (
                            <Form.Item
                                key={`${item.name}${item?.initialValue}`}
                                name={item.name}
                                rules={item?.rules}
                                help={item?.help}
                                initialValue={item?.initialValue}
                                className={`register-form-${item.name}`}
                            >
                                <Input
                                    name={item.name}
                                    type={
                                        item?.isPassword ? 'password' : 'text'
                                    }
                                    addonBefore={item.addonBefore}
                                    placeholder={tUser[`user@${item.name}`]}
                                    disabled={item?.disabled}
                                    maxLength={
                                        ''
                                    }
                                    addonAfter={
                                        item?.isVerifiedCode && (
                                            <div style={{ cursor: countdown > 0 ? 'default' : 'pointer' }}
                                                onClick={handleSendVerification}
                                                disabled={countdown > 0}
                                            >
                                                {countdown > 0
                                                    ? `${countdown} ${countdown === 1
                                                        ? tUser[
                                                        'user@second'
                                                        ]
                                                        : tUser[
                                                        'user@seconds'
                                                        ]
                                                    }`
                                                    : tUser[
                                                    'user@getVerificationCode'
                                                    ]}
                                            </div>
                                        )
                                    }
                                />
                            </Form.Item>
                        );
                    })}
                    {errorMsg && <p className="red">{errorMsg}</p>}
                    {/* <Form.Item className="register-btn">
                        <Button type="primary" htmlType="submit">
                            {tUser['register@submit']}
                        </Button>
                    </Form.Item> */}
                </Form>
                {children}
                <div className='confirm-modal-btns'>
                    <button onClick={() => {
                        if (!phoneValue) {
                            setAlert({
                                show: true,
                                title: tCommon['common@failed'],
                                message: tError['error@must_fill_mobile_number'],
                            });
                            return;
                        }
                        if (!codeValue) {
                            setAlert({
                                show: true,
                                title: tCommon['common@failed'],
                                message: tError['error@must_fill_verify_code'],
                            });
                            return;
                        }

                        //should send api to verify code

                        onConfirm(phoneValue);
                    }}>{tCommon["common@confirm"]}</button>
                    <button onClick={onCancel}>{tCommon["common@cancel"]}</button>
                </div>
            </ConfirmContnet>
            <AlertModal
                {...alert}
                onConfirm={() => setAlert({ ...INIT_ALERT })}
            />
        </BaseModal>
    );
};

export default MobileVerifyModal;

const ConfirmContnet = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: white;
    padding: 20px 40px;
    gap: 20px;
    min-width: 300px;
    >p {
        text-align: center;
        font-size: ${FONT_SIZE.ALERT_MODAL_TITLE};
        font-weight: 700;
    }
    
    .confirm-modal-btns {
        display: flex;
        justify-content: center;
        gap: 20px;
        >button {
            width: 50%;
        }
    }

    &-head {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;

        h1 {
            font-size: 28px;
            font-weight: 500;
            line-height: 1.2;
            margin: 0 auto;
        }
    }
    &-form {
        button {
            margin-top: 16px;
            width: 100%;
            background: ${({ theme }) => theme.primary} !important;
            color: #fff;
        }
    }
`;
