import React, { useEffect } from 'react';
// import styled from 'styled-components';
// import { useTranslation } from 'react-i18next';
import AlertModal from '@components/shared/modals/AlertModal';
// import { Card, Statistic, Divider, message } from 'antd';
import { Divider } from 'antd';

import QRCode from "react-qr-code";
// import { CopyToClipboard } from 'react-copy-to-clipboard';

const BankQRCodeModal = ({ show, onConfirm, title, QRCodeValue }) => {
    // const { t } = useTranslation();
    // const tPayment = t('payment', { returnObjects: true });
    // const [messageApi, contextHolder] = message.useMessage();



    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <AlertModal
            show={show}
            title={title}
            children={
                <>
                    {/* {contextHolder} */}

                    <Divider />
                    <div style={{ height: "auto", margin: "0 auto", maxWidth: 240, width: "100%" }}>
                        <QRCode
                            size={360}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={QRCodeValue}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                    <Divider />
                    {/* <Card>
                        <Statistic
                            valueRender={() => {
                                return (
                                    <BankInfo>
                                        {`${QRCodeValue}`}
                                        <CopyToClipboard
                                            text={
                                                QRCodeValue
                                            }
                                            style={{
                                                marginLeft: 16,
                                            }}
                                            onCopy={() => {
                                                messageApi.open(
                                                    {
                                                        type: 'success',
                                                        content:
                                                            tPayment[
                                                            'payment@acc-copied-success'
                                                            ],
                                                    }
                                                );
                                            }}
                                        >
                                            <div className="copy-icon" />
                                        </CopyToClipboard>
                                    </BankInfo>
                                );
                            }}
                        ></Statistic>
                    </Card> */}
                </>
            }

            onConfirm={onConfirm}
        />

    );
};

export default BankQRCodeModal;

// const BankInfo = styled.div`
//     color: black;
//     margin-top: 16px;
//     display: flex;
//     align-items: center;
//     .copy-icon {
//         cursor: pointer;
//         mask-image: url('/images/shared/copy.svg');
//         mask-repeat: no-repeat;
//         mask-position: center;
//         mask-size: contain;
//         min-width: 18px;
//         min-height: 18px;
//         background: black;
//     }
//     .qrcode-icon {
//         cursor: pointer;
//         mask-image: url('/images/shared/qrcode.svg');
//         mask-repeat: no-repeat;
//         mask-position: center;
//         mask-size: contain;
//         min-width: 18px;
//         min-height: 18px;
//         background: black;
//     }
// `;