import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { Z_INDEXS } from 'src/constants/numbers';

const MODAL_VIEW_ID = 'view-for-modal';

// z-index: ${Z_INDEXS.MODAL};
const ModalWrap = styled.div`
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
    height: 100%;
    background: rgba(16, 22, 30, 0.2);
    // padding-bottom: 10vh;
    // backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    .no-padding {
        padding: 0px;
    }

    ${props => props.justifyContent && `justify-content: ${props.justifyContent};`}
    ${props => props.alignItems && `align-items: ${props.alignItems};`}
`;

const BaseModal = (props) => {
    const { show, handleOutsideClick, justifyContent, alignItems, children } = props;
    const mountView = document.getElementById(MODAL_VIEW_ID);
    const handleClick = (e) => {
        if (handleOutsideClick) handleOutsideClick(e);
        e.stopPropagation();
    };
    if (!show) return null;

    const widget = <ModalWrap onClick={handleClick} justifyContent={justifyContent}
        alignItems={alignItems}
    >{children}</ModalWrap>;
    return mountView ? createPortal(widget, mountView) : widget;
};

export const ModalContainer = () => (
    <div id={MODAL_VIEW_ID} style={{ zIndex: Z_INDEXS.MODAL, position: 'relative', height: '-webkit-fill-available' }} />
);

export default BaseModal;
