import React, { useMemo, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import ReactSelect from 'react-select';
import classNames from 'classnames';
// import { MdOutlineAccountCircle } from 'react-icons/md';
import { FONT_SIZE, ROOT_HEADER_HEIGHT } from '@constants/numbers';
import useIsMobile from '@hooks/useIsMobile';
import { LOCAL_SELECTIONS } from '@constants/config';
import { useTranslation } from 'react-i18next';
import ROUTES from '@constants/routes';
import { useSidebarMenu } from '@hooks/useSidebarMenu';
import MarqueeView from '@components/shared/MarqueeView';
import { useNavigate, useLocation } from 'react-router-dom';
import { SWITCH_ROUTES } from '@constants/routes';
import { useGlobalProps } from '@hooks/useGlobalProps';
import { Avatar } from 'antd';
export default function RootHeader({
    onToggleLoginModal,
    onToggleSideBarModal,
    changeLanguage,
    selectedLang,
    isLogin,
    acc,
}) {
    const isMobile = useIsMobile();
    const { isExpand, onToggle } = useSidebarMenu();
    const { t } = useTranslation();
    const tCommon = t('common', { returnObjects: true });
    const {
        displayProfilePic,
        avatarUrl,
        user,
        isLiveChatOn,
        isTelegramOn,
        telegramAccount,
        isWhatsappOn,
        whatsappAccount
    } = useGlobalProps();

    const langSelections = useMemo(
        () =>
            LOCAL_SELECTIONS.map((k) => {
                return { ...k, imgPath: `/images/country/${k.value}.png` };
            }),
        []
    );
    const handleLangChange = (langOption) => {
        if (langOption.value === selectedLang) return;
        if (langOption.value) changeLanguage(langOption.value);
    };

    const location = useLocation();

    const navigate = useNavigate();
    let jkeyRef = useRef();

    useEffect(() => {
        async function fetch() {
            const params = new URLSearchParams(location.search);
            jkeyRef.current = params.get('jkey');
        }
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectedOption = langSelections.filter(
        (l) => l.value === selectedLang
    );
    const controlStyle = isMobile
        ? {
            border: 'none',
            width: 38,
            background: 'transparent',
            overflow: 'hidden',
            '&:hover': { boxShadow: 'none' },
        }
        : {};
    const valueContainerStyle = isMobile ? { padding: '0px 0px 0px 6px' } : {};
    const customSelectStyles = {
        control: (base) => ({
            ...base,
            height: 30,
            minHeight: 30,
            ...controlStyle,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: 30,
            padding: '0 6px',
            ...valueContainerStyle,
        }),
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: 30,
        }),
        container: (provided, state) => ({
            ...provided,
        }),
        menu: (provided, state) => ({
            ...provided,
            marginLeft: isMobile ? -135 : 0,
            width: 170,
        }),
    };

    const dispalyLoginBtn = (!isLogin && !isExpand) || (!isLogin && isMobile);
    const dispalyAccount = !dispalyLoginBtn && acc && isMobile;

    return (
        <HeaderWrap>
            <div className="header-left-wrap">
                <ReactSVG
                    className="humburger-icon"
                    wrapper="span"
                    src="/images/menu/hamburger-icon.svg"
                    onClick={isMobile ? onToggleSideBarModal : onToggle}
                />
                {isLiveChatOn && (
                    <div className="live-chat-btn">
                        <Link
                            to={`${ROUTES.ROOT}${ROUTES.LIVE_CHAT}`}
                            target="_blank"
                        >
                            <img src="/images/shared/livechat.png" alt="livechat" />
                        </Link>
                        {/* <img src="/images/shared/livechat.png" alt="livechat" />
                        <Link to={`${ROUTES.ROOT}${ROUTES.LIVE_CHAT}`} target="_blank">
                            {tCommon['rootHeader@livechat']}
                        </Link> */}
                    </div>
                )}
                {isTelegramOn && telegramAccount && (
                    <div className="telegram-btn">
                        <Link
                            to={`${ROUTES.TELEGARM}/${telegramAccount}`}
                            target="_blank"
                        >
                            <img
                                src="/images/shared/telegram.png"
                                alt="telegram"
                            />
                        </Link>
                    </div>
                )}
                {isWhatsappOn && whatsappAccount && (
                    <div className="whatsapp-btn">
                        <Link
                            // to={`${ROUTES.WHATSAPP}/${whatsappAccount}`}
                            to={whatsappAccount.startsWith('http') ? whatsappAccount : `${ROUTES.WHATSAPP}/${whatsappAccount}`}
                            target="_blank"
                        >
                            <img
                                src="/images/shared/whatsapp.png"
                                alt="whatsapp"
                            />
                        </Link>
                    </div>
                )}
            </div>
            <MarqueeView />
            <div className="header-right-wrap">
                {!jkeyRef.current && dispalyLoginBtn && (
                    <button className="login-btn" onClick={onToggleLoginModal}>
                        {tCommon['common@login']}
                    </button>
                )}
                {jkeyRef.current && dispalyLoginBtn && (
                    <button
                        className="login-btn"
                        onClick={() => {
                            navigate({
                                pathname: SWITCH_ROUTES.REGISTER,
                                search: `?jkey=${jkeyRef.current}`,
                            });
                        }}
                    >
                        {tCommon['common@register']}
                    </button>
                )}
                {dispalyAccount && (
                    <div className="header-mobile-account">
                        {displayProfilePic && (
                            <Avatar
                                style={{ marginRight: '12px' }}
                                size={33}
                                src={avatarUrl || '/images/avatar.png'}
                            />
                        )}
                        {!displayProfilePic && user && user.user_role !== 0 && (
                            <Avatar
                                style={{ marginRight: '4px' }}
                                size={20}
                                src={`/images/avatar/${user.user_role}.png`}
                            />
                        )}
                        {/* <MdOutlineAccountCircle /> */}
                        <span>{acc}</span>
                    </div>
                )}
                <ReactSelect
                    className={classNames('lang-selector', { isMobile })}
                    styles={customSelectStyles}
                    value={selectedOption}
                    options={langSelections}
                    isSearchable={false}
                    // menuIsOpen={true}
                    onChange={handleLangChange}
                    formatOptionLabel={(c) => (
                        <div className="lang-option">
                            <img src={c.imgPath} alt={`c-img-${c.label}`} draggable="false" />
                            <span>{c.label}</span>
                        </div>
                    )}
                />
            </div>
        </HeaderWrap>
    );
}
// z-index: ${Z_INDEXS.ROOT_HEADER};

const HeaderWrap = styled.div`
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    height: ${ROOT_HEADER_HEIGHT}px;
    width: 100%;
    max-width: 100%;
    background: white;

    .header-mobile-account {
        .ant-avatar img {
            width: 100%;
            height: auto;
        }
    }

    .header-btns {
        display: flex;
        gap: 10px;
    }

    .header-left-wrap {
        display: flex;
        align-items: center;
        .humburger-icon {
            cursor: pointer;
            display: flex;
            align-items: center;
            svg {
                height: 20px;
                width: 20px;
                margin-top: 3px;
            }
        }
        .live-chat-btn {
            display: flex;
            align-items: center;
            padding-left: 15px;
            gap: 5px;
            img {
                height: 15px;
            }
        }
        .telegram-btn {
            padding-left: 8px;
            img {
                margin-top: 3px;
                height: 20px;
            }
        }
        .whatsapp-btn {
            padding-left: 8px;
            img {
                margin-top: 3px;
                height: 24px;
            }
        }
    }

    .header-right-wrap {
        display: flex;
        gap: 6px;
        > button {
            height: 30px;
            max-height: 30px;
            padding: 3px 0.75rem;
            font-size: ${FONT_SIZE.NORMAL};
            background: ${({ theme }) => theme.primary};
            color: white;
            border: none;
            text-wrap: nowrap;
        }
    }

    .lang-selector {
        width: 170px;
        .lang-option {
            display: flex;
            align-items: center;
            gap: 10px;
            > img {
                height: 20px;
                width: 20px;
            }
        }
    }

    .isMobile {
        width: 38px;
        margin-right: -6px;
        input {
            color: transparent !important;
        }
    }

    .header-mobile-account {
        display: flex;
        align-items: center;
        gap: 3px;
        > svg {
            height: 20px;
            width: 20px;
            margin-top: -2px;
        }
    }
`;
// #endregion
