import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useGlobalProps } from '@hooks/useGlobalProps';
import { clearUserKeys, getUKeyInLS } from '@utils/ls';
import { SWITCH_ROUTES } from '@constants/routes';
import { useTranslation } from 'react-i18next';
import { logout } from '@api/user';

export default function LogoutPage() {
    const { setUser, noBanner } = useGlobalProps()
    const { t } = useTranslation()
    // const navigate = useNavigate();

    useEffect(() => {
        const handleLogout = async () => {
            const ukey = getUKeyInLS()
            if (ukey) logout({ ukey })
            setUser(null)
            clearUserKeys()
            window.location.href = noBanner ? SWITCH_ROUTES.ROOT + window.location.search : SWITCH_ROUTES.ROOT;
            // navigate(SWITCH_ROUTES.ROOT);
        };
        handleLogout().catch(console.error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
            }}
        >
            <p>{t("logout@logging-out")}</p>
        </div>
    );
}
