/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import ConfirmModal from '@components/shared/modals/ConfirmModal';
import { getHideBannerInLS, setHideBannerInLS } from '@utils/ls';
import { getCurrentYYYYMMDD } from '@utils/util';
import '@splidejs/react-splide/css';
import { getBanners } from '@api/other';
import { IMAGE_DOMAINS, STATUS_CODE } from '@constants/api';
import { Primary } from '@constants/colors';
import { useSidebarMenu } from '@hooks/useSidebarMenu';
import { useGlobalProps } from '@hooks/useGlobalProps';
import { getPlayGameUrl } from '@api/game';
import { getDVCInLS } from '@utils/ls';
import { useNavigate } from 'react-router-dom';
import { SWITCH_ROUTES } from '@constants/routes';
import useMobileDetect from '@hooks/useMobileDetect';
import NewsModal from './NewsModal';

const BannerType = {
    none: 0,
    game: 1,
    link: 2,
    announce: 3,
    news: 4
};

export default function HomeBanner() {
    const [banners, setBanners] = useState(null);
    const [hideBanner, setHideBanner] = useState(true);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const { isExpand } = useSidebarMenu();
    const splideRef = useRef(null);
    const { user, noBanner, setNoBanner } = useGlobalProps();
    const isMobileDevice = useMobileDetect();
    const [selectedBanner, setSelectedBanner] = useState(null);

    const navigate = useNavigate();

    const { i18n, t } = useTranslation();
    const handleHideBanner = () => {
        setHideBannerInLS(i18n.language, getCurrentYYYYMMDD());
        setHideBanner(true);
    };
    const getData = async () => {
        const res = await getBanners(i18n.language);
        if (res) {
            setBanners(
                res.map((r) => {
                    return {
                        ...r,
                        imagePath: `${IMAGE_DOMAINS.BO}${r.imagePath}`,
                    };
                })
            );
        } else {
            setBanners([]);
        }
    };

    const getGameUrl = async (gameId) => {
        try {
            let params = { gid: gameId };
            if (!user || user.mode === 1) {
                params.dvc = user?.device_id || getDVCInLS();
            } else {
                params.ukey = user.ukey;
            }
            const res = await getPlayGameUrl(params);
            if (res.rtn !== STATUS_CODE.SUCCESS) throw Error(res.msg);

            return res.play_url;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    const handleClick = async (banner) => {
        if (banner.type === BannerType.game) {
            try {
                const url = await getGameUrl(banner.gameCode);
                if (url) {
                    navigate(SWITCH_ROUTES.GAME, {
                        state: {
                            gameUrl: isMobileDevice ? url : `${url}&fullscreen=1`,
                        },
                    });
                }

            } catch (error) {
                console.log(' error ->  ', error);
            }
        } else if (banner.type === BannerType.link) {
            window.open(banner.url, '_blank');
        } else if (banner.type === BannerType.announce) {
            setSelectedBanner(banner);
        } else if (banner.type === BannerType.news) {
            setSelectedBanner({ ...banner, title: banner.news.title, content: banner.news && banner.news.content });
        }
    };

    useEffect(() => {
        getData();
        const str = getHideBannerInLS(i18n.language);
        if (str && str.length === 8) {
            const todayStr = getCurrentYYYYMMDD();
            try {
                if (parseInt(todayStr) - parseInt(str) > 0)
                    setHideBanner(false);
            } catch (e) {
                setHideBanner(false);
            }
        } else {
            setHideBanner(false);
        }
    }, [i18n.language]);

    useEffect(() => {
        if (splideRef.current) {
            const splide = splideRef.current.splide;
            splide.refresh();
        }
    }, [isExpand]);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search) // id=123

        if (!!query.get('nobanner')) {
            setNoBanner(true);
        }
    }, []);

    if (hideBanner || !banners || banners.length === 0) return null;

    if (noBanner)
        return <></>

    return (
        <HomeBannerWrap>
            <Splide
                ref={splideRef}
                options={{
                    classes: {
                        prev: 'splide__arrow--prev display-none',
                        next: 'splide__arrow--next display-none',
                    },
                    autoplay: true,
                    interval: 3500,
                    type: 'loop',
                    heightRatio: 0.40741,
                    arrows: true,
                    speed: 400,
                    lazyLoad: 'nearby',
                    pauseOnHover: false,
                    pauseOnFocus: false,
                    // drag: 'free',
                }}
                className="home-banner"
            >
                {banners &&
                    banners.map((banner, idx) => (
                        <SplideSlide key={`${banner.imagePath}-${idx}`}>
                            <img
                                src={banner.imagePath}
                                alt={`Banner ${idx}-${banner.imagePath}`}
                                onClick={() => handleClick(banners[idx])}
                                style={
                                    !banner.type ? {} : { cursor: 'pointer' }
                                }
                            />
                        </SplideSlide>
                    ))}
            </Splide>
            <div
                className="close-icon"
                onClick={() => setOpenConfirmModal(true)}
            />
            <ConfirmModal
                show={openConfirmModal}
                message={t('banner@close-tip')}
                onConfirm={handleHideBanner}
                onCancel={() => setOpenConfirmModal(false)}
            />
            <NewsModal
                show={selectedBanner !== null}
                onClose={() => setSelectedBanner(null)}
                {...selectedBanner}
            />
        </HomeBannerWrap>
    );
}

const HomeBannerWrap = styled.div`
    position: relative;
    width: 100%;
    height: auto;

    .home-banner {
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${Primary.LightYellow};
        box-sizing: border-box;
        width: 100%;

        .splide__track {
            height: 100%;
            width: 100%;
        }

        .splide__slide img {
            width: 100%;
        }
    }

    .close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        mask-image: url('/images/shared/close.svg');
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        min-width: 18px;
        min-height: 18px;
        background: white;
    }

    .display-none {
        display: none;
    }
`;
