import { MAX_PAD_WIDTH, FONT_SIZE, HEIGHT } from '@constants/numbers';
import styled from 'styled-components';
import './View.css';

export const CustomColumn = (props) => {
    const { title, errorMsg, child, notRequired } = props;
    return (
        <>
            <label className={notRequired ? 'notRequired' : ''}>{title}</label>
            <div>
                {child}
                {errorMsg && <small>{errorMsg}</small>}
            </div>
        </>
    );
};

export const CustomColumnAlignStart = (props) => {
    const { title, errorMsg, child, notRequired } = props;
    return (
        <>
            <label className={notRequired ? 'notRequired' : ''}>{title}</label>
            <div>
                {child}
                {errorMsg && <small>{errorMsg}</small>}
            </div>
        </>
    );
};

export const Column = (props) => {
    const { title, errorMsg, notRequired, innerRef, type, ...others } = props;
    // const classname = errorMsg ? 'invalid' : '';
    const classname = `${errorMsg ? 'invalid ' : ''}${type === 'number' ? 'hide-step-buttons' : ''}`;

    return (
        <>
            <label className={notRequired ? 'notRequired' : ''}>{title}</label>
            <div>
                <input ref={innerRef} {...others} className={classname} type={type} />
                {errorMsg && <small>{errorMsg}</small>}
            </div>
        </>
    );
};



export const ColumnWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    > label::after {
        content: '*';
        padding-right: 3px;
        font-size: 1rem;
        font-weight: 600;
        color: red;
    }

    .notRequired {
        display: none;
    }
    .notRequired::after {
        content: '';
    }
    > div {
        display: flex;
        flex-direction: column;
        color: red;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        word-break: break-word;
        small {
            padding: 3px;
            color: red;
        }
    }

    @media (min-width: ${MAX_PAD_WIDTH}px) {
        display: grid;
        grid-template-columns: minmax(0, 150px) 1fr;
        row-gap: 20px;
        align-items: ${(props) => props.alignItems || 'center'};
        .notRequired {
            display: block;
        }
    }

    input {
        font-size: ${FONT_SIZE.NORMAL};
        height: ${HEIGHT.INPUT};
        border-width: 1px;
        border-color: #4d4d4d;
        box-shadow: unset;
        font-weight: 400;
        border-radius: 0.375rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        padding: 0px 12px;
    }
    .invalid {
        border: 2px solid red !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
`;

export const Button = styled.button`
    padding: 0.5rem 0.75rem;
    background: ${({ theme }) => theme.primary};
    width: fit-content;
    color: white;
    border-radius: 5px;
    cursor: pointer;

    &:disabled {
        border: 1px solid ${({ theme }) => theme.primary};
        color: lightgray;
        opacity: 0.7;
    }
`;

export const ButtonRow = styled.div`
    display: flex;
  
    justify-content: start; // This will keep space between the items and also keep items centered
    
    flex-wrap: wrap;
    flex-grow: 1;

    > div {
        margin-bottom: 10px;
        flex-basis: 50%;
        width: 50%;

        @media (min-width: ${MAX_PAD_WIDTH}px) {
            flex-basis: 33%;
            width: 33%;
        }
    }
`;

export const OutlineButton = styled.button`
    padding: 0.5rem 0.75rem;
    // padding: 5px 15px;
    color: ${({ theme, textColor }) => textColor ?? theme.primary};
    border: 2px solid ${({ theme }) => theme.primary};
    width: fit-content;
    border-radius: 5px;
    cursor: pointer;

    &:disabled {
        border: 2px solid ${({ theme }) => theme.primary};
        color: lightgray;
        opacity: 0.7;
    }
`;


export const AmountWrap = styled.div`
    display: block;
    align-items: flex-start;
    justify-content: start;
    flex-wrap: wrap;

    input {
        margin-right: 12px;
        margin-bottom: 24px;
        width: 100%;

        ${({ wrappedWidth, hasOptions }) => hasOptions && wrappedWidth > 0 ? `
            @media (min-width: ${wrappedWidth}px) {
                width: ${hasOptions ? 'auto' : '100%'};
            }
        `: `
            @media (min-width: ${MAX_PAD_WIDTH * 2}px) {
                width: ${hasOptions ? 'auto' : '100%'};
            }
        `}    
    }

    ${({ wrappedWidth, hasOptions }) => hasOptions && wrappedWidth > 0 ? `
        @media (min-width: ${wrappedWidth}px) {
            display: ${hasOptions ? 'flex' : 'block'};
        }
    ` : `
        @media (min-width: ${MAX_PAD_WIDTH * 2}px) {
            display: ${hasOptions ? 'flex' : 'block'};
        }
    `}
    
`;

export const ButtonsWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 24px;
`;

export const MobileOutlineButtonWrap = styled.div`
    padding: 0px
        ${({ index }) => {
        if (index % 2 === 0) return '8px';
        return '0px';
    }}
        0px
        ${({ index }) => {
        if (index % 2 === 0) return '0px';

        return '8px';
    }};
    @media (min-width: ${MAX_PAD_WIDTH}px) {
        padding: 0px 8px;
    }
    display: flex;
`;

export const PaymentPageWrap = styled.div`
    display: flex;
    flex-direction: column;
    alignitems: center;
    justifycontent: center;
    width: 100%;
    gap: 10px;
    .mt-20 {
        margin-top: 20px;
    }
    .mb-60 {
        margin-bottom: 60px;
    }
    .mb-1 {
        margin-bottom: 1rem;
    }
    .pl-3 {
        // padding-left: 3rem;
    }

    //for transfer page
    .ant-input-group-addon {
        display: none;
    }
    input {
        border-radius: 0.375rem !important;
    }
`;

export const PolicyWrap = styled.div`
    width: 100%;
    > div {
        background: rgb(248, 249, 250);
        padding: 1rem 0rem;
    }

    @media (min-width: ${MAX_PAD_WIDTH}px) {
        > div {
            padding: 1rem 2rem;
        }
    }
`;

const EmptyViewWrap = styled.div`
    color: black;
`;

export const EmptyView = (tCommon) => () => {
    if (!tCommon) return null;
    return <EmptyViewWrap>{tCommon['table@no-data']}</EmptyViewWrap>;
};

export const FullWidthDivide = styled.div`
    height: 1px;
    width: 100%;
    background-color: #979797;
    margin: 40px 0px 20px 0px;
`;

export const QRCodeFrame = styled.div`
    position: relative;
    display: inline-block;
    width: 180px;
    height: 180px;
`;

export const QRCodeLogoImage = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 42px;
    height: 43px;
`;

export const PaymentOptionOutlineButton = styled(OutlineButton)`
    margin-right: 16px;

    border: 2px solid
        ${(prop) => {
        return prop.isSelected
            ? 'rgba(167, 23, 26, 1)'
            : 'rgba(0, 0, 0, 0.1)';
    }};

    &:disabled {
        border: 2px solid
            ${(prop) => {
        return prop.isSelected
            ? 'rgba(167, 23, 26, 1)'
            : 'rgba(0, 0, 0, 0.1)';
    }};
    }
`;

export const PaymentImg = styled.img`
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
`;
