import React, {
    useState,
    useEffect
} from 'react';
import { ThemeProvider } from 'styled-components';
import { theme as defaultTheme } from '@constants/theme';
import { getColors } from '@api/other';
import { useGlobalProps } from '@hooks/useGlobalProps';

const GlobalThemeProvider = ({ children }) => {
    const { setGlobalTheme } = useGlobalProps();
    const [theme, setTheme] = useState(null);
    const getColorsConfig = async () => {
        let res = await getColors()
        if (res && typeof res === 'object' && res.primary && res.minor) {
            setTheme({ ...defaultTheme, ...res })
            setGlobalTheme({ ...defaultTheme, ...res })
        } else {
            setTheme(defaultTheme)
            setGlobalTheme(defaultTheme)

        }

    }

    useEffect(() => {
        getColorsConfig()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!theme) return null
    return (
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
    );
};



export default GlobalThemeProvider;
