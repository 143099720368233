import styled from 'styled-components';
import {
    MENU_LOGO_VIEW_HEIGHT,
    SIDEBAR_WIDTH_COLLAPSE,
    SIDEBAR_WIDTH_EXPAND,
} from '@constants/numbers';

const AFTER_LOGIN_HEIGHT = MENU_LOGO_VIEW_HEIGHT + 130;
export const SidebarWrap = styled.div`
    ${({ gameDisplaying }) =>
        gameDisplaying &&
        `
        display: none;
    `}


    color: white;
    flex-grow: 1;
    box-sizing: border-box;
    background: ${({ theme }) => theme.minor};
    width: ${({ isExpand }) =>
        isExpand ? `${SIDEBAR_WIDTH_EXPAND}px` : `${SIDEBAR_WIDTH_COLLAPSE}px`};
    min-width: ${({ isExpand }) =>
        isExpand ? `${SIDEBAR_WIDTH_EXPAND}px` : `${SIDEBAR_WIDTH_COLLAPSE}px`};
    max-width: ${({ isExpand }) =>
        isExpand ? `${SIDEBAR_WIDTH_EXPAND}px` : `${SIDEBAR_WIDTH_COLLAPSE}px`};
    ${({ isMobile }) =>
        isMobile &&
        `
        height: -webkit-fill-available;
    `}

    .logo-wrap {
        margin-bottom: 16px;
        box-sizing: border-box;
        border-bottom: 1px solid #ccc;
        box-sizing: border-box;
        text-align: center;
        margin-bottom: 16px;
        padding: ${({ isExpand }) =>
        isExpand ? '40px 0px 24px 0px' : '40px 0px 24px 0px'};
        ${({ isExpand }) =>
        !isExpand &&
        `
                display: flex; justify-content: center;
                flex-direction: column;
            `}

        > img {
            background: ${({ theme }) => theme.primary};
            width: ${({ isExpand }) => (isExpand ? '100%' : '100%')};
            height: ${({ isExpand }) => (isExpand ? 'auto' : '80px')};
        }

        .login-btn {
            cursor: pointer;
            margin-top: 16px;
            text-align: center;
            border-radius: 800px;
            background: ${({ theme }) => theme.primary};
            color: white;
            font-size: 16px;
            padding: ${({ isExpand }) =>
        isExpand ? '0.375rem 1.5rem' : '0.375rem 0.5rem'};
        }
    }

    .sidebar-items {
        ${({ isMobile, isLogin, isExpand }) =>
        isMobile
            ? `
                height: calc(100vh - ${isLogin ? AFTER_LOGIN_HEIGHT : MENU_LOGO_VIEW_HEIGHT
            }px);
                max-height: calc(100vh - ${isLogin ? AFTER_LOGIN_HEIGHT : MENU_LOGO_VIEW_HEIGHT
            }px);`
            : `  
                height: calc(100% - ${isLogin && isExpand
                ? AFTER_LOGIN_HEIGHT
                : MENU_LOGO_VIEW_HEIGHT
            }px);
                max-height: calc(100% - ${isLogin && isExpand
                ? AFTER_LOGIN_HEIGHT
                : MENU_LOGO_VIEW_HEIGHT
            }px);
           `}
        overflow: auto;
        padding-bottom: 120px;
    }
`;
