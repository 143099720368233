import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import BaseModal from 'src/components/shared/modals/BaseModal';
import { FlexDirectionConfigs } from '@constants/config';
import { FONT_SIZE, MAX_PAD_WIDTH } from '@constants/numbers';

const AlertModal = ({ show, title, onConfirm, actionTitle, message, children }) => {
    const { t } = useTranslation();
    const tCommon = t('common', { returnObjects: true });
    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    return (
        <BaseModal
            show={show}
            justifyContent={FlexDirectionConfigs.Center}
            alignItems={FlexDirectionConfigs.Center}
            handleOutsideClick={stopPropagation}
        >
            <AlertContnet onClick={stopPropagation}>
                <p className="alert-title">{title}</p>
                {message && <p>{message}</p>}
                {children}
                <div>
                    <button onClick={onConfirm}>
                        {actionTitle ? actionTitle : tCommon['common@confirm']}
                    </button>
                </div>
            </AlertContnet>
        </BaseModal>
    );
};

export default AlertModal;

const AlertContnet = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    background: white;
    padding: 20px 40px;
    gap: 10px;
    width: auto;
    max-width: 95vw;

    > p {
        margin: 10px 0px;
    }
    .alert-title {
        font-size: ${FONT_SIZE.ALERT_MODAL_TITLE};
        font-weight: 700;
    }

    @media (min-width: ${MAX_PAD_WIDTH}px) {
        width: auto;
        max-width: 800px;
        min-width: 300px;
    }
`;
