import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import BaseModal from 'src/components/shared/modals/BaseModal';
import { FlexDirectionConfigs } from '@constants/config';
import { FONT_SIZE } from '@constants/numbers';
import { Button } from '@components/shared/View';

const VNPaymentModal = ({ show, onCancel, onConfirm, message, children }) => {
    const { t } = useTranslation();
    const tPayment = t('payment', { returnObjects: true });

    const [countdown, setCountdown] = useState(null);
    const [buttonText, setButtonText] = useState('Confirm');
    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const startCountdown = () => {
        let timeLeft = 300;
        setButtonText(`${tPayment['payment@execute']} (${timeLeft})`);

        // Clear any existing countdown
        if (countdown) {
            clearInterval(countdown);
        }

        const timer = setInterval(() => {
            timeLeft -= 1;
            setButtonText(`${tPayment['payment@execute']} (${timeLeft})`);

            if (timeLeft <= 0) {
                clearInterval(timer);
                setButtonText(`${tPayment['payment@execute']}`);
                onCancel();
            }
        }, 1000);

        setCountdown(timer);
    };

    useEffect(() => {
        if (show === true) {
            startCountdown();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <BaseModal
            show={show}
            justifyContent={FlexDirectionConfigs.Center}
            alignItems={FlexDirectionConfigs.Center}
            handleOutsideClick={stopPropagation}
        >
            <ConfirmContnet onClick={stopPropagation}>
                <ConfirmContentHeader>
                    <h3>{message}</h3>
                    <div className="close-icon" onClick={onCancel} />
                </ConfirmContentHeader>

                <ConfirmContentBody>
                    {children}
                    <div className="confirm-modal-btns">
                        <Button onClick={onConfirm}>
                            {/* {`${tCommon['common@confirm']} (${countdown})`} */}
                            {buttonText}
                        </Button>
                    </div>
                </ConfirmContentBody>
            </ConfirmContnet>
        </BaseModal>
    );
};

export default VNPaymentModal;

const ConfirmContentHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin 0px 16px;
`;

const ConfirmContentBody = styled.div`
    padding: 20px 40px;
`;

const ConfirmContnet = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: white;
    gap: 20px;
    min-width: 300px;

    > p {
        text-align: center;
        font-size: ${FONT_SIZE.ALERT_MODAL_TITLE};
        font-weight: 700;
    }

    .confirm-modal-btns {
        display: flex;
        justify-content: center;
        gap: 20px;
        > button {
            width: 50%;
        }
    }

    .close-icon {
        position: absolute;
        right: 15px;
        cursor: pointer;
        mask-image: url('/images/shared/close.svg');
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        min-width: 18px;
        min-height: 18px;
        background: black;
    }
`;
