/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AffiliateOverview from '@components/shared/AffiliateOverview';
import {
    Button,
    Column,
    ColumnWrap,
    CustomColumn,
    PaymentPageWrap,
    PolicyWrap,
    QRCodeFrame,
    QRCodeLogoImage,
} from '@components/shared/View';
import { useGlobalProps } from '@hooks/useGlobalProps';
import { STATUS_CODE } from '@constants/api';
import AlertModal from '@components/shared/modals/AlertModal';
import { SWITCH_ROUTES } from '@constants/routes';
import { displayAmountWithDecimalPlace } from '@utils/util';
import { withdrawal, setOPout } from '@api/payment';
import { getWithdrawCode } from '@api/other';
import { isValidURL } from '@utils/util';

import { parseErrorRes } from '@utils/parseErrors';
import ConfirmModal from '@components/shared/modals/ConfirmModal';
import { FONT_SIZE } from '@constants/numbers';
import { getWPolicy } from '@api/other';
import BankTable, { parseBankRes } from '@components/bankAccount/BankTable';
import { IS_KH } from '@constants/config';
import { Col, Row, Spin } from 'antd';
import { displayAmountWithPrecision } from '@utils/util';

const INIT_ALERT = {
    show: false,
    title: '',
};
export default function WithdrawalPage() {
    const [userWithdrawUrl, setUserWithdrawUrl] = useState('');
    const [qrCodeLoading, setQrCodeLoading] = useState(false);
    const [userQRCodeUrl, setUserQRCodeUrl] = useState('');

    const [amount, setAmount] = useState('');
    const [defaultBank, setDefaultBank] = useState(null);
    const [showNoDefaultBankAlert, setShowNoDefaultBankAlert] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [errorAlert, setErrorAlert] = useState({ ...INIT_ALERT });
    const [policy, setPolicy] = useState(null);
    const navigate = useNavigate();
    const { user, userData, balance, decimalPlace, mainUnit, mainUnitRaw, getBalance, pixelCodes } = useGlobalProps();
    const { i18n, t } = useTranslation();
    const tCommon = t('common', { returnObjects: true });
    const tPayment = t('payment', { returnObjects: true });
    const tBank = t('bank', { returnObjects: true });
    const tError = t('error', { returnObjects: true });
    // const [messageApi, contextHolder] = message.useMessage();

    const getPolicy = async () => {
        const res = await getWPolicy(i18n.language);
        if (res && res[0]) setPolicy(res[0]);
        else setPolicy(null);
    };
    const handleInputChange = async (e) => {
        const targetValue = e.target.value;
        const name = e.target.name;
        if (name === 'amount') {
            if (balance?.bln && targetValue > balance.bln)
                setAmount(balance.bln);
            else setAmount(targetValue);
        } else if (name === 'userWithdrawUrl') {
            setUserWithdrawUrl(targetValue);

            if (isValidURL(targetValue)) {
                console.log(' is valid url ---->  ', encodeURI(targetValue));
                setQrCodeLoading(true);
                try {
                    const imgSrc = await getWithdrawCode(
                        encodeURI(targetValue)
                    );
                    console.log(' image src -> ', imgSrc);
                    if (imgSrc) setUserQRCodeUrl(imgSrc);
                    else setUserQRCodeUrl('');
                } catch (error) {
                    setUserQRCodeUrl('');
                } finally {
                    setQrCodeLoading(false);
                }
            } else {
                setUserQRCodeUrl('');
            }
        }
    };
    const goBankAccountPage = () => {
        setShowNoDefaultBankAlert(false);
        navigate(SWITCH_ROUTES.BANKACCOUNT);
    };
    const getFee = () => {
        if (
            !amount ||
            !balance?.out_fee_per
        )
            return 0;
        try {
            const basicAmount = parseInt(amount);
            return displayAmountWithDecimalPlace(
                basicAmount * (balance?.out_fee_per / 100),
                decimalPlace
            );
        } catch (e) {
            return 0;
        }
    };

    useEffect(() => {
        if (user?.ukey) getBalance();
    }, [user, getBalance]);


    useEffect(() => {
        getPolicy();
    }, [i18n.language]);


    useEffect(() => {
        if (
            !userData?.acc_list ||
            userData?.acc_list?.length < 1
        ) {
            setShowNoDefaultBankAlert(true);
            return;
        }

        const bank = userData.acc_list.filter(
            (b) => b[5] === true
        )?.[0];
        console.log('bank', parseBankRes([bank]));
        if (bank.length === 6) {
            setDefaultBank(parseBankRes([bank]));
        } else setShowNoDefaultBankAlert(true);
    }, [userData]);

    const doWithdrawal = async () => {
        setShowConfirmModal(false);
        // const newTab = window.open('', '_blank');
        // const successRes = {"rtn":0,"msg":"success","url":"https://c.igp.gs/gggtest8/index.html?a:d1583b88-f8e2-a818-422f-218943936653,b:2023-7-22-14-15,c:MYR,d:100,e:Bamboo test Account -2,f:MY345678A@gmail.com,g:0112345678,h:333333212119,i:RJHIMYKL,s:1","nofee_yn":false}
        // const failedRes = {"rtn":51,"msg":"deposite at least 100","url":"","nofee_yn":false}
        // const res = failedRes;

        if (IS_KH) {
            const res = await setOPout({
                ukey: user.ukey,
                val: amount,
                prcnm: 'ABA',
                ua: userWithdrawUrl,
            });
            if (res?.rtn === STATUS_CODE.SUCCESS) {
                // newTab.location.href = res.url;
                // navigate(SWITCH_ROUTES.WITHDRAWAL_BANKING, {
                //     state: {
                //         gameUrl: res?.url,
                //     },
                // });

                setErrorAlert({
                    show: true,
                    title: tCommon['common@success'],
                    message: tPayment['payment@withdrawl-successfully'],
                });
                setUserQRCodeUrl('');
                setUserWithdrawUrl('');
                setAmount('');
            } else {
                // newTab.close();
                setErrorAlert({
                    show: true,
                    title: tCommon['common@failed'],
                    message: parseErrorRes(tError, res),
                });
            }
        } else {
            const res = await withdrawal({ ukey: user.ukey, val: amount });
            if (res?.rtn === STATUS_CODE.SUCCESS && res?.url) {
                // newTab.location.href = res.url;
                navigate(SWITCH_ROUTES.WITHDRAWAL_BANKING, {
                    state: {
                        gameUrl: res?.url,
                    },
                });
                setAmount('');
            } else {
                // newTab.close();
                setErrorAlert({
                    show: true,
                    title: tCommon['common@failed'],
                    message: parseErrorRes(tError, res),
                });
            }
        }
    };

    const canSubmit = defaultBank && amount >= balance?.out_min;
    const feeText = tPayment['payment@withdrawal-fee-msg']
        ?.replace('{percent}', balance?.out_fee_per)
        ?.replace('{fee}', getFee());
    const displayPolicy = policy && policy.content?.length > 0;
    return (
        <PaymentPageWrap>
            {/* {contextHolder} */}
            <AffiliateOverview />

            {!IS_KH && <BankTable dataSource={defaultBank} tBank={tBank} />}

            <ColumnWrap className="mt-20 mb-1 pl-3">
                {IS_KH && (
                    <Column
                        title={tPayment['payment@user-withdraw-code']}
                        name={'userWithdrawUrl'}
                        value={userWithdrawUrl}
                        onChange={handleInputChange}
                    />
                )}
                <Column
                    title={`${tPayment['payment@withdrawal-amount']}${mainUnit && ` ${mainUnit}`
                        }`}
                    name={'amount'}
                    value={amount}
                    disabled={!balance?.bln}
                    type="number"
                    onChange={handleInputChange}
                />
                {balance && (
                    <CustomColumn
                        notRequired
                        child={
                            <Row justify="space-between">
                                <Col>
                                    <WithdrawalFeeInfo>
                                        <p>
                                            {balance?.out_min > 0 && !user?.ko_max && `${tPayment['payment@withdrawal-min-amount']}: ${displayAmountWithPrecision(
                                                balance?.out_min
                                            )}${mainUnit && ` ${mainUnit}`
                                                }`}
                                            {!balance?.out_min && user?.ko_max > 0 && `${tPayment['payment@withdrawal-max-amount']}: ${displayAmountWithPrecision(
                                                user?.ko_max
                                            )}${mainUnit && ` ${mainUnit}`
                                                }`}
                                            {balance?.out_min > 0 && user?.ko_max > 0 && `${tPayment['payment@withdrawal-amount-limit']
                                                }: ${displayAmountWithPrecision(
                                                    balance?.out_min
                                                )} ~ ${displayAmountWithPrecision(
                                                    user?.ko_max
                                                )}${mainUnit && ` ${mainUnit}`
                                                }`}
                                        </p>
                                        {/* {balance?.nofee_yn ===
                                            WITHDRAWAL_FEE_STATUS.NO_FEE && (
                                            <p>
                                                {
                                                    tPayment[
                                                        'payment@withdrawal-no-fee-msg'
                                                    ]
                                                }
                                            </p>
                                        )} */}
                                        {balance?.out_fee_per > 0 && (
                                            <p>{feeText}</p>
                                        )}
                                    </WithdrawalFeeInfo>
                                </Col>
                                <Col>
                                    {IS_KH && (
                                        <>
                                            {qrCodeLoading && (
                                                <SpinContainer>
                                                    <Spin
                                                        style={{
                                                            top: '50%',
                                                            position:
                                                                'absolute',
                                                        }}
                                                    />
                                                </SpinContainer>
                                            )}
                                            {userQRCodeUrl && (
                                                <QRCodeFrame>
                                                    <QRCodeLogoImage
                                                        src="https://link.payway.com.kh/images/usd-aba-qr.svg"
                                                        alt="aba-logo"
                                                    />
                                                    <img
                                                        src={userQRCodeUrl}
                                                        class="uk-animation-fade"
                                                        alt="aba-qrcode"
                                                    />
                                                </QRCodeFrame>
                                            )}
                                        </>
                                    )}
                                </Col>
                            </Row>
                        }
                    />
                )}

                <CustomColumn
                    child={
                        <Button
                            onClick={() => {
                                if (!IS_KH && !canSubmit) {
                                    setErrorAlert({
                                        show: true,
                                        title: tCommon['common@failed'],
                                        message:
                                            tError[
                                            'error@deposit_insufficient_amount'
                                            ],
                                    });
                                    return;
                                }

                                if (pixelCodes && pixelCodes.length > 0) {
                                    pixelCodes.forEach((code) => window.fbq('trackSingleCustom', code, 'WithdrawlSubmission'));
                                }

                                setShowConfirmModal(true);
                            }}
                        >
                            {tCommon['common@submit']}
                        </Button>
                    }
                    notRequired
                />
            </ColumnWrap>

            {displayPolicy && (
                <PolicyWrap>
                    <div dangerouslySetInnerHTML={{ __html: policy.content }} />
                </PolicyWrap>
            )}

            <AlertModal
                show={showNoDefaultBankAlert}
                title={tPayment['payment@no-default-bank-title']}
                message={tPayment['payment@no-default-bank-msg']}
                onConfirm={goBankAccountPage}
            />
            <ConfirmModal
                show={showConfirmModal}
                message={tPayment['payment@confirm-withdrawal']}
                children={
                    <WithdrawalFeeInfo>
                        <p className="text-center">{`${tPayment['payment@withdrawal-amount']}: ${amount} ${mainUnitRaw && ` ${mainUnitRaw}`
                            }`}</p>
                        {balance?.out_fee_per > 0(
                            <p className="text-center">{feeText}</p>
                        )}
                    </WithdrawalFeeInfo>
                }
                onConfirm={doWithdrawal}
                onCancel={() => setShowConfirmModal(false)}
            />
            <AlertModal
                {...errorAlert}
                onConfirm={() => setErrorAlert({ ...INIT_ALERT })}
            />
        </PaymentPageWrap>
    );
}

const WithdrawalFeeInfo = styled.div`
    color: black;
    > p {
        margin: 0;
        margin-top: 5px;
        font-size: ${FONT_SIZE.ALERT_MODAL_TITLE};
        font-weight: 800;
    }
    .text-center {
        text-align: center;
    }
`;

const SpinContainer = styled.div`
    textalign: center;
    position: relative;
    width: 180px;
    height: 180px;
`;
