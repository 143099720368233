import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AutoComplete, Input } from 'antd';
import AffiliateOverview from '@components/shared/AffiliateOverview';
import {
    Button,
    Column,
    ColumnWrap,
    CustomColumn,
    PaymentPageWrap,
} from '@components/shared/View';
import { useGlobalProps } from '@hooks/useGlobalProps';
import { getFriendList, transfer } from '@api/payment';
import { STATUS_CODE } from '@constants/api';
import ConfirmModal from '@components/shared/modals/ConfirmModal';
import AlertModal from '@components/shared/modals/AlertModal';
import { FONT_SIZE } from '@constants/numbers';
import { parseErrorRes } from '@utils/parseErrors';

const INIT_INPUT_FILED = {
    amount: '',
    transferTo: '',
};
const INIT_ALERT = {
    show: false,
    title: '',
};
export default function TransferPage() {
    const [inputField, setInputField] = useState({ ...INIT_INPUT_FILED });
    const [friendList, setFriendList] = useState([]);
    const [options, setOptions] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [alert, setAlert] = useState({ ...INIT_ALERT });
    const { user, balance, reGetuserInfo, mainUnit, getBalance } = useGlobalProps();
    const { t } = useTranslation();
    const tCommon = t('common', { returnObjects: true });
    const tPayment = t('payment', { returnObjects: true });
    const tError = t('error', { returnObjects: true });
    const handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;
        let val = target.value;
        if (name === 'amount') {
            if (balance?.bln && val > balance.bln) val = balance.bln;
        }
        setInputField((prev) => {
            return { ...prev, [name]: val };
        });
    };
    const onSearch = (text) => {
        setOptions(friendList.filter((f) => f.value.includes(text)));
    };
    const onSelect = (data) => {
        setInputField((prev) => {
            return { ...prev, transferTo: data };
        });
    };
    const isDisabled = () => {
        if (!inputField.amount || !inputField.transferTo) return true;
        try {
            return parseInt(inputField.amount) > 0 ? false : true;
        } catch (e) { }
    };
    const doTransfer = async () => {
        setShowConfirmModal(false);
        const res = await transfer({
            ukey: user.ukey,
            tkey: user.nkey,
            toacc: inputField.transferTo,
            val: inputField.amount,
        });
        // const succRes = {"rtn":0,"msg":"success","blnAft":990.00,"nkey":"16d5b4d8-da82-eaf0-776d-d1897cd95d13"}

        if (res?.rtn === STATUS_CODE.SUCCESS) {
            setInputField({ ...INIT_INPUT_FILED });
            reGetuserInfo();
            getBalance();
            setAlert({
                show: true,
                title: tCommon['common@success'],
                message: tPayment['payment@transfer-success'],
            });
        } else {
            setAlert({
                show: true,
                title: tCommon['common@failed'],
                message: parseErrorRes(tError, res),
            });
        }
    };

    useEffect(() => {
        if (user?.ukey) {
            getFriendList({ ukey: user.ukey }).then((res) => {
                // const succRes = {"rtn":0,"msg":"success","friend_count":6,"friend_list":["TT97008","TT97006","TT856911A","TT583652A","TT856965A","TT000886A"]}
                // console.log(res)
                if (
                    res?.rtn === STATUS_CODE.SUCCESS &&
                    res?.friend_list?.length > 0
                ) {
                    const opts = res.friend_list.map((a) => {
                        return { value: a, label: a };
                    });
                    setFriendList(opts);
                    setOptions(opts);
                }
            });
        }
    }, [user]);

    useEffect(() => {
        if (user?.ukey) getBalance();
    }, [user, getBalance]);


    return (
        <PaymentPageWrap>
            <AffiliateOverview />

            <ColumnWrap className="mt-20 mb-60 pl-3">
                <CustomColumn
                    title={tPayment['payment@transfer-to']}
                    child={
                        <StyledAutoComplete
                            // popupClassName="certain-category-search-dropdown"
                            // dropdownMatchSelectWidth={500}
                            // style={{ width: 250 }}
                            options={options}
                            onSearch={onSearch}
                            value={inputField.transferTo}
                            onSelect={onSelect}
                        >
                            <Input.Search
                                name="transferTo"
                                size="large"
                                // placeholder="input here"
                                // value={inputField.transferTo}
                                onChange={handleInputChange}
                            />
                        </StyledAutoComplete>
                    }
                />
                <Column
                    title={`${tPayment['payment@transfer-amount']}${mainUnit && ` ${mainUnit}`
                        }`}
                    name={'amount'}
                    value={inputField.amount}
                    type="number"
                    onChange={handleInputChange}
                />
                <br />
                <CustomColumn
                    notRequired
                    child={
                        <Button
                            onClick={() => {
                                if (isDisabled()) {
                                    setAlert({
                                        show: true,
                                        title: tCommon['common@failed'],
                                        message: tError['error@no-fill-all'],
                                    });
                                    return;
                                }
                                setShowConfirmModal(true);
                            }}
                        >
                            {tCommon['common@submit']}
                        </Button>
                    }
                />
            </ColumnWrap>

            <ConfirmModal
                show={showConfirmModal}
                message={tPayment['payment@confirm-transfer']}
                children={
                    <TransferInfo>
                        <p>{`${tPayment['payment@transfer-to']}: ${inputField.transferTo}`}</p>
                        <p>{`${tPayment['payment@transfer-amount']}: ${inputField.amount}`}</p>
                    </TransferInfo>
                }
                onConfirm={doTransfer}
                onCancel={() => setShowConfirmModal(false)}
            />
            <AlertModal
                {...alert}
                onConfirm={() => setAlert({ ...INIT_ALERT })}
            />
        </PaymentPageWrap>
    );
}

const TransferInfo = styled.div`
    color: black;
    text-align: center;
    > p {
        margin: 0;
        margin-top: 5px;
        font-size: ${FONT_SIZE.ALERT_MODAL_TITLE};
        font-weight: 800;
    }
`;

const StyledAutoComplete = styled(AutoComplete)`
    height: auto;
    .ant-select-single {
        height: auto;
    }
`;
