import {
    IS_AW_KH_STG,
    IS_AW_MM_STG,
    IS_AW_VN_STG,
    IS_AW_KH_PROD,
    IS_AW_MM_PROD,
    IS_AW_VN_PROD,
    IS_AW_BD_PROD,
    IS_AW_LA_PROD,
    IS_AW_KE_PROD,
    IS_AW_NL_PROD,
    IS_BITSBET_PROD,
    IS_GW_STG,
    IS_GW_PORD,
    IS_PECH_PROD,
    IS_PECH_STG,
    IS_AW_BD_STG,
    IS_AW_LA_STG,
    IS_AW_KE_STG,
    IS_AW_NL_STG,
    IS_BITSBET_STG,
    IS_LOCAL
} from './config';

const IS_HTTP = window.location.protocol === 'http:';

// export const GET_IP_DOMAIN = 'https://api.ipify.org?format=json'

let BO_API_DOMAIN = 'https://api-stg.gw123.net/api';
let IMAGE_DOMAIN = 'https://api-stg.gw123.net';
let API_DOMAIN = 'https://m1.igp.gs/api';
// let VN_API_DOMAIN = 'https://c.igp.gs/zrt';
let VN_API_DOMAIN = 'https://c.igp.gs/zrt/qpay';
let VN_API_V2_DOMAIN = 'https://c.igp.gs/zrt/vn2';

// let KE_API_DOMAIN = 'https://c.igp.gs/mpesa01/index.html';
// let KE_API_DOMAIN = 'https://c.igp.gs/mpesa/index.html';
let KE_API_DOMAIN = 'https://c.igp.gs/zrt/mpesa02/gggMP2.zrt';


if (IS_HTTP) {
    BO_API_DOMAIN = 'http://api-stg.gw123.net/api';
    IMAGE_DOMAIN = 'http://api-stg.gw123.net';
    // API_DOMAIN = 'http://m1.igp.gs/api';
}

const protocol = IS_HTTP ? 'https' : 'https';
if (IS_GW_PORD) {
    BO_API_DOMAIN = `${protocol}://api.gw123.net/api`;
    IMAGE_DOMAIN = `${protocol}://api.gw123.net`;
    API_DOMAIN = 'https://my.igp.gs/api/';
} else if (IS_GW_STG) {
    BO_API_DOMAIN = `${protocol}://api-stg.gw123.net/api`;
    IMAGE_DOMAIN = `${protocol}://api-stg.gw123.net`;
    API_DOMAIN = 'https://m1.igp.gs/api';
} else if (IS_AW_KH_PROD) {
    BO_API_DOMAIN = `${protocol}://kh-api.aw123.cc/api`;
    IMAGE_DOMAIN = `${protocol}://kh-api.aw123.cc`;
    API_DOMAIN = 'https://kha.igp.gs/api';
} else if (IS_AW_MM_PROD) {
    BO_API_DOMAIN = `${protocol}://mm-api.aw123.cc/api`;
    IMAGE_DOMAIN = `${protocol}://mm-api.aw123.cc`;
    API_DOMAIN = 'https://mma.igp.gs/api';
} else if (IS_AW_VN_PROD) {
    BO_API_DOMAIN = `${protocol}://vn-api.aw123.cc/api`;
    IMAGE_DOMAIN = `${protocol}://vn-api.aw123.cc`;
    API_DOMAIN = 'https://vna.igp.gs/api';
} else if (IS_PECH_PROD) {
    BO_API_DOMAIN = `${protocol}://api.pech.mobi/api`;
    IMAGE_DOMAIN = `${protocol}://api.pech.mobi`;

    API_DOMAIN = 'https://kha.igp.gs/api';
} else if (IS_AW_BD_PROD) {
    BO_API_DOMAIN = `${protocol}://bd-api.aw123.cc/api`;
    IMAGE_DOMAIN = `${protocol}://bd-api.aw123.cc`;

    API_DOMAIN = 'https://bda.igp.gs/api';
} else if (IS_AW_KE_PROD || IS_BITSBET_PROD) {
    BO_API_DOMAIN = `${protocol}://ke-api.aw123.cc/api`;
    IMAGE_DOMAIN = `${protocol}://ke-api.aw123.cc`;

    API_DOMAIN = 'https://kea.igp.gs/api';
} else if (IS_AW_LA_PROD) {
    BO_API_DOMAIN = `${protocol}://la-api.aw123.cc/api`;
    IMAGE_DOMAIN = `${protocol}://la-api.aw123.cc`;

    API_DOMAIN = 'https://laa.igp.gs/api';
} else if (IS_AW_NL_PROD) {
    BO_API_DOMAIN = `${protocol}://nl-api.aw123.cc/api`;
    IMAGE_DOMAIN = `${protocol}://nl-api.aw123.cc`;

    API_DOMAIN = 'https://nla.igp.gs/api';
} else if (IS_AW_KH_STG) {
    BO_API_DOMAIN = `${protocol}://kh-api-stg.aw123.cc/api`;
    IMAGE_DOMAIN = `${protocol}://kh-api-stg.aw123.cc`;

    API_DOMAIN = 'https://k2.igp.gs/api';
} else if (IS_AW_MM_STG) {
    BO_API_DOMAIN = `${protocol}://mm-api-stg.aw123.cc/api`;
    IMAGE_DOMAIN = `${protocol}://mm-api-stg.aw123.cc`;
    API_DOMAIN = 'https://m2.igp.gs/api';
} else if (IS_AW_VN_STG) {
    BO_API_DOMAIN = `${protocol}://vn-api-stg.aw123.cc/api`;
    IMAGE_DOMAIN = `${protocol}://vn-api-stg.aw123.cc`;
    API_DOMAIN = 'https://v2.igp.gs/api';
} else if (IS_PECH_STG) {
    BO_API_DOMAIN = `${protocol}://api-stg.pech.mobi/api`;
    IMAGE_DOMAIN = `${protocol}://api-stg.pech.mobi`;
    API_DOMAIN = 'https://k2.igp.gs/api';
} else if (IS_AW_BD_STG) {
    BO_API_DOMAIN = `${protocol}://bd-api-stg.aw123.cc/api`;
    IMAGE_DOMAIN = `${protocol}://bd-api-stg.aw123.cc`;

    API_DOMAIN = 'https://b1.igp.gs/api';
} else if (IS_AW_KE_STG || IS_BITSBET_STG) {
    BO_API_DOMAIN = `${protocol}://ke-api-stg.aw123.cc/api`;
    IMAGE_DOMAIN = `${protocol}://ke-api-stg.aw123.cc`;

    API_DOMAIN = 'https://k1.igp.gs/api';
} else if (IS_AW_LA_STG) {
    BO_API_DOMAIN = `${protocol}://la-api-stg.aw123.cc/api`;
    IMAGE_DOMAIN = `${protocol}://la-api-stg.aw123.cc`;

    API_DOMAIN = 'https://l1.igp.gs/api';
} else if (IS_AW_NL_STG) {
    BO_API_DOMAIN = `${protocol}://nl-api-stg.aw123.cc/api`;
    IMAGE_DOMAIN = `${protocol}://nl-api-stg.aw123.cc`;

    API_DOMAIN = 'https://n1.igp.gs/api';
} else if (IS_LOCAL) {
    BO_API_DOMAIN = `http://localhost:5141/api`;
    IMAGE_DOMAIN = `http://localhost:5141`;

    API_DOMAIN = 'https://v2.igp.gs/api';
}

/*
api:
stage
k2.igp.gs, m2.igp.gs, v2.igp.gs
Prod
kh.igp.gs, bm.igp.gs, vn.igp.gs
*/

export { BO_API_DOMAIN, IMAGE_DOMAIN, API_DOMAIN };

export const APIS = {
    USER: {
        REQ_SMS: `${API_DOMAIN}/doSendJoinCode.ashx`, //?dvc, tel, pwd, bd(ddmm), intro
        // GET_DEVICE: `${API_DOMAIN}/getDevice.ashx`, //?uip=1.1.1.1
        GET_USER_INFO: `${API_DOMAIN}/getuserinfo.ashx`, //?logo(=gw123), dvc, jkey?
        REGISTER: `${API_DOMAIN}/doUserApply.ashx`, //?dvc, tel, pwd, bd(ddmm), intro
        LOGIN: `${API_DOMAIN}/userLogin.ashx`, //?dvc, acc, pwd
        LOGOUT: `${API_DOMAIN}/userLogout.ashx`, //ukey
        GET_USER_DATA: `${API_DOMAIN}/getUserData.ashx`, //ukey
        SET_USER_DATA: `${API_DOMAIN}/setUserData.ashx`, //ukey, bd(ddmm), opwd, npwd
        GET_USER_BALANCE: `${API_DOMAIN}/getuserbalance.ashx`, //ukey
        GET_USER_BANK: `${API_DOMAIN}/getUserBank.ashx`, //ukey
        NEW_USER_BANKE: `${API_DOMAIN}/newUserBank.ashx`, //?ukey, bank, banknm, bankacc
        SET_USER_BANKE: `${API_DOMAIN}/setUserBank.ashx`, //?ukey, bank, bankacc
        UPDATE_USER_BANKE: `${API_DOMAIN}/updUserBank.ashx`, //?ukey, bank, bankacc, index
        GET_TRANS_LOG: `${API_DOMAIN}/getTransLog.ashx`, // ?ukey, std, endd, pgs, pgidx, tc
        GET_A_DAY: `${API_DOMAIN}/getAday.ashx`, // ?ukey, std, endd
        GET_A_DETAIL: `${API_DOMAIN}/getAdetail.ashx`, // ?ukey, date
        GET_GAME_REC: `${API_DOMAIN}/getGameRec.ashx`, // ?ukey, std, endd, pgs, pgidx
        UPLOAD_AVATAR_IMAGE: `${BO_API_DOMAIN}/Users/upload`, // ?ukey, std, endd, pgs, pgidx
        GET_AVATAR_IMAGE_URL: `${BO_API_DOMAIN}/Users/getAvatarImage`,
        DELETE_AVATAR_IMAGE_URL: `${BO_API_DOMAIN}/Users/deleteAllAvatarImages`,
        UPDATE_AVATAR_IMAGE_URL: `${BO_API_DOMAIN}/Users/updateAvatarImage`,
        REQ_SMS_FP: `${API_DOMAIN}/doSendCpwd.ashx`, //?tel 
        CHANGE_PASSWORD_FP: `${API_DOMAIN}/doChangePWD.ashx`, //?tel=0112345678&chc=665186&npwd=147258396&unm=my name&bd=2301
        REGISTER_V2: `${API_DOMAIN}/prcUserApply.ashx`, //?dvc, tel, pwd, bd(ddmm), unm, intro
        REGISTER_V2_SUBMIT_VERIFY_CODE: `${API_DOMAIN}/doJCApply.ashx`,//?dvc, tel, jc
        FORGOT_PASSWORD_V2: `${API_DOMAIN}/getPwdApply.ashx`,//dvc=&tel=&unm=&bd=
    },
    PAYMENT: {
        GET_FRIEND_LIST: `${API_DOMAIN}/getFriendList.ashx`, //?ukey
        TRANSFER: `${API_DOMAIN}/doTrans.ashx`, //?ukey, val
        DEPOSIT: `${API_DOMAIN}/get3rdIn.ashx`, //?ukey, val, unm
        WITHDRAWAL: `${API_DOMAIN}/get3rdout.ashx`, //?ukey, val
        DO_BONUS_IN: `${API_DOMAIN}/doBonusIn.ashx`, //?ukey, val, tkey
        SET_OP_IN: `${API_DOMAIN}/setOPin.ashx`, //?參數：ukey, prcnm(半自動系統，每個國家要問建安), ub(客人銀行), ua(客人帳號), val(金額), ob(op銀行), oa(op帳號)塞完就等OP處理
        SET_OP_OUT: `${API_DOMAIN}/setOPout.ashx`, //?ukey, val, tkey
        DO_OP_IN: `${API_DOMAIN}/doOPin.ashx`, //?參數：sts: 1=成功	>1 失敗 (才需要塞msg) op 差不多等於 prcnm  這裡如果塞 &ub=&ua=&val= 會更正(不一定要)
        DO_OP_OUT: `${API_DOMAIN}/doOPout.ashx`, //?ukey, val, tkey,
        GET_AGENT_BANK: `${API_DOMAIN}/getAgentBank.ashx`, //?ukey
        VN_DEPOSIT: `${VN_API_DOMAIN}/${IS_AW_VN_PROD ? 'gggMD52' : 'gggMD5'}.zrt`, //?amount=1500.00&userId=99005&ukey=
        VN_DEPOSIT_V2: `${VN_API_V2_DOMAIN}/gggMD55.zrt`, //?amount=1500.00&userId=99005&ukey=

        VN_WITHDRAW: `${VN_API_DOMAIN}/${IS_AW_VN_PROD ? 'gggMD52' : 'gggMD5'}.zrt`, //?amount=1500.00&userId=99005&ukey=
        VN_WITHDRAW_V2: `${VN_API_V2_DOMAIN}/gggMD66.zrt`,//?amount=500&userId=0912231115&ukey=51bf18a0-1\6c4-c5ac-1156-2df6e4186e18&number=712&prod=0&bank_account=abcdefg&bank_id=1548&bank_no=0123456789

        //本次修改state：1充值，2提款。然后添加test:k1或k1a代表stage和prod，这两个头的值杰
        KE_DEPOSIT: `${KE_API_DOMAIN}`, //?amount=10&userId=vn141242a&phonenumber=0122223333&state=1&test=k1&ukey=dc81e2d7-984b-3641-a39e-2079251a7879
        KE_WITHDRAW: `${KE_API_DOMAIN}`, //?amount=10&userId=vn141242a&phonenumber=0122223333&state=2&test=k1&ukey=dc81e2d7-984b-3641-a39e-2079251a7879
        PECH_DEPOSIT: `${API_DOMAIN}/book3rdIn.ashx`, //?tc: 客人輸入的交易碼(建議trim掉),  val & cur 如果有輸入就帶進來 https://k2.igp.gs/api/book3rdIn.ashx?bank=ABA&tc=1233211234568&ukey=10ed003e-8ea1-a738-d9d3-73a9c6385c18&val=50&cur=USD
    },
    GAME: {
        GET_GAME_LIST: `${API_DOMAIN}/getgamelist.ashx`, //?grp=E
        GET_PLAY_GAME: `${API_DOMAIN}/getplaygame.ashx`, //?gid=5501&dvc=a6720a03-4243-d013-3163-d933c372f920
        GET_BAAG_PLAY_GAME: `${API_DOMAIN}/get3rdUrl.ashx`, //?ukey=84df4198-e75b-3d4c-5787-9136f5985d18
        GROUPS: `${BO_API_DOMAIN}/Groups`, //?language=bahasa
        GET_GAMES_BY_LIST: `${BO_API_DOMAIN}/Games`, //?listId=xxx
    },
    SYSTEM: {
        GET_ALLOW_USER_GRADING: `${BO_API_DOMAIN}/SystemMetas/allowUserGrading`,
        GET_DISPLAY_PROFILE_PIC: `${BO_API_DOMAIN}/SystemMetas/displayProfilePic`,
        GET_CURRENCY_DECIMAL_PLACE: `${BO_API_DOMAIN}/SystemMetas/currencyDecimalPlace`,
        GET_DISPLAY_DEPOSIT_AMOUNT_OPTIONS: `${BO_API_DOMAIN}/SystemMetas/displayDepositAmountOptions`,
        GET_DEPOSIT_AMOUNT_OPTIONS: `${BO_API_DOMAIN}/SystemMetas/depositAmountOptions`,
        GET_DISPLAY_WITHDRAW_AMOUNT_OPTIONS: `${BO_API_DOMAIN}/SystemMetas/displayWithdrawAmountOptions`,
        GET_WITHDRAW_AMOUNT_OPTIONS: `${BO_API_DOMAIN}/SystemMetas/withdrawAmountOptions`,
        GET_IS_LIVE_CHAT_ON: `${BO_API_DOMAIN}/SystemMetas/isLiveChatOn`,
        GET_IS_TELEGRAM_ON: `${BO_API_DOMAIN}/SystemMetas/isTelegramOn`,
        GET_TELEGRAM_ACCOUNT: `${BO_API_DOMAIN}/SystemMetas/telegramAccount`,
        GET_IS_WHATSAPP_ON: `${BO_API_DOMAIN}/SystemMetas/isWhatsappOn`,
        GET_WHATSAPP_ACCOUNT: `${BO_API_DOMAIN}/SystemMetas/whatsappAccount`,
        GET_IS_INSTALLPROMPT_NEWS: `${BO_API_DOMAIN}/SystemMetas/installPromptNews`,
        GET_MENU_ITEM_KEYS: `${BO_API_DOMAIN}/SystemMetas/menuItemKeys`,
        GET_IS_FORGOT_PASSWORD_ENABLED: `${BO_API_DOMAIN}/SystemMetas/isForgotPasswordEnabled`,
        GET_SHARE_DIALOG_LAYOUT: `${BO_API_DOMAIN}/SystemMetas/shareDialogLayout`,
        GET_DEFAULT_LANGUAGE: `${BO_API_DOMAIN}/SystemMetas/defaultLanguage`,
        GET_DEPOSIT_IS_SEMI_AUTO: `${BO_API_DOMAIN}/SystemMetas/depositIsSemiAuto`,
        GET_DEPOSIT_QRCODE_OPTIONS: `${BO_API_DOMAIN}/SystemMetas/depositQRCodeOptions`,
        GET_PIXELCODES: `${BO_API_DOMAIN}/SystemMetas/metaPixelCodes`,
        GET_REGISTER_FLOW_TYPE: `${BO_API_DOMAIN}/SystemMetas/registerFlowType`,
        GET_FORGOT_PASSWORD_FLOW_TYPE: `${BO_API_DOMAIN}/SystemMetas/forgotPasswordFlowType`,
        GET_DEPOSIT_STEPPER_OPTIONS: `${BO_API_DOMAIN}/SystemMetas/depositStepperOptions`,
        GET_WITHDRAW_STEPPER_OPTIONS: `${BO_API_DOMAIN}/SystemMetas/withdrawStepperOptions`,
        GET_AD_PAGE_ANNOUCEMENT: `${BO_API_DOMAIN}/SystemMetas/adPageAnnouncement`


    },
    OTHER: {
        LIVE_CHAT: `${API_DOMAIN}/getLiveChat.ashx`, //?dvc=5b420d31-9770-da13-2740-6e111940ca20
        MARQUEE: `${BO_API_DOMAIN}/Marquee`, //?language=bahasa
        BANNERS: `${BO_API_DOMAIN}/Banners`, //?language=bahasa
        ANNOUNCES: `${BO_API_DOMAIN}/ANNOUNCES`, //?language=bahasa
        NEWS: `${BO_API_DOMAIN}/News`, //?language=bahasa
        MAINTENANCES: `${BO_API_DOMAIN}/Maintenances`, //?language=bahasa
        COLORS: `${BO_API_DOMAIN}/Logo/color`,
        DEPOSIT_POLICY: `${BO_API_DOMAIN}/DepositPolicy`,
        WITHDRAWAL_POLICY: `${BO_API_DOMAIN}/WithdrawPolicy`,
        GET_BANK_OUT_LIST: `${API_DOMAIN}/getBankOutList.ashx`,
    },
};

export const IMAGE_DOMAINS = {
    GAME: `${IMAGE_DOMAIN}/gameicons`,
    LOGOS: `${IMAGE_DOMAIN}/Images`,
    BO: `${IMAGE_DOMAIN}/`,
    ORIGIN: `${IMAGE_DOMAIN}`
};

export const STATUS_CODE = {
    SUCCESS: 0,
    NEED_BIRTH: 95,
    MISSING_USER: 92,
    NEED_FULLNAME: 98,
    TIMEOUT: 51,

};

export const ADD_BANK_STATUS_CODE = {
    SUCCESS: 0,
    ACCOUNT_EXIST: 9,
};

export const WITHDRAWAL_FEE_STATUS = {
    NO_FEE: 1,
    REQUIRE_FEE: 0,
};
