import React from 'react';
import styled from 'styled-components';

export default function PromosPage() {
    return (
        <PromosPageWrap>
            PromosPage
            <div className="App">
                    ~~~~~~
            </div>
        </PromosPageWrap>
    );
}

const PromosPageWrap = styled.div`
    display: flex;
    // flex-direction: column;
    width: 100%;
    background: yellow;
    box-sizing: border-box;
    border: 1px solid black;
`;
