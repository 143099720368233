import { META_PREFIX } from '@constants/config';
export function getKVInLS(key) {
    return window.localStorage.getItem(key);
}
export function setKVInLs(key, value) {
    if (typeof window === 'undefined') return;
    window.localStorage.setItem(key, value);
}

const LANG_KEY = META_PREFIX + 'i18nextLng';
export function getLangInLS() {
    const result = getKVInLS(LANG_KEY);
    return result;
}

const SIDEBAR_MODE_KEY = META_PREFIX + 'sidebarMode'; //'collapse' : 'expand'
export function getSidebarMode() {
    const result = getKVInLS(SIDEBAR_MODE_KEY);
    return result;
    // return result || (window.innerWidth < 1280 ? 'collapse' : 'expand');
}
export function setSidebarMode(type) {
    setKVInLs(SIDEBAR_MODE_KEY, type);
}

const HIDE_BANNER_KEY = META_PREFIX + 'hideBanner'; //YYYYMMDD
export function getHideBannerInLS(lang) {
    const result = getKVInLS(`${HIDE_BANNER_KEY}-${lang}`);
    return result;
}
export function setHideBannerInLS(lang, str) {
    setKVInLs(`${HIDE_BANNER_KEY}-${lang}`, str);
}

const HIDE_ANNOUNCEMENT_KEY = META_PREFIX + 'hideAnnouncement'; //YYYYMMDD
export function getHideAnnouncementInLS(lang) {
    const result = getKVInLS(`${HIDE_ANNOUNCEMENT_KEY}-${lang}`);
    return result;
}
export function setHideAnnouncementInLS(lang, str) {
    setKVInLs(`${HIDE_ANNOUNCEMENT_KEY}-${lang}`, str);
}

const LS_DVC_KEY = META_PREFIX + 'dvc'; //device token
export function getDVCInLS() {
    const result = getKVInLS(LS_DVC_KEY);
    return result;
}
export function setDVCInLS(str) {
    setKVInLs(LS_DVC_KEY, str);
}

const LS_USER_KEY = META_PREFIX + 'ukey'; //ukey token
export function getUKeyInLS() {
    const result = getKVInLS(LS_USER_KEY);
    return result;
}
export function setUkeyInLS(str) {
    setKVInLs(LS_USER_KEY, str);
}

const LS_J_KEY = META_PREFIX + 'jkey'; //ukey token
export function getJKeyInLS() {
    const result = getKVInLS(LS_J_KEY);
    return result;
}
export function setJkeyInLS(str) {
    setKVInLs(LS_J_KEY, str);
}

const LS_N_KEY = META_PREFIX + 'nkey'; //ukey token
export function getNKeyInLS() {
    const result = getKVInLS(LS_N_KEY);
    return result;
}
export function setNkeyInLS(str) {
    setKVInLs(LS_N_KEY, str);
}

export function clearUserKeys() {
    setUkeyInLS(null);
    setJkeyInLS(null);
    setNkeyInLS(null);
    setUserRoleInLS(null);
}

const LS_REMEMBER_ME = META_PREFIX + 'rememberme';
export function getRememberInLS() {
    const result = getKVInLS(LS_REMEMBER_ME);
    return result;
}
export function setRememberInLS(bool) {
    setKVInLs(LS_REMEMBER_ME, bool);
}
const LS_ACC = META_PREFIX + 'acc';
export function getAccInLS() {
    const result = getKVInLS(LS_ACC);
    return result;
}
export function setAccInLS(str) {
    setKVInLs(LS_ACC, str);
}
const LS_PASS = META_PREFIX + 'pass';
export function getPassInLS() {
    const result = getKVInLS(LS_PASS);
    return result;
}
export function setPassInLS(str) {
    setKVInLs(LS_PASS, str);
}

const LS_BIRTH = META_PREFIX + 'birth';
export function getBirthInLS() {
    const result = getKVInLS(LS_BIRTH);
    return result;
}
export function setBirthInLS(str) {
    setKVInLs(LS_BIRTH, str);
}

const LS_USER_ROLE = META_PREFIX + 'user_role';
export function getUserRoleInLS() {
    const result = getKVInLS(LS_USER_ROLE);
    return result;
}
export function setUserRoleInLS(number) {
    setKVInLs(LS_USER_ROLE, number);
}
