import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ROUTES, { SWITCH_ROUTES } from '@constants/routes';
import { FaPeopleArrows, FaMobile, FaLock, FaSms } from 'react-icons/fa';
import { TfiReload } from 'react-icons/tfi';
import { Button, Form, Input, Spin } from 'antd';
import { useGlobalProps } from '@hooks/useGlobalProps';
import {
    FONT_SIZE,
    MAX_PAD_WIDTH,
    PASSWORD_MAX_LENGTH,
} from '@constants/numbers';
import { validPassword } from '@utils/verify';
import { getDVCInLS, setDVCInLS } from '@utils/ls';
import { STATUS_CODE } from '@constants/api';
import { parseDoUserApplyRes } from '@utils/parseErrors';
import AlertModal from '@components/shared/modals/AlertModal';
import { register, registerV2 } from '@api/user';
import CaptchaView, { generateCaptcha } from '@components/shared/CaptchaView';
import SimpleLangSelector from '@components/header/SimpleLangSelector';
import BirthdaySelect from 'src/components/shared/BirthdaySelect';
import { getUserInfo, requestSMS } from '@api/user';
import { useLocation } from 'react-router-dom';
import FullNameTexFiled from '@components/shared/FullNameTextFiled';
import RegisterMobileVerifyModal from '@components/shared/modals/RegisterMobileVerifyModal';


const INIT_ALERT = {
    show: false,
    title: '',
    message: '',
    children: null,
};
const PHONE_DEFAULT_TELHD = '01';
const PHONE_DEFAULT_MIN = 0;
const PHONE_DEFAULT_MAX = 13;
export default function RegisterPage() {
    const [errorMsg, setErrorMsg] = useState('');
    const [alert, setAlert] = useState({ ...INIT_ALERT });
    const [captchaAnswer, setCaptchaAnswer] = useState(generateCaptcha());
    const [captchaV, setCaptchaV] = useState('');
    const [needSMS, setNeedSMS] = useState(false);

    const { user, isLiveChatOn, isTelegramOn, telegramAccount, isWhatsappOn, whatsappAccount, pixelCodes, registerFlowType } =
        useGlobalProps();
    const { t } = useTranslation();
    // eslint-disable-next-line no-unused-vars
    const [isSending, setIsSending] = useState(false); // 是否正在發送驗證碼
    const [countdown, setCountdown] = useState(0); // 倒數計時
    const [phoneValue, setPhoneValue] = useState('');
    const [userIntro, setUserIntro] = useState(user?.intro);

    const [mobileVerifyModalInfo, setMobileVerifyModalInfo] = useState({ show: false, phone: '' });


    const tUser = t('user', { returnObjects: true });
    const tCommon = t('common', { returnObjects: true });
    const tError = t('error', { returnObjects: true });
    const tModal = t('modal', { returnObjects: true });
    const location = useLocation();
    const closeAlert = () => setAlert({ ...INIT_ALERT });
    const reloadCaptcha = () => setCaptchaAnswer(generateCaptcha());
    let jkeyRef = useRef();
    const handleChange = (e) => {
        // const { phone } = e.target.value;
        if (e.target.name === 'phone') {
            setPhoneValue(e.target.value);
        }
    };

    // #region API
    const handleSubmit = async (values) => {

        setErrorMsg('');
        if (!user?.intro) {
            setErrorMsg(tError['error@no-affiliate']);
            return;
        }
        const { phone, password, month, day, smsVerificationCode, full_name } = values;

        if (user.dvc_type === 'n' && (!full_name || (!month || !day))) {
            setErrorMsg(tError['error@no-fill-all']);
            return;
        } else if (user.dvc_type === 'b' && (!month || !day)) {
            setErrorMsg(tError['error@no-fill-all']);
            return;
        }

        if (!phone || !password) {
            setErrorMsg(tError['error@no-fill-all']);
            return;
        }

        if (
            needSMS &&
            (!phone || !password || !smsVerificationCode)
        ) {
            setErrorMsg(tError['error@no-fill-all']);
            return;
        }

        if (
            !needSMS &&
            captchaAnswer.toLowerCase() !== captchaV?.toLowerCase()
        ) {
            setErrorMsg(tError['error@wrong-captcha']);
            return;
        }

        const req = {
            dvc: getDVCInLS(),
            tel: phone.trim(),
            pwd: password,

            intro: user.intro,
        };

        if (user.dvc_type === 'n') {

            if (!full_name) {
                setErrorMsg(tError['error@input-full-name']);
                return;
            }
            if (!(full_name.trim().length >= 5)) {
                setErrorMsg(tError['error@input-full-name-length']);
                return;
            }
            req.unm = full_name;
            req.bd = `${day}${month}`;
        } else if (user.dvc_type === 'b') {
            req.bd = `${day}${month}`;
            req.unm = 'aw123';
        } else {
            req.bd = `${'01'}${'01'}`;
            req.unm = 'aw123';
        }

        if (needSMS) {
            req.jc = smsVerificationCode;
        }

        var res;

        if (registerFlowType === 2) {
            res = await registerV2(req);
        } else {
            res = await register(req);
        }
        console.log(' phone number -->  ', phone)
        // setMobileVerifyModalInfo({ show: true, phone: phone })
        // return;
        // const failRes = {"rtn":86,"msg":"not mobile tel","acc":"","pwd":"","bd":"","ukey":"","jkey":"","nkey":""}
        // const successRes = {"rtn":0,"msg":"success","acc":"MY010102A","pwd":"123123","bd":"0101","ukey":"90e4b25e-efa0-ed11-add7-358393c89b18","jkey":"f54f3fb3-1552-99ef-c01c-1a204df0e5c2","nkey":"80b2453e-9d22-fb46-1035-51895d1ef549"}
        if (res && res?.rtn === STATUS_CODE.SUCCESS) {


            if (res.acc === 'sms') {
                //show sms dialog
                setMobileVerifyModalInfo({ show: true, phone: phone })
            } else {
                setAlert({
                    show: true,
                    title: tModal['register@register-successful'],
                    children: (
                        <RegisterSuccessfulDesc>
                            {tModal['register@register-successful-desc']}
                            <br />
                            {res?.acc}
                            <br />
                            {res?.pwd}
                        </RegisterSuccessfulDesc>
                    ),
                    onConfirm: () => {
                        closeAlert();
                        window.location.href = SWITCH_ROUTES.ROOT;
                    },
                });

            }


        } else {
            setAlert({
                show: true,
                title: tCommon['common@failed'],
                message: parseDoUserApplyRes(tError, res),
                children: null,
                onConfirm: closeAlert,
            });
        }
    };
    // #endregion

    // #region form config
    const validatorPassword = (_rule, value) => {
        if (!value) return Promise.reject();
        if (validPassword(value)) return Promise.resolve();
        return Promise.reject();
    };
    const validatorPhone = (_rule, value) => {
        if (!value) return Promise.reject();
        const trimmedValue = value.trim();

        const validLen = !(
            trimmedValue.length < user.minln || trimmedValue.length > user.maxln
        );
        if (user?.telhd && user?.minln && user?.maxln) {
            const telhds = user?.telhd.split(',');
            let isValid = false;
            telhds.forEach((e) => {
                if (!isValid && trimmedValue.startsWith(e) && validLen) {
                    isValid = true;
                }
            });
            if (!!isValid) return Promise.resolve();
        }

        return Promise.reject();
    };
    const handleSendVerification = async () => {
        setErrorMsg('');

        if (countdown > 0) {
            return;
        }

        if (!phoneValue) {
            setErrorMsg(tError['error@no-fill-phone']);
            return;
        }

        setIsSending(true);
        const res = await requestSMS({
            tel: phoneValue.trim(),
            jkey: jkeyRef.current,
        });
        setIsSending(false);
        console.log(' REQ SMS RES -->  ', res);
        if (!res || res?.rtn !== STATUS_CODE.SUCCESS) {
            if (res?.rtn === 49) {
                setErrorMsg(tError['error@limitReached']);
                return;

            } else if (res?.rtn === 77) {
                setAlert({
                    show: true,
                    title: tCommon['common@failed'],
                    message: parseDoUserApplyRes(tError, res),
                    children: null,
                    onConfirm: closeAlert,
                });
            } else {
                setAlert({
                    show: true,
                    title: tCommon['common@failed'],
                    message: parseDoUserApplyRes(tError, res),
                    children: null,
                    onConfirm: closeAlert,
                });

                return;

            }
        }

        setCountdown(120);

        const timer = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown <= 1) {
                    clearInterval(timer);
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);
    };

    const hintPhone = tUser['hint@phone']
        ? tUser['hint@phone']
            .replace('{telhd}', user?.telhd || PHONE_DEFAULT_TELHD)
            .replace('{minln}', user?.minln || PHONE_DEFAULT_MIN)
            .replace('{maxln}', user?.maxln || PHONE_DEFAULT_MAX)
        : '';
    const form = [
        {
            name: 'affiliate',
            addonBefore: <FaPeopleArrows />,
            initialValue: userIntro,
            disabled: true,
        },
        {
            name: 'phone',
            rules: [
                {
                    required: true,
                    message: hintPhone,
                    min: user?.minln || PHONE_DEFAULT_MIN,
                    max: user?.maxln || PHONE_DEFAULT_MAX,
                    whitespace: false,
                    validator: validatorPhone,
                },
            ],
            addonBefore: <FaMobile />,
            help: hintPhone,
        },
        {
            name: 'smsVerificationCode',
            rules: [
                {
                    required: true,
                    message: tUser['hint@smsVerification'],
                    whitespace: false,
                    len: 6, // 假設驗證碼是 6 位數
                },
            ],
            addonBefore: <FaSms />, // 更換為合適的 icon
            help: tUser['hint@smsVerification'],
            isVerifiedCode: true,
        },
        {
            name: 'password',
            rules: [
                {
                    required: true,
                    message: tUser['hint@password'],
                    min: 6,
                    max: PASSWORD_MAX_LENGTH,
                    whitespace: false,
                    validator: validatorPassword,
                },
            ],
            addonBefore: <FaLock />,
            help: tUser['hint@password'],
            isPassword: true,
        },
    ];

    useEffect(() => {
        async function fetch(jkey) {
            let dvc = getDVCInLS();
            if (!dvc) {
                const res = await getUserInfo();
                if (res.rtn === STATUS_CODE.SUCCESS && res.device_id) {
                    setDVCInLS(res.device_id);
                    dvc = res.device_id;
                    setUserIntro(res.intro);
                }
            }

            let userInfoRes;
            if (jkey) {
                userInfoRes = await getUserInfo({ jkey });
            } else {
                userInfoRes = await getUserInfo({ dvc });
            }

            setUserIntro(userInfoRes.intro);

            setNeedSMS(userInfoRes && userInfoRes.sms_yn === 1 && registerFlowType === 1);
            if (userInfoRes.jkey) {
                jkeyRef.current = userInfoRes.jkey;
            } else {
                const params = new URLSearchParams(location.search);
                jkeyRef.current = params.get('jkey');
            }
        }

        const params = new URLSearchParams(location.search);
        jkeyRef.current = params.get('jkey');

        fetch(jkeyRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registerFlowType]);


    // #endregion

    return (
        <RegisterPageWrap getCodeButtonDisabled={countdown > 0}>

            <Helmet>
                <title>{tUser['register@title']}</title>
            </Helmet>
            <div className="register-card">
                <div className="register-card-head">
                    <h1>{tUser['register@title']}</h1>
                </div>
                <Form
                    className="register-card-form"
                    colon={false}
                    onFinish={handleSubmit}
                    onChange={handleChange}
                    size="large"
                >
                    {form.map((item) => {
                        if (item.name === 'smsVerificationCode' && !needSMS) {
                            return (
                                <div
                                    key={`${item.name}${item?.initialValue}`}
                                ></div>
                            );
                        }
                        return (
                            <Form.Item
                                key={`${item.name}${item?.initialValue}`}
                                name={item.name}
                                rules={item?.rules}
                                help={item?.help}
                                initialValue={item?.initialValue}
                                className={`register-form-${item.name}`}
                            >
                                <Input
                                    name={item.name}
                                    type={
                                        item?.isPassword ? 'password' : 'text'
                                    }
                                    addonBefore={item.addonBefore}
                                    placeholder={tUser[`user@${item.name}`]}
                                    disabled={item?.disabled}
                                    maxLength={
                                        item?.isPassword
                                            ? PASSWORD_MAX_LENGTH
                                            : ''
                                    }
                                    addonAfter={
                                        item?.isVerifiedCode && (
                                            <div
                                                onClick={isSending ? null : handleSendVerification}
                                                disabled={countdown > 0}
                                            >
                                                {isSending ?
                                                    <SpinContainer>
                                                        <Spin
                                                            className='spinner'
                                                            style={{
                                                                color: 'white',
                                                                top: '25%',
                                                                left: '45%',
                                                                position: 'absolute',
                                                            }}
                                                        /> </SpinContainer>
                                                    : countdown > 0
                                                        ? `${countdown} ${countdown === 1
                                                            ? tUser[
                                                            'user@second'
                                                            ]
                                                            : tUser[
                                                            'user@seconds'
                                                            ]
                                                        }`
                                                        : tUser[
                                                        'user@getVerificationCode'
                                                        ]}

                                            </div>
                                        )
                                    }
                                />
                            </Form.Item>
                        );
                    })}
                    {user?.dvc_type === 'n' && <>
                        <FullNameTexFiled isRegister={true} isInitialEmpty={true} />
                        <BirthdaySelect isRegister={true} isInitialEmpty={true} />
                    </>}
                    {user?.dvc_type === 'b' && <BirthdaySelect isRegister={true} isInitialEmpty={true} />}

                    {!needSMS && (
                        <CaptchaWrap>
                            <CaptchaView captchaValue={captchaAnswer} />
                            <TfiReload onClick={reloadCaptcha} />
                            <Input
                                placeholder={tUser['hint@captcha']}
                                onChange={(e) => setCaptchaV(e.target.value)}
                            />
                        </CaptchaWrap>
                    )}

                    {errorMsg && <p className="red">{errorMsg}</p>}
                    <Form.Item className="register-btn">
                        <Button type="primary" htmlType="submit" onClick={(e) => {
                            if (pixelCodes && pixelCodes.length > 0) {
                                pixelCodes.forEach((code) => {
                                    if (window.fbq) window.fbq('trackSingle', code, 'CompleteRegistration')
                                });
                            }
                        }}>
                            {tUser['register@submit']}
                        </Button>
                    </Form.Item>
                </Form>
                {isLiveChatOn && (
                    <Link
                        className="register-card-livechat"
                        to={`${ROUTES.ROOT}${ROUTES.LIVE_CHAT}`}
                        target="_blank"
                    >
                        {tCommon['rootHeader@livechat']}
                    </Link>
                )}
                {isTelegramOn && telegramAccount && (
                    <Link
                        className="register-card-telegram"
                        to={`${ROUTES.TELEGARM}/${telegramAccount}`}
                        target="_blank"
                    >
                        {tCommon['rootHeader@telegram']}
                        <img
                            width={'25px'}
                            src="/images/shared/telegram.png"
                            alt="telegram"
                        />
                    </Link>
                )}
                {isWhatsappOn && whatsappAccount && (
                    <Link
                        className="register-card-telegram"
                        // to={`${ROUTES.WHATSAPP}/${whatsappAccount}`}
                        to={whatsappAccount.startsWith('http') ? whatsappAccount : `${ROUTES.WHATSAPP}/${whatsappAccount}`}
                        target="_blank"
                    >
                        {tCommon['rootHeader@whatsapp']}
                        <img
                            width={'25px'}
                            src="/images/shared/whatsapp.png"
                            alt="whatsapp"
                        />
                    </Link>
                )}

                <SimpleLangSelector wrapClassname="lang-selector-wrap" />
            </div>
            <RegisterMobileVerifyModal
                show={mobileVerifyModalInfo.show}
                message={tUser['register@title']}
                initiaPhoneValue={mobileVerifyModalInfo.phone}
                onConfirm={(phone) => {
                    // setInputField({ ...inputField, mobile: phone });
                    setMobileVerifyModalInfo({ ...mobileVerifyModalInfo, show: false })
                }}
                onCancel={() => setMobileVerifyModalInfo({ ...mobileVerifyModalInfo, show: false })}
            />
            <AlertModal {...alert} />
        </RegisterPageWrap>
    );
}

const RegisterPageWrap = styled.div`
    display: flex;
    background-image: url(/images/ra-bg.png);
    background-size: cover;
    background-repeat: no-repeat
    width: 100%;
    height: -webkit-fill-available;
    justify-content: center;
    align-items: center;


    .ant-form-item {
        display: inline-block;
        margin-bottom: 48px;

        &.register-btn {
            margin: 5px auto 16px !important;
        }
    }

    .register-card {
        position: relative;
        background: #fff;
        height: 90vh;
        width: 95vw;
        overflow-x: hidden;
        box-sizing: box-sizing;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
        border-radius: 0.375rem;
        padding: 48px;
        @media (min-width: ${MAX_PAD_WIDTH}px) {
            max-height: 670px;
            max-width: 500px;
        }
        .ant-form-item {
            width: 100%;
        }

        &-head {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 24px;

            h1 {
                font-size: 28px;
                font-weight: 500;
                line-height: 1.2;
                margin: 0 auto;
            }
        }
        &-form {
            button {
                margin-top: 16px;
                width: 100%;
                background: ${({ theme }) => theme.primary} !important;
                color: #fff;
            }
        }
        &-livechat {
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            width: 100%;
            height: 40px;
            background: #fff;
            border: 1px solid ${({ theme }) => theme.primary};
            border-radius: 8px;
            color: ${({ theme }) => theme.primary};
            transition: all .15s ease-in-out;

            &:hover {
                background: ${({ theme }) => theme.primary};
                color: #fff;
            }
        }

        &-telegram {
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            width: 100%;
            height: 40px;
            margin-top: 8px;
            gap: 10px;
            background: #fff;
            border: 1px solid ${({ theme }) => theme.primary};
            border-radius: 8px;
            color: ${({ theme }) => theme.primary};
            transition: all .15s ease-in-out;

            &:hover {
                background: ${({ theme }) => theme.primary};
                color: #fff;
            }
        }
    }

    .register-form-affiliate {
        margin-bottom: 10px !important;
        >div {
            >div {
                >div:nth-child(2) {
                    display: none !important;
                }
            }
        }
        span, input {
            color: black;
        }
    }

    .register-form-smsVerificationCode {

        .ant-input-group-addon:last-child {
            cursor:  ${(props) =>
        props.getCodeButtonDisabled ? 'default' : 'pointer'};
            width:30%;
            div {
                color:#fff;
                &:link,
                &:visited,
                &:hover,
                &:active {
                    color: #fff;
                }
            }
            background-color: ${({ theme }) =>
        (props) =>
            props.getCodeButtonDisabled ? 'grey' : theme.primary};
        } 
    }

    .red {
        color: red;
        font-size: ${FONT_SIZE.NORMAL};
        margin: 8px 0px;
    }

    .lang-selector-wrap {
        position: absolute;
        top: 15px;
        right: 20px;
    }
`;

const RegisterSuccessfulDesc = styled.p`
    color: red;
    font-size: ${FONT_SIZE.NORMAL};
    font-weight: 700;
    margin: 8px 0px;
    text-align: center;
`;

const CaptchaWrap = styled.div`
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;

    > svg {
        width: 20px;
        height: 20px;
        cursor: pointer;
        flex-shrink: 0; // Prevents the reload icon from shrinking
    }

    > input {
        width: calc(100% - 45% - 30px);
        padding-left: 10px;
        height: 38px;
        border-radius: 8px;
        font-size: 16px;
        border: 1px solid lightgray;
        flex-grow: 1; // Allows the input to grow and fill available space
        max-width: calc(100% - 60px); // Adjusts max-width to prevent overflow
    }
    > input::placeholder {
        font-size: 16px;
        font-weight: 200;
        margin-left: -12px;
    }

    @media (max-width: 768px) { // Adjusts styles for devices with width up to 768px
        justify-content: space-between; // Spreads items out to use available space
        flex-wrap: wrap;

        > input {
            max-width: 100%; // Allows the input to take full width on smaller screens
            order: 3; // Moves the input field to the bottom
            margin-top: 10px; // Adds some space above the input field when it wraps
        }

        > svg {
            order: 2; // Positions the reload icon in the middle
        }
    }
`;
const SpinContainer = styled.div`
    textalign: center;
    position: relative;
    width: 100px;
    height: 40px;
    .spinner {
        .ant-spin-dot-item {
            background-color: white;
          }
    }
`;