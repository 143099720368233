import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Select, ConfigProvider, Tag } from 'antd';
import AffiliateOverview from '@components/shared/AffiliateOverview';
import { Button, CustomColumn, PaymentPageWrap } from '@components/shared/View';
import { useGlobalProps } from '@hooks/useGlobalProps';
import { MAX_PAD_WIDTH } from '@constants/numbers';
import QuickTimeRangePickers from '@components/shared/QuickTimeRangePickers';
import { getTransLog } from '@api/user';
import { STATUS_CODE } from '@constants/api';
import {
    DATE_FORMAT,
    TRANSACTION_TYPE_OPTIONS,
    TRANS_COLUMN_KEYS,
    TRANS_MAX_DATE_RANGE,
    TRANS_PAGE_SIZE,
    TRANS_QUICK_PICKERS,
    TRANS_TYPE_ALL,
    formatTransData,
} from '@components/transLog/configAndFunc';
import PaginationTable, {
    renderPagination,
} from '@components/shared/PaginationTable';
import { parseErrorRes } from '@utils/parseErrors';
import AlertModal from '@components/shared/modals/AlertModal';
import { TransactionLogStatusEnum } from '@constants/config';
import { displayAmountWithDecimalPlace } from '@utils/util';

const TODAY = dayjs();
const INIT_ALERT = {
    show: false,
    title: '',
};
export default function TransactionLogPage() {
    const [startDate, setStartDate] = useState(TODAY);
    const [endDate, setEndDate] = useState(TODAY);
    const [selectedType, setSelectedType] = useState(TRANS_TYPE_ALL);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [alert, setAlert] = useState({ ...INIT_ALERT });
    const { user, mainUnit, decimalPlace, getBalance } = useGlobalProps();

    const { t } = useTranslation();
    const tCommon = t('common', { returnObjects: true });
    const tTrans = t('transaction', { returnObjects: true });
    const tError = t('error', { returnObjects: true });

    const typeOptions = TRANSACTION_TYPE_OPTIONS.map((t) => {
        return { ...t, label: tTrans[`trans@type-${t.value}`] };
    });
    const paginationConfig = {
        current: currentPage,
        pageSize: TRANS_PAGE_SIZE,
        simple: true,
        showLessItems: true,
        total: totalCount,
        defaultPageSize: TRANS_PAGE_SIZE,
        showSizeChanger: false,
        // pageSizeOptions: ['5', '10', '20', '30', '50'],
        itemRender: renderPagination(tCommon, currentPage),
    };
    const getData = async (page) => {
        setLoading(true);
        const p = page || currentPage;
        let req = {
            ukey: user.ukey,
            std: startDate.format(DATE_FORMAT),
            endd: endDate.format(DATE_FORMAT),
            pgs: TRANS_PAGE_SIZE,
            pgidx: p,
        };
        if (selectedType) req.tc = selectedType;
        // const failedRes = {"rtn":123,"msg":"Error test.","page_index":0,"rec_count":0,"rec_list":null}
        const res = await getTransLog(req);
        if (res?.rtn === STATUS_CODE.SUCCESS || res?.rtn === 9) {
            // 9 ="No data in range"
            if (res?.rec_list) setData(formatTransData(res.rec_list));
            else setData([]);
            if (res?.page_index && res.page_index !== p)
                setCurrentPage(res.page_index);
            if (totalCount !== res?.rec_count) setTotalCount(res?.rec_count);
        } else {
            setAlert({
                show: true,
                title: tCommon['common@failed'],
                message: parseErrorRes(tError, res),
            });
        }
        setLoading(false);
    };
    const columns = [
        {
            title: tTrans['trans@date'],
            dataIndex: TRANS_COLUMN_KEYS.DATE,
            key: TRANS_COLUMN_KEYS.DATE,
            width: '20%',
            render: (text) => text?.substr(0, 19),
        },
        {
            title: tTrans['trans@type'],
            dataIndex: TRANS_COLUMN_KEYS.TYPE,
            key: TRANS_COLUMN_KEYS.TYPE,
            render: (text, record) => {
                const status = record[TRANS_COLUMN_KEYS.STATUS];
                let tagColor;
                switch (status) {
                    case TransactionLogStatusEnum.COMPLETED:
                        tagColor = '#006400';
                        break;
                    case TransactionLogStatusEnum.WAITING:
                        tagColor = '#FF8C00';
                        break;
                    case TransactionLogStatusEnum.FAILED:
                        tagColor = '#8B0000';
                        break;
                    default:
                        tagColor = 'black'; // default color in case of unexpected status
                }
                return (
                    <div className="type-status-row">
                        {tTrans[`trans@type-${text}`]}
                        <Tag className="custom-tag" color={tagColor}>
                            {tTrans[`trans@status-${status}`]}
                        </Tag>
                    </div>
                );
            },
        },
        {
            title: `${tTrans['trans@amount']}${mainUnit && ` ${mainUnit}`}`,
            dataIndex: TRANS_COLUMN_KEYS.AMOUNT,
            key: TRANS_COLUMN_KEYS.AMOUNT,
            render: (text, _) => <>{displayAmountWithDecimalPlace(
                Number(text),
                decimalPlace
            )}</>
        },
        {
            title: `${tTrans['trans@balance']}${mainUnit && ` ${mainUnit}`}`,
            dataIndex: TRANS_COLUMN_KEYS.BALANCE,
            key: TRANS_COLUMN_KEYS.BALANCE,
            render: (text, record) => {
                // console.log(' record ->  ', record);
                if (record.type === 8 && (record.status === TransactionLogStatusEnum.WAITING || record.status === TransactionLogStatusEnum.FAILED)) {
                    // return <div style={{ color: 'lightgray' }}>
                    //     {tTrans[`trans@status-${record.status}`]}
                    // </div>
                    return <></>
                }
                return <>{displayAmountWithDecimalPlace(
                    Number(text),
                    decimalPlace
                )}</>
            }
        },
    ];
    const handleOptionChange = (value) => {
        setSelectedType(value);
    };
    const onPageChange = (pagination, _filters, _sorter, _extra) => {
        const { current } = pagination; //, pageSize
        setCurrentPage(current);
        getData(current);
    };
    const handlesubmit = async () => {
        await getData();
    };
    useEffect(() => {
        if (user?.ukey) getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (user?.ukey) getBalance();
    }, [user, getBalance]);


    return (
        <TransactionLogPageWrap>
            <AffiliateOverview />

            <div className="tran-log-type-selector">
                <CustomColumn
                    title={tTrans['trans@type']}
                    child={
                        <ConfigProvider>
                            <Select
                                className="tran-type-select"
                                size="large"
                                value={selectedType}
                                onChange={handleOptionChange}
                                options={typeOptions}
                            />
                        </ConfigProvider>
                    }
                />
            </div>

            <QuickTimeRangePickers
                maxDateRange={TRANS_MAX_DATE_RANGE}
                quickPickers={TRANS_QUICK_PICKERS}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
            />

            <div className="btn-center-wrap mt-20">
                <Button onClick={handlesubmit}>
                    {tCommon['common@submit']}
                </Button>
            </div>

            <PaginationTable
                tCommon={tCommon}
                rowKey={(r) => `${Math.random()}-${r[TRANS_COLUMN_KEYS.DATE]}`}
                dataSource={data}
                columns={columns}
                pagination={paginationConfig}
                onChange={onPageChange}
                currentPage={currentPage}
                loading={loading}
            />

            <AlertModal
                {...alert}
                onConfirm={() => setAlert({ ...INIT_ALERT })}
            />
        </TransactionLogPageWrap>
    );
}

const TransactionLogPageWrap = styled(PaymentPageWrap)`
    .tran-log-type-selector {
        display: flex;
        width: 60%;
        gap: 20px;
        align-items: center;
        .tran-type-select {
            width: 25vw;
            min-width: 220px;
        }
    }
    .btn-center-wrap {
        display: flex;
        // justify-content:center;
    }

    table {
        margin: 0 0 3rem 0;
    }

    .custom-tag {
        color: white;
        border: none;
        margin-left: 10px;
        border-radius: 12px;
    }

    .type-status-row {
        display: flex;
        align-items: center;
        justify-content: start;
    }

    @media (max-width: ${MAX_PAD_WIDTH}px) {
        .tran-log-type-selector {
            width: 100%;
            > div:nth-child(2) {
                display: flex;
                flex: 1;
            }
            .tran-type-select {
                display: flex;
                flex: 1;
            }
        }
    }
`;

// const TEST_DATA = [["2023/07/29 11:36:43.660","AA comm",7,7],["2023/07/28 17:47:22.197","unknown",10000,0],["2023/07/26 16:50:22.977","unknown",100000,0]]
