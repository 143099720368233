/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useGlobalProps } from '@hooks/useGlobalProps';
import { useNavigate } from 'react-router-dom';
import { SWITCH_ROUTES } from '@constants/routes';
import AlertModal from '@components/shared/modals/AlertModal';

const LoggedOutModal = () => {
    const { showLoggedOutModal, setShowLoggedOutModal } = useGlobalProps()
    const { t } = useTranslation()
    const tModal = t("modal", { returnObjects: true })
    const navigate = useNavigate();

    const onConfirm = () => {
        navigate(SWITCH_ROUTES.LOGOUT)
        setShowLoggedOutModal(false)
    }

    return (
        <AlertModal show={showLoggedOutModal} 
            title={tModal["loggedOut@title"]}
            children={
                <AlertMsg>
                    <p>{tModal["loggedOut@msg"]} </p>
                </AlertMsg>}
            onConfirm={onConfirm}/>
    );
};

export default LoggedOutModal;

const AlertMsg = styled.div`
    text-align: center;
`