/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';

import {
    Column,
    CustomColumnAlignStart,
    AmountWrap,
    // ButtonsWrap,
    // Button
} from '@components/shared/View';
import AmountSelectionsView from '@components/shared/AmountSelectionsView';
// import { FaMinus, FaPlus } from "react-icons/fa";

const AmountColumn = (props) => {
    const { mainUnit, title, displayAmountOptions, amount, onAmountChange, onSelecteAmount, amountInfos, displayStepper
        // onIncreaseAmount, onDecreaseAmount, displayStepper
    } = props;
    const amountRef = useRef(null);
    const optionsRef = useRef(null);
    const [wrappedWidth, setWrappedWidth] = useState(0);
    // const  = useRef(true);
    const [skipped, setSkipped] = useState(true);
    const originalDiff = useRef(null);

    function handleL2SResize() {
        if (optionsRef.current != null && amountRef.current != null) {

            if (Math.abs(optionsRef.current.offsetTop - amountRef.current.offsetTop) > 10) {
                return window.innerWidth;
            }
        }
        return 0;
    }

    function handleS2LResize() {
        if (optionsRef.current != null && amountRef.current != null) {
            if (Math.abs(optionsRef.current.offsetTop - amountRef.current.offsetTop) === 0) {
                return window.innerWidth;
            }

        }
        return 0;
    }

    useEffect(() => {
        const detectedL2S = () => {
            const width = handleL2SResize();
            if (width > 0) {
                console.log(' set width ->  ', width);
                setWrappedWidth(width + 1);
            }
        }

        const detectedS2L = () => {
            const width = handleS2LResize();

            if (width > 0) {
                console.log(' set width ->  ', width);
                setWrappedWidth(width + 1);
            }
        }



        // let originWrapped = false;


        if (!wrappedWidth) {
            if (optionsRef.current != null && amountRef.current != null) {
                // console.log(' optionsRef.current.offsetTop ->  ', optionsRef.current.offsetTop);
                // console.log(' amountRef.current.offsetTop ->  ', amountRef.current.offsetTop);
                // console.log(' skipped ->  ', skipped);

                if (!skipped) {
                    // console.log(' originalDiff -->  ', originalDiff);

                    // if (originalDiff.current == null) {
                    //     originalDiff.current = Math.abs(optionsRef.current.offsetTop - amountRef.current.offsetTop);
                    // }
                    if (Math.abs(optionsRef.current.offsetTop - amountRef.current.offsetTop) > 10) {
                        window.addEventListener('resize', detectedS2L)
                        console.log(' listen S2L ');
                        detectedS2L();
                    } else {
                        window.addEventListener('resize', detectedL2S)
                        console.log(' listen L2S ');
                        detectedL2S();
                    }
                }

                setSkipped(false);


            }
        }
        // detectedL2S();
        // detectedS2L();


        return () => {
            window.removeEventListener('resize', detectedL2S)
            window.removeEventListener('resize', detectedS2L)
        };

    }, [wrappedWidth, amountRef.current, optionsRef.current, displayAmountOptions, originalDiff.current, skipped]);

    return (
        <CustomColumnAlignStart
            title={`${title}${mainUnit && ` ${mainUnit}`
                }`}
            child={
                <AmountWrap
                    hasOptions={displayAmountOptions}
                    wrappedWidth={wrappedWidth}
                >
                    <Column
                        innerRef={amountRef}
                        name={'amount'}
                        value={amount}
                        type="number"
                        readOnly={displayAmountOptions}
                        onChange={onAmountChange}
                    />
                    {/* {displayStepper &&
                        <ButtonsWrap>
                            <Button
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '60px',
                                    height: '40px',
                                    marginLeft: '8px',
                                    marginRight: '8px'
                                }}
                                disabled={false}
                                onClick={onDecreaseAmount} >
                                <FaMinus />
                            </Button>

                            <Button
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '60px',
                                    height: '40px',
                                }}
                                disabled={false}
                                onClick={onIncreaseAmount} >
                                <FaPlus />
                            </Button>
                        </ButtonsWrap>} */}

                    {displayAmountOptions &&
                        (<AmountSelectionsView innerRef={optionsRef} amountInfos={amountInfos} onSelectAmount={onSelecteAmount} displayStepper={displayStepper} />)}

                </AmountWrap>
            }
        />
    )
}

export default AmountColumn