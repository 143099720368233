import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import HomeBanner from '@components/home/HomeBanner';
import HomeTabs from '@components/home/HomeTabs';
import AnnouncementModal from '@components/shared/modals/AnnouncementModal';
import MobileKeyInfo from '@components/home/MobileKeyInfo';
import { useGlobalProps } from '@hooks/useGlobalProps';
import NewsModal from '@components/home/NewsModal';
import { useTranslation } from 'react-i18next';

export default function HomePage() {
    const { user, adPageAnnouncement, setAdPageAnnouncement } = useGlobalProps();

    const { i18n } = useTranslation();

    const [isLoggedIn, setIsLoggedIn] = useState(null);
    useEffect(() => {
        if (!isLoggedIn) {
            setIsLoggedIn(user && !!user.jkey);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);
    console.log("adPageAnnouncement ->   ", adPageAnnouncement);
    console.log("i18n.language ->   ", i18n.language);

    return (
        <HomePageWrap>

            <HomeBanner />
            <MobileKeyInfo />
            <HomeTabs />
            {/* <AddToHomeFloatButton /> */}
            {isLoggedIn && <AnnouncementModal />}
            <NewsModal
                show={adPageAnnouncement && adPageAnnouncement[i18n.language] && adPageAnnouncement[i18n.language] !== ""}
                onClose={() => setAdPageAnnouncement((prev) => { return { ...prev, [i18n.language]: "" } })}
                {...adPageAnnouncement[i18n.language]}
            />
        </HomePageWrap>
    );
}


const HomePageWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
