import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FONT_SIZE, MAX_PAD_WIDTH } from '@constants/numbers';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
const ImageSelectBar = ({
    selectedDefaultAvatar,
    avatars,
    handleDefaultAvatarClick,
}) => {
    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const [showMaleLeftArrow, setShowMaleLeftArrow] = useState(false);
    const [showMaleRightArrow, setShowMaleRightArrow] = useState(true);
    const avatarContainerRef = useRef(null);

    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - avatarContainerRef.current.offsetLeft);
        setScrollLeft(avatarContainerRef.current.scrollLeft);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - avatarContainerRef.current.offsetLeft;
        const walk = x - startX;
        avatarContainerRef.current.scrollLeft = scrollLeft - walk;
    };

    useEffect(() => {
        const container = avatarContainerRef.current;
        if (container) {
            container.addEventListener('scroll', checkScrollPosition);
        }
        return () => {
            if (container) {
                container.removeEventListener('scroll', checkScrollPosition);
            }
        };
    }, []);

    const checkScrollPosition = () => {
        const container = avatarContainerRef.current;
        if (container) {
            setShowMaleLeftArrow(container.scrollLeft > 0);
            setShowMaleRightArrow(
                container.scrollLeft <
                    container.scrollWidth - container.clientWidth
            );
        }
    };

    return (
        <BarWrap onClick={stopPropagation}>
            <div className="default-avatar-wrapper">
                <div
                    className="arrow left"
                    style={{
                        display: showMaleLeftArrow ? 'block' : 'none',
                    }}
                >
                    <LeftCircleFilled />
                </div>
                <div
                    className="default-avatar-container"
                    ref={avatarContainerRef}
                    onMouseDown={handleMouseDown}
                    onMouseLeave={handleMouseLeave}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                >
                    {avatars.map((avatarSrc, index) => (
                        <img
                            key={index}
                            src={avatarSrc}
                            draggable={false}
                            alt={`Default Avatar ${index + 1}`}
                            className={
                                selectedDefaultAvatar === avatarSrc
                                    ? 'selected'
                                    : ''
                            }
                            onClick={() => handleDefaultAvatarClick(avatarSrc)}
                        />
                    ))}
                </div>
                <div
                    className="arrow right"
                    style={{
                        display: showMaleRightArrow ? 'block' : 'none',
                    }}
                >
                    <RightCircleFilled />
                </div>
            </div>
        </BarWrap>
    );
};

export default ImageSelectBar;

const BarWrap = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    background: white;
    padding: 8px 40px;
    gap: 10px;
    width: auto;
    max-width: 95vw;

    > p {
        margin: 10px 0px;
    }
    .alert-title {
        font-size: ${FONT_SIZE.ALERT_MODAL_TITLE};
        font-weight: 700;
    }

    @media (min-width: ${MAX_PAD_WIDTH}px) {
        width: auto;
        max-width: 800px;
        min-width: 300px;
    }

    .default-avatar-wrapper {
        overflow-x: auto; // Enables horizontal scrolling
        width: 100%; // Or set to another fixed value
        position: relative;
        display: flex;
        justify-content: center;
    }

    .default-avatar-container {
        display: flex;
        gap: 10px;
        overflow-x: auto; // Enables horizontal scrolling
        white-space: nowrap; // Prevents items from wrapping to the next line
        width: 90%;
        img {
            cursor: pointer;
            border: 2px solid transparent;
            user-select: none;
            &.selected {
                border-color: ${({ theme }) => theme.primary};
                border-width: 2px;
            }
        }
    }
    .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 1;
        &.left {
            left: 0;
        }
        &.right {
            right: 0;
        }
    }
    .close-icon {
        position: absolute;
        right: 15px;
        cursor: pointer;
        mask-image: url('/images/shared/close.svg');
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        min-width: 18px;
        min-height: 18px;
        background: black;
    }
`;
