/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useIsMobile from '@hooks/useIsMobile';
import { useGlobalProps } from '@hooks/useGlobalProps';
import { displayAmountWithDecimalPlace } from '@utils/util';
import { useNavigate } from 'react-router-dom';
import { SWITCH_ROUTES } from '@constants/routes';
import { getOperatingSystem, getChromeVersion } from '@utils/util';

export default function MobileKeyInfo() {
    const isMobile = useIsMobile();
    const { balance, userData, setShowShardModal, decimalPlace, menuItems } =
        useGlobalProps();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const tCommon = t('common', { returnObjects: true });

    const { user } = useGlobalProps();
    const tPayment = t('payment', { returnObjects: true });
    const isLogin = !!user?.ukey;

    if (!isMobile || !isLogin) return null;

    const goTo = (r) => () => navigate(r);

    return (
        <MobileKeyInfoWrap
            optionFontSize={
                getOperatingSystem(window) === 'Windows OS' ? '10px' : '10px'
            }
            textMarginBottom={getChromeVersion() === 78 ? '0px' : '-10px'}
            btnMarginLeft={getChromeVersion() === 78 ? '6px' : '0px'}
        >
            <div>
                <p>
                    {tPayment['payment@mobile-balance']}{' '}
                    {displayAmountWithDecimalPlace(balance?.bln, decimalPlace)}
                </p>
                <p>
                    {tPayment['payment@mobile-bonus-balance']}{' '}
                    {displayAmountWithDecimalPlace(
                        userData?.bonus,
                        decimalPlace
                    )}
                </p>
            </div>

            <div className="mobile-quick-btns">
                {/* <button onClick={goTo(SWITCH_ROUTES.DEPOSIT)}>
                    <img alt="mobile-quick-share" src="/images/deposit-btn.png" />
                </button>
                <button onClick={goTo(SWITCH_ROUTES.WITHDRAWAL)}>
                    <img alt="mobile-quick-share" src="/images/withdrawal-btn.png" />
                </button>
                <button onClick={()=>setShowShardModal(true)}>
                    <img alt="mobile-quick-share" src="/images/share-btn.png" />
                </button> */}

                {menuItems.some(item => item.menuName === 'deposit') && (
                    <div
                        className="mobile-quick-btn"
                        onClick={goTo(SWITCH_ROUTES.DEPOSIT)}
                    >
                        <div>
                            <img
                                alt="mobile-quick-share"
                                src="/images/btn-deposit.svg"
                            />
                            <span>{tCommon['mobile@shortcut-deposit']}</span>
                        </div>
                    </div>
                )}

                {menuItems.some(item => item.menuName === 'withdrawal') && (
                    <div
                        className="mobile-quick-btn"
                        onClick={goTo(SWITCH_ROUTES.WITHDRAWAL)}
                    >
                        <div>
                            <img
                                alt="mobile-quick-share"
                                src="/images/btn-withdrawal.svg"
                            />
                            <span>{tCommon['mobile@shortcut-withdraw']}</span>
                        </div>
                    </div>
                )}

                <div
                    className="mobile-quick-btn"
                    onClick={() => setShowShardModal(true)}
                >
                    <div>
                        <img
                            alt="mobile-quick-share"
                            src="/images/btn-share.svg"
                        />
                        <span>{tCommon['mobile@shortcut-share']}</span>
                    </div>
                </div>
            </div>
        </MobileKeyInfoWrap>
    );
}

const MobileKeyInfoWrap = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 8px;
    p {
        margin: 0px;
    }
    div {
        display: flex;
        gap: 10px;
    }
    > div:first-child {
        flex-direction: column;
        gap: 5px;
    }

    .mobile-quick-btns {
        gap: 6px;
        .mobile-quick-btn {
            width: 60px;
            height: 60px;
            border: ${({ theme }) => `1px solid ${theme.primary}`};
            background: ${({ theme }) => theme.primary};
            border-radius: 5px; // Optional, for rounded edges
            margin-left: ${(props) => props.btnMarginLeft};

            > div {
                width: fit-content; /* Add this */
                white-space: nowrap; /* Add this */
                width: 59px;
                height: 59px;
                display: flex;
                flex-direction: column; // Add this
                align-items: center; // To center the child items horizontally
                background: linear-gradient(
                    to bottom,
                    ${({ theme }) => theme.primary},
                    ${({ theme }) => theme.primary}
                ); // Gradient from light red to dark red
                // border: 1px solid white;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2),
                    inset 0px 2px 3px rgba(255, 255, 255, 0.2);
                // border-radius: 5px; // Optional, for rounded edges
                > img {
                    height: 60%;
                    margin-top: 4px;
                    margin-bottom: ${(props) => props.textMarginBottom};
                }
                > span {
                    height: 40%;
                    font-weight: 700;
                    font-size: ${(props) => props.optionFontSize};
                    color: #fff;
                }
            }

            &:hover {
                // Optional, for a press down effect when hovered
                transform: translateY(2px);
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2),
                    inset 0px 1px 2px rgba(255, 255, 255, 0.2);
            }

            &:active {
                // Optional, for a press down effect when active (clicked)
                transform: translateY(4px);
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2),
                    inset 0px 1px 1px rgba(255, 255, 255, 0.2);
            }
        }

        // gap: 20px;
        // button {
        //     box-shadow: none;
        //     padding: 0;
        //     > img {
        //         width: 13vw;
        //     }
        // }
    }
`;
