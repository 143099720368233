import { FONT_SIZE } from '@constants/numbers';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    html {
      box-sizing: border-box;
    }
    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }

    body, #root {
      position: relative;
      display: block;
      height: -webkit-fill-available;
      max-height: -webkit-fill-available;
      width: 100vw;
      overflow: hidden;
      font-size: ${FONT_SIZE.NORMAL};
    }

    .top-marquee {
      // height: 37px;
      font-size: 15px;
      // width: 100vw;
      color: #000;
      // padding: 8px 0px;
      font-weight: 400;

      flex: 1;
      position: relative;
      margin: 0px 15px;
    }

    * {
    // font-family: 'Inter', 'Noto Sans', sans-serif;
    font-family: 'Poppins', sans-serif;
      // Firefox hide scrollbar
      scrollbar-width: none;
    }

    @font-face {
      font-family: 'MonsalGothic-ExtraBoldItalic';
      src: url(/fonts/monsal-gothic-bold-italic.otf) format('woff');
    }

   ::-webkit-scrollbar {
     // hide all scrollbar
      display: none;
    }
    
    html,
    body {
        height: 100%;
        margin: 0;
        line-height: 1.5;
        color: #121212;
    }
    body {
      transition: all 0.50s linear;
      margin: 0px !important;
      overflow: hidden;
    }

    button {
      font-size: 1rem;
      font-family: inherit;
      border: none;
      border-radius: 8px;
      padding: 0.5rem 0.75rem;
      box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.2), 0 1px 2px hsla(0, 0%, 0%, 0.2);
      background-color: white;
      line-height: 1.5;
      margin: 0;
      cursor: pointer;
      color: #3992ff;
      font-weight: 500;
    }
    button:hover {
      box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.6), 0 1px 2px hsla(0, 0%, 0%, 0.2);
    }

    button:active {
      box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.4);
      transform: translateY(1px);
    }

    .ant-table.ant-table-bordered {   
      .ant-table-container,   
      .ant-table-thead > tr > th, 
      .ant-table-tbody > tr > td { 
          border-color: #CBCBCB !important; 
      } 
    }
    .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >tbody>tr>td {
      border-inline-color: #CBCBCB !important; 
    }

    .ant-picker-panel-container {
        button {
            box-shadow: none !important;
        }
    }

    .ant-picker-content {
      > thead {
        > tr {
            th {
              color: #FF9A19 !important;
            }
        }
      }
    }

  .ant-select-item-option-content {       
    font-size: ${FONT_SIZE.NORMAL} !important; 
  }

`;
export default GlobalStyles;
