import { APIS } from "@constants/api";
import { generalFetch } from "@utils/generalFetch";
import { objectToQueryString } from "@utils/util";

export const getGameGroup = (language) => {
    return generalFetch(`${APIS.GAME.GROUPS}?language=${language}`, 'GET');
};

export const getGameListByGroup = (grp) => { //grp from getGameGroup()
    return generalFetch(`${APIS.GAME.GET_GAME_LIST}?grp=${grp}`, 'GET');
};

export const getGameListByListId = (listId) => {
    return generalFetch(`${APIS.GAME.GET_GAMES_BY_LIST}?listId=${listId}`, 'GET');
};

export const getPlayGameUrl = (reuqest) => { //{gid, dvc}  gid: game id, dvc: device token.  after login -> ukey
    return generalFetch(`${APIS.GAME.GET_PLAY_GAME}${objectToQueryString(reuqest)}`, 'GET');
};

export const getBBAGPlayGameUrl = (reuqest) => { //{gid, dvc}  gid: game id, dvc: device token.  after login -> ukey
    return generalFetch(`${APIS.GAME.GET_BAAG_PLAY_GAME}${objectToQueryString(reuqest)}`, 'GET');
};