import { SWITCH_ROUTES } from './routes';

const GW_PROD_DOMAIN = 'gw123.net';
const GW_STG_DOMAIN = 'web-stg.gw123.net';

const AW_PROD_KH_DOMAIN = 'kh.aw123.cc';
const AW_PROD_MM_DOMAIN = 'mm.aw123.cc';
const AW_PROD_VN_DOMAIN = 'vn.aw123.cc';
const AW_PROD_BD_DOMAIN = 'bd.aw123.cc';
const AW_PROD_LA_DOMAIN = 'la.aw123.cc';
const AW_PROD_KE_DOMAIN = 'ke.aw123.cc';
const AW_PROD_NL_DOMAIN = 'nl.aw123.cc';


const AW_STG_KH_DOMAIN = 'kh-stg.aw123.cc';
const AW_STG_MM_DOMAIN = 'mm-stg.aw123.cc';
const AW_STG_VN_DOMAIN = 'vn-stg.aw123.cc';
const AW_STG_BD_DOMAIN = 'bd-stg.aw123.cc';
const AW_STG_LA_DOMAIN = 'la-stg.aw123.cc';
const AW_STG_KE_DOMAIN = 'ke-stg.aw123.cc';
const AW_STG_NL_DOMAIN = 'nl-stg.aw123.cc';

const PECH_PROD_DOMAIN = 'pech.mobi';
const PECH_STG_DOMAIN = 'stg.pech.mobi';

const BITSBET_PROD_DOMAIN = 'bitsbet.co.ke';
const BITSBET_STG_DOMAIN = 'stg.bitsbet.co.ke';

const AW789_PROD_DOMAIN = 'aw789.site';
const CHEAP899_PROD_DOMAIN = 'cheap899.site';
const FISH123_PROD_DOMAIN = 'fish123.site';
const VN345_PROD_DOMAIN = 'vn345.site';
const XYZ567_PROD_DOMAIN = 'xyz567.store';


export const IS_GW_PORD = window.location.hostname === GW_PROD_DOMAIN;
export const IS_GW_STG = window.location.hostname === GW_STG_DOMAIN;
// export const IS_GW_STG = true;

export const IS_LOCAL = window.location.hostname === 'localhost';
// export const IS_LOCAL = false;

export const IS_GW = IS_GW_PORD || IS_GW_STG;

export const IS_BITSBET_PROD = window.location.hostname === BITSBET_PROD_DOMAIN;
export const IS_BITSBET_STG = window.location.hostname === BITSBET_STG_DOMAIN;

export const IS_PECH_PROD = window.location.hostname === PECH_PROD_DOMAIN;
export const IS_PECH_STG = window.location.hostname === PECH_STG_DOMAIN;
// export const IS_PECH_STG = true;

export const IS_AW_KH_PROD = window.location.hostname === AW_PROD_KH_DOMAIN;
export const IS_AW_MM_PROD = window.location.hostname === AW_PROD_MM_DOMAIN;
export const IS_AW_VN_PROD = window.location.hostname === AW_PROD_VN_DOMAIN
    || window.location.hostname === AW789_PROD_DOMAIN
    || window.location.hostname === CHEAP899_PROD_DOMAIN
    || window.location.hostname === FISH123_PROD_DOMAIN
    || window.location.hostname === VN345_PROD_DOMAIN
    || window.location.hostname === XYZ567_PROD_DOMAIN;
// export const IS_AW_VN_PROD = true;



export const IS_AW_BD_PROD = window.location.hostname === AW_PROD_BD_DOMAIN;
export const IS_AW_LA_PROD = window.location.hostname === AW_PROD_LA_DOMAIN;
export const IS_AW_KE_PROD = window.location.hostname === AW_PROD_KE_DOMAIN;
export const IS_AW_NL_PROD = window.location.hostname === AW_PROD_NL_DOMAIN;

export const IS_AW_KH_STG = window.location.hostname === AW_STG_KH_DOMAIN;
// export const IS_AW_KH_STG = true;

export const IS_AW_MM_STG = window.location.hostname === AW_STG_MM_DOMAIN;
// export const IS_AW_MM_STG = true;

export const IS_AW_VN_STG = window.location.hostname === AW_STG_VN_DOMAIN;
// export const IS_AW_VN_STG = true;

export const IS_AW_BD_STG = window.location.hostname === AW_STG_BD_DOMAIN;
// export const IS_AW_BD_STG = true;

export const IS_AW_LA_STG = window.location.hostname === AW_STG_LA_DOMAIN;
// export const IS_AW_LA_STG = true;

export const IS_AW_KE_STG = window.location.hostname === AW_STG_KE_DOMAIN;
// export const IS_AW_KE_STG = true;

export const IS_AW_NL_STG = window.location.hostname === AW_STG_NL_DOMAIN;
// export const IS_AW_NL_STG = true;

export const IS_AW_KH = IS_AW_KH_PROD || IS_AW_KH_STG;
export const IS_AW_MM = IS_AW_MM_PROD || IS_AW_MM_STG;
export const IS_AW_VN = IS_AW_VN_PROD || IS_AW_VN_STG;
export const IS_PECH = IS_PECH_PROD || IS_PECH_STG;
export const IS_AW_BD = IS_AW_BD_PROD || IS_AW_BD_STG;
export const IS_AW_LA = IS_AW_LA_PROD || IS_AW_LA_STG;
export const IS_AW_KE = IS_AW_KE_PROD || IS_AW_KE_STG;
export const IS_AW_NL = IS_AW_NL_PROD || IS_AW_NL_STG;
export const IS_BITSBET = IS_BITSBET_PROD || IS_BITSBET_STG;

export const IS_KH = IS_AW_KH || IS_PECH;


export function shouldDisplayBalanceError() {

    if (IS_GW_STG
        || IS_AW_BD_STG
        || IS_AW_KH_STG
        || IS_AW_LA_STG
        || IS_AW_MM_STG
        || IS_AW_NL_STG
        || IS_AW_VN_STG
        || IS_AW_KE_STG
        || IS_PECH_STG
        || IS_BITSBET_STG) return true;

    return false;
}

export const TransactionLogStatusEnum = {
    WAITING: 0,
    COMPLETED: 8,
    FAILED: 2,
};

export const UserRoleEnum = {
    TRIAL: 0,
    L1: 1,
    L2: 2,
    L3: 3,
};

const MenuNameEnum = {
    HOME: 'home',
    DEPOSIT: 'deposit',
    WITHDRAWAL: 'withdrawal',
    TRANSFER: 'transfer',
    BANKACCOUNT: 'bankaccount',
    TRANSACTION_LOG: 'transaction-log',
    BET_LOG: 'bet-log',
    AFFILIATE_LOG: 'affiliate-log',
    PROFILE: 'profile',
    // Add other menu names as needed
};

export const CommonMenuItems = {
    [MenuNameEnum.HOME]: {
        menuName: 'home',
        icon: 'home',
        path: '/',
    },
    [MenuNameEnum.DEPOSIT]: {
        menuName: 'deposit',
        icon: 'deposit',
        path: SWITCH_ROUTES.DEPOSIT,
        needLogin: true,
        menuNamePrefix: getBankingPrefix(),
    },
    [MenuNameEnum.WITHDRAWAL]: {
        menuName: 'withdrawal',
        icon: 'withdrawal',
        path: SWITCH_ROUTES.WITHDRAWAL,
        needLogin: true,
        menuNamePrefix: getBankingPrefix(),
    },
    [MenuNameEnum.TRANSFER]: {
        menuName: 'transfer',
        icon: 'transfer',
        path: SWITCH_ROUTES.TRANSFER,
        needLogin: true,
    },
    [MenuNameEnum.BANKACCOUNT]: {
        menuName: 'bankaccount',
        icon: 'bankaccount',
        path: SWITCH_ROUTES.BANKACCOUNT,
        needLogin: true,
    },
    [MenuNameEnum.TRANSACTION_LOG]: {
        menuName: 'transaction-log',
        icon: 'transaction-log',
        path: SWITCH_ROUTES.TRANSATION_LOG,
        needLogin: true,
    },
    [MenuNameEnum.BET_LOG]: {
        menuName: 'bet-log',
        icon: 'bet-log',
        path: SWITCH_ROUTES.BET_LOG,
        needLogin: true,
    },
    [MenuNameEnum.AFFILIATE_LOG]: {
        menuName: 'affiliate-log',
        icon: 'affiliate-log',
        path: SWITCH_ROUTES.AFFILIATE_LOG,
        needLogin: true,
    },
    [MenuNameEnum.PROFILE]: {
        menuName: 'profile',
        icon: 'password',
        path: SWITCH_ROUTES.PROFILE,
        needLogin: true,
    },
};

function getMenuItems() {
    if (IS_GW || IS_LOCAL) {
        const orderedKeys = Object.values(MenuNameEnum);
        const orderedMenuItemsArray = orderedKeys.map(
            (key) => CommonMenuItems[key]
        );

        return orderedMenuItemsArray;
    } else {
        const orderedKeys = Object.values(MenuNameEnum);

        // Filter out the keys for the items to be excluded
        let filteredKeys = [];

        if (IS_KH || IS_AW_VN_STG || IS_AW_MM_STG) {
            filteredKeys = orderedKeys.filter(
                (key) => ![MenuNameEnum.BANKACCOUNT].includes(key)
            );
        } else {
            filteredKeys = orderedKeys.filter(
                (key) =>
                    ![
                        MenuNameEnum.DEPOSIT,
                        MenuNameEnum.WITHDRAWAL,
                        MenuNameEnum.BANKACCOUNT,
                    ].includes(key)
            );
        }

        const filteredMenuItemsArray = filteredKeys.map(
            (key) => CommonMenuItems[key]
        );


        return filteredMenuItemsArray;
    }
}

export const MENU_ITEMS = getMenuItems();


export const MENU_ITEM_HOME = {
    menuName: 'home',
    icon: 'home',
    path: '/',
};

export const MENU_ITEM_LOGOUT = {
    menuName: 'logout',
    icon: 'logout',
    path: SWITCH_ROUTES.LOGOUT,
};
export const NO_BALANCE_ROUTES = [
    SWITCH_ROUTES.REGISTER,
    SWITCH_ROUTES.LIVE_CHAT,
    SWITCH_ROUTES.LOGOUT,
];

export const FlexDirectionConfigs = {
    FlexStart: 'flex-start',
    FlexEnd: 'flex-end',
    Center: 'center',
};

export const LOCALS = {
    cn: 'cn',
    en: 'en',
    bahasa: 'bahasa',
    vietnamese: 'vietnamese',
    khmer: 'khmer',
    myanmar: 'myanmar',
    bengali: 'bengali',
    laotian: 'laotian',
    thai: 'thai',
    nederland: 'nederland'
};

export const LOCALS_IGS_CODE_MAP = {
    cn: 'zh-cn',
    en: 'en-us',
    bahasa: 'ms-my',
    vietnamese: 'vi-vn',
    khmer: 'en-us',
    myanmar: 'my-mm',
    bengali: 'en-us',
    laotian: 'en-us',
    thai: 'th-th',
    nederland: 'en-us'
};

function getLanguageSelections() {

    const baseLangs = [
        { label: '简体中文', value: LOCALS.cn },
        { label: 'English', value: LOCALS.en },
    ];
    if (IS_LOCAL) {
        return [
            ...baseLangs,
            { label: 'Bahasa', value: LOCALS.bahasa },
            { label: 'ភាសាខ្មែរ', value: LOCALS.khmer },
            { label: 'မြန်မာဘာသာ', value: LOCALS.myanmar },
            { label: 'Tiếng Việt', value: LOCALS.vietnamese },
            { label: 'বাংলা', value: LOCALS.bengali },
            { label: 'ອັກສອນລາວ', value: LOCALS.laotian },
            { label: 'ภาษาไทย', value: LOCALS.thai },
            { label: 'Nederland', value: LOCALS.nederland },
        ];
    } else if (IS_GW) {
        return [...baseLangs, { label: 'Bahasa', value: LOCALS.bahasa }];
    } else if (IS_KH || IS_PECH) {
        return [...baseLangs, { label: 'ភាសាខ្មែរ', value: LOCALS.khmer }];
    } else if (IS_AW_MM) {
        return [...baseLangs, { label: 'မြန်မာဘာသာ', value: LOCALS.myanmar }];
    } else if (IS_AW_VN) {
        return [
            { label: 'Tiếng Việt', value: LOCALS.vietnamese },
            ...baseLangs,
        ];
    } else if (IS_AW_BD) {
        return [...baseLangs, { label: 'বাংলা', value: LOCALS.bengali }];
    } else if (IS_AW_LA) {
        return [
            ...baseLangs,
            { label: 'ອັກສອນລາວ', value: LOCALS.laotian },
            { label: 'ภาษาไทย', value: LOCALS.thai },
        ];
    } else if (IS_AW_NL) {
        return [
            ...baseLangs,
            { label: 'Nederland', value: LOCALS.nederland },
        ];
    }


    return baseLangs;
}

export const LOCAL_SELECTIONS = getLanguageSelections();

function getMetaTitle() {
    if (IS_GW) {
        return 'GW123';
    } else if (IS_PECH) {
        return 'PECH';
    } else if (IS_AW_KH) {
        return 'AW123';
    } else if (IS_AW_MM) {
        return 'AW123';
    } else if (IS_AW_VN) {
        return 'AW123';
    } else if (IS_AW_BD) {
        return 'AW123';
    } else if (IS_AW_LA) {
        return 'AW123';
    } else if (IS_AW_KE) {
        return 'AW123';
    } else if (IS_AW_NL) {
        return 'AW123';
    } else if (IS_BITSBET) {
        return 'BITSBET';
    }

    return 'GW123';
}

export const META_TITLE = getMetaTitle();

function getMetaDescription() {
    if (IS_GW) {
        return 'The Best Online Game Platform Malaysia';
    } else if (IS_KH || IS_PECH) {
        return 'The Best Online Game Platform Combodia';
    } else if (IS_AW_MM) {
        return 'The Best Online Game Platform Myanmar';
    } else if (IS_AW_VN) {
        return 'The Best Online Game Platform Vietnam';
    } else if (IS_AW_BD) {
        return 'The Best Online Game Platform in Bangladesh';
    } else if (IS_AW_LA) {
        return 'The Best Online Game Platform in Laos';
    } else if (IS_AW_KE || IS_BITSBET) {
        return 'The Best Online Game Platform in Kenya';
    } else if (IS_AW_NL) {
        return 'The Best Online Game Platform in Nederland';
    }

    return 'The Best Online Game Platform Malaysia';
}

export const META_DESCRIPTION = getMetaDescription();

function getMaintenanceText() {
    if (IS_GW) {
        return 'Notis Penyelenggaraan Sistem\nLaman Web Rasmi Ditangguhkan Sementara';
    } else if (IS_KH) {
        return 'សេវាកម្មថ្មីប្រកាសពិនិត្យប្រភេទប្រព័ន្ធបណ្ដាញ\nការចូលទៅកាន់គេហទំព័រក្រៅវិស្វកររបស់យើងមិនមានទេ';
    } else if (IS_AW_MM) {
        return 'စနစ်ထိန်းသိမ်းမှုသတိပေးချက်\nတရားဝင် ဝဘ်ဆိုဒ်ကို ယာယီ မရနိုင်ပါ။';
    } else if (IS_AW_VN) {
        return 'Thông báo bảo trì hệ thống\nTrang web chính thức tạm ngừng sử dụng';
    }

    return 'Notis Penyelenggaraan Sistem\nLaman Web Rasmi Ditangguhkan Sementara';
}

export const MAINTENANCE_TEXT = getMaintenanceText();

function getMetaPrefix() {
    const prefix = window.location.hostname.split('.')[0];

    return prefix + '_';
}

export const META_PREFIX = getMetaPrefix();

function getBankingPrefix() {
    if (IS_AW_KH) {
        return '';
    } else if (IS_AW_VN) {
        return '';
    } else {
        return '';
    }
}


export const BANK_METAS_INFO = {
    MOMO: {
        name: 'MOMO',
        imageUrl: '/images/payments/momo.jpg'

    },
    ZALO: {
        name: 'ZALO',
        imageUrl: '/images/payments/zalo.jpg'

    },
    ABA: {
        name: 'ABA',
        imageUrl: '/images/payments/aba.jpg'

    },
    WING: {
        name: 'WING',
        imageUrl: '/images/payments/wing.jpg',
        // imageWidth: '240px'
    },
    KBZ: {
        name: 'KBZ',
        imageUrl: '/images/payments/kbz.jpg'

    },
    WAVE: {
        name: 'WAVE',
        imageUrl: '/images/payments/wave.jpg'

    },
    AYA: {
        name: 'AYA',
        imageUrl: '/images/payments/aya.jpg'

    },
    bKash: {
        name: 'bKash',
        imageUrl: '/images/payments/bkash.jpg'

    },
    Nagad: {
        name: 'Nagad',
        imageUrl: '/images/payments/nagad.png'

    },
    BCEL: {
        name: 'BCEL',
        imageUrl: '/images/payments/bcel.jpg'

    },
    MPESA: {
        name: 'MPESA',
        imageUrl: '/images/payments/mpesa.jpg'

    },
    MYBK01: {
        name: 'MYBK01',
        imageUrl: '/images/payments/MYBK01.jpg'
    },
    MYBK02: {
        name: 'MYBK02',
        imageUrl: '/images/payments/MYBK02.png',
        imageWidth: '60px'
    },
    MYBK03: {
        name: 'MYBK03',
        imageUrl: '/images/payments/MYBK03.png',
        imageWidth: '60px'
    },
    MYBK04: {
        name: 'MYBK04',
        imageUrl: '/images/payments/MYBK04.png',
        imageWidth: '60px'
    },
    MYBK05: {
        name: 'MYBK05',
        imageUrl: '/images/payments/MYBK05.png',
        imageWidth: '60px'
    }

}